import { Component, Input } from '@angular/core';

@Component({
    selector: 'hvac-alert-chip',
    templateUrl: './alert-chip.component.html',
    styleUrls: ['./alert-chip.component.scss']
})
export class AlertChipComponent {
    @Input() alertType: 'critical' | 'moderate' | 'informational';
}
