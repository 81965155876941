import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity, ContentModelContent } from 'common/models';

@Component({
    selector: 'hvac-highlight-section',
    templateUrl: './highlight-content-section.component.html',
    styleUrls: ['./highlight-content-section.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HighlightContentSectionComponent implements OnInit {
    @Input() data: BaseContentEntity;
    @Input() fullWidth: boolean;
    mediaHighlights: ContentModelContent[];
    linkOnlyHighlights: BaseContentEntity[];
    buttonData: string;
    linkText: string;

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.mediaHighlights = this.data?.Content.list.$values ? this.data.Content.list.$values.filter((item: any) => Boolean(item.Content.primaryMedia)) : [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.linkOnlyHighlights = this.data?.Content.list.$values ? this.data.Content.list.$values.filter((item: any) => !item.Content.primaryMedia) : [];
        if (this.data?.Content.links?.linkText) {
            this.buttonData = this.data.Content.links;
            this.linkText = this.data.Content.links.linkText;
        }
    }
}
