import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { environment } from 'common/environments/environment';
import { Store } from '@ngxs/store';

@Component({
    selector: 'hvac-dealer-dropdown-menu',
    templateUrl: './dropdown-dealer-locator-menu.component.html',
    styleUrls: ['../dropdown-styles.component.scss', './dropdown-dealer-locator-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DealerDropdownMenuComponent {
    @Input() iconSrc: string;
    @Input() menuActive = false;
    @Output() openMenuEmitter: EventEmitter<boolean> = new EventEmitter();
    zipCodeSearchInput = new UntypedFormControl('');
    showClear = false;
    public isTotaline = environment.brand === 'Totaline';
    constructor(
        private readonly store: Store
    ) { }

    ngOnInit() {
        this.zipCodeSearchInput.valueChanges.subscribe((value) => {
            this.showClear = value.length > 1;
        });
    }

    toggleMenu() {
        this.openMenuEmitter.emit(!this.menuActive);
    }

    onSearch() {
        this.toggleMenu();
        const query = { q: this.zipCodeSearchInput.value };
        this.store.dispatch(new Navigate(['/distributor-locator'], query));
    }

    onCurrentLocationClick() {
        this.toggleMenu();
        const query = { q: 'location' };
        this.store.dispatch(new Navigate(['/distributor-locator'], query));
    }

    clearSearch() {
        this.zipCodeSearchInput.setValue('');
    }
}
