import { Component } from '@angular/core';

@Component({
    selector: 'hvac-product-property-list-item',
    templateUrl: './product-property-list-item.component.html',
    styleUrls: ['./product-property-list-item.component.scss'],
    host: { class: 'hvac-product-property-list-item' },
    standalone: true
})
export class ProductPropertyListItemComponent {}
