import { Component, Input, ViewEncapsulation } from '@angular/core';

import { RefType } from 'common/components/link/link.component';

export interface RichContentCardText {
    type: string,
    Fragments: string[]
}

@Component({
    selector: 'hvac-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentCardComponent {
    @Input() cardContent: {
        body: string;
        imgSrc: string;
        link: string;
        target: string;
        title: string;
    };

    cardLinkType: RefType;
    maxBodyLength = 117;
    ngOnInit() {
        this.cardLinkType = this.cardContent.target === '_self' ? RefType.routerLink : RefType.href;
        const body = this.cardContent.body as unknown as RichContentCardText;
        if (body && body.Fragments) {
            const bodyVal = body.Fragments[0] as string;
            this.maxBodyLength = bodyVal.includes('<img') ? 320 : 117;
        }
    }
}
