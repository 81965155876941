import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export type InputType = 'number' | 'text' | 'date' | 'email' | 'tel' | 'password' | undefined;
type IconPosition = 'left' | 'right';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;


@Component({
    selector: 'utc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InputComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() label: string;
    @Input() placeholder?: string;
    @Input() type?: InputType;
    @Input('value') _value?: string;
    @Input() min?: number;
    @Input() step?: number;
    @Input() hideLabel = false;
    @Input() disabled = false;
    @Input() ariaLabel?: string;
    @Input() required = false;
    // TODO: refactor forms
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() inputControl: any;
    @Input() labelIcon?: string;
    @Input() iconLabel?: string;
    @Input() iconPosition?: IconPosition;
    @Input() infoMessage: string;
    @Input() errorMessage: string | null;
    @Input() error: boolean;
    @Input() hasIconFunction: boolean;
    @Input() disableInputIcon?: boolean;
    @Input() disabledInput?: boolean;
    @Input() shadow: 'light' | 'default' | 'none' = 'default';
    @Input() size: 'default' | 'small' = 'default';
    @Input() mask?: boolean = false;

    @Output() enterKeyFunction = new EventEmitter<string>();
    @Output() keystrokeFunction = new EventEmitter<Event>();
    @Output() blurFunction = new EventEmitter<string>();
    @Output() focusFunction = new EventEmitter<Event>();
    @Output() iconClickFunction = new EventEmitter<string>();

    @ViewChild('input') input: ElementRef;
    onChange = noop;
    onTouched = noop;
    isFocused = false;
    className: string;
    minimizeLabel: boolean;
    maxLength = 524288
    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.type = 'text';
        this.inputId = '';
        this.label = '';
        this.placeholder = '';
        this._value = '';
        this.className = '';
    }

    get max(): number | undefined {
        return this.maxLength;
    }

    get value(): string | undefined {
        return this._value;
    }

    @Input()
    set max(val: number | undefined) {
        this.maxLength = val || 524288;
    }

    set value(val: string | undefined) {
        this._value = val;
        this.minimizeLabel = this._value !== '';
    }

    @HostListener('keydown', ['$event'])
    public keyListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code.toLowerCase() === 'enter') {
            this.enterKeyFunction.emit(this.input.nativeElement.value);
        }
        else {
            this.keystrokeFunction.emit(this.input.nativeElement.value);
        }
        this.minimizeLabel = this._value !== '';

        this.cdr.detectChanges();
    }

    getNativeInputType(type: InputType) {
        return type;
    }

    ngOnInit() {
        this.disabled = this.inputControl?.disabled;
        this.inputControl.registerOnDisabledChange((val: boolean) => {
            this.disabled = val;
        });

        this.className = `hvac-input hvac-input-${this.type}`;
        // eslint-disable-next-line no-self-assign
        this.placeholder = this.placeholder;
        this.writeValue(this.inputControl.value);
        this.inputControl.valueChanges.subscribe((value: string) => {
            this.writeValue(value);
            this.minimizeLabel = this._value !== '';
        });
        if (this.mask && this.inputControl.value) {
            this.maxLength = 14;
            const maskedNumber = this.inputControl.value.toString().match(/(\d{3})(\d{3})(\d{4})/);
            this.inputControl.value = `(${maskedNumber![1]}) ${maskedNumber![2]}-${maskedNumber![3]}`;
        }
    }

    ngOnChanges() {
        if (this.mask) {
            const phoneInput = document.getElementById(`_${this.inputId}`);
            if (phoneInput) {
                phoneInput.addEventListener('input', (elem) => {
                    const x = (elem.target as HTMLInputElement).value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    (x) ? (elem.target as HTMLInputElement).value = x[2] ? `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}` : x[1] : '';
                });
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    inputBlur() {
        this.isFocused = false;
        this.blurFunction.emit(this.input.nativeElement.value);
    }

    inputFocus() {
        this.isFocused = true;
        this.focusFunction.emit(this.input.nativeElement.value);
    }

    inputButtonClick() {
        this.iconClickFunction.emit(this.input.nativeElement.value);
    }

    writeValue(val: string) {
        this._value = val;
        this.minimizeLabel = this._value !== '';
    }
}
