import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'hvac-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnDestroy {
    ngOnDestroy$ = new Subject();

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
