<ul class="hvac-browse-options hvac-browse-products" *ngIf="(products$ | async)?.length; else noProducts">
        <li class="hvac-browse-category" *ngFor="let product of products$ | async">
            <hvac-browse-product-tile [product]="product"></hvac-browse-product-tile>
        </li>
</ul>
<ng-template #noProducts>
    <ng-template [ngIf]="!noActiveProducts" [ngIfElse]="noActiveMessage">
        <utc-loading-spinner  class="hvac-browse-products-loading"></utc-loading-spinner>
    </ng-template>
    <ng-template #noActiveMessage>
        <div class="hvac-product-content-empty">
            <p class="hvac-product-content-empty-message">{{ 'CATALOG.NO_ACTIVE_PRODUCTS_MESSAGE' | translate }}</p>
        </div>
    </ng-template>
</ng-template>
