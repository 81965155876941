<div class="date-picker-container">
    <span *ngIf="size !== 'small' && label" class="date-picker-label">
        {{ label }}<abbr *ngIf="required" title="required" class="hvac-input-label-required">{{ required ? '*' : ''}}</abbr>
    </span>
    <div class="hvac-input-container hvac-input-date-picker" [ngClass]="{
        'hvac-input-small': size === 'small',
        'hvac-input-no-shadow': !!shadow,
        'hvac-input-light-shadow': !!lightShadow,
        'utc-disabled': disabled,
        'hvac-input-readonly': readonly,
        'hvac-input-min-label': minimizeLabel,
        'hvac-input-has-value': minimizeLabel
    }">
        <input class="hvac-input"
            id="_{{ inputId }}"
            name="_{{ inputId }}"
            type="date"
            [formControl]="formControl"
            [min]="min"
            [max]="max"
            [(ngModel)]="value"
            [attr.disabled]="disabled ? '' : null"
            [attr.required]="required || null"
            [readonly]="readonly"
        />
    </div>
</div>
