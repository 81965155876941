import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface JumpBarLink {
    id: string[];
    link: string;
    container: HTMLElement;
}

@Injectable({ providedIn: 'root' })
export class JumpbarService {
    jumpbarLinks$;
    jumpBarIsEmpty = new BehaviorSubject<Boolean>(true);
    jumpBarIsEmpty$ = this.jumpBarIsEmpty.asObservable();

    private jumpbarSubject = new BehaviorSubject<JumpBarLink[]>([] as JumpBarLink[]);

    constructor() {
        this.jumpbarLinks$ = this.jumpbarSubject.asObservable();
    }

    registerJumpBarLink(jumpLink: JumpBarLink) {
        this.jumpbarSubject.next(
            [...this.jumpbarSubject.value, jumpLink]
        );
    }

    deregisterJumpBarLink(removeLink: JumpBarLink) {
        if (removeLink) {
            const updatedLinks = this.jumpbarSubject.value
                .filter((jumpLink) => jumpLink.id.join('|') === removeLink.id.join('|'));
            this.jumpbarSubject.next(updatedLinks);
        }
    }

    setJumpBarLink(jumpLinks: JumpBarLink[]) {
        this.jumpbarSubject.next(jumpLinks);
    }

    clearJumpBarData() {
        this.jumpbarSubject.next([]);
        this.setEmptyJumpBar(true);
    }

    setEmptyJumpBar(isEmpty: boolean) {
        return this.jumpBarIsEmpty.next(isEmpty);
    }
}
