import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Document } from 'common/models';
import { DOCUMENT_RESOURCE_TYPE } from 'common/models/document';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { buildRepository } from '../repository/utils';
import { AppendSearchResults, ReceiveSearchResults } from '../search/search.actions';

export interface DocumentsStateModel {
    repository: ResourceRepositoryModel<Document>;
}

@State<DocumentsStateModel>({
    name: 'documents',
    defaults: { repository: {} }
})
@Injectable({ providedIn: 'root' })
export class DocumentsState {
    @Selector()
    static repository({ repository }: DocumentsStateModel): ResourceRepositoryModel<Document> {
        return repository;
    }

    @Action([ReceiveSearchResults, AppendSearchResults])
    receiveSearchResults(ctx: StateContext<DocumentsStateModel>, action: ReceiveSearchResults|AppendSearchResults) {
        const documents = action.results.documents || { results: [] };
        const documentResults: Document[] = documents.results;
        const repository = {
            ...ctx.getState().repository,
            ...buildRepository<Document, 'id'>(documentResults, 'id', DOCUMENT_RESOURCE_TYPE)
        };

        return ctx.patchState({ repository });
    }
}
