<div>
    <div class="hvac-input-container" [ngClass]="{
        'hvac-input-min-label': minimizeLabel,
        'hvac-input-error':error,
        'hvac-input-focused': isFocused,
        'hvac-input-icon-left': iconPosition === 'left',
        'hvac-input-icon-right': iconPosition === 'right',
        'hvac-input-small': size === 'small',
        'hvac-input-no-shadow': shadow === 'none',
        'hvac-input-light-shadow': shadow === 'light',
        'utc-disabled': disabled
    }">
        <input class="hvac-input"
            [className]="className"
            id="_{{ inputId }}"
            name="_{{ inputId }}"
            [type]="getNativeInputType(type)"
            [value]="_value"
            [min]="min"
            [max]="max"
            [step]="step"
            [attr.aria-label]="ariaLabel"
            [formControl]="inputControl"
            #input (blur)="inputBlur()"
            (focus)="inputFocus()"
            [placeholder]="placeholder"
            [attr.disabled]="disabledInput ? '' : null"
            [attr.required]="required || null"
            [maxLength]="maxLength"
        />

        <utc-svg class='hvac-input-icon' *ngIf="labelIcon && !hasIconFunction" [src]="labelIcon"></utc-svg>

        <button [disabled]="disableInputIcon" class='hvac-input-icon' *ngIf="labelIcon && hasIconFunction" (click)="inputButtonClick()">
            <utc-svg [src]="labelIcon"></utc-svg>
            <span class="hvac-accessible-text">{{ iconLabel || label }}</span>
        </button>

        <label class="hvac-input-label" [ngClass]="{ 'hvac-label-hidden': hideLabel }" for="_{{ inputId }}">
            {{ label }}<abbr *ngIf="required" title="required" class="hvac-input-label-required">{{ required ? '*' : ''}}</abbr>
        </label>

    </div>
    <div *ngIf="(errorMessage && error) || infoMessage" class='hvac-input-message-container hvac-input-show-message'>
        <div *ngIf="error && errorMessage">
            <utc-svg src="assets/icons/info.svg"></utc-svg>
            <span class='hvac-input-message' [innerHTML]="errorMessage | safe:'html'"></span>
        </div>
        <div *ngIf="infoMessage">
            <span class='hvac-input-message' [innerHTML]="infoMessage | safe:'html'"></span>
        </div>
    </div>
</div>
