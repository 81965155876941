import { Injectable } from '@angular/core';

const CATEGORIES: { urn: string, icon: string }[] = require('./browse-categories-icons.json');
const defaultCategory = CATEGORIES.find((category) => category.urn === 'default') || {
    urn: '',
    icon: ''
};

@Injectable({ providedIn: 'root' })
export class BrowseCategoriesIconsService {
    getCategoryIcon(urn: string): string {
        const selectedCategory = CATEGORIES.find((category) => category.urn === urn);

        return selectedCategory ? selectedCategory.icon : defaultCategory.icon;
    }
}
