import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TrackerService } from 'common/services/analytics/services/tracker.service';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, delay, filter, map } from 'rxjs/operators';

@Component({
    selector: 'utc-download-link',
    templateUrl: './download-link.component.html',
    styleUrls: ['./download-link.component.scss', '../button/button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DownloadLinkComponent {
    @Input() url = '';

    showSuccess$ = new BehaviorSubject<boolean>(false);
    hideSuccess$ = this.showSuccess$.pipe(
        filter((show) => show),
        debounceTime(1000),
        delay(1000),
        map(() => false)
    );

    successToggle$ = merge(this.showSuccess$, this.hideSuccess$);

    constructor(private readonly tracker: TrackerService) {}

    onClick() {
        setTimeout(() => {
            this.showSuccess$.next(true);
            this.tracker.captureEvent('PublicDocDownload', { docUrl: this.url });
        }, 0);
    }
}
