import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, shareReplay, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SvgLoaderService {
    private readonly cache = new Map<string, string>();
    private readonly inProgressReqs = new Map<string, Observable<string>>();

    constructor(private readonly httpClient: HttpClient) {}

    loadSvg(url: string) {
        if (this.cache.get(url)) {
            return of(this.cache.get(url) as string);
        }

        if (this.inProgressReqs.get(url)) {
            return this.inProgressReqs.get(url) as Observable<string>;
        }

        const request = this.httpClient
            .get(url, { responseType: 'text' })
            .pipe(
                shareReplay(1),
                tap((resp) => {
                    this.cache.set(url, resp);
                }),
                finalize(() => {
                    this.inProgressReqs.delete(url);
                })
            );
        this.inProgressReqs.set(url, request);

        return request;
    }
}
