import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export interface Option {
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

@Component({
    selector: 'hvac-options-menu',
    templateUrl: './options-menu.component.html',
    styleUrls: ['../dropdown-styles.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OptionsMenuComponent {
    @Input() menuOptions: Option[] = [];
    @Input() selectedOption: Option | null = null;
    @Output() change = new EventEmitter<Option>();
    @Input() describedBy: string;
    @Input() hintText: string = '';
    @Output() openMenuEmitter: EventEmitter<boolean> = new EventEmitter();


    @Input() menuActive = false;

    toggleMenu() {
        this.menuActive = !this.menuActive;
        this.openMenuEmitter.emit(this.menuActive);
    }

    setOption(option: Option) {
        this.menuActive = false;

        if (!this.selectedOption || option.value !== this.selectedOption.value) {
            this.selectedOption = option;
            this.change.emit(option);
        }
    }
}
