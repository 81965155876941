import { ResourceRepositoryModel } from './repository.model';

export const buildRepository = <T, K extends keyof T>(resources: T[], key: K, resourceType: string): ResourceRepositoryModel<T> => resources?.reduce(
    (repo, resource) => ({
        ...repo,
        [`${resource[key]}`]: {
            ...resource,
            resourceType
        }
    }),
    {}
);

