export enum BrandFamily {
    CBP = 'cbp',
    CMNA = 'cmna',
    ICP = 'icp',
    GENERIC = 'generic',
    TOTALINE = 'totaline'
}

export enum UserType {
    INTERNAL = 'Internal'
}

export type BrandFamilyType = `${BrandFamily}`

export interface Brand {
    brandName: string;
    imageUrl?: string;
    brandFamily: BrandFamily;
    themedBrand?: boolean;
}
export interface CatalogBrand {
    code: string;
    name: string;
}

export interface BrandList {
    brandName: string;
    brandCode: string;
    selected?: boolean;
    catalogBrands?: CatalogBrand[];
}
