import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { ToastService } from 'common/services/toast.service';
import { WINDOW } from 'common/window.provider';
import { combineLatest, Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { LocalStorageService } from '../../../../common/services/localStorage/localStorage.service';
import { DisableFooterSignUp } from '../../../../common/store/ui/ui.actions';
import { UiState } from '../../../../common/store/ui/ui.state';
import { EmailValidator, getErrorMessage, NewsLetterParam, SignUpNewsLetterService } from './service/signup-newsletter.service';
const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: 'hvac-signup-newsletter',
    templateUrl: './signup-newsletter.component.html',
    styleUrls: ['./signup-newsletter.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SignUpNewsLetterComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Select(UiState.newsLetterSignUp) newsLetterSignUp$: Observable<any>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    distributorOptionData: any[] = [];
    showEmailSignUp = environment.features.newsLetterSignUp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    distributorId: any
    emailSignUpStatus: string | null;
    emailValidationControl = new UntypedFormControl('', [EmailValidator(101, true, validEmailRegex)]);

    errorMessages$ = this.translate.get('CREATE_ACCOUNT_EMAIL.ERROR_MESSAGES');
    emailErrorMessage$ = combineLatest([this.emailValidationControl.statusChanges, this.errorMessages$]).pipe(
        map(([valid, message]) => (valid ? getErrorMessage(this.emailValidationControl.errors, message) : ''))
    );

    constructor(
        private readonly signUpNewsLetterService: SignUpNewsLetterService,
        private readonly localStorageService: LocalStorageService,
        private readonly store: Store,
        private translate: TranslateService,
        private toastService: ToastService,
        @Inject(WINDOW) private readonly window: Window
    ) {}

    ngOnInit(): void {
        this.emailSignUpStatus = this.localStorageService.isEmailSignUpCompleted();
        this.signUpNewsLetterService.fetchDistributorData().subscribe((distributorData) => {
            if (Array.isArray(distributorData)) {
                distributorData?.forEach((distributor: {Name:string, ID:string}) => {
                    this.distributorOptionData.push(
                        {
                            title: distributor.Name,
                            value: distributor.ID
                        }
                    );
                });
            }
        });

        this.newsLetterSignUp$.pipe(
            skip(1)
        ).subscribe((newsLetterSKip) => {
            this.window.scrollTo(0, 0);
            this.localStorageService.setEmailSignUp(!newsLetterSKip.ui);
            this.emailSignUpStatus = this.localStorageService.isEmailSignUpCompleted();
        });
    }

    filterQuery(selection: {value:string}) {
        this.distributorId = selection.value;
    }

    onEmailSubscription() {
        if (this.emailValidationControl.valid && this.distributorId) {
            this.signUpNewsLetterService.updatenewsletterSubscription({
                email: this.emailValidationControl.value,
                distributor: this.distributorId
            } as NewsLetterParam).subscribe((_data: string) => {
                this.localStorageService.hideSignup(true);
                this.localStorageService.setEmailSignUp(true);
                this.emailSignUpStatus = this.localStorageService.isEmailSignUpCompleted();

                this.store.dispatch(new DisableFooterSignUp(true));

                this.toastService.add({
                    content: this.translate.instant('SIGNUP_NEWSLETTER.EMAIL_SUBSCRIPTION_SUCCESS'),
                    theme: 'success',
                    id: 'EMAIL_SUBSCRIPTION_SUCCESS',
                    closeable: true,
                    autoClose: true
                });
            });
        }
    }

    closeSignUpNewsletter() {
        this.localStorageService.setEmailSignUp(true);
        this.emailSignUpStatus = this.localStorageService.isEmailSignUpCompleted();
    }
}
