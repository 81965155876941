import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuLinkDetails } from 'common/models';
import { LinkType } from '../../../components/link/link.component';


@Component({
    selector: 'utc-content-list-item',
    templateUrl: './content-list-item.component.html',
    styleUrls: ['./content-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentListItemComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() item: any;
    imageLink: boolean;
    buttonLink: boolean;
    linkType: LinkType = LinkType.Button;
    links: MenuLinkDetails;
    content: MenuLinkDetails;

    ngOnInit() {
        this.content = this.item.Content;
        this.links = this.content.links;
        this.imageLink = this.hasLink() && !this.content.links.linkText;
        this.buttonLink = this.hasLink() && this.content.links.linkText;
    }

    hasLink(): boolean {
        const links: MenuLinkDetails[] = [].concat(this.item.Content.links);

        return links.some((link: MenuLinkDetails) => Boolean(link.linkExternal || link.linkInternalParameter));
    }
}
