import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterStateModel } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'utc-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeroComponent implements OnDestroy {
    ngOnDestroy$ = new Subject();

    public isLandingPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(
                router.state && (
                    router.state.url.includes('/products') || router.state.url.includes('/marketing') || router.state.url.includes('/learning')
                ) && !router.state.url.includes('detail') && !router.state.url.includes('search') && !router.state.url.includes('parts-catalog')
            ))
        );

    public isSearchPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('search')))
        );

    public isDetailPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('detail')))
        );

    public isCovidPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('covid')))
        );

    constructor(private readonly store: Store) {}


    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
