import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';
import { PagePositionService, ViewportState } from 'common/services/page-position.service';
import { UrlService } from 'common/services/url.service';
import { ContentState } from 'common/store/content/content.state';
import { WINDOW } from 'common/window.provider';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface JumpBarLinkToShow {
    title: string;
    link: string;
    container: HTMLElement;
}

interface ScrollToLocation {
    offset: number;
    id: string;
}

@Component({
    selector: 'hvac-content-jump-bar',
    templateUrl: './jump-bar.component.html',
    styleUrls: ['./jump-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class JumpbarComponent implements OnInit, OnDestroy, OnChanges {
    @Input() stickyHeader?: boolean = true;
    @Input() possibleJumpLinks: JumpBarLink[] | string[] = [];
    @ViewChild('scrollList', { static: false }) scrollList: ElementRef;
    @Select(ContentState.contentLoading) contentLoading$: Observable<boolean>;

    ngOnDestroy$ = new Subject();
    jumpLinkIds: { [key: string]: string } = {};
    links = new BehaviorSubject<JumpBarLinkToShow[]>([]);

    scrollPoint: number;
    headerHeight = 0;
    jumpbarHeight = 0;
    subnavHeight = 0;
    defaultSubnavHeight = 62;
    activeHighlightCushion = 10;
    setFocusBuffer = 10;
    scrollTo$: Subject<ScrollToLocation | null> = new Subject();
    jumpBarEmpty = this.jumpbarService.jumpBarIsEmpty$;
    activeLink$: Observable<JumpBarLinkToShow|undefined>;
    sticky$: Observable<boolean>;
    selectedJumpBarItem$ = new BehaviorSubject<JumpBarLinkToShow>({
        container: this.element.nativeElement,
        link: '',
        title: ''
    });

    private possibleJumpLinks$ = new BehaviorSubject<string[]>([])
    private possibleIds: string[];

    constructor(private readonly jumpbarService: JumpbarService,
        private readonly pagePositionService: PagePositionService,
        private readonly element: ElementRef,
        private readonly urlService: UrlService,
        private readonly cdr: ChangeDetectorRef,
        @Inject(WINDOW) private readonly window: Window) { }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === 'possibleJumpLinks') {
                const change = changes[propName];
                this.possibleJumpLinks$.next(change.currentValue);
            }
        }
    }

    ngOnInit() {
        this.possibleJumpLinks$.subscribe((possibleLink: string[]) => {
            if (possibleLink.length > 0) {
                this.initilizeJumpBar();
            }
        });
    }

    initilizeJumpBar() {
        this.jumpLinkIds = {};
        this.links.next([]);
        if (this.possibleJumpLinks$.value) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.possibleJumpLinks$.value.forEach((link: any) => {
                if (link.linkText) {
                    this.jumpLinkIds[link.linkInternal?.Id] = link.linkText;

                    return;
                }

                this.jumpLinkIds[link] = link;
            });
        }

        this.possibleIds = [];
        this.possibleIds = Object.keys(this.jumpLinkIds);
        this.jumpbarService.jumpbarLinks$.pipe(
            delay(0),
            map((jumpBarLinks:JumpBarLink[]) => {
                const removeDuplicates: JumpBarLink[] = Array.from(jumpBarLinks.reduce((objA, objB) => objA.set(objB.link, objB), new Map()).values());
                const tempLinks = new Set();
                if (!this.stickyHeader) {
                    this.setDefaultSelection(removeDuplicates[0]);
                }
                removeDuplicates.forEach((jumpbarLink: JumpBarLink) => {
                    jumpbarLink.id.forEach((id) => {
                        if (this.possibleIds.includes(id)) {
                            return tempLinks.add({
                                ...jumpbarLink,
                                title: this.jumpLinkIds[id]
                            });
                        }

                        return;
                    });
                });

                this.links.next([...tempLinks] as JumpBarLinkToShow[]);
            })
        ).subscribe();

        this.links.subscribe((links) => {
            if (!this.stickyHeader && links.length > 0) {
                this.setDefaultSelection({
                    link: links[0].link,
                    id: [links[0].title],
                    container: links[0].container
                });
            }
            const emptyJumpBar = Boolean(!links.length || links.length <= 0);
            this.jumpbarService.setEmptyJumpBar(emptyJumpBar);
        });

        this.contentLoading$.subscribe((contentLoading: boolean) => {
            if (!contentLoading && this.element) {
                this.scrollPoint = this.element.nativeElement.offsetTop + this.element.nativeElement.offsetHeight;
            }
            else {
                this.scrollPoint = Infinity;
            }
        });
        this.subnavHeight = this.element.nativeElement.firstElementChild.offsetHeight ?
            this.element.nativeElement.firstElementChild.offsetHeight :
            this.defaultSubnavHeight;

        this.activeLink$ = combineLatest([
            this.links,
            this.pagePositionService.viewportState$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            filter(([sections]) => Boolean(sections.length)),
            map(([sections, viewportState]) => {
                const scrollTop = viewportState.scroll.top;
                const activeSection = sections.find((section) => {
                    const topOffset = this.subnavHeight + this.activeHighlightCushion;
                    const sectionTop = this.pagePositionService.getAbsoluteElementPosition(section.container).top - topOffset;
                    const sectionBottom = sectionTop + section.container.offsetHeight;

                    return scrollTop >= sectionTop && scrollTop < sectionBottom;
                });

                return activeSection;
            }),
            tap(() => this.cdr.detectChanges())
        );

        this.sticky$ = this.pagePositionService.viewportState$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((viewportState: ViewportState) => {
                if (!this.stickyHeader) {
                    return false;
                }
                const cutoff = this.scrollPoint || Infinity;

                return viewportState.scroll.top > cutoff;
            }),
            distinctUntilChanged(),
            tap(() => this.cdr.detectChanges())
        );

        this.scrollTo$.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((scrollTo) => this.pagePositionService.viewportState$.pipe(
                map((pagePosition) => ({
                    scrollTo,
                    pagePosition
                }))
            )),
            filter(({ scrollTo }) => Boolean(scrollTo)),
            map(({ scrollTo, pagePosition }) => ({
                scrollTo,
                pagePosition
            } as { scrollTo: ScrollToLocation, pagePosition: ViewportState })),
            filter(({ scrollTo, pagePosition }) => {
                const { scroll } = pagePosition;
                const { offset } = scrollTo;
                const under = offset - this.setFocusBuffer;
                const over = offset + this.setFocusBuffer;
                const scrolledNear = scroll.top >= under && scroll.top <= over;

                return scrolledNear || !scroll.changed;
            }),
            distinctUntilChanged((a, b) => a.scrollTo.id === b.scrollTo.id)
        ).subscribe(({ scrollTo }) => {
            this.scrollTo$.next(null);
            if (scrollTo) {
                this.urlService.skipToContent(scrollTo.id);
            }
        });
    }

    ngOnDestroy() {
        this.jumpbarService.setEmptyJumpBar(true);
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    setDefaultSelection(linkItem: JumpBarLink) {
        this.selectedJumpBarItem$.next({
            container: linkItem?.container,
            link: linkItem?.link,
            title: linkItem?.link
        });
    }


    scrollToSection(section: JumpBarLinkToShow, id: string) {
        if (!this.stickyHeader) {
            this.selectedJumpBarItem$.next(section);
        }
        const header = document.querySelector<HTMLElement>('.hvac-header');
        this.headerHeight = header ? header.offsetHeight : 0;
        const jumpbar = document.querySelector<HTMLElement>('.hvac-jump-bar');
        this.jumpbarHeight = jumpbar ? jumpbar.offsetHeight : 0;
        const containerTop = this.pagePositionService.getAbsoluteElementPosition(section.container).top - (this.headerHeight + this.jumpbarHeight);
        const currentTop = window.pageYOffset || document.documentElement.scrollTop;
        const topOffset = containerTop - this.subnavHeight - (currentTop > containerTop ? this.headerHeight : 0);
        this.window.scrollTo({
            left: 0,
            top: topOffset,
            behavior: 'smooth'
        });
        this.scrollTo$.next({
            offset: topOffset,
            id
        });
    }
}


