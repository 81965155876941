import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

export type RouterMatcher = (router: ActivatedRouteSnapshot) => boolean;

export const findChildRoute = (matcher: RouterMatcher, routerState: RouterStateSnapshot): ActivatedRouteSnapshot|null => {
    let route = routerState.root;

    if (matcher(route)) {
        return route;
    }

    while (route.firstChild) {
        route = route.firstChild;

        if (matcher(route)) {
            return route;
        }
    }

    return null;
};

export const lastChildRoute = (routerState: RouterStateSnapshot): ActivatedRouteSnapshot => {
    let route = routerState.root;
    while (route.firstChild) {
        route = route.firstChild;
    }

    return route;
};
