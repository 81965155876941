
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchTextFilter' })
export class SearchTextFilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(productUrns: any, args: any): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return productUrns.filter((productUrn: any) => productUrn.urn?.toString().toLowerCase().includes(args?.toLowerCase()));
    }
}
