import { AbstractControl, ValidatorFn } from '@angular/forms';

const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*+=]/;
const LOWER_CHARACTER_REGEX = /[a-z]/;
const UPPER_CHARACTER_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;

export interface InputErrorObject {
    'LENGTH'?: boolean;
    'REQUIRED'?: boolean;
}

export interface UserNameErrorObject {
    'USERNAME_LENGTH'?: boolean;
    'REQUIRED'?: boolean;
    'INCORRECT_USERNAME_FORMAT'?: boolean;
}

export interface PhoneInputErrorObject {
    'LENGTH'?: boolean;
    'REQUIRED'?: boolean;
    'PHONE_FORMAT'?: boolean;
}

export interface EmailInputErrorObject {
    'LENGTH'?: boolean;
    'REQUIRED'?: boolean;
    'EMAIL_FORMAT'?: boolean;
}

export interface CompanyCodeInputErrorObject {
    'REQUIRED'?: boolean;
    'NUMBERS_ONLY'?: boolean;
    'MAX_LENGTH': boolean;
}

export interface EmailCodeInputErrorObject {
    'REQUIRED'?: boolean;
    'NUMBERS_ONLY'?: boolean;
    'MAX_LENGTH': boolean;
    'MIN_LENGTH': boolean;
}

export interface PostalCodeInputErrorObject {
    'POSTAL_CODE_MIN_LENGTH'?: boolean;
}

export function createAccountInputValidator(maxLength: number, isRequired: boolean): ValidatorFn {
    return (control: AbstractControl): InputErrorObject | null => {
        const errorObject: InputErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length >= maxLength) {
                errorObject.LENGTH = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountUserNameValidator(maxLength: number, isRequired: boolean, patternRegex?: RegExp): ValidatorFn {
    return (control: AbstractControl): InputErrorObject | null => {
        const errorObject: UserNameErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length >= maxLength) {
                errorObject.USERNAME_LENGTH = true;
            }
            if (patternRegex && !patternRegex.test(controlValue)) {
                errorObject.INCORRECT_USERNAME_FORMAT = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountEmailInputValidator(maxLength: number, isRequired: boolean, patternRegex: RegExp): ValidatorFn {
    return (control: AbstractControl): EmailInputErrorObject | null => {
        const errorObject: EmailInputErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined' && controlValue !== null) {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length >= maxLength) {
                errorObject.LENGTH = true;
            }
            if (patternRegex && !patternRegex.test(controlValue)) {
                errorObject.EMAIL_FORMAT = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountPhoneInputValidator(maxLength: number, isRequired: boolean, patternRegex: RegExp): ValidatorFn {
    return (control: AbstractControl): PhoneInputErrorObject | null => {
        const errorObject: PhoneInputErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length >= maxLength) {
                errorObject.LENGTH = true;
            }
            if (patternRegex && !patternRegex.test(controlValue)) {
                errorObject.PHONE_FORMAT = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): PhoneInputErrorObject | null => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorObject: any = {};
        const controlValue = control.value;
        let complexityCount = 0;

        if (NUMBER_REGEX.test(controlValue)) {
            complexityCount += 1;
        }
        if (SPECIAL_CHARACTER_REGEX.test(controlValue)) {
            complexityCount += 1;
        }
        if (UPPER_CHARACTER_REGEX.test(controlValue)) {
            complexityCount += 1;
        }
        if (LOWER_CHARACTER_REGEX.test(controlValue)) {
            complexityCount += 1;
        }

        if (complexityCount < 4) {
            errorObject.COMPLEXITY_REQUIREMENT = true;
        }
        if (controlValue.length < 8) {
            errorObject.MIN_LENGTH = true;
        }
        if (Object.keys(errorObject).length !== 0) {
            return errorObject;
        }

        return null;
    };
}

export function createExistingPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): PhoneInputErrorObject | null => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorObject: any = {};
        const controlValue = control.value;
        if (controlValue.length < 4) {
            errorObject.MIN_LENGTH = true;
        }
        if (Object.keys(errorObject).length !== 0) {
            return errorObject;
        }

        return null;
    };
}

export function createAccountCompanyCodeValidator() {
    return (control: AbstractControl): EmailCodeInputErrorObject | null => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorObject: any = {};
        const numbersOnlyRegex = /^[0-9]*$/;
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (!numbersOnlyRegex.test(controlValue)) {
                errorObject['NUMBERS_ONLY'] = true;
            }
            if (controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (controlValue.length > 10) {
                errorObject.MAX_LENGTH = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountEmailCodeValidator() {
    return (control: AbstractControl): CompanyCodeInputErrorObject | null => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorObject: any = {};
        const numbersOnlyRegex = /^[0-9]*$/;
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (!numbersOnlyRegex.test(controlValue)) {
                errorObject['NUMBERS_ONLY'] = true;
            }
            if (controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (controlValue.length > 6) {
                errorObject.MAX_LENGTH = true;
            }
            if (controlValue.length < 6) {
                errorObject.MIN_LENGTH = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function createAccountPostalCodeValidator() {
    return (control: AbstractControl): PostalCodeInputErrorObject | null => {
        const value = (control.value || '').replace(/\s/g, '');
        if (value.length < 3) {
            return { POSTAL_CODE_MIN_LENGTH: true };
        }

        return null;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(errors: any, messages: any) {
    if (errors) {
        const error = Object.keys(errors)[0];

        return messages[error];
    }

    return null;
}
