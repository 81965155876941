import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { NavMenu } from 'common/services/navigation-content.service';
import { BrandingState } from 'common/store/branding/branding.state';
import { ContentFilteringPreferenceType, ContentState } from 'common/store/content/content.state';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-category-navigation',
    templateUrl: './category-navigation.component.html',
    styleUrls: ['./category-navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CategoryNavigationComponent implements OnInit, OnDestroy {
    @Input() navItem: NavMenu;

    @Select(NavigationState.currentPage) navItem$!: Observable<NavMenu>;
    @Select(ContentState.contentFilteringPreference) userPrefCompanyType$: Observable<ContentFilteringPreferenceType>;
    @Select(BrandingState.themeBrand) brand$ : Observable<string>;

    ngOnDestory$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sectionsData: any = [];
    companyType: ContentFilteringPreferenceType
    srcBrand = 'default';

    ngOnInit() {
        combineLatest([this.navItem$, this.userPrefCompanyType$]).pipe(takeUntil(this.ngOnDestory$)).subscribe(([data, inputCompanyType]) => {
            this.companyType = inputCompanyType ? inputCompanyType : 'Res&Com';
            this.sectionsData = this.filterData(data.children);
        });

        this.brand$.subscribe((brand: string) => {
            if (brand.toLowerCase() !== 'carrier' && brand.toLowerCase() !== 'bryant') {
                this.srcBrand = 'default';
            }
            else {
                this.srcBrand = brand.toLowerCase();
            }
        });
    }

    ngOnDestroy() {
        this.ngOnDestory$.next();
        this.ngOnDestory$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterData(navDataItems: any[]) {
        const sectionsArray = [];
        const filteredNoTagData = navDataItems.filter((obj) => (obj.tag === ''));
        if (!this.companyType || this.companyType === 'Res&Com') {
            const filteredResidentailData = navDataItems.filter((obj) => (obj.tag === 'residential'));
            const filteredCommercialData = navDataItems.filter((obj) => (obj.tag === 'commercial'));

            if (filteredCommercialData.length > 0) {
                sectionsArray.push(filteredCommercialData);
            }
            if (filteredResidentailData.length > 0) {
                sectionsArray.push(filteredResidentailData);
            }
        }
        else {
            const filteredSpecific = navDataItems.filter((obj) => obj.tag === this.companyType.toLowerCase());
            if (filteredSpecific.length > 0) {
                sectionsArray.push(filteredSpecific);
            }
        }
        if (filteredNoTagData.length > 0) {
            sectionsArray.push(filteredNoTagData);
        }

        return sectionsArray;
    }

    getIconsForSections(section: string) {
        if (section === 'residential') {
            return 'assets/icons/residential-building.svg';
        }
        else if (section === 'commercial') {
            return 'assets/icons/commercial-buildings.svg';
        }

        return 'assets/icons/resAndComIcon.svg';
    }
}
