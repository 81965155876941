<a *ngIf="isExternalURL(); else internalBlock" [href]="routeUrl" target="_blank" class="hvac-auto-suggest-link">
    <div class="hvac-auto-suggest-text-container">
        <p class="hvac-auto-suggest-text">{{title}}</p>
    </div>
</a>
<ng-template #internalBlock>
    <a [routerLink]="[routeUrl]" class="hvac-auto-suggest-link">
        <div class="hvac-auto-suggest-text-container">
            <p class="hvac-auto-suggest-text">{{title}}</p>
        </div>
    </a>
</ng-template>