import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, skip, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BrandService } from '../../../services/brand.service';

const FILTER_TITLE_MAP: { [key: string]: string } = {
    DMSType: 'DOCUMENT_TYPE',
    Status: 'STATUS',
    Brands: 'BRANDS'
};

const FILTER_TITLE_MAP_INVERSE: { [key: string]: string } = {
    DOCUMENT_TYPE: 'DMSType',
    STATUS: 'Status',
    BRANDS: 'Brands'
};

const FILTER_NAME_MAP: { [key: string]: string } = { Language: 'LANGUAGE' };

export interface Filter {
    name: string;
    value: string;
    count: number;
    category: string;
}

export interface FilterUi extends Filter {
    selected?: boolean;
    label: string;
}

export interface FilterCategoryUI {
    filters: FilterUi[];
    name: string;
    title: string;
    collapsed?: boolean;
    showAll?: boolean;
}

export interface SingleFilter {
    title: string,
    status: boolean,
    category: string
}

export interface FilterData {
    filterInput: SingleFilter[][];
    searchInput: string
}
@Component({
    selector: 'hvac-document-filter-list',
    templateUrl: './hvac-filter-list.component.html',
    styleUrls: ['./hvac-filter-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HvacFilterListComponent implements OnDestroy, OnInit {
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    @Output() public filterQuery = new EventEmitter<FilterData>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public closeFilter = new EventEmitter<any>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterModel$ = new BehaviorSubject<any>({});
    filterSearch$: BehaviorSubject<string> = new BehaviorSubject('');
    query$: BehaviorSubject<{ [filter: string]: FilterUi }> = new BehaviorSubject({});
    collapsedCategories$: BehaviorSubject<{ [category: string]: boolean }> = new BehaviorSubject({});
    showAllCategories$: BehaviorSubject<{ [category: string]: boolean }> = new BehaviorSubject({});
    searchKeyUpSubject$: BehaviorSubject<string> = new BehaviorSubject('');
    ngOnDestroy$ = new Subject();
    searchInputData = ''
    filterInputData: SingleFilter[][] = [];

    filterCategories$: Observable<FilterCategoryUI[]> = combineLatest([
        this.filterModel$,
        this.translateService.get('PRODUCTS_LITERATURE.FILTER_CATEGORIES'),
        this.translateService.get('PRODUCTS_LITERATURE.FILTER_NAMES'),
        this.collapsedCategories$,
        this.showAllCategories$,
        this.query$
    ]).pipe(
        map(([filterModel, categoryTranslations, filterTranslations, collapsedCategories, showAllCategories, query]) => {
            const filters = filterModel || [];

            return filters.map((filterCategory: {name: string, filterType: Array <string>}) => {
                const categoryFilterTranslations = filterTranslations[FILTER_NAME_MAP[filterCategory.name]] || {};

                return {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    filters: filterCategory.filterType.map((filter: any) => ({
                        ...filter,
                        selected: query[filter.name]?.selected || false,
                        label: categoryFilterTranslations[filter.name] || filter.name,
                        category: FILTER_TITLE_MAP_INVERSE[filterCategory.name] || filterCategory.name
                    })),
                    name: filterCategory.name,
                    title: categoryTranslations[FILTER_TITLE_MAP[filterCategory.name]] || filterCategory.name,
                    category: FILTER_TITLE_MAP[filterCategory.name] || filterCategory.name,
                    collapsed: collapsedCategories[filterCategory.name],
                    showAll: showAllCategories[filterCategory.name]
                };
            });
        })
    );

    constructor(
        private readonly translateService: TranslateService,
        private readonly brandService: BrandService
    ) { }

    get filterData() {
        // get the latest value from _data BehaviorSubject
        return this.filterModel$.getValue();
    }

    @Input()
    set filterData(value) {
        this.filterModel$.next(value);
    }

    ngOnInit() {
        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(600),
            skip(1)
        ).subscribe((searchTextValue) => {
            this.searchInputData = searchTextValue;
            this.filterQuery.next({
                filterInput: this.filterInputData,
                searchInput: this.searchInputData
            });
        });

        this.query$.pipe(
            skip(1),
            tap((filterQuery) => {
                const groupedFilters: {[key: string]: SingleFilter[]} = {};
                Object.keys(filterQuery).filter((validReq) => filterQuery[validReq].selected).forEach((validReq) => {
                    const filterObj = {
                        title: validReq,
                        status: filterQuery[validReq].selected || false,
                        category: filterQuery[validReq].category

                    };
                    if (groupedFilters[filterObj.category]) {
                        groupedFilters[filterObj.category].push(filterObj);
                    }
                    else {
                        groupedFilters[filterObj.category] = [filterObj];
                    }
                });
                const nestedArray = Object.keys(groupedFilters).map((group) => groupedFilters[group]);
                this.filterInputData = nestedArray;
                this.filterQuery.next({
                    filterInput: this.filterInputData,
                    searchInput: this.searchInputData
                });
            })
        ).subscribe();
    }

    toggleFilterGroup(filterCategory: FilterCategoryUI) {
        const collapsedCategories = this.collapsedCategories$.value;
        this.collapsedCategories$.next({
            ...collapsedCategories,
            [filterCategory.name]: !collapsedCategories[filterCategory.name]
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleFilter(event: Event, filter: any, _category: FilterCategoryUI) {
        const queries = this.query$.value;
        this.query$.next({
            ...queries,
            [filter.name]: {
                selected: (event.target as HTMLInputElement).checked,
                category: filter.category,
                name: filter.name
            }
        });
    }

    toggleShowAll(filterCategory: FilterCategoryUI) {
        const showAllCategories = this.showAllCategories$.value;

        this.showAllCategories$.next({
            ...showAllCategories,
            [filterCategory.name]: !showAllCategories[filterCategory.name]
        });

        setTimeout(() => {
            const parentFilterButton = document.getElementById(filterCategory.title) as HTMLElement;
            if (parentFilterButton) {
                parentFilterButton.focus();
            }
        }, 0);
    }

    inputKeyup(event: KeyboardEvent) {
        this.searchKeyUpSubject$.next((event.target as HTMLInputElement).value);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    onSearch(event: Event) {
        event.preventDefault();

        this.searchInputData = this.searchInput.nativeElement.value.toLowerCase();
        this.filterQuery.next({
            filterInput: this.filterInputData,
            searchInput: this.searchInputData
        });
    }

    clearSearch() {
        this.searchInputData = '';
        this.searchInput.nativeElement.value = '';
        this.filterQuery.next({
            filterInput: this.filterInputData,
            searchInput: this.searchInputData
        });
    }

    closeFilterMenu() {
        this.closeFilter.next(false);
    }

    showBrandForNonICP(title: string) {
        if (title === 'Brand') {
            return !this.brandService.isICPBrand(environment.brand);
        }

        return true;
    }
}
