import { Injectable } from '@angular/core';

const LINK_IMAGES = {
    apple: 'assets/images/apple-app-store.png',
    android: 'assets/images/google-play.png'
};

const APPLE_LINK: RegExp = /apps\.apple\.com/;
const ANDROID_LINK: RegExp = /play.google.com/;

@Injectable({ providedIn: 'root' })
export class PublicLinkIconService {
    getLinkIcon(link: { linkExternal?: string, linkInternalParameter?: string }): string|null {
        const href = link.linkInternalParameter || link.linkExternal || '';

        if (APPLE_LINK.test(href)) {
            return LINK_IMAGES.apple;
        }

        if (ANDROID_LINK.test(href)) {
            return LINK_IMAGES.android;
        }

        return null;
    }
}
