import { environment } from 'common/environments/environment';
import { Product, SearchQuery, SearchResource, SearchResults, SerialNumberResults } from 'common/models';

const documentSearchTerm = /\b(?:brochure|installation|install|manual|product\s?data|service|warrant(?:y|ies)|document)/i;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasResults = (searchCategory?: SearchResource<any>) => searchCategory && searchCategory.results.length > 0;

const isExactMatch = (inputQuery: string, searchCategory: SearchResource<Product> | undefined) => {
    const lowerCaseQuery = inputQuery.toLowerCase();
    const queryNoDoubleQuote = lowerCaseQuery.replace(/["]+/g, '');
    const query = queryNoDoubleQuote.replace(/[']+/g, '');

    return searchCategory ? searchCategory.results.find((result: {urn:string}) => result.urn.toLowerCase() === query) : false;
};

const anySerialNumberModelMatch = (products: SearchResource<Product>|undefined, serialNumbers: SerialNumberResults|undefined): boolean => {
    if (!(products && products.results && products.results.length && serialNumbers && serialNumbers.results && serialNumbers.results.length)) {
        return false;
    }

    const serialNumberModels = serialNumbers.results.map(({ model }) => model);

    return products.results.some((product) => serialNumberModels.some((model) => model.startsWith(product.urn) || product.urn.startsWith(model)));
};

export const getMostRelevantSearchCategory = (results: SearchResults, query: SearchQuery) => {
    if (anySerialNumberModelMatch(results.products, results.serialNumbers)) {
        return 'products';
    }

    if (documentSearchTerm.test(query.q) && hasResults(results.documents)) {
        return 'documents';
    }

    if (hasResults(results.content) && environment.brand !== 'private') {
        return 'content';
    }

    if (hasResults(results.products) && isExactMatch(query.q, results.products)) {
        return 'products';
    }

    if (hasResults(results.documents)) {
        return 'documents';
    }

    return 'products';
};

