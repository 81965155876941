/* eslint-disable no-use-before-define */
export const DOCUMENT_RESOURCE_TYPE = 'document';

export interface FileTypes {
    [key: string]: string;
    [index: number]: string;
}

export interface Document {
    SecurityGroup?: string;
    securityGroup?: { name: string };
    brand: string[];
    catalogueNumber: string;
    current: boolean;
    fileExtension: string;
    fileType: string;
    formNumber: string;
    id: string;
    mimeType: string;
    // YYYY-MM-DD HH:MM:SS | YYYY-MM-DD
    printDate: string;
    prodModelNumber: string[];
    score: number;
    subtype: string;
    title: string;
    type: string;
    url: string;
    resourceType: 'document';
}

export interface DocumentQuery {
    skip?: number;
    count: number;
    brand?: string[];
    sampleMail?: boolean;
    printDate?: string;
    releaseToDate?: string;
    publishedDate?: string;
    country?: string[];
    type?: string[];
    subtype?: string[];
    modelNumbers?: string[];
    status?: string[];
    title?: string[];
    contentId?: string[];
    partNumber?: string[];
    documentGroup?: string[];
    category?: string[];
    subCategory?: string[];
    division?: string[];
    isParts?: boolean;
}

export enum DocumentQueryType {
    WHATS_NEW = 'whatsnew',
    BULLETIN = 'bulletin',
    DYNAMIC = 'dynamic',
    PRODUCT_LITERATURE = 'productliterature'
}

export interface DocumentField {
    fieldName: DocumentFieldName;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortFunction?: any;
}

export enum DocumentFieldName {
    BRAND = 'Brands',
    STATUS = 'Status',
    FORM_NUMBER = 'FormNumber',
    COMMENTS = 'Comments',
    DOCUMENT_TITLE = 'DocumentTitle',
    PRINT_DATE = 'PrintDate',
    SUB_TYPE = 'Subtype',
    RELEASE_DATE = 'ReleaseDate',
    CATALOG_NUMBER = 'CatalogueNumber'
}

export interface DocumentResult {
    ResultCount: string,
    Documents: ProductLiteratureDocument[]
}

export interface ProductLiteratureDocument {
    ID: string;
    DocumentTitle: string;
    Type?: (string)[] | null;
    Subtype?: (string)[] | null;
    Brands?: (string)[] | null;
    CatalogueNumber: string;
    Status: string;
    ModelNumbers?: (string)[] | null;
    DocumentMIMEType: string;
    DocumentBaseURL: string;
    DocumentPath: string;
    Verticals?: (string)[] | null;
    Language: string;
    Country: string;
    PublishedDate: string;
    DocumentSize: string;
    Region: string;
    ReleaseDate: string;
    FormNumber: string;
    ContentID: string;
    Author: string;
    PrintDate: string;
    SampleMail: string;
    SourceDepartment: string;
    Division: string;
    ModelCategory: string;
    PartNumber: string;
    DocumentGroup: string;
    FileType: string;
    Category: string;
    Comments: string;
    SubCategory: string;
    BrandFamily: string;
    DMSCategory: string;
    DMSType: string;
    SecurityGroup: string;
}
