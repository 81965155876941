<tr class="expandable" [ngClass]="{'no-bottom-border':isExpanded}">
    <td class="hvac-table-list-column-icon" (click)="onHeaderRowClick( expandableRowData );$event.stopPropagation()">
        <div class="hvac-table-list-icon">
            <utc-svg src="assets/icons/inbox-multi-file-icon.svg"></utc-svg>
        </div>
    </td>
    <td *ngFor='let header of dataHeaders' class="no-bottom-border"
        (click)="onHeaderRowClick( expandableRowData );$event.stopPropagation()" [ngClass]="{'hvac-table-column-error': expandableRowData[header] === 'Error'}">
        <div class="child-data-container">
            <div class="no-date-time" *ngIf="header === 'completed' && (expandableRowData.status === 'In Progress' || expandableRowData.status === 'CANCELLED' || expandableRowData.status === 'Error'); else otherColumns">--</div>
            <ng-template #otherColumns>
                <div [ngClass]="{ 'hvac-table-column-active': isRead === false && header === 'name' }">{{ expandableRowData[header] }}</div>
            </ng-template>
            <div *ngIf="header === 'name' && expandableRowData.isExpandable" class="hvac-item-count">
                {{expandableRowData.details.length}}</div>
            <utc-svg class="row-error" *ngIf="expandableRowData[header] === 'Error'"
                src="assets/icons/banner-error.svg"></utc-svg>
        </div>
    </td>
    <td class="hvac-table-list-column-menu" *ngIf="contextMenu"
        (click)="toggleContextMenu(expandableRowData);$event.stopPropagation()">
        <div class="hvac-table-dropdow-three-dots"
            [ngClass]="{'hvac-table-dropdow-three-dots-z-index': getMenuActionRow(expandableRowData) }">
        </div>
        <div class="hvac-table-dropdown-content"
            [ngClass]="{'hvac-table-list-column-menu-expanded': getMenuActionRow(expandableRowData) }">
            <div></div>
            <ol *ngIf="enableView; else fullMenu">
                <li *ngFor='let menu of contextMenu' (click)="onContextClick( expandableRowData , menu )">
                    {{ menu }}
                </li>
            </ol>
            <ng-template #fullMenu>
                <ol>
                    <li *ngFor='let menu of contextMenu | slice:1'
                        (click)="onContextClick( expandableRowData , menu )">{{ menu
                        }}
                    </li>
                </ol>
            </ng-template>
        </div>
    </td>
</tr>
<tr class="expandable no-bottom-border" [ngClass]="{'hide':!isExpanded, 'show':isExpanded}"
    *ngFor='let data of expandableRowData.details'>
    <td class="hvac-table-list-column-icon" (click)="onContextClick( data , 'row' );$event.stopPropagation()">
    </td>
    <td *ngFor='let header of dataHeaders' (click)="onContextClick( data , 'row' );$event.stopPropagation()"
        class="no-bottom-border"
        [ngClass]="{'hvac-table-column-error': expandableRowData[header] === 'Error','name-column-padding': header === 'name'}">
        <div class="child-data-container">
            <div *ngIf="header=== 'name'" class="hvac-table-list-icon" [ngClass]="{'document-light-icon': data.status !== 'Completed'}">
                <utc-svg [src]="fileIcon(data.fileType)"></utc-svg>
            </div>
            <div *ngIf="header === 'name' " class="column-value">{{ data[header] }}</div>
        </div>
    </td>
    <td class="hvac-table-list-column-menu">
    </td>
</tr>
