<ul class='hvac-private-navigation-link-list'
    [ngClass]="{
        'hvac-private-navigation-link-list-expanded': (firstLevel || (showMenu$ | async)),
        'hvac-private-navigation-first-level-list': firstLevel,
        'hvac-private-navigation-second-level-list': secondLevel,
        'hvac-private-navigation-current-list': isCurrentMenu$ | async
    }">
    <li (mouseenter)="handleMouseEnter(child)"
        (focus)="handleMouseEnter(child)"
        class='hvac-private-navigation-list-item'
        *ngFor="let child of navContent.children"
        [ngClass]="{ 'hvac-private-navigation-list-item-current': navMenuCurrent(child) | async }">

        <ng-container *ngIf="child">
            <a *ngIf="child.hrefTarget === '_self'; else externalLink" [routerLink]="child.url"
                [target]="child.hrefTarget"
                hvacButton='text'
                hvacButtonIcon
                class="hvac-private-navigation-link">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
            <ng-template #externalLink>
                <a [href]="child.url"
                    [target]="child.hrefTarget"
                    hvacButton='text'
                    hvacButtonIcon
                    class="hvac-private-navigation-link">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>
            </ng-template>

            <ng-template #content>
                <span [ngClass]="{'single-line-text': firstLevel}" class="hvac-private-navigation-link-title">{{child.title}}</span>
                <utc-svg class="hvac-private-navigation-nested-icon" *ngIf="child.children.length"
                    [ngClass]="{'hvac-private-navigation-nested-icon-right': !firstLevel}"
                    src='assets/icons/chevron-down.svg'></utc-svg>
            </ng-template>

            <hvac-navigation-nested-container
                *ngIf="child.children"
                [firstLevel]="false"
                [secondLevel]="firstLevel"
                [navContent]="child">
            </hvac-navigation-nested-container>
        </ng-container>
    </li>
</ul>
