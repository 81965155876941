import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { DatePickerDateFormat } from 'common/components/date-range-picker/date-range-picker.component';

@Pipe({ name: 'datePickerInputDate' })
export class DatePickerInputDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) { }

    transform(date: number | undefined, dateType: DatePickerDateFormat): string | null {
        if (typeof date === 'number') {
            if (dateType === 'date') {
                return this.datePipe.transform(date, AppConstants.dateFormatMed);
            }

            return this.datePipe.transform(date, AppConstants.dateFormatMedWithTime);
        }

        return null;
    }
}
