import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

type TableTheme = 'default' | 'zebra';

@Component({
    selector: 'hvac-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TableComponent {
    @Input() private responsive = false;
    @Input() private theme: TableTheme = 'default';

    @HostBinding('class.hvac-table') get hostclass() {
        return true;
    }

    @HostBinding('class.hvac-table-responsive') get isResponsive() {
        return this.responsive;
    }

    @HostBinding('class.hvac-table-theme-zebra') get isZebraTheme() {
        return this.theme === 'zebra';
    }
}
