import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'hvac-public-links',
    templateUrl: './public-links.component.html',
    styleUrls: ['./public-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PublicLinksComponent {
    @Input() data: BaseContentEntity;
}
