<div class="hvac-system-product-list-item">
    <hvac-system-product-card
        [image]="resource.image"
        [name]="resource.name"
        [showFaultIndicator]="resource.showFaultIndicator"
        [descriptors]="resource.descriptors"
        [productLink]="resource.productLink"
        [systemType]="resource.systemType"
        [type]="resource.type"
        [noDataMessage]="resource.noDataMessage"
    ></hvac-system-product-card>
</div>

