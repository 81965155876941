<section *ngFor="let section of sectionsData" class="category-navigation-section">
    <img *ngIf="section[0].tag !== 'residential' && section[0].tag !== 'commercial'; else svg" class="category-navigation-icon" [src]="'assets/icons/resAndComIcon-'+srcBrand+'.svg'" alt="category-navigation-icon" />
    <ng-template #svg><utc-svg class="category-navigation-icon" [src]="getIconsForSections(section[0].tag)"></utc-svg></ng-template>
    <div class="nav-items">
        <ul class="hvac-category-navigation">
            <li *ngFor="let child of section" class="hvac-category-navigation-item">
                <a *ngIf="child.hrefTarget === '_self'; else externalLink"
                    [routerLink]="[child.url]"
                    [target]="child.hrefTarget"
                    hvacButton="default"
                    class="hvac-category-navigation-link">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>

                <ng-template #externalLink>
                    <a [href]="child.url"
                        [target]="child.hrefTarget"
                        hvacButton="default"
                        class="hvac-category-navigation-link">
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>
                </ng-template>

                <ng-template #content>
                    {{child.title}}
                </ng-template>
            </li>
        </ul>
    </div>
</section>
