import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Option } from 'common/components/select/select.component';
import { FilterPipe } from 'common/pipes/filter.pipe';
import { BehaviorSubject } from 'rxjs';
import { AppConstants } from 'common/app-constants';

export interface HvacAutoCompleteEvent {
    loading?: boolean;
    loadMore?: boolean | boolean;
}

@Component({
    selector: 'hvac-autocomplete',
    templateUrl: './hvac-auto-complete.component.html',
    styleUrls: ['./hvac-auto-complete.component.scss']
})
export class HvacAutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
    @Input() inputId: string;
    @Input() label: string;
    @Input() placeholder?: string;
    @Input('value') _value?: string;
    @Input() disabled = false;
    @Input() ariaLabel?: string;
    @Input() inputControl: UntypedFormControl;
    @Input() error: boolean;
    @Input() disabledInput?: boolean;
    @Input() data?: Option[];
    @Input() show?: 'name' | 'value' | 'nameValue' = 'nameValue';
    @Input() required: boolean;
    @Input() errorMessage: string;
    @Input() infoMessage: string;
    @Input() event: HvacAutoCompleteEvent = { loading: false }
    @Input() inputValue: string;
    @Input() shadow: 'light' | 'default' | 'none' = 'default';

    @Output() enterKeyFunction = new EventEmitter<string>();
    @Output() keystrokeFunction = new EventEmitter<string>();
    @Output() blurFunction = new EventEmitter<string>();
    @Output() onSelect = new EventEmitter<Option>();
    @Output() onLoadMore = new EventEmitter();

    @ViewChild('input') input: ElementRef;
    isFocused = false;
    dataList$ = new BehaviorSubject<Option[]>([]);
    event$ = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });

    className: string;
    minimizeLabel: boolean;
    isOpen: boolean;
    clickInsideOf: boolean = false;
    clickInsideResetTime: NodeJS.Timeout;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.inputId = '';
        this.label = '';
        this.placeholder = '';
        this._value = '';
        this.className = '';
    }

    get value(): string | undefined {
        return this._value;
    }

    set value(val: string | undefined) {
        this._value = val;
        this.minimizeLabel = this.hasValue();
    }

    @HostListener('click')
    clickInside() {
        this.clickInsideOf = true;
        this.isOpen = true;
        this.resetClickInside();
    }

    @HostListener('keyup', ['$event'])
    public keyListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code.toLowerCase() === 'enter') {
            this.enterKeyFunction.emit(this.input.nativeElement.value);
        }
        else {
            this.keystrokeFunction.emit(this.input.nativeElement.value);
        }
        this.minimizeLabel = this.hasValue();

        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.disabled = this.inputControl?.disabled;
        this.inputControl.registerOnDisabledChange((val: boolean) => {
            this.disabled = val;
        });
        this.writeValue(this.inputControl.value);
        this.inputControl.valueChanges.subscribe((value: string) => {
            if (!value) {
                this.dataList$.next([]);

                return;
            }
            this.writeValue(value);
            this.minimizeLabel = this.hasValue();
            setTimeout(() => {
                this.isOpen = true;
                this.dataList$.next(new FilterPipe().transform(this.data, ['name', 'value'], value));
            }, AppConstants.debounceTime200);
        });

        this.dataList$.subscribe((_data) => {
            this.resetClickInside();
        });

        if (this.inputValue && this.inputControl) {
            this.inputControl.setValue(this.inputValue);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.data?.currentValue) {
            this.dataList$.next(new FilterPipe().transform(this.data, ['name', 'value'], this.inputControl.value));
        }

        if (changes?.event?.currentValue) {
            this.event$.next(changes?.event?.currentValue);
        }

        this.updateData();
    }

    setSelection(option: string) {
        const parentSelection = this.dataList$.value.find((parentCompany: Option) => parentCompany.value === option);
        if (parentSelection && this.show === 'name') {
            this.inputControl.setValue(`${parentSelection?.name}`);
        }
        else if (parentSelection && this.show === 'value') {
            this.inputControl.setValue(`${parentSelection?.value}`);
        }
        else if (parentSelection && this.show === 'nameValue') {
            this.inputControl.setValue(`${parentSelection?.name} - ${parentSelection?.value}`);
        }
        else {
            this.inputControl.setValue(`${parentSelection?.name} `);
        }
        this.onSelect.emit(parentSelection);
        this.isOpen = false;
        this.handleClick(true);
    }

    hasValue() {
        return this._value !== '' && this._value !== null;
    }

    writeValue(val: string) {
        this._value = val;
        this.minimizeLabel = this.hasValue();
    }

    handleClick(close?: boolean) {
        if (this.clickInsideOf) {
            return;
        }
        if (close) {
            this.isOpen = false;
        }
        else {
            this.isOpen = !this.isOpen;
        }

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.clickInsideResetTime) {
            clearTimeout(this.clickInsideResetTime);
        }
    }

    private resetClickInside() {
        this.clickInsideResetTime = setTimeout(() => {
            this.clickInsideOf = false;
        }, AppConstants.debounceTime200);
    }

    private updateData() {
        this.disabled = this.inputControl?.disabled;
        this.inputControl.registerOnDisabledChange((val: boolean) => {
            this.disabled = val;
        });
        this.writeValue(this.inputControl.value);
        this.inputControl.valueChanges.subscribe((value: string) => {
            if (!value) {
                this.dataList$.next([]);

                return;
            }

            this.writeValue(value);
            this.minimizeLabel = this.hasValue();
            setTimeout(() => {
                this.isOpen = true;
                const parentSelection = this.dataList$.value.find((parentCompany: Option) => parentCompany.value === value);
                this.dataList$.next(new FilterPipe().transform(this.data, ['name', 'value'], `${parentSelection?.name} - ${parentSelection?.value}`));
            }, 200);
        });
    }
}
