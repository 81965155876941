import { Provider } from '@angular/core';
import { OktaService } from './okta.service';
import { OktaBaseService } from './okta-base.service';
import { environment } from 'common/environments/environment';

export const provider: Provider = {
    provide: OktaBaseService,
    useClass: environment.features.auth.login
        ? OktaService
        : OktaBaseService
};
