import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterStateModel } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ContentComponent } from 'common/content/services/content-component.service';
import { BaseContentEntity } from 'common/models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'utc-page-hero',
    templateUrl: './page-hero.component.html',
    styleUrls: ['./page-hero.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageHeroComponent implements OnDestroy, ContentComponent {
    @Input() data: BaseContentEntity;
    ngOnDestroy$ = new Subject();

    public isLandingPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(
                router.state && (
                    router.state.url.includes('/products') || router.state.url.includes('/marketing') || router.state.url.includes('/learning')
                ) && !router.state.url.includes('detail') && !router.state.url.includes('search')
            ))
        );

    public isSearchPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('search')))
        );

    public isDetailPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('detail')))
        );

    public isCovidPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url.includes('covid')))
        );

    constructor(private readonly store: Store) {}


    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}

