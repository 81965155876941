import { ExtendedSearchResults, Filter, SearchQuery, SearchType, ViewMode } from 'common/models';

export class ChangeViewMode {
    static readonly type = '[Search] ChangeViewMode';
    constructor(public viewMode: ViewMode, public category: SearchType) {}
}

export class PerformSearch {
    static readonly type = '[Search] PerformSearch';
    constructor(public query: SearchQuery) {}
}

export class RedoSearch {
    static readonly type = '[Search] RedoSearch';
}

export class FetchNextPage {
    static readonly type = '[Search] FetchNextPage';
}

export class AddFilter {
    static readonly type = '[Search] AddFilter';
    constructor(public filter: Filter) {}
}

export class RemoveFilter {
    static readonly type = '[Search] RemoveFilter';
    constructor(public filter: Filter) {}
}

export class ReceiveSearchResults {
    static readonly type = '[Search] ReceiveSearchResults';
    constructor(public results: ExtendedSearchResults) {}
}

export class AppendSearchResults {
    static readonly type = '[Search] AppendSearchResults';
    constructor(public results: ExtendedSearchResults) {}
}

export class SetSortMode {
    static readonly type = '[Search] SetSortMode';
    constructor(public sortParameter: string, public sortMode: string) {}
}

export class HeaderSearchVisible {
    static readonly type = '[UI] HeaderSearchVisible';
    constructor(public visible: boolean) {}
}
