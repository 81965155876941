import { DocumentQuery } from 'common/models/document';
import { DocumentField, DocumentFieldName } from '../../../../models/document';
import { QueryBase, QueryInterface } from './query-base';
import { DataTableFilterOption } from 'common/models/data-table';
import { FilterPipe } from 'common/pipes/filter.pipe';


const FILTER_MAP :string[] = ['DMSType', 'Status', 'Brands', 'Language'];

export class ProductLiteratureQuery extends QueryBase implements QueryInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generateQuery(data: any): DocumentQuery {
        return {
            skip: Number(data.skip),
            count: Number(data.maxItems),
            status: this.parseRichTextData(data.status ? data.status : ''),
            modelNumbers: data.modelNumbers,
            category: data.category,
            isParts: data.isParts
        } as DocumentQuery;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseModelType(modelNumbers: string[]): any {
        return modelNumbers;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchFilterOptions(documents: any[]): any[] {
        const x = FILTER_MAP.map((type) => {
            const filterDocumentType = documents.map((name) => name[type]).reduce((names, name) => {
                const count = names[name] || 0;
                names[name] = count + 1;

                return names;
            }, []);

            return {
                filterType: Object.keys(filterDocumentType).filter(Boolean).map((filtertitle) => ({
                    name: filtertitle,
                    count: filterDocumentType[filtertitle]
                })),
                filters: Object.keys(filterDocumentType).filter(Boolean),
                name: type
            };
        });

        return x;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilterOptions(documents:any [], oldFilterOptions: DataTableFilterOption[]): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        oldFilterOptions.forEach((oldFilter: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tempFilters: any[] = [];
            oldFilter.filters.forEach((filter: {[key:string]:string}) => {
                const filtered = new FilterPipe().transform(documents, FILTER_MAP, filter.name);
                const newCount = filtered.length || 0;
                tempFilters.push({
                    name: filter.name,
                    newCount: newCount
                });
            });
            oldFilter.filters = tempFilters;
        });

        return oldFilterOptions;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterByType(documents: any[]): any {
        const filterDocumentType = documents.map((name) => name[FILTER_MAP[0]]).reduce((names, name) => {
            const count = names[name] || 0;
            names[name] = count + 1;

            return names;
        }, []);

        const x = {
            data: Object.keys(filterDocumentType).filter(Boolean).map((filtertitle) => ({
                title: filtertitle,
                count: filterDocumentType[filtertitle],
                documents: documents.filter((filterDocument) => filterDocument.DMSType === filtertitle)
            }))
        };

        return x;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseRichTextData(data: any): string[] {
        if (data && data.$values) {
            return data.$values.map((value: {Fragments:string}) => value.Fragments);
        }
        if (data && data.length > 0) {
            return [data];
        }

        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageSize(data: any): number {
        return data.Content.itemsPerPage;
    }

    fields(_data: DocumentFieldName): DocumentField[] {
        const fieldsToDisplay = [
            DocumentFieldName.DOCUMENT_TITLE,
            DocumentFieldName.FORM_NUMBER,
            DocumentFieldName.RELEASE_DATE,
            DocumentFieldName.PRINT_DATE
        ];

        return this.fieldFilters.filter((fieldFilter: DocumentField) => fieldsToDisplay.includes(fieldFilter.fieldName));
    }
}
