<button type="button" (click)="choosePart()" class="hvac-auto-suggest-link">
    <div class="hvac-auto-suggest-image-container">
        <utc-image-fallback
            fallbackImageSrc="assets/icons/default.svg"
            [suppliedImageSrc]="image"
            classNames="hvac-auto-suggest-product-image"
            size="small"
            ></utc-image-fallback>
    </div>
    <div class="hvac-auto-suggest-text-container">
        <p class="hvac-auto-suggest-text">{{partNumber}}</p>
    </div>
</button>
