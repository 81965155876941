import { Injectable } from '@angular/core';

interface Heap {

    track: (event: string, properties?: Object) => void;

    identify: (identity: string) => void;

    resetIdentity: () => void;

    addUserProperties: (properties: Object) => void;

    addEventProperties: (properties: Object) => void;

    removeEventProperty: (property: string) => void;

    clearEventProperties: () => void;

    appid: string;

    userId: string;

    identity: string | null;

}


declare let heap: Heap;

@Injectable({ providedIn: 'root' })

export class HeapService {
    track(eventName: string, eventProps?: Object) {
        heap.track(eventName, eventProps);
    }

    identify(identity:string) {
        heap.identify(identity);
    }

    addUserProperties(properties: Object) {
        heap.addUserProperties(properties);
    }
}
