import { Component, Input } from '@angular/core';
import { SystemProduct } from 'common/models/system-product';

@Component({
    selector: 'hvac-system-product-list-item',
    templateUrl: './system-product-list-item.component.html',
    styleUrls: ['./system-product-list-item.component.scss']
})
export class SystemProductListItemComponent {
    @Input() resource: SystemProduct;
}
