import { Component, ViewEncapsulation } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { CatalogCategory, CatalogState } from 'common/store/catalog/catalog.state';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrowseNavigation, BrowseNavigationService } from '../../services/browse-navigation.service';

@Component({
    selector: 'hvac-browse-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent {
    @Select(CatalogState.catalogCategories) categories$!: Observable<CatalogCategory[]>;
    @Select(RouterState.state) routerState$!: Observable<RouterStateSnapshot>;
    navigation$: Observable<BrowseNavigation> = this.browseNav.navigation$;

    category$: Observable<CatalogCategory|undefined> = combineLatest([
        this.navigation$,
        this.categories$
    ]).pipe(
        map(([browseNavigation, categories]) => {
            const category = browseNavigation.category || '';

            return categories.find(({ urn }) => urn === category);
        })
    );

    subcategory$: Observable<CatalogCategory|undefined> = combineLatest([
        this.navigation$,
        this.category$
    ]).pipe(
        map(([browseNavigation, category]) => {
            const subcategoryUrn = `${browseNavigation.category}/${browseNavigation.subcategory}`;

            return category && category.children.find(({ urn }) => urn === subcategoryUrn);
        })
    );

    categoryActive$: Observable<boolean> = this.category$.pipe(
        map((category) => Boolean(category))
    );

    subcategoryActive$: Observable<boolean> = combineLatest([this.category$, this.subcategory$]).pipe(
        map(([category, subcategory]) => {
            if (subcategory) {
                return true;
            }

            if (!category) {
                return false;
            }

            return Boolean(!category.children || category.children && !category.children.length);
        })
    );

    subcategoryCurrent$: Observable<boolean> = combineLatest([this.category$, this.subcategory$]).pipe(
        map(([category, subcategory]) => {
            if (subcategory) {
                return false;
            }

            return Boolean(category && category.children && category.children.length);
        })
    );

    subcategoryEnabled$: Observable<boolean> = this.category$.pipe(
        map((category) => Boolean(category && category.children && category.children.length))
    );

    productsCurrent$: Observable<boolean> = combineLatest([this.category$, this.subcategory$]).pipe(
        map(([category, subcategory]) => {
            if (subcategory) {
                return true;
            }

            return Boolean(category && !category.children.length);
        })
    );

    constructor(private readonly browseNav: BrowseNavigationService) {}

    browseToParent(catalogCategory: CatalogCategory) {
        this.browseNav.navigate({
            ...this.getParentSection(catalogCategory),
            pushToHistory: true
        });
    }

    private getParentSection(catalogCategory: CatalogCategory): BrowseNavigation {
        const [category, subcategory] = catalogCategory.urn.split('/');

        if (subcategory) {
            return { category };
        }

        return {};
    }
}
