import { Component, ViewEncapsulation } from '@angular/core';
import { ContentCardComponent } from '../content-card.component';
@Component({
    selector: 'hvac-icon-content-card',
    templateUrl: './icon-content-card.component.html',
    styleUrls: ['../content-card.component.scss', './icon-content-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IconContentCardComponent extends ContentCardComponent {
    maxBodyLength = 197;
}
