import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { ContentUrlModule } from 'common/pipes/content-url.module';
import { RichTextModule } from 'common/pipes/rich-text/rich-text.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CheckListComponent } from './check-list/check-list.component';
import { FeaturedContentItemComponent } from './featured-content/featured-content-item.component';
import { FeaturedContentComponent } from './featured-content/featured-content.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IcpInteractiveQuestionnaireComponent } from './icp-interactive-questionnaire/icp-interactive-questionnaire.component';
import { LoginPanelContainerComponent } from './login-panel-container/login-panel-container.component';
import { MarketingLinksComponent } from './marketing-links/marketing-links.component';
import { PublicLinkComponent } from './public-link/public-link.component';
import { SearchHeroComponent } from './../../../common/components/search-hero/search-hero.component';
import { SignUpNewsLetterComponent } from './signup-newsletter/signup-newsletter.component';
import { NgxPopperModule } from 'common/libs/ngx-popper/popper.module';
const publicComponents = [
    CheckListComponent,
    FeaturedContentComponent,
    FeaturedContentItemComponent,
    FooterComponent,
    HeaderComponent,
    LoginPanelContainerComponent,
    MarketingLinksComponent,
    PublicLinkComponent,
    SearchHeroComponent,
    SignUpNewsLetterComponent,
    IcpInteractiveQuestionnaireComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonComponentsModule,
        RichTextModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPopperModule,
        TranslateModule,
        PdfJsViewerModule,
        ContentUrlModule
    ],
    declarations: publicComponents,
    exports: [...publicComponents, CommonComponentsModule]
})
export class ComponentsModule {}
