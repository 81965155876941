import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

import { ContentService } from 'common/services/content.service';
import { BehaviorSubject } from 'rxjs';

export interface SiteMap {
    Url: string;
    brands?: string[]
}

@Injectable({ providedIn: 'root' })
export class ErrorService {
    public errorStatus$: BehaviorSubject<number> = new BehaviorSubject(0);

    public siteMap$: BehaviorSubject<SiteMap[]> = new BehaviorSubject([{ Url: '' }]);

    constructor(
        private readonly contentService: ContentService,
        private readonly router: Router
    ) {
        this.contentService.siteMap$.subscribe((sitemap: SiteMap[]) => {
            this.siteMap$.next(sitemap);
        });
    }

    public isTridion(pageURL: string): SiteMap | undefined {
        if (pageURL.includes('products/detail/')) {
            return;
        }
        const lastPath = pageURL.split('/').pop()?.split('?')[0]?.replace('.html', '') || '';

        return this.siteMap$.value.find((option: SiteMap) => (option.Url.split('/').pop() || '').toLowerCase() === lastPath.toLowerCase());
    }

    public isAngularPage(pageURL: string): boolean {
        const routes = this.router.config.map((pathObj: Route) => pathObj.path?.replace(':id', ''));

        const url = pageURL.replace('%20', ' ');

        return Boolean(routes.find((route) => {
            if (route === '') {
                return false;
            }

            return url.startsWith(`/${route}`);
        }));
    }
}
