<section class="hvac-tab-container-details-content-section">
    <div>
        <div class="hvac-product-content-section-overview">
            <div class="hvac-product-content-section-overview-description">
                <utc-input class='hvac-tab-container-search'
                [inputControl]="searchControl" [label]="'SEARCH' | translate" type="text"></utc-input>
                <div class="hvac-tab-container-data-container">
                    <div class="hvac-tab-container-family-container">
                        <div class="hvac-mobile-tab-select-container">
                            <utc-select
                                variant="primary"
                                [selectId]="'tab-link-id'"
                                [group]="mobileTabSelectGroup"
                                control="mobileTabSelectControl"
                                [options]="selectOptions"
                                ></utc-select>
                        </div>
                        <ul class="hvac-tab-container-tab-list">
                            <li *ngFor="let tab of shownContentList | async">
                                <utc-button
                                    buttonType="button"
                                    classNames="hvac-tab-component-button"
                                    label="{{ tab.name }}"
                                    [title]="tab.name"
                                    (click)="selectTab(tab)"
                                    [ngClass]="{'hvac-button-active': tab.id === (activeTab$ | async)?.id}"></utc-button>
                            </li>
                        </ul>
                    </div>
                    <div class="hvac-tab-container-data-content">
                        <div class='hvac-tab-container-link-container'
                            [ngClass]="{'hvac-tab-container-active': section.id === (activeTab$ | async)?.id, 'story-highlight-theme': section.theme === 'story_highlights'}"
                            *ngFor="let section of shownContentList | async">
                            <h1 class='hvac-tab-container-link-title'>{{section.name}}</h1>
                            <div class='hvac-tab-container-link-list-container' *ngFor="let linkList of section.list">
                                <h3 *ngIf="linkList.name" class='hvac-tab-container-link-header'>
                                    {{linkList.name}}
                                </h3>
                                <ul class='hvac-tab-container-link-list'>
                                    <li *ngFor="let link of linkList.list">
                                        <a *ngIf="link.target === '_blank'; else routerLink" [href]="link.link" [target]="link.target" class="hvac-tab-container-link-link">
                                            <ng-template [ngIf]="enableStyleForNewTabLinksFrCrntPath" [ngIfElse]="showTextWithoutStyle">
                                                <h3 class="hvac-tab-container-link-count">{{link.count}}</h3>
                                                <div class="hvac-tab-container-link-details">
                                                    <p class='hvac-tab-container-link-linktitle'>{{link.name}}</p>
                                                    <p *ngIf="link.subtitle" class='hvac-tab-container-link-subtitle'>{{link.subtitle}}</p>
                                                </div>
                                            </ng-template>
                                            <ng-template #showTextWithoutStyle>
                                                {{link.name}}
                                            </ng-template>
                                        </a>
                                        <ng-template #routerLink>
                                            <a [routerLink]="[link.link]" [target]="link.target" class="hvac-tab-container-link-link">
                                                <ng-template [ngIf]="section.theme === 'story_highlights'" [ngIfElse]="regularLink">
                                                    <h3 class="hvac-tab-container-link-count">{{link.count}}</h3>
                                                    <div class="hvac-tab-container-link-details">
                                                        <p class='hvac-tab-container-link-linktitle'>{{link.name}}</p>
                                                        <p *ngIf="link.subtitle" class='hvac-tab-container-link-subtitle'>{{link.subtitle}}</p>
                                                    </div>
                                                </ng-template>
                                                <ng-template #regularLink>
                                                    {{link.name}}
                                                </ng-template>
                                            </a>
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
