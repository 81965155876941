<div class='hvac-signup-panel'>
    <div class='hvac-signup-cell hvac-signup-heading'>
        <h3 class='hvac-h3 hvac-signup-heading-title'>{{ 'HVACP_SIGNUP.HEADLINE' | translate }}</h3>
        <h5 class='hvac-h5' [innerHtml]="signupHeadHtml$ | async"></h5>
    </div>
    <div class='hvac-signup-cell'>
        <div class='hvac-login-widget-container'></div>
        <div class='hvac-reg-info-form'>
            <form [formGroup]="goSiteRegistrationForm">
                <utc-input inputId="dealer-phone-number-input"
                    [inputControl]="goSiteRegistrationForm.controls.phone"
                    type="text"
                    [disabled]="false"
                    label="{{ 'HVACP_SIGNUP.PHONE_FIELD_PROMPT' | translate }}"
                    [error]="showError"
                    [hideLabel]="false"
                    class="hvac-dealer-phone-number-input"></utc-input>
                <utc-input inputId="dealer-password-input"
                    [inputControl]="goSiteRegistrationForm.controls.password"
                    type="password"
                    [disabled]="false"
                    label="{{ 'HVACP_SIGNUP.PW_PROMPT' | translate }}"
                    [errorMessage]="errorMessage"
                    [error]="showError"
                    [hideLabel]="false"
                    class="hvac-dealer-signup-easy-pw-input"></utc-input>
                <div class='hvac-login-signup-container'>
                    <button hvacButton="primary" [disabled]="!goSiteRegistrationForm.controls.phone.valid || !goSiteRegistrationForm.controls.password.valid" (click)="onSubmit()">
                        <span>{{ 'HVACP_SIGNUP.SUBMIT_BUTTON' | translate }} </span>
                        <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
