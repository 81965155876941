import { ContentComponent } from 'common/content/services/content-component.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LinkType } from '../../../components/link/link.component';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'utc-check-list',
    templateUrl: './check-list.component.html',
    styleUrls: ['./check-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckListComponent implements ContentComponent {
    @Input() data: BaseContentEntity;
    linkType: LinkType = LinkType.Button;
}
