import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ErrorService } from 'common/content/services/error.service';
import { environment } from 'common/environments/environment';
import { BrandService } from 'common/services/brand.service';
import { CatalogService } from 'common/services/catalog.service';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
    FetchCatalogCategories,
    FetchPartsCatalogCategories,
    FetchCatalogCategoryProducts,
    FetchCatalogProductAttributes,
    FetchCatalogProductDataOverview,
    FetchCatalogProductDocuments,
    ReceiveCatalogCategories,
    ReceiveCatalogCategoryProducts,
    ReceiveCatalogProductAttributes,
    ReceiveCatalogProductDataOverview,
    ReceiveCatalogProductDocuments,
    SetCurrentBrand,
    ReceivePartsCatalogCategories,
    ClearCatalogCategories
} from './catalog.actions';

export interface CatalogCategory {
    urn: string;
    name: string;
    sortOrder: number;
    children: CatalogCategory[];
    parent: CatalogCategory | null;
    isPartCategory?: boolean
}
export interface PartsCatalogCategory {
    name: string;
    code: string;
    children: PartsCatalogCategory[];
}

export interface CategoryProducts {
    [categoryUrn: string]: string[];
}

export interface CatalogStateModel {
    categories: CatalogCategory[];
    categoryProducts: CategoryProducts;
    currentBrand: string;
}

@State<CatalogStateModel>({
    name: 'catalog',
    defaults: {
        categories: [],
        categoryProducts: {},
        currentBrand: ''
    }
})
@Injectable({ providedIn: 'root' })
export class CatalogState {
    public isTotaline = environment.brand === 'Totaline';
    constructor(
        private readonly catalogApiService: CatalogService,
        private readonly brandService: BrandService,
        private readonly errorService: ErrorService
    ) { }

    @Selector()
    static catalogCategories({ categories }: CatalogStateModel): CatalogCategory[] {
        return categories;
    }

    @Selector()
    static partsCatalogCategories({ categories }: CatalogStateModel): CatalogCategory[] {
        return categories;
    }

    @Selector()
    static categoryProducts({ categoryProducts }: CatalogStateModel): CategoryProducts {
        return categoryProducts;
    }

    @Selector()
    static currentBrand({ currentBrand }: CatalogStateModel): string {
        return currentBrand;
    }

    @Action(FetchCatalogCategories)
    fetchCatalogCategories(ctx: StateContext<CatalogStateModel>) {
        return this.catalogApiService
            .fetchPrimaryCategories()
            .pipe(
                tap((catalogData) => {
                    if ((catalogData.categories && catalogData.categories?.length <= 0) || (catalogData.items && catalogData.items?.length <= 0)) {
                        ctx.patchState({ categories: [] });

                        return;
                    }
                    ctx.dispatch(new ReceiveCatalogCategories(catalogData));
                })
            );
    }

    @Action(FetchPartsCatalogCategories)
    fetchPartsCatalogCategories(ctx: StateContext<CatalogStateModel>) {
        return this.catalogApiService
            .fetchPartsCategories(`${environment.brand.toLowerCase() === 'totaline' ? 'brands=TTL&' : ''}fields=FULL&includeSubCategories=true`)
            .pipe(
                tap((catalogData) => {
                    if (catalogData.categories && catalogData.categories?.length <= 0) {
                        ctx.patchState({ categories: [] });

                        return;
                    }
                    ctx.dispatch(new ReceivePartsCatalogCategories(catalogData));
                })
            );
    }

    @Action(ReceiveCatalogCategories)
    receiveCatalogCategories(ctx: StateContext<CatalogStateModel>,
        action: ReceiveCatalogCategories) {
        return ctx.patchState({
            categories: action.categories.items?.map((category): CatalogCategory => {
                const catalogCategory: CatalogCategory = {
                    urn: category.urn,
                    name: category.name,
                    sortOrder: category.sortOrder,
                    parent: null,
                    children: []
                };

                catalogCategory.children = (category.subcategories || []).map((subcategory): CatalogCategory => ({
                    urn: subcategory.urn,
                    name: subcategory.name,
                    sortOrder: subcategory.sortOrder,
                    children: [],
                    parent: catalogCategory
                }));

                return catalogCategory;
            })
        });
    }

    @Action(ReceivePartsCatalogCategories)
    receivePartsCatalogCategories(ctx: StateContext<CatalogStateModel>,
        action: ReceivePartsCatalogCategories) {
        return ctx.patchState({
            categories: action.categories.categories?.map((category): CatalogCategory => {
                const categories: CatalogCategory = {
                    name: category.name,
                    urn: category.code,
                    sortOrder: 1,
                    parent: null,
                    isPartCategory: true,
                    children: []
                };

                categories.children = (category.subCategories || []).map((subcategory): CatalogCategory => ({
                    name: subcategory.name,
                    urn: subcategory.code,
                    children: [],
                    sortOrder: 1,
                    isPartCategory: true,
                    parent: categories
                }));

                return categories;
            })
        });
    }

    @Action(ClearCatalogCategories)
    removeSearchFilter(ctx: StateContext<CatalogStateModel>) {
        ctx.patchState({ categories: [] });
    }

    @Action(FetchCatalogCategoryProducts)
    fetchCatalogCategoryProducts(ctx: StateContext<CatalogStateModel>, action: FetchCatalogCategoryProducts) {
        return this.catalogApiService
            .fetchCategoryProducts(action.category)
            .pipe(
                tap((categoryProducts) => ctx.dispatch(new ReceiveCatalogCategoryProducts(
                    action.category,
                    categoryProducts
                )))
            );
    }

    @Action(ReceiveCatalogCategoryProducts)
    receiveCatalogCategoryProducts(ctx: StateContext<CatalogStateModel>, action: ReceiveCatalogCategoryProducts) {
        return ctx.patchState({
            categoryProducts: {
                ...ctx.getState().categoryProducts,
                [action.category]: action.categoryProducts.items.filter((product) => product.active).map(({ urn }) => urn)
            }
        });
    }

    @Action(FetchCatalogProductAttributes)
    fetchCatalogProductAttributes(ctx: StateContext<CatalogStateModel>, action: FetchCatalogProductAttributes) {
        return this.catalogApiService.fetchProductAttributes(action.product).pipe(
            withLatestFrom(this.brandService.getBrands()),
            tap(([productAttributes, allBrands]) => {
                if (!productAttributes) {
                    return this.errorService.errorStatus$.next(404);
                }

                this.errorService.errorStatus$.next(0);

                const filteredBrands = allBrands.filter((item) => productAttributes?.brandNames.includes(item));
                const brand = filteredBrands[0] ? filteredBrands[0] : productAttributes.brandNames[0];
                ctx.dispatch(new SetCurrentBrand(brand));
                ctx.dispatch(new ReceiveCatalogProductAttributes(productAttributes));
            })
        );
    }

    @Action(FetchCatalogProductDataOverview)
    fetchCatalogProductDataOverview(ctx: StateContext<CatalogStateModel>, action: FetchCatalogProductDataOverview) {
        return this.catalogApiService.fetchProductDataOverview(action.product).pipe(
            tap((productAttributes) => ctx.dispatch(new ReceiveCatalogProductDataOverview(productAttributes)))
        );
    }

    @Action(FetchCatalogProductDocuments)
    fetchCatalogProductDocuments(ctx: StateContext<CatalogStateModel>, action: FetchCatalogProductDocuments) {
        return this.catalogApiService.fetchProductDocuments(action.product).pipe(
            tap((productDocuments) => ctx.dispatch(new ReceiveCatalogProductDocuments(productDocuments, action.product)))
        );
    }

    @Action(SetCurrentBrand)
    setCurrentBrand(ctx: StateContext<CatalogStateModel>, action: SetCurrentBrand): CatalogStateModel {
        return ctx.patchState({ currentBrand: action.currentBrand });
    }
}

