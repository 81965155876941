import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PartsService } from 'common/views/parts/service/parts.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'hvac-part-suggestion',
    templateUrl: './part-suggestion.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PartSuggestionComponent implements OnDestroy {
    @Input() partNumber: string = '';
    @Input() imageUrl: string;
    ngOnDestroy$ = new Subject();
    public image: string;

    constructor(
        private partsService: PartsService,
        private router: Router
    ) {}


    ngOnInit() {
        this.image = this.imageUrl || '';
    }


    choosePart() {
        const catalogRoute = this.partsService.getCatalogRoute();

        this.partsService.getPartsDetails(this.partNumber).pipe(
            takeUntil(this.ngOnDestroy$),
            catchError((error) => {
                this.partsService.partsDetails$.next(null);
                this.router.navigate([`${catalogRoute}/parts-details`], { queryParams: { part: this.partNumber } });
                throw error;
            })
        ).subscribe((data) => {
            this.partsService.partsDetails$.next(data);
            const { part, category, subcategory } = this.partsService.getPartDetailUrl(data);

            if (category && subcategory) {
                this.router.navigate([catalogRoute, category, subcategory], { queryParams: { part: part } });
            }
            else {
                this.router.navigate([`${catalogRoute}/parts-details`], { queryParams: { part: part } });
            }
        });
    }


    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
