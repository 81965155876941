import { CommonComponentsModule } from 'common/components/common-components.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextModule } from 'common/pipes/rich-text/rich-text.module';
import { BrowseComponent } from './browse.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CatalogProductsComponent } from './components/catalogProducts/catalog-products.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SubcategoriesComponent } from './components/subcategories/subcategories.component';
import { CategoryTileComponent } from './components/tiles/category-tile.component';
import { ProductTileComponent } from './components/tiles/product-tile.component';
import { SubcategoryTileComponent } from './components/tiles/subcategory-tile.component';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        BrowseComponent,
        CatalogProductsComponent,
        CategoriesComponent,
        CategoryTileComponent,
        ProductTileComponent,
        SubcategoriesComponent,
        SubcategoryTileComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        RichTextModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        BrowseComponent,
        CategoriesComponent,
        SubcategoriesComponent
    ]
})
export class BrowseModule {}
