import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'common/environments/environment';
import { Observable, of } from 'rxjs';

export interface ApiOptions {
    headers: HttpHeaders;
}

@Injectable({ providedIn: 'root' })
export class ApiOptionsService {
    getAuthedHttpOptions(): Observable<ApiOptions> {
        return of({ headers: new HttpHeaders() });
    }

    getHttpOptions(): ApiOptions {
        return { headers: new HttpHeaders({ appid: environment.appId }) };
    }

    getOpenHttpOptions(): ApiOptions {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            })
        };
    }
}
