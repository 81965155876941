<div class="hvac-select-container" [ngClass]="{
    'hvac-input-min-label': minimizeLabel,
    'hvac-select-primary': variant==='primary',
    'hvac-select-small': size === 'small',
    'hvac-select-no-shadow': shadow === 'none',
    'hvac-select-light-shadow': shadow === 'light',
    'hvac-select-multiple': multiple,
    'hvac-open': isOpen,
    'hvac-disabled': disabled
}" [formGroup]="group" (hvacClickOutside)="handleClick(true)">
    <label *ngIf="valueText?.length === 0" [attr.for]="selectId" class="hvac-input-label">{{ defaultOption }}<abbr *ngIf="required" title="required" class="hvac-select-label-required">{{ required ? '*' : ''}}</abbr></label>
    <select [attr.required]="required || null" [formControlName]="control" class="hvac-select-control" [attr.id]="selectId" [multiple]="multiple ? 'multiple': null" [attr.disabled]="disabled ? 'disabled' : null">
        <option class="hvac-select-control-option" *ngIf="placeholder" [ngValue]="defaultEmptyVal">{{ placeholder }}</option>
        <option class="hvac-select-control-option" *ngFor="let option of options$ | async" [ngValue]="option">{{ option.name || option }}</option>
    </select>
    <div class="hvac-select-mask" [ngClass]="{ 'hvac-select-error': error }" (click)="handleClick()">
        <span class="hvac-select-mask-text" [ngClass]="{'hvac-select-mask-text-disbaled': disabled}">{{ valueText }}</span>
        <utc-svg class="hvac-select-dropdown-icon" src="assets/icons/chevron-down.svg"></utc-svg>
    </div>
    <div *ngIf="(errorMessage && error) || infoMessage" class='hvac-input-message-container hvac-input-show-message'>
        <div *ngIf="errorMessage">
            <utc-svg src="assets/icons/info.svg"></utc-svg>
            <span class='hvac-input-message' [innerHTML]="errorMessage | safe:'html'"></span>
        </div>
        <div *ngIf="infoMessage">
            <span class='hvac-input-message' [innerHTML]="infoMessage | safe:'html'"></span>
        </div>
    </div>
    