import { UIContentResponse } from 'common/models';
import { ContentFilteringPreferenceType } from './content.state';

export class SetContentLoading {
    static readonly type = '[Content] SetContentLoading';
    constructor(public readonly loading: boolean) { }
}

export class ClearUIPageContent {
    static readonly type = '[Content] ClearUIPageContent';
}

export class FetchUIContent {
    static readonly type = '[Content] FetchUIContent';
}

export class ReceiveUIContent {
    static readonly type = '[Content] ReceiveUIContent';
    constructor(public readonly uiContent: UIContentResponse) {}
}

export class FetchUIPageContent {
    static readonly type = '[Content] FetchUIPageContent';
    constructor(public readonly url: string) { }
}

export class SetContentFilteringPreference {
    static readonly type = '[Content] SetContentFilteringPreference';
    constructor(public readonly preferenceType: ContentFilteringPreferenceType) { }
}

export class SetContentBrandPreference {
    static readonly type = '[Content] SetContentBrandPreference';
    constructor(public readonly brand: string) { }
}

export class SetBrandLists {
    static readonly type = '[Content] SetBrandLists'
    constructor(public brandList: string[]) {}
}

export class SetDealerAccessCheckLoading {
    static readonly type = '[Content] SetDealerAccessCheckLoading';
    constructor(public readonly loading: boolean) { }
}
