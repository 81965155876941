import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textOverflow' })
export class TextOverflowPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(text: any, maxLength: number) {
        let textTransform = text.changingThisBreaksApplicationSecurity ? text.changingThisBreaksApplicationSecurity : text;
        if (textTransform.length > maxLength) {
            const substr = textTransform.substr(0, maxLength).trim();
            textTransform = substr.concat('...');
        }

        return textTransform;
    }
}
