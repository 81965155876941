import { Product, Document, Content, Part } from 'common/models';

export const PLACEHOLDER_RESOURCE_TYPE = 'placeholder';

export interface Placeholder {
    id: string;
    resourceType: 'placeholder';
}

export type All = Product | Document | Content | Part | Placeholder;
