<div class='hvac-login-panel' [ngClass]="{ 'hvac-login-panel-visible': loginPanelVisible$ | async, 'hvac-login-panel-logged-in': (isLoggedIn$ | async)}" #panel>
    <div class='hvac-login-heading'><h2 class='hvac-h2' [ngClass]="{ 'hvac-h2-icp': isICP }">{{ isICP ? content.HVACP_PARTNERS_SIGN_IN : content.SIGN_IN | translate }}</h2>
        <button *ngIf="isICP || isPublicCBP" #loginCloseButton hvacButton="default" size="small" hvacButtonIcon hvacButtonDark class='hvac-button hvac-button-small hvac-button-dark' (click)="closeSideNav()">
            <utc-svg src="assets/icons/close.svg"></utc-svg>
            <span>{{ content.CLOSE | translate }}</span>
        </button>
    </div>
    <div class='hvac-login-body'>
        <div id='widget-container' class='hvac-login-widget-container'></div>
        <ng-container *ngIf="registrationContent">
            <div *ngIf="!(isLoggedIn$ | async); else loggedOut" class='hvac-registration-section'>
                <h2 class='hvac-h2'>{{ isICP ? registrationContent.DONT_HAVE_ACCOUNT : registrationContent.SIGN_UP | translate }}</h2>
                <div class='hvac-registration-body'>
                    <div *ngIf="isICP; else publicSite">
                        <p class="hvac-registration-text1">{{ registrationContent.SIGN_IN_WITH_DEALER_CREDENTIALS1 | translate }}</p>
                        <button class="hvac-text-link hvac-signup-link" (click)="onSignUpClick()"> {{ registrationContent.SIGN_UP | translate }}</button>
                        <p class="hvac-registration-text2">{{ registrationContent.SIGN_IN_WITH_DEALER_CREDENTIALS2 | translate }}</p>
                    </div>
                    <ng-template #publicSite>
                        <p class='hvac-registration-body hvac-registration-body-public'>{{ registrationContent.CREATE_ACCOUNT_DESCRIPTION | translate }}</p>
                    </ng-template>
                </div>
                <div class='hvac-login-signup-container'>
                    <a *ngIf="!isICP" hvacLink="alt" class='hvac-create-account-link' href="{{ registrationContent.createAccountLink }}">{{ registrationContent.CREATE_ACCOUNT_LEARN_MORE | translate }}</a>
                    <a [ngClass]="{'icp-signup-button' : isICP }" hvacButton="secondary" hvacButtonDark hvacButtonIcon [href]= "signInButtonAction$ | async" target="{{isICP ? '_blank' : '_self'}}"
                        class='hvac-register-button'><span>{{ isICP ? registrationContent.SIGN_IN : registrationContent.REGISTER | translate }}</span>
                        <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                    </a>
                </div>
                <div *ngIf="!isICP" class='hvac-login-terms-container'>
                    <a href='{{registrationContent.PRIVACY_LINK | translate}}' hvacLink="primary" rel="noopener noreferrer" target="_blank">{{registrationContent.PRIVACY | translate}}</a>
                    <a href='{{ registrationContent.TERMS_LINK | translate }}' hvacLink="primary" rel="noopener noreferrer" target="_blank">{{ registrationContent.TERMS | translate }}</a>
                </div>
            </div>
            <ng-template #loggedOut><div class='hvac-registration-section'>
                <h2 class='hvac-h2'>{{ content.SIGNED_IN | translate }}</h2>
                <button hvacButton="primary" hvacButtonDark hvacButtonIcon (click)="logout()"><span>{{ content.SIGN_OUT | translate }}</span>
                    <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                </button>
            </div></ng-template>
        </ng-container>
    </div>
</div>
<div (click)="closeSideNav()" class='hvac-login-panel-overlay' *ngIf="loginPanelVisible$ | async"></div>
