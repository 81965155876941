interface PublicFeatures {
    auth: {
        login: boolean;
        externalLogin: boolean;
    };
    brandFilter: {
        brand: boolean;
        content: boolean;
    };
    covid19Information: boolean;
    goSiteRegistration?: boolean;
    newsLetterSignUp: boolean;
    regulatoryQuestionnaire: boolean;
    product: {
        worksWellWith: boolean;
        options: boolean;
    };
    searchContent?: boolean;
    signInTeaser?: boolean;
    socialLogin: boolean;
    suggestions: boolean;
    partsCatalog: boolean;
}

interface CrossReference {
    categories: string;
    categoryQuery: string;
    suggest: string;
    crossReferenceProductSearch: string;
    crossReferenceFilterSearch: string;
    productDetail: string;
}

export interface EnvConfig {
    envPath: string;
    env: string;
    features: {
        auth: {
            login: boolean;
            externalLogin: boolean
        };
        brandFilter: {
            brand: boolean;
            content: boolean
        };
        covid19Information: boolean;
        goSiteRegistration: boolean;
        newsLetterSignUp: boolean;
        regulatoryQuestionnaire: boolean;
        product: {
            worksWellWith: boolean;
            options: boolean
        };
        searchContent: boolean;
        signInTeaser: boolean;
        socialLogin: boolean;
        suggestions: boolean;
        partsCatalog: boolean;
    };
    gatewayPath: string;
    gatewayPathNoProtocol: string;
    iframePath: string;
    privateBaseUrl: string;
    production: boolean;
    auth: {
        baseUrl: string;
        clientId: string;
        identityProviders?: {
            type: string;
            id: string;
        }[];
    }
}

export interface PublicConfig {
    appId: string;
    env: string;
    appziSrc?: string,
    auth: {
        baseUrl: string;
        clientId: string;
        identityProviders?: {
            type: string;
            id: string;
        }[];
    };
    brand: string;
    brandPath: 'icp' | 'carrier' | 'bryant' | 'totaline';
    envPath: string;
    features: PublicFeatures;
    gatewayPath: string;
    gatewayPathNoProtocol: string;
    iframePath: string;
    jpMorganPath?: string;
    privateBaseUrl: string;
    production: boolean;
    publicBaseUrl: string;
    siteCode: number;
    socialLogin?: {
        idpDisplay: string;
    };
}

export interface PublicEnvironment {
    production: boolean;
    env: string;
    brand: string;
    appId: string;
    appziSrc?: string;
    captchaKey?: string;
    secureBaseUrl: string;
    logoutUrl: string;
    loginErrorUrl: string;
    logoutFrameUrl: string;
    secureCallbackUrl?: string;
    chatbotUrl?: string;
    primerFrameUrl?: string;
    api: {
        accountQuicklinksPersonalizationApi?: string;
        accountStatusApi: string;
        accountUserApi: string;
        accountUserPrefApi: string;
        captcha?: string;
        catalog: string;
        coolCalcTerms?: string;
        coolCalcSupportRequest?: string;
        contact?: string;
        content: string;
        legacyLogin: string;
        companyId: string;
        distributorLocator: string;
        document?: string;
        documentPublic?: string;
        documentPublicParts?: string;
        lastLoginApi: string;
        payment?: string;
        paymentConfirmation?: string;
        publicContent?: string;
        search: string;
        siteMap: string;
        suggestions: string;
        jpMorganApi?: string;
        totalineContent: string;
        crossReference?: CrossReference;
        pim: {
            catalog: string;
            suggestions: string;
            partDetail: string;
            search: string;
        }
    };
    auth: {
        createAccountUrl: string;
        createAccountRegisterUrl: string;
        baseUrl: string;
        clientId: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
        identityProviders?: {
            type: string;
            id: string;
        }[]
    }
    frameContent?: {
        baseUrl: string;
        searchUrl?: string;
        maxLoaderTime: number;
        inboxUrl?: string;
    };
    features: PublicFeatures;
    documents?: {
        whatsNewAge?: number;
        bulletinAge?: number;
    };
    socialLogin?: {
        idpDisplay: string;
    }
}
