import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Option {
    name: string;
    value: string;
    disabled?: boolean;
}

@Component({
    selector: 'hvac-select-menu',
    templateUrl: './select-menu.component.html',
    styleUrls: ['./select-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SelectMenuComponent {
    @Input() size: 'small' | 'large' = 'large';
    @Input() disabled: boolean;
    @Input() placeholder: string = '';
    @Input() icon: string;
    @Input() addPlaceholderAsOption: Boolean;
    @Output() public selected = new EventEmitter<string>();

    public initialValue = {
        name: '',
        value: ''
    };

    public selectedOption$ = new BehaviorSubject<Option>(this.initialValue);
    public active: boolean;
    options$ = new BehaviorSubject<Option[] | null>([] as Option[]);
    loading$ = new BehaviorSubject<boolean | null>(false);
    private _selectedValue: string;

    get selectedValue(): string {
        return this._selectedValue;
    }

    get options(): Option[] {
        return this.options;
    }

    get loading(): boolean {
        return this.loading;
    }

    @Input() set selectedValue(value) {
        if (this._selectedValue !== value) {
            this._selectedValue = value;
            this.setSelectedOption();
        }
    }

    @Input()
    set options(value: Option[] | null) {
        const sortedOptions = value?.slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
        this.options$.next(sortedOptions!);
    }

    @Input()
    set loading(value: boolean | null) {
        this.loading$.next(value);
    }

    ngOnInit() {
        if (this.selectedValue) {
            this.setSelectedOption();
        }

        this.options$.subscribe((categoryOptions: Option[] | null) => {
            if (this.addPlaceholderAsOption) {
                this.initialValue = {
                    name: this.placeholder,
                    value: ''
                };
                categoryOptions?.unshift(this.initialValue);
            }
            const selectedOption = categoryOptions?.find((option: Option) => option.value === this.selectedValue) || this.initialValue;
            this.selectedOption$.next(selectedOption);
        });
    }

    setSelectedOption() {
        const selectedOption = this.options$.value?.find((option) => option.value === this.selectedValue) || this.initialValue;
        this.selectedOption$.next(selectedOption);
    }

    setSelected(option: Option) {
        this._selectedValue = option.value;
        this.selectedOption$.next(option);
        this.active = false;
        this.selected.emit(option.value);
    }

    toggleOptions() {
        this.active = !this.active;
    }
}
