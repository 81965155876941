import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Event, Router, Scroll } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { httpInterceptorProviders } from 'common/interceptors/interceptor.provider';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { ContentRoutingModule } from 'common/content/content-routing.module';
import { ContentModule } from 'common/content/content.module';
import { environment } from 'common/environments/environment';
import { AnalyticsModule } from 'common/services/analytics/analytics.module';
import { provider as oktaProvider } from 'common/services/okta/okta.provider';
import { OktaService } from 'common/services/okta/okta.service';
import { PagePositionService } from 'common/services/page-position.service';
import { AuthState } from 'common/store/auth/auth.state';
import { BrandingState } from 'common/store/branding/branding.state';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { DocumentsState } from 'common/store/documents/documents.state';
import { PartsState } from 'common/store/parts/parts.state';
import { GdprState } from 'common/store/gdpr/gdpr.state';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { ProductsState } from 'common/store/products/products.state';
import { HeaderSearchVisible } from 'common/store/search/search.actions';
import { SearchState } from 'common/store/search/search.state';
import { SiteContentState } from 'common/store/site-content/site-content.state';
import { ClearSuggestions } from 'common/store/suggestions/suggestions.actions';
import { SuggestionsState } from 'common/store/suggestions/suggestions.state';
import { MobileMenuVisible } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { AppRoutingModule } from 'public/app/app-routing.module';
import { ComponentsModule } from 'public/app/components/components.module';
import { filter } from 'rxjs/operators';
import { SafePipeModule } from 'safe-pipe';
import { ApiOptionsServicePublic } from './services/api-options/api-options-public.service';
import { AppComponent } from './app.component';
import { CallbackModule } from './views/callback/callback.module';
import { BrowseModule } from 'common/views/browse/browse.module';
import { WINDOW_PROVIDERS } from 'common/window.provider';
import { AppCommonModule } from 'common/app-common.module';
import { PlatformModule } from '@angular/cdk/platform';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/translations/', '.json');
}

export const apiOptionsProvider: Provider = {
    provide: ApiOptionsService,
    useClass: ApiOptionsServicePublic
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppCommonModule,
        BrowserModule,
        BrowseModule,
        ComponentsModule,
        ContentModule,
        AppRoutingModule,
        ContentRoutingModule,
        AnalyticsModule,
        CallbackModule,
        PlatformModule,
        NgxsModule.forRoot([
            AuthState,
            BrandingState,
            CatalogState,
            ContentState,
            DocumentsState,
            PartsState,
            GdprState,
            LoginPanelState,
            NavigationState,
            ProductsState,
            SearchState,
            SiteContentState,
            SuggestionsState,
            UiState
        ], { developmentMode: !environment.production }),
        NgxsRouterPluginModule.forRoot(),
        SafePipeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: true })
    ],
    providers: [
        httpInterceptorProviders,
        oktaProvider,
        apiOptionsProvider,
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        translate: TranslateService,
        router: Router,
        viewportScroller: ViewportScroller,
        store: Store,
        private readonly pagePositionService: PagePositionService,
        private readonly okta: OktaService
    ) {
        translate.setDefaultLang('en');
        translate.use('en');

        const language = translate.getBrowserLang();
        translate.use(language.match(/en|fr/) ? language : 'en');

        this.okta.resumePublicSession();

        router.events.pipe(
            filter((evt: Event): evt is Scroll => evt instanceof Scroll)
        ).subscribe((evt) => {
            // Close the mobile menu on navigation change
            store.dispatch(new MobileMenuVisible(false));

            // Clear the suggestions on navigation change
            store.dispatch(new ClearSuggestions());

            // Reset Visibility of Nav Links by removing class


            if (!window.location.pathname.includes('search')) {
                store.dispatch(new HeaderSearchVisible(false));
            }

            // Routes that dont include browse
            if (!window.location.pathname.includes('browse')) {
                // Reset focus on navigation change
                const htmlEl = document.getElementById('utc-html') as HTMLElement;
                if (htmlEl) {
                    htmlEl.focus();
                }

                if (evt.position) {
                    // backward navigation
                    viewportScroller.scrollToPosition(evt.position);
                }
                else if (evt.anchor) {
                    // anchor navigation
                    viewportScroller.scrollToAnchor(evt.anchor);
                }
                else {
                    // forward navigation
                    viewportScroller.scrollToPosition([0, 0]);
                }
            }

            // Routes that do include browse
            if (window.location.pathname.includes('browse') && !window.location.pathname.includes('parts-catalog')) {
                const browseEl = document.querySelector('utc-browse') as HTMLElement;
                const browseElPosition = this.pagePositionService.getAbsoluteElementPosition(browseEl);
                const bufferForHeaderHeight = 62;

                window.scroll({
                    left: 0,
                    top: browseElPosition.top - bufferForHeaderHeight,
                    behavior: 'smooth'
                });
            }
        });
    }
}
