import { Pipe, PipeTransform } from '@angular/core';
const usCanadaRegex =
    /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

@Pipe({ name: 'phone' })
export class FormatphonenumberPipe implements PipeTransform {
    transform(value?:string) {
        const cleanedNumber = (`${value}`).replace(/\D/g, '');
        const matchedNumber = cleanedNumber.match(usCanadaRegex);
        let formattedNumber = '';
        if (matchedNumber) {
            formattedNumber = `(${matchedNumber[2]}) ${matchedNumber[3]}-${matchedNumber[4]}`;
        }

        return formattedNumber;
    }
}
