<div class="hvac-public-link">
    <ng-container *ngIf="hasSingleLink; else multipleLinks">
        <hvac-link [link]="primaryLink" class="hvac-public-link-item">
            <utc-media class="hvac-public-link-image-container" [content]="content"></utc-media>
            <div class="hvac-public-link-details">
                <h3 class="hvac-public-link-name" [innerHTML]="content.title | richText"></h3>
                <p class="hvac-public-link-description" [innerHTML]="content.text | richText"></p>
            </div>
        </hvac-link>
    </ng-container>
    <ng-template #multipleLinks>
        <div class="hvac-public-link-item">
            <utc-media class="hvac-public-link-image-container" [content]="content"></utc-media>
            <div class="hvac-public-link-details">
                <h3 class="hvac-public-link-name" [innerHTML]="content.title | richText"></h3>
                <p class="hvac-public-link-description" [innerHTML]="content.text | richText"></p>
            </div>
            <span class="hvac-public-link-extras" *ngIf="hasMultipleLinks">
                <hvac-link target="_blank" [link]="link" *ngFor="let link of links" class="hvac-public-link-extras-link">
                    <img [ngClass]="{'hvac-store-logo-hidden' : !isPlatformMatch(link) }" [src]="getLinkImage(link)" class="hvac-public-link-image hvac-app-store-badge" alt={{link.linkDescription}} />
                </hvac-link>
            </span>
        </div>
    </ng-template>
</div>
