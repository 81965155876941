import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from 'public/app/views/callback/callback.component';
import { DistributorLocatorComponent } from 'common/views/distributor-locator/distributor-locator.component';
import { environment } from '../../common/environments/environment';
import { PaymentsComponent } from './views/payments/payments.component';

const cbpRoutes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('public/app/views/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'products/detail',
        loadChildren: () => import('public/app/views/products/products.module').then((m) => m.ProductsModule)
    },
    {
        path: 'search',
        loadChildren: () => import('common/views/search/search.module').then((m) => m.SearchModule)
    },
    {
        path: 'covid',
        loadChildren: () => import('public/app/views/covid/covid.module').then((m) => m.CovidModule)
    },
    {
        path: 'products/parts/parts-catalog',
        loadChildren: () => import('common/views/parts/parts.module').then((m) => m.PartsModule)
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'signout',
        redirectTo: '/home'
    },
    {
        path: 'logout',
        redirectTo: '/home?logout=true'
    },
    {
        path: 'callback',
        component: CallbackComponent
    }
];

const icpRoutes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('public/app/views/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'products/detail',
        loadChildren: () => import('public/app/views/products/products.module').then((m) => m.ProductsModule)
    },
    {
        path: 'support/technical-literature',
        loadChildren: () => import('common/views/product-literature/product-literature.module').then((m) => m.ProductLiteratureModule)
    },
    {
        path: 'search',
        loadChildren: () => import('common/views/search/search.module').then((m) => m.SearchModule)
    },
    {
        path: 'covid',
        loadChildren: () => import('public/app/views/covid/covid.module').then((m) => m.CovidModule)
    },
    {
        path: 'products/parts/parts-catalog',
        loadChildren: () => import('common/views/parts/parts.module').then((m) => m.PartsModule)
    },
    {
        path: 'distributor-locator',
        component: DistributorLocatorComponent
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'signout',
        redirectTo: '/home'
    },
    {
        path: 'logout',
        redirectTo: '/home?logout=true'
    },
    {
        path: 'callback',
        component: CallbackComponent
    }
];

const totalineRoutes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('public/app/views/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'support/technical-literature',
        loadChildren: () => import('common/views/product-literature/product-literature.module').then((m) => m.ProductLiteratureModule)
    },
    {
        path: 'search',
        loadChildren: () => import('common/views/search/search.module').then((m) => m.SearchModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('public/app/views/contact/contact.module').then((m) => m.ContactModule)
    },
    {
        path: 'covid',
        loadChildren: () => import('public/app/views/covid/covid.module').then((m) => m.CovidModule)
    },
    {
        path: 'products/parts-catalog',
        loadChildren: () => import('common/views/parts/parts.module').then((m) => m.PartsModule)
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'distributor-locator',
        component: DistributorLocatorComponent
    },
    {
        path: 'signout',
        redirectTo: '/home'
    },
    {
        path: 'logout',
        redirectTo: '/home?logout=true'
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'products/cross-reference-tool',
        loadChildren: () => import('public/app/views/cross-reference/cross-reference.module').then((m) => m.CrossReferenceModule)
    }
];

const paymentRoute = {
    path: 'payments',
    component: PaymentsComponent
};

let routes: Routes = [] as Routes;

if (environment.brand === 'Carrier' || environment.brand === 'Bryant') {
    routes = cbpRoutes;

    if (environment.brand === 'Carrier') {
        routes.push(paymentRoute);
    }
}
else if (environment.brand === 'Totaline') {
    routes = totalineRoutes;
}
else {
    routes = icpRoutes;
}


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
