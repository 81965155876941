import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { CatalogCategory, CatalogState } from 'common/store/catalog/catalog.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BrowseNavigationService } from '../../services/browse-navigation.service';

@Component({
    selector: 'hvac-browse-subcategories',
    templateUrl: './subcategories.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SubcategoriesComponent implements OnDestroy {
    @Select(CatalogState.catalogCategories) catalogCategories$!: Observable<CatalogCategory[]>;

    ngOnDestroy$ = new Subject();

    category$ = this.browseNavigation.navigation$.pipe(
        takeUntil(this.ngOnDestroy$),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((nav: any) => nav.category)
    );

    categories$ = combineLatest([this.category$, this.catalogCategories$]).pipe(
        map(([category, catalogCategories]) => {
            const primaryCategory = catalogCategories.find((cat) => (cat.urn === category));
            if (!primaryCategory) {
                return [];
            }

            return primaryCategory.children;
        })
    );

    constructor(private readonly browseNavigation: BrowseNavigationService) {}

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
