import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { DatePickerDateFormat, enumDatePickerField } from 'common/components/date-range-picker/date-range-picker.component';
import { format } from 'date-fns';
import isValid from 'date-fns/isValid';

const ENABLE_LOG = false;

export interface DateRangeValidationResult {
    isValidResult: boolean;
    errorReason?: string;
}

@Injectable({ providedIn: 'root' })
export class DateRangePickerService {
    constructor(private translate: TranslateService) {
    }

    public getDateStringFromTime(dateTime: number | undefined, dateType: DatePickerDateFormat) {
        if (!dateTime || dateTime === 0) {
            return null;
        }

        if (dateType === 'date') {
            return format(dateTime, AppConstants.dateFormatMed);
        }

        return format(dateTime, AppConstants.dateFormatMedWithTimeEscaped);
    }

    public getTimezoneCorrectedDate(date: Date, dateType: DatePickerDateFormat) {
        if (dateType === 'date') {
            // Date input values lose their timezone offset, so this must be
            // added back in to get a correct date.
            const localOffset = date.getTimezoneOffset() * 60 * 1000;

            return new Date(date.getTime() + localOffset).getTime();
        }

        return date.getTime();
    }

    public getInValidDateResultValue(
        errorReason: string,
        inputDates: { startDate?: string | number, endDate?: string | number, minStartDate?: string | number, maxEndDate?: string | number }
    ): DateRangeValidationResult {
        if (ENABLE_LOG) {
            console.log(`Error Reason: ${errorReason}, input dates for invalid date value`, inputDates);
        }

        return {
            isValidResult: false,
            errorReason
        };
    }

    public getValidDateResultValue(): DateRangeValidationResult {
        return { isValidResult: true };
    }

    public getIgnoreDateResultValue(): DateRangeValidationResult {
        return { isValidResult: true };
    }

    public getTimeFromDateString(dateString: string, dateType: DatePickerDateFormat): number {
        return this.getTimezoneCorrectedDate(new Date(dateString), dateType);
    }

    public offsetMinutesToMilliseconds(startDateEndDateOffsetMinutes: number) {
        return startDateEndDateOffsetMinutes * (1000 * 60);
    }

    public getStartDateValidationResult(
        startDateTime: number | undefined,
        minStartDate: number | undefined,
        endDate: number | undefined,
        startDateEndDateOffsetMinutes: number,
        isRequired: boolean
    ): DateRangeValidationResult {
        if (!startDateTime) {
            return isRequired ?
                this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.EMPTY_DATE_VALUE', { fieldName: enumDatePickerField.START_DATE_FIELD.toString() }), { startDate: startDateTime })
                : this.getIgnoreDateResultValue();
        }

        if (!isValid(new Date(startDateTime))) {
            return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.INVALID_DATE_VALUE', { fieldName: enumDatePickerField.START_DATE_FIELD.toString() }), { startDate: startDateTime });
        }

        if (minStartDate) {
            if (startDateTime < minStartDate) {
                return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.START_DATE_LESS_THAN_MIN_DATE_ERROR'), {
                    startDate: startDateTime,
                    minStartDate: minStartDate
                });
            }
        }

        if (endDate && (startDateTime + this.offsetMinutesToMilliseconds(startDateEndDateOffsetMinutes)) > endDate) {
            return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.START_DATE_GREATER_THAN_END_DATE_ERROR'), {
                endDate: endDate,
                startDate: startDateTime
            });
        }

        return this.getValidDateResultValue();
    }

    public getEndDateValidationResult(
        endDateTime: number | undefined,
        maxEndDate: number | undefined,
        startDate: number | undefined,
        startDateEndDateOffsetMinutes: number,
        isRequired: boolean
    ): DateRangeValidationResult {
        if (!endDateTime) {
            return isRequired ?
                this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.EMPTY_DATE_VALUE', { fieldName: enumDatePickerField.END_DATE_FIELD.toString() }), { endDate: endDateTime })
                : this.getIgnoreDateResultValue();
        }

        if (!isValid(new Date(endDateTime))) {
            return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.INVALID_DATE_VALUE', { fieldName: enumDatePickerField.END_DATE_FIELD.toString() }), { endDate: endDateTime });
        }

        if (maxEndDate) {
            if (endDateTime > maxEndDate) {
                return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.END_DATE_GREATER_THAN_MAX_DATE_ERROR'), {
                    endDate: endDateTime,
                    maxEndDate: maxEndDate
                });
            }
        }

        if (startDate && endDateTime < (startDate + this.offsetMinutesToMilliseconds(startDateEndDateOffsetMinutes))) {
            return this.getInValidDateResultValue(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.END_DATE_LESS_THAN_START_DATE_ERROR'), {
                endDate: endDateTime,
                startDate: startDate
            });
        }

        return this.getValidDateResultValue();
    }

    public areDatesEqualInStringFormat(dateValue: number | undefined, dateString: string): boolean {
        return new Date(dateValue || 0).toISOString().substring(0, 10) === dateString;
    }
}

