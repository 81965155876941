<section class="hvac-featured-content hvac-page-section" *ngIf="data">
    <div class="hvac-featured-content-heading">
        <h3 class="hvac-featured-content-title hvac-h3" [innerHTML]="data.Content.title | richText"></h3>
        <p *ngIf="data.Content.text" class="hvac-featured-content-description" [innerHTML]="data.Content.text | richText"></p>
    </div>
    <ul class="hvac-featured-content-items">
        <li class="hvac-featured-content-item-container" *ngFor="let item of data.Content.list.$values">
            <utc-content-list-item [item]="item"></utc-content-list-item>
        </li>
    </ul>
    <a *ngIf="data.Content.links.linkExternal" [href]="data.Content.links.linkExternal" class="hvac-featured-content-cta hvac-shadow-button-with-icon" target="_blank">{{ data.Content.links.linkText }}</a>
</section>
