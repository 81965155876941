import { Component, ElementRef, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { LinkType } from 'common/components/link/link.component';
import { ContentComponent } from 'common/content/services/content-component.service';
import { JumpbarService } from 'common/content/services/jump-bar.service';
import { FileIcons } from 'common/models/fileIcons';

@Component({
    selector: 'hvac-accordian',
    templateUrl: './accordian.component.html',
    styleUrls: ['./accordian.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccordianComponent implements ContentComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    @Input() inList: boolean;
    @Input() colourTheme: boolean;

    accordianOpen: boolean;
    title = '';
    body: {
        Fragments: string[];
    };

    titleId = '';
    linkList: {
        link: string;
        isHeader: boolean;
        linkType: LinkType;
        icon: string;
        image: string;
        subtitle: string;
        title: string;
    }[] = [];

    listImage = null;

    constructor(
        private readonly jumpBarService: JumpbarService,
        private readonly element: ElementRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue) {
            this.initializeData();
        }
    }

    ngOnInit() {
        this.initializeData();
        if (!this.inList) {
            this.jumpBarService.registerJumpBarLink({
                link: this.titleId,
                id: [this.titleId],
                container: this.element.nativeElement
            });
        }
    }

    initializeData() {
        if (this.data?.Content) {
            this.title = this.data.Content.title;
            this.body = this.data.Content.text;
            this.titleId = this.data.Id;
            this.listImage = this.data.Content.primaryMedia && this.data.Content.primaryMedia.BinaryContent ? this.data.Content.primaryMedia.BinaryContent.Url : null;
            if (this.data.Content.links) {
                this.linkList = this.renderList(this.data.Content.links);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderList(linkList: any) {
        if (linkList.$values) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return linkList.$values.map((link: any) => {
                if (link.linkInternal && link.linkInternal.Content) {
                    const linkContent = link.linkInternal.Content;
                    // list of link components (proper way)

                    return ({
                        title: linkContent.title,
                        subtitle: linkContent.subTitle,
                        link: linkContent.links,
                        isHeader: false,
                        icon: this.getFileExtensionImage(linkContent.links.linkInternalParameter || linkContent.links.linkExternal),
                        image: linkContent.primaryMedia && linkContent.primaryMedia.BinaryContent ? linkContent.primaryMedia.BinaryContent.Url : null
                    });
                }

                // list of in-accordian-component links

                return ({
                    title: link.linkText,
                    link: link,
                    linkType: link.linkOperation.Key,
                    icon: this.getFileExtensionImage(link.linkInternalParameter || link.linkExternal),
                    isHeader: Boolean(!link.linkInternalParameter && !link.linkExternal),
                    image: null
                });
            });
        }
        // single Links
        else if (linkList.linkInternal) {
            const linkContent = linkList.linkInternal.Content;

            return [{
                title: linkContent.title,
                subtitle: linkContent.subTitle,
                link: linkContent.links,
                isHeader: false,
                icon: this.getFileExtensionImage(linkContent.links.linkInternalParameter || linkContent.links.linkExternal),
                image: linkContent.primaryMedia && linkContent.primaryMedia.BinaryContent ? linkContent.primaryMedia.BinaryContent.Url : null
            }];
        }
        else if (linkList.linkOperation && linkList.linkText) {
            return [{
                title: linkList.linkText,
                link: linkList,
                linkType: linkList.linkOperation.Key,
                icon: this.getFileExtensionImage(linkList.linkInternalParameter || linkList.linkExternal),
                isHeader: Boolean(!linkList.linkInternalParameter && !linkList.linkExternal),
                image: linkList.primaryMedia && linkList.primaryMedia.BinaryContent ? linkList.primaryMedia.BinaryContent.Url : null
            }];
        }

        return [];
    }

    getFileExtensionImage(link: string) {
        if (!link) {
            return FileIcons['pdf'];
        }
        const extension = link.split('.').pop() || 'pdf';

        return FileIcons[extension] ? FileIcons[extension] : FileIcons['pdf'];
    }

    toggleAccordian() {
        this.accordianOpen = !this.accordianOpen;
    }

    ngOnDestroy() {
        if (this.titleId && this.element && this.element.nativeElement) {
            this.jumpBarService.deregisterJumpBarLink({
                link: this.titleId,
                id: [this.titleId],
                container: this.element.nativeElement
            });
        }
    }
}
