<div class="hvac-public-link">
    <ng-template [ngIf]="resource.assetLink" [ngIfElse]="nonLink">
        <a [href]="resource.assetLink" target="_blank" class="hvac-public-link-item">
            <div class="hvac-public-link-image-container">
                <img class="hvac-public-link-image" [src]="resource.media.src" srcset="{{resource.media.src}}, {{resource.media.src2x}} 2x" [alt]="resource.title" />
            </div>
            <div class="hvac-public-link-details">
                <h3 class="hvac-public-link-name">{{ resource.title }}</h3>
                <p class="hvac-public-link-description">{{resource.description}}</p>
            </div>
        </a>
    </ng-template>
    <ng-template #nonLink>
        <div class="hvac-public-link-item">
            <div class="hvac-public-link-image-container">
                <img class="hvac-public-link-image" [src]="resource.media.src" srcset="{{resource.media.src}}, {{resource.media.src2x}} 2x" [alt]="resource.title">
            </div>
            <div class="hvac-public-link-details">
                <h3 class="hvac-public-link-name">{{ resource.title }}</h3>
                <p class="hvac-public-link-description">{{resource.description}}</p>
            </div>
            <span class="hvac-public-link-extras" *ngIf="resource.additionalLinks">
                <a target="_blank" [href]="link.link" *ngFor="let link of resource.additionalLinks" class="hvac-public-link-extra-content">
                    <img [src]="link.image" class="hvac-public-link-image hvac-app-store-badge" alt={{link.alt}} />
                </a>
            </span>
        </div>
    </ng-template>
</div>
