import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildHttpParams } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { ExtendedSearchResults, PartsSearchQueryParms, PartsSearchResults, SearchQuery, SearchResults } from 'common/models';
import { BrandFamily } from 'common/models/brand.model';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';
import { BrandOverwriteType, UserService } from './user.service';

const SEARCH_PAGE_SIZE = 24;

@Injectable({ providedIn: 'root' })
export class SearchService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly userService: UserService
    ) { }

    partsSearch(query: SearchQuery) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const userBrand$: Observable<BrandOverwriteType> = this.userService.getBrandFamily();

        return combineLatest([options$, userBrand$]).pipe(
            mergeMap(([options, userBrandFamily]) => {
                const params = this.buildPimParams(query, userBrandFamily);

                return this.httpClient.get<PartsSearchResults>(environment.api.pim.search, {
                    ...options,
                    params
                }).pipe(
                    map((response) => (response)),
                    catchError(() => of({} as PartsSearchResults))
                );
            })
        );
    }


    fetchSearchResults(query: SearchQuery) {
        const standardParams = this.buildParams(query);
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => {
                const productResults$ = this.httpClient.get<SearchResults>(environment.api.search, {
                    params: standardParams,
                    ...options
                }).pipe(
                    catchError(() => of({})),
                    map((response) => response)
                );

                return combineLatest([this.partsSearch(query), productResults$]).pipe(
                    map(([partsResults, productResults]) => {
                        if (Object.keys(partsResults).length) {
                            const { rcProducts, pagination, ...rest } = partsResults;
                            const partResults = {
                                parts: {
                                    results: rcProducts,
                                    count: pagination.totalResults,
                                    ...rest
                                }
                            };

                            return [partResults, productResults];
                        }

                        return [partsResults, productResults];
                    }),
                    map(([partsResults, productResults]): ExtendedSearchResults => {
                        const results: ExtendedSearchResults = {
                            ...productResults,
                            ...partsResults
                        };

                        return results;
                    })
                );
            })
        );
    }

    private buildPimParams(query: SearchQuery, brandFamily: BrandOverwriteType) {
        const { q: searchTerm, skip = 0, max = SEARCH_PAGE_SIZE } = query;
        const params: PartsSearchQueryParms = {
            q: searchTerm,
            pageSize: max,
            currentPage: skip / max
        };
        // // Do not pass anything to filter parts if user has both CBP & ICP
        if (brandFamily !== BrandFamily.GENERIC) {
            params['brands'] = brandFamily;
        }

        return params;
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private buildParams(query: SearchQuery): any {
        if (environment.brand === 'private') {
            return buildHttpParams({
                ...{
                    skip: 0,
                    max: SEARCH_PAGE_SIZE
                },
                ...query,
                ...{ type: 'all' }
            });
        }

        return buildHttpParams({
            ...{
                skip: 0,
                max: SEARCH_PAGE_SIZE
            },
            ...query,
            ...{
                type: 'all',
                brand: environment.brand
            }
        });
    }
}
