import { Injectable } from '@angular/core';
import { QueryInterface } from './query-base';
import { WhatsNewQuery } from './whats-new-query';
import { BulletinQuery } from './bulletin-query';
import { DynamicQuery } from './dynamic-query';
import { DocumentQuery, DocumentQueryType, DocumentField } from 'common/models/document';
import { DataTableLayout, DataTableFilterOption, DataTableSort } from 'common/models/data-table';
import { OktaBaseService } from 'common/services/okta/okta-base.service';
import { ProductLiteratureQuery } from './product-literature-query';

@Injectable({ providedIn: 'root' })
export class QueryFactory {
    constructor(private readonly whatsNewQuery: WhatsNewQuery,
        private readonly bulletinQuery: BulletinQuery,
        private readonly dynamicQuery: DynamicQuery,
        private readonly productLiteratureQuery: ProductLiteratureQuery,
        private readonly oktaService: OktaBaseService) {}


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createQuery(data: any): any {
        return this.oktaService.getTokenBrands().then((brands: string[]) => ({
            ...this.generateFactory(data).generateQuery(data),
            brand: this.generateFactory(data).filterBrands(data, brands)
        } as DocumentQuery));
    }

    filter(data: string, documents: string[]) {
        return this.generateFactory(data).filter(documents);
    }

    fields(data: string): DocumentField[] {
        return this.generateFactory(data).fields(data);
    }

    fetchFilterOptions(data: string, documents: string[]): DataTableFilterOption[] {
        return this.generateFactory(data).fetchFilterOptions(documents);
    }

    updateFilterOptions(data: string, documents: string[], filterOptions: DataTableFilterOption[]): DataTableFilterOption[] {
        return this.generateFactory(data).updateFilterOptions(documents, filterOptions);
    }

    filterByType(data: string, documents: string[]): DataTableFilterOption[] {
        return this.generateFactory(data).filterByType(documents);
    }


    applyFilterAndSearch(data: string, documents: string[], option: DataTableFilterOption, searchText: string): string[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.generateFactory(data).applyFilterOption(documents, option).filter((document: any) => searchText === '' || document.DocumentTitle.toLowerCase().includes(searchText));
    }

    pageSize(data: string) {
        return Number(this.generateFactory(data).pageSize(data));
    }

    layout(data: string): DataTableLayout {
        return this.generateFactory(data).layout();
    }

    isSearchable(data: string): boolean {
        return this.generateFactory(data).isSearchable(data);
    }

    enableLanguageFilter(data: string) {
        return this.generateFactory(data).enableLanguageFilter(data);
    }

    enableStatusFilter(data: string) {
        return this.generateFactory(data).enableStatusFilter(data);
    }

    sort(data: string): DataTableSort {
        return this.generateFactory(data).sort(data);
    }

    generateFactory(data: string): QueryInterface {
        const type = this.getType(data);
        if (type === DocumentQueryType.WHATS_NEW) {
            return this.whatsNewQuery;
        }
        if (type === DocumentQueryType.BULLETIN) {
            return this.bulletinQuery;
        }
        if (type === DocumentQueryType.DYNAMIC) {
            return this.dynamicQuery;
        }
        if (type === DocumentQueryType.PRODUCT_LITERATURE) {
            return this.productLiteratureQuery;
        }
        throw new Error(`No matching actions query type ${type}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getType(data: any): string {
        if (data.queryType && data.queryType === 'productliterature') {
            return 'productliterature';
        }
        if (data.ComponentTemplate?.Title === 'CT_Blank') {
            return 'dynamic';
        }

        return data.Content.tags?.$values.find((tag:string) => tag.includes('type')).split(':')[1];
    }
}
