<form class="hvac-search-hero" (submit)="onSubmit()" [ngClass]="{'icp-search-hero': isIcpSite }">
    <div class="hvac-serch-hero-search-bar">
        <utc-search-input
            [formControl]="queryControl"
            class="hvac-search-hero-input"
            inputId="hvac-search-hero-input-id"
            name="q"
            type="text"
            [hideLabel]="true"
            [placeholder]="placeholder"
            [theme]="searchTheme"
            autocomplete="off"
            [enableAutoSuggestions]="queryControl.valid"></utc-search-input>
        <button buttonType="submit" class="hvac-search-hero-submit hvac-button">
            <span class="hvac-accessible-text">{{ 'SEARCH' | translate }}</span>
            <utc-svg class="hvac-icon-button-icon" src="assets/icons/search.svg"></utc-svg>
        </button>
    </div>
    <p class="hvac-search-hero-tips" *ngIf="!isTotaline">
        <utc-svg class="hvac-search-hero-tips-icon" src="assets/icons/info.svg"></utc-svg>
        {{ searchTipKey | translate }}
    </p>
</form>
