<div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{
    'hvac-dropdown-menu-expanded': menuActive,
    'hvac-dropdown-menu-half': theme === 'half'
}">
    <button class="hvac-dropdown-menu-selected" (click)="toggleMenu()">
        <span class="hvac-dropdown-menu-selected-label">{{ title }}</span>
        <utc-svg class="hvac-dropdown-menu-selected-chevron" [src]="iconSrc"></utc-svg>
    </button>
    <ol class="hvac-dropdown-menu-options">
        <li class="hvac-dropdown-menu-option-item" *ngFor="let option of menuOptions">
            <a *ngIf="option.action === 'link'; else newTab" (click)="setOption()" class="hvac-dropdown-menu-option"
                target="_self" [routerLink]="[option.value]">
                {{ option.title }}
            </a>
            <ng-template #newTab>
                <a class="hvac-dropdown-menu-option" (click)="setOption()" href="{{option.value}}" target="_blank">
                    {{ option.title }}
                </a>
            </ng-template>
        </li>
    </ol>
</div>