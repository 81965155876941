import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MobileMenuVisible } from 'common/store/ui/ui.actions';


type NavOptionActionType = 'link' | 'new_tab';

export interface NavOption {
    title: string;
    value: string;
    action: NavOptionActionType;
}

export enum DropDownThemes {
    DEFAULT = 'default',
    DEFAULT_HALF = 'half'
}

@Component({
    selector: 'hvac-dropdown-navigation',
    templateUrl: './dropdown-navigation.component.html',
    styleUrls: ['../dropdown-styles.component.scss', './dropdown-navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropdownNavigationComponent {
    @Input() menuOptions: NavOption[] = [];
    @Input() theme: DropDownThemes = DropDownThemes.DEFAULT;
    @Input() title: string;
    @Input() menuActive = false;
    @Output() openMenuEmitter: EventEmitter<boolean> = new EventEmitter();
    @Input() iconSrc: string;

    constructor(private readonly store: Store) {}

    toggleMenu() {
        this.openMenuEmitter.emit(!this.menuActive);
    }

    setOption() {
        this.toggleMenu();
        this.store.dispatch(new MobileMenuVisible(false));
    }
}
