import { InjectionToken, FactoryProvider } from '@angular/core';

export interface AppWindow extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    coolcalc: any;
}

export const WINDOW = new InjectionToken<AppWindow>('window');

const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: () => window
};

export const WINDOW_PROVIDERS = [
    windowProvider
];
