import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'common/environments/environment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BrandService } from './brand.service';
import { BrandFamily } from 'common/models/brand.model';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { BrandItem, UserStatus } from 'common/models/account-status';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';

// generic is used to return both icp and cbp results
export type BrandOverwriteType = 'generic' | 'icp' | 'ttl' | 'cmna' | 'car,bry,pay'

@Injectable({ providedIn: 'root' })
export class UserService implements OnDestroy {
    @Select(ContentState.contentBrand) userSelBrand$: Observable<string>;
    @Select(AccountStatusState.getBrands) userBrands$: Observable<BrandItem[]>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<UserStatus>;
    isPublicPlus: UserStatus;
    ngOnDestroy$ = new Subject();
    public usersBrands$: BehaviorSubject<string[]> = new BehaviorSubject(['']);

    constructor(
        private readonly brandService: BrandService
    ) {
        if (environment.brand === 'private') {
            this.isPublicPlusUser$.subscribe((isPublicPlus) => {
                if (isPublicPlus) {
                    this.userBrands$.pipe(
                        map((userBrands: BrandItem[]) => {
                            const filteredBrands = userBrands.filter((brand) => (brand.parentBrand.toLowerCase() !== 'icl'));
                            const brandNames = filteredBrands.map((brand) => brand.brandName);

                            return brandNames;
                        })
                    ).subscribe((brands: string[]) => {
                        this.usersBrands$.next(brands);
                    });
                }
                else {
                    combineLatest([this.userSelBrand$, this.userBrands$]).pipe(
                        filter(([selectedBrand, allBrands]) => Boolean(selectedBrand) && Boolean(allBrands?.length)),
                        map(([userSelectedBrand, allUsersBrands]) => {
                            if (userSelectedBrand === 'default') {
                                return allUsersBrands?.map((brand) => brand.brandName);
                            }

                            return [userSelectedBrand];
                        })
                    ).subscribe((brands: string[]) => {
                        this.usersBrands$.next(brands);
                    });
                }
            });
        }
        else {
            this.usersBrands$.next([environment.brand]);
        }
    }

    getBrandFamily(): Observable<BrandOverwriteType> {
        return this.usersBrands$.pipe(
            filter((brands) => Boolean(brands[0] !== '')),
            map((brands: string[]) => {
                const brandFamily = this.brandService.getBrandFamily(brands[0]);

                if (brands.length > 1) {
                    const hasICP = brands.some((brandOption) => this.brandService.isICPBrand(brandOption));
                    const hasCBP = brands.some((brandOption) => {
                        const isPayne = brandOption.toLowerCase() === 'payne';

                        return isPayne || this.brandService.isCBPBrand(brandOption);
                    });

                    if (hasICP && hasCBP) {
                        // return both icp and cbp parts
                        return BrandFamily.GENERIC;
                    }
                    else if (hasCBP) {
                        return 'car,bry,pay';
                    }

                    return BrandFamily.ICP;
                }

                if (brandFamily === BrandFamily.CBP || brands[0].toLowerCase() === 'payne') {
                    return 'car,bry,pay';
                }
                else if (brandFamily === BrandFamily.TOTALINE) {
                    return 'ttl';
                }
                else if (brandFamily === BrandFamily.CMNA) {
                    return 'cmna';
                }

                return 'icp';
            })
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
