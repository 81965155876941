import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { BaseResponse } from 'common/models/account-status';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';

@Injectable({ providedIn: 'root' })
export class LastLoginService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendLastLogin(): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<BaseResponse>(environment.api.lastLoginApi, null, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}

