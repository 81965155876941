const filePath = 'assets/icons';

export const FileIcons: Record<string, string> = {
    'Adobe Acrobat': `${filePath}/pdf.svg`,
    'Adobe Acrobat (Zipped)': `${filePath}/pdf-zipped.svg`,
    'AVI': `${filePath}/video.svg`,
    'CHM': `${filePath}/code.svg`,
    'DXF, AutoCad Drawing File (Zipped)': `${filePath}/autocad-zipped.svg`,
    'EPS (CMYK)': `${filePath}/eps.svg`,
    'EPS (RGB)': `${filePath}/eps.svg`,
    'Graphic Files (Zipped)': `${filePath}/graphic-files-zipped.svg`,
    'HTM': `${filePath}/html5.svg`,
    'HTML': `${filePath}/html5.svg`,
    'JPG, 100 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, 200 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, 400 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, 720 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, Large Format': `${filePath}/image-file.svg`,
    'JPG, Size Unknown': `${filePath}/image-file.svg`,
    'JPG, Size Unspecified': `${filePath}/image-file.svg`,
    'JPG, True Color (24-bit) 200 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, True Color (24-bit) 400 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'JPG, True Color (24-bit) 720 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'Macromedia Flash': `${filePath}/flash.svg`,
    'Macromedia Flash (Zipped)': `${filePath}/flash-zipped.svg`,
    'Microsoft Excel': `${filePath}/msexcel.svg`,
    'Microsoft Excel (Zipped)': `${filePath}/msexcel-zipped.svg`,
    'Microsoft PowerPoint': `${filePath}/msppt.svg`,
    'Microsoft PowerPoint (exe)': `${filePath}/msppt-exe.svg`,
    'Microsoft PowerPoint (Zipped)': `${filePath}/msppt-zipped.svg`,
    'Microsoft WORD': `${filePath}/msword.svg`,
    'Microsoft WORD (Zipped)': `${filePath}/msword-zipped.svg`,
    'MP3': `${filePath}/audio.svg`,
    'MPG': `${filePath}/video.svg`,
    'null': `${filePath}/document.svg`,
    '': `${filePath}/document.svg`,
    'PNG, 100 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PNG, 200 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PNG, 400 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PNG, 720 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PNG, Portable Network Graphics': `${filePath}/image-file.svg`,
    'PNG, True Color (24 Bit), 100 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PNG, True Color (24 Bit), 720 Pixel Max Dimension': `${filePath}/image-file.svg`,
    'PRC (Palm Pilot Program)': `${filePath}/document.svg`,
    'PSD, PhotoShop': `${filePath}/psd-file.svg`,
    'SAL (i-Vu Open products)': `${filePath}/document.svg`,
    'Software': `${filePath}/software.svg`,
    'Software (Zipped)': `${filePath}/software-zipped.svg`,
    'Text File': `${filePath}/document.svg`,
    'TIF, Size Unspecified': `${filePath}/image-file.svg`,
    'Video Clip': `${filePath}/video.svg`,
    'WMV': `${filePath}/video.svg`,
    'WRF (WebEx Recorder File)': `${filePath}/wrf.svg`,
    'XML': `${filePath}/code.svg`,
    'ZIP, Autodesk REVIT and 3D DRW Files': `${filePath}/autodesk-zipped.svg`,
    'Default': `${filePath}/document.svg`,
    'jpg': `${filePath}/image-file.svg`,
    'pdf': `${filePath}/pdf.svg`,
    'html': `${filePath}/html5.svg`,
    'mp4': `${filePath}/video.svg`,
    'aspx': `${filePath}/document.svg`,
    'doc': `${filePath}/msword.svg`,
    'docx': `${filePath}/msword.svg`,
    'avi': `${filePath}/video.svg`,
    'eps': `${filePath}/eps.svg`,
    'xls': `${filePath}/msexcel.svg`,
    'xlsx': `${filePath}/msexcel.svg`,
    'ppt': `${filePath}/msppt.svg`,
    'pptx': `${filePath}/msppt.svg`,
    'mp3': `${filePath}/audio.svg`,
    'png': `${filePath}/image-file.svg`,
    'xml': `${filePath}/code.svg`,
    'zip': `${filePath}/autodesk-zipped.svg`,
    'csv': `${filePath}/csv.svg`
};

export const DownloadableFileTypes = [
    'PDF',
    'EXE',
    'XLS',
    'XLSX',
    'DOC',
    'DOCX',
    'MP3',
    'MP4',
    'JPG',
    'AVI',
    'EPS',
    'PPT',
    'PPTX',
    'PNG',
    'ZIP',
    'CSV'

];

