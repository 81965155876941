import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterStateModel } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ProductAttributes, ProductDetails } from 'common/models';
import { ProductsState } from 'common/store/products/products.state';
import { combineLatest, Observable, of } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UrlService {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;

    public secureSiteUrl: string;
    public isProductDetailPage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            map((router) => Boolean(router.state && router.state.url.includes('/products/detail')))
        );

    attributes$: Observable<ProductAttributes|undefined> = this.activeProduct$.pipe(
        map((product) => product && product.attributes)
    );

    constructor(
        private translate: TranslateService,
        private readonly store: Store
    ) {
        this.translate.stream('LOGIN_URL').subscribe((value) => {
            this.secureSiteUrl = value;
        });
    }

    isExternalUrl(url: string): boolean {
        return (/^(?:[a-z]+:)?\/\//).test(url);
    }

    setLoginUrl(): Observable<string> {
        return combineLatest([this.isProductDetailPage$, this.attributes$]).pipe(
            map(([isProductDetailPage, attributes]) => {
                const productUrn = attributes && attributes.urn;

                if (isProductDetailPage && productUrn) {
                    return this.translate.stream('PRODUCT_CATALOG_URL').pipe(
                        map((value) => `${value}/${productUrn}`)
                    );
                }

                return of(this.secureSiteUrl);
            }),
            concatAll()
        );
    }

    skipToContent(content: string) {
        const skipToContentEl = document.getElementById(content);
        if (skipToContentEl) {
            skipToContentEl.setAttribute('tabindex', '-1');
            skipToContentEl.focus();
            skipToContentEl.addEventListener('blur', () => {
                skipToContentEl.removeAttribute('tabindex');
            });
        }
    }
}
