import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hvac-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabBarComponent {
    @Input() variant: 'secondary';

    @HostBinding('class.hvac-tab-bar') get hostclass() {
        return true;
    }

    @HostBinding('class.hvac-secondary') get getVariant() {
        return this.variant === 'secondary';
    }
}
