/* eslint-disable no-use-before-define */
import { All, Content, Document, Product } from 'common/models';
import { MockerRequest } from 'service-mocker/server';
import { Part } from './parts';

export type SearchType = 'all'|'products'|'documents'|'content'|'frameContent'|'parts';

export type ViewMode = 'grid'|'list';

export interface Scored {
    score: number;
}

export interface FilterSet {
    [filterName: string]: string|number|undefined|(string|number)[];
}

export interface SortSet {
    [sortMode: string]: string|number|undefined|(string|number)[];
}

export interface SearchQuery extends FilterSet, SortSet {
    q: string;
    skip?: number;
    max?: number;
    type?: SearchType;
}

export interface SearchRequest extends MockerRequest {
    query: SearchQuery;
}

export interface Filter {
    name: string;
    value: string;
    count: number;
    category: FilterCategory;
}

export interface FilterCategory {
    name: string;
    filters: Filter[];
}

export interface SearchMetadata {
    categories: FilterCategory[];
}

export interface SearchResource<T> {
    metadata?: SearchMetadata;
    results: T[];
    count: number;
    viewMode?: ViewMode;
}

export interface SerialNumberResult {
    serialNumber: string;
    model: string;
}

export interface SerialNumberResults {
    results: SerialNumberResult[];
    'other-brand-results': Array<{serialNumber: string; brand: string; }>;
}

export interface SearchResults {
    all?: SearchResource<All & Scored>;
    products?: SearchResource<Product & Scored>;
    documents?: SearchResource<Document & Scored>;
    content?: SearchResource<Content & Scored>;
    serialNumbers?: SerialNumberResults;
    frameContent?: true;
}

export interface ExtendedSearchResults {
    all?: SearchResource<All & Scored>;
    products?: SearchResource<Product & Scored>;
    documents?: SearchResource<Document & Scored>;
    content?: SearchResource<Content & Scored>;
    serialNumbers?: SerialNumberResults;
    parts?: SearchResource<Part>;
    frameContent?: true;
}
