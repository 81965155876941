import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginPanelContent, LoginPanelRegistrationContent } from 'common/components/login-panel/login-panel.component';
import { environment } from 'common/environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const createBrandedUrl = (url: string|undefined): string => {
    if (!url) {
        return '';
    }

    const { brand } = environment;
    const u = new URL(url);
    u.searchParams.set('brandref', brand);

    return u.toString();
};

const buildCreateAccountRegisterUrl = () => createBrandedUrl(environment.auth && environment.auth.createAccountRegisterUrl);

const buildCreateAccountUrl = (contentLink?: string) => {
    const url = contentLink || (environment.auth && environment.auth.createAccountUrl);

    return createBrandedUrl(url);
};

@Component({
    templateUrl: './login-panel-container.component.html',
    selector: 'hvac-login-panel-container',
    encapsulation: ViewEncapsulation.None
})

export class LoginPanelContainerComponent {
    createAccountRegisterLink = buildCreateAccountRegisterUrl();

    loginPanelContent$: Observable<LoginPanelContent> = this.translations.get('LOGIN_PANEL').pipe(startWith([{}]));

    loginPanelRegistrationContent$: Observable<LoginPanelRegistrationContent> = this.translations.get('LOGIN_PANEL_REGISTRATION')
        .pipe(
            startWith({}),
            map((content: LoginPanelRegistrationContent) => ({
                ...content,
                createAccountLink: buildCreateAccountUrl(),
                createAccountRegisterLink: this.createAccountRegisterLink
            }))
        );

    constructor(private readonly translations: TranslateService) {}
}
