import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from '../../../models';

@Component({
    selector: 'hvac-video-list',
    templateUrl: './video-list.component.html',
    styleUrls: ['./video-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VideoListComponent {
    @Input() data: BaseContentEntity;


    trackByFn(index: number, item: {id:string}) {
        return index.toString().concat(item.id);
    }
}
