<ul class="hvac-browse-options hvac-browse-categories">
    <ng-container *ngIf="isPartsCatalog; else unsorted">
        <li class="hvac-browse-category" *ngFor="let category of categoriesSorted">
            <hvac-browse-category-tile [category]="category"></hvac-browse-category-tile>
        </li>
    </ng-container>
    <ng-template #unsorted>
        <li class="hvac-browse-category" *ngFor="let category of allCategories">
            <hvac-browse-category-tile [category]="category"></hvac-browse-category-tile>
        </li>
    </ng-template>
</ul>
