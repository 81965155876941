<label class="hvac-radio-button-container">
    <input
        class="hvac-radio-button-input"
        type="radio"
        [attr.id]="inputId"
        [attr.name]="inputName"
        [attr.disabled]="disabled ? '' : null"
        [checked]="checked"  />
    <span class="hvac-radio-button-input-text"><ng-content></ng-content></span>
</label>
