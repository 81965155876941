<button
    [ngClass]="classNames"
    [class.hvac-icon-button]="iconSrc"
    [class.hvac-button-success]="successToggle$ | async"
    [attr.id]="buttonId ? buttonId : null"
    [attr.title]="title ? title : null"
    [attr.disabled]="disabled ? disabled : null"
    type="{{buttonType}}"
    class="hvac-button">
    <div *ngIf="!(successToggle$ | async)" class="hvac-button-initial-state">
        <span [class.hvac-accessible-text]="iconSrc">{{ label }}</span>

        <ng-container *ngIf="iconSrc">
            <utc-svg class="hvac-icon-button-icon" [src]="iconSrc"></utc-svg>
        </ng-container>
    </div>

    <div *ngIf="successToggle$ | async" class="hvac-button-success-state">
        <span [class.hvac-accessible-text]="successIconSrc">{{ successLabel }}</span>

        <ng-container *ngIf="successIconSrc">
            <utc-svg class="hvac-icon-button-icon" [src]="successIconSrc"></utc-svg>
        </ng-container>
    </div>
</button>
