import { Component, Input } from '@angular/core';
import { Link } from 'common/models/link';

@Component({
    selector: 'hvac-product-descriptor',
    templateUrl: './product-descriptor.component.html',
    styleUrls: ['./product-descriptor.component.scss']
})
export class ProductDescriptorComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() infoLink?: Link;
}
