<div class="hvac-image-fallback-container" [ngClass]="{ 'hvac-fallback-size-small': size === 'small' }">
    <img
        *ngIf="!isImageLinkBroken"
        [ngClass]="classNames"
        [src]="suppliedImageSrc"
        [alt]="suppliedImageAlt"
        (error)="checkImageStatus($event)" />
    <div
        class="hvac-fallback-icon-container"
        *ngIf="isImageLinkBroken">
        <div class="hvac-fallback-icon-circle">
            <utc-svg class="hvac-fallback-icon" [src]="fallbackImageSrc"></utc-svg>
        </div>
    </div>
</div>
