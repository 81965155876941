import { Scored } from 'common/models';
import { MockerRequest } from 'service-mocker/server';

export type SuggestionsType = 'products'|'documents'|'content';

export interface SuggestionsQuery {
    q: string;
}

export type SuggestLimiter = 'parts' | 'products' | '';

export interface SuggestionsRequest extends MockerRequest {
    query: SuggestionsQuery;
}

export interface SuggestionsResource<T> {
    results: T[];
}

export interface SuggestionItem {
    title: string;
    url: string;
    subtype?: string[];
    fileType?: string;
    urn?: string
}

export interface DocumentSuggestion extends SuggestionItem {
    subtype: string[];
    type: string;
    fileType?: string;
    fileExtension?: string;
}

export interface ProductSuggestion extends SuggestionItem {
    urn: string;
}

export interface PartSuggestionItem extends Omit<SuggestionItem, 'urn'> {
    urn: string;
}

export interface PartSuggestionResult {
    channel: string;
    code: string;
    description: string;
    media?: {
        fileName: string;
        format: string;
        url: string;
    }
}


export interface PartsSuggestionsResults {
    rcProduct: PartSuggestionResult[]
}

export interface SuggestionsResults {
    all?: SuggestionsResource<SuggestionItem & Scored>;
    products?: SuggestionsResource<ProductSuggestion & Scored>;
    documents?: SuggestionsResource<DocumentSuggestion & Scored>;
    content?: SuggestionsResource<SuggestionItem & Scored>;
}

export interface ExtendedSuggestionsResults {
    all?: SuggestionsResource<SuggestionItem & Scored>;
    products?: SuggestionsResource<ProductSuggestion & Scored>;
    documents?: SuggestionsResource<DocumentSuggestion & Scored>;
    content?: SuggestionsResource<SuggestionItem & Scored>;
    parts?: SuggestionsResource<PartSuggestionItem & Scored>;
}
