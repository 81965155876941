import { Injectable, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { WINDOW } from 'common/window.provider';
import { distinctUntilChanged } from 'rxjs/operators';


const MOBILE_SIZE = 375;
const TABLET_SIZE = 768;
const DESKTOP_SIZE = 1024;

export const BreakpointSize = {
    Mobile: `(min-width: ${MOBILE_SIZE}px)`,
    Tablet: `(min-width: ${TABLET_SIZE}px)`,
    Desktop: `(min-width: ${DESKTOP_SIZE}px)`
} as const;

@Injectable({ providedIn: 'root' })

export class BreakPointService {
    readonly breakpoint$ = this.breakpointObserver
        .observe(Object.values(BreakpointSize))
        .pipe(
            distinctUntilChanged()
        )

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private breakpointObserver: BreakpointObserver
    ) {}

    isDesktop: () => boolean = () => this.window.innerWidth >= DESKTOP_SIZE;
    isTablet: () => boolean = () => this.window.innerWidth <= DESKTOP_SIZE && this.window.innerWidth >= TABLET_SIZE;
    isMobile: () => boolean = () => this.window.innerWidth <= TABLET_SIZE && this.window.innerWidth >= MOBILE_SIZE;
}
