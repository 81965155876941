import { NgModule } from '@angular/core';
import { ContentUrlPipe } from 'common/pipes/content-url.pipe';
import { DecodeUriComponentPipe } from 'common/pipes/decode-uri-component.pipe';

@NgModule({
    declarations: [
        ContentUrlPipe,
        DecodeUriComponentPipe
    ],
    exports: [
        ContentUrlPipe,
        DecodeUriComponentPipe
    ]
})
export class ContentUrlModule {}
