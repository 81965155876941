import { Location } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { UserStatus } from 'common/models/account-status';
import { NavMenu } from 'common/services/navigation-content.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { FetchCatalogCategories, FetchPartsCatalogCategories } from 'common/store/catalog/catalog.actions';
import { CatalogCategory, CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { StoreRecentActivity } from 'common/store/navigation/navigation.actions';
import { NavigationState } from 'common/store/navigation/navigation.state';
import * as he from 'he';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { DataResolverService } from '../../content/services/data-resolver.service';
import { UpdateUserContentFilteringPreference } from '../../store/account-status/account-status.actions';
import { BrowseNavigation, BrowseNavigationService } from './services/browse-navigation.service';

enum BrowseView {
    category = 'category',
    subcategory = 'subcategory',
    product = 'product'
}

export interface Data {
    [key:string]:string;
    Content: string;
    tags: string;
}


@Component({
    selector: 'hvac-browse',
    templateUrl: './browse.component.html',
    styleUrls: ['./browse.component.scss'],
    providers: [
        BrowseNavigationService
    ],
    encapsulation: ViewEncapsulation.None
})
export class BrowseComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any
    @Input() updateRoute: string;

    @Select(CatalogState.catalogCategories) categories$!: Observable<CatalogCategory[]>;
    @Select(NavigationState.navigation) navigation$: Observable<NavMenu>;
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;
    @Select(ContentState.contentBrandList) userBrands$: Observable<string[]>;
    @Select(ContentState.contentFilteringPreference) contentFilteringPreference$: Observable<string>;

    browseVariables: BrowseNavigation;
    ngOnDestory$ = new Subject();
    view$: Observable<BrowseView> = this.browseNav.navigation$.pipe(
        map((section: BrowseNavigation) => {
            if (section.category && typeof section.subcategory !== 'undefined') {
                return BrowseView.product;
            }

            if (section.category) {
                return BrowseView.subcategory;
            }

            return BrowseView.category;
        })
    );

    noCatalogData = false;
    removableBrand = false;
    public isTotaline = environment.brand === 'Totaline';

    constructor(
        private readonly store: Store,
        private readonly browseNav: BrowseNavigationService,
        private readonly title: Title,
        private readonly location: Location,
        private readonly dataResolverService: DataResolverService
    ) { }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: Event) {
        if (this.updateRoute) {
            this.browseVariables = this.formatBrowseVariables((event.target as Window).location.pathname);
            this.categories$.pipe(takeUntil(this.ngOnDestory$)).subscribe((res) => {
                if (res.length && res.length > 0) {
                    this.navigateWithRouteParams(this.browseVariables, res, false);
                }
            });
        }
    }

    ngOnInit() {
        this.isTotaline ? this.store.dispatch(new FetchPartsCatalogCategories()) : this.store.dispatch(new FetchCatalogCategories());
        combineLatest([this.brand$, this.userBrands$, this.contentFilteringPreference$]).pipe().subscribe(([brand, brandList, filterPreference]) => this.removableBrand = (Boolean(brandList?.length > 1 && brand !== 'default') || filterPreference !== 'Res&Com'));
        if (this.data && this.data.Content && this.data.Content.tags) {
            this.updateRoute = this.data.Content.tags;
        }
        if (this.updateRoute) {
            this.browseVariables = this.formatBrowseVariables(window.location.pathname);
            this.categories$.pipe(takeUntil(this.ngOnDestory$), skip(1)).subscribe((res) => {
                if (res.length && res.length > 0) {
                    this.navigateWithRouteParams(this.browseVariables, res, true);
                    this.noCatalogData = false;
                }
                else {
                    this.noCatalogData = true;
                }
            });
        }

        this.browseNav.navigation$.pipe(takeUntil(this.ngOnDestory$)).subscribe((navigation) => {
            if (this.updateRoute) {
                let newUrl = this.updateRoute;
                if (!navigation.category) {
                    if (navigation.pushToHistory) {
                        history.pushState(this.location.getState(), newUrl, newUrl);
                    }
                    this.formatPageTitle(navigation);

                    return this.location.replaceState(newUrl);
                }

                newUrl = newUrl.concat(`/${navigation.category}`);
                if (navigation.subcategory) {
                    newUrl = newUrl.concat(`/${navigation.subcategory}`);
                }
                if (navigation.pushToHistory) {
                    history.pushState(this.location.getState(), newUrl, newUrl);
                }
                this.formatPageTitle(navigation);

                return this.location.replaceState(newUrl);
            }

            return;
        });
    }

    formatBrowseVariables(url: string) {
        const browseVariables = {} as BrowseNavigation;
        const categories = url.replace(`${this.updateRoute}/`, '');
        const categoriesArray = categories.split('/');
        if (categoriesArray[0]) {
            browseVariables.category = categoriesArray[0];
        }
        if (categoriesArray[1]) {
            browseVariables.subcategory = categoriesArray[1];
        }

        return browseVariables;
    }

    navigateWithRouteParams(routeParams: BrowseNavigation, categories: CatalogCategory[], pushToHistory: boolean) {
        if (!routeParams.subcategory) {
            const category = categories.filter((categoryToCheck) => categoryToCheck.urn === routeParams.category);
            const checkChildren = category[0] ? category[0] : null;
            if (checkChildren && checkChildren.children && checkChildren.children.length > 0) {
                // eslint-disable-next-line no-undefined
                routeParams.subcategory = undefined;
            }
            else {
                routeParams.subcategory = null;
            }
        }

        return this.browseNav.navigate({
            category: routeParams.category,
            subcategory: routeParams.subcategory,
            pushToHistory: pushToHistory
        });
    }

    formatPageTitle(browseVariables: BrowseNavigation) {
        let pageTitle = null;
        const capitalizeRegex = /(\b[a-z](?!\s))/g;
        if (browseVariables.category) {
            pageTitle = he.decode(browseVariables.category).trim();
            const array = pageTitle.split('-').join(' ');
            pageTitle = array.replace(capitalizeRegex, (x: string) => x.toUpperCase());
            if (browseVariables.subcategory) {
                const newArray = he.decode(browseVariables.subcategory).trim().split('-').join(' ');
                const subTitle = newArray.replace(capitalizeRegex, (x: string) => x.toUpperCase());
                pageTitle = pageTitle.concat(`: ${subTitle}`);
            }
        }
        const recentActivityTitle = pageTitle ? `Product Catalog: ${pageTitle}` : 'Product Catalog';
        this.store.dispatch(new StoreRecentActivity(window.location.pathname, recentActivityTitle));

        return pageTitle ? this.title.setTitle(pageTitle) : this.title.setTitle('Product Catalog');
    }

    ngOnDestroy() {
        this.ngOnDestory$.next();
        this.ngOnDestory$.complete();
    }

    removeBrandFilter() {
        if (this.removableBrand) {
            this.store.dispatch(new UpdateUserContentFilteringPreference('Res&Com'));


            return this.dataResolverService.updateUserBrandPreferences('default', 'Res&Com');
        }

        return;
    }
}
