import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Content, CONTENT_RESOURCE_TYPE } from 'common/models';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { buildRepository } from '../repository/utils';
import { AppendSearchResults, ReceiveSearchResults } from '../search/search.actions';

export interface SiteContentStateModel {
    repository: ResourceRepositoryModel<Content>;
}

@State<SiteContentStateModel>({
    name: 'content',
    defaults: { repository: {} }
})
@Injectable({ providedIn: 'root' })
export class SiteContentState {
    @Selector()
    static repository({ repository }: SiteContentStateModel): ResourceRepositoryModel<Content> {
        return repository;
    }

    @Action([ReceiveSearchResults, AppendSearchResults])
    receiveSearchResults(ctx: StateContext<SiteContentStateModel>, action: ReceiveSearchResults|AppendSearchResults) {
        const content = action.results.content || { results: [] };
        const contentResults = content.results;
        const repository = {
            ...ctx.getState().repository,
            ...buildRepository(contentResults, 'id', CONTENT_RESOURCE_TYPE)
        };

        return ctx.patchState({ repository });
    }
}
