
<div class='hvac-jump-bar' [class.hvac-jump-bar-sticky]="sticky$ | async" [ngClass]="{'jump-bar-empty': (jumpBarEmpty | async)}">
    <div #scrollList class='hvac-jump-bar-list'>
        <button
            id="{{link.title.replace(' ', '_')}}"
            class='hvac-jump-bar-link'
            *ngFor="let link of (links | async)"
            (click)="scrollToSection(link, link.title)"
            [ngClass]="{ 'hvac-product-subnav-section-link-active': link.title === (activeLink$ | async)?.title ||  link.title === (selectedJumpBarItem$ | async)?.title}"
            >
            {{link.title}}</button>
    </div>
</div>
