import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'hvac-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() quarterLimit: number = 6;
    @Input() quarterValue: number | null = 0;
    @Input() formatter: string = '/';
    public value: number | null;
    public displayValue: string;

    ngOnInit(): void {
        switch (this.formatter) {
            case 'percent':
                this.displayValue = `${this.quarterValue}%`;
                this.value = this.quarterValue;
                break;

            default:
                if (this.quarterValue !== null) {
                    if (this.quarterValue < this.quarterLimit) {
                        const tempValue: number = (this.quarterValue / this.quarterLimit) * 100;
                        this.value = Math.floor(tempValue);
                        this.displayValue = `${this.quarterValue}/${this.quarterLimit}`;

                        return;
                    }
                    else if (this.quarterValue >= this.quarterLimit) {
                        this.value = 100;
                        this.displayValue = `${this.quarterValue}/${this.quarterLimit}`;

                        return;
                    }
                    else if (this.quarterValue === 0) {
                        this.value = 0;
                        this.displayValue = `-/${this.quarterLimit}`;

                        return;
                    }
                }
                break;
        }
    }
}
