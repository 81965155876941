import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'utc-check-list-item',
    templateUrl: './check-list-item.component.html',
    styleUrls: ['./check-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckListItemComponent {
    @Input() item: BaseContentEntity;
}
