import { HttpErrorResponse } from '@angular/common/http';

export class TranslatableError extends Error {
    public readonly content: string;
    public readonly name: string;
}

export class UserNameConflictError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CHECK_USERNAME.CONFLICT';
    public readonly name = 'userNameConflict';
}

export class UserNameError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CHECK_USERNAME.GENERAL';
    public readonly name = 'userNameGeneral';
}

export class CreateAccountConflictError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.CONFLICT';
    public readonly name = 'createAccountConflict';
}

export class EmailConflictError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CHECK_EMAIL.CONFLICT';
    public readonly name = 'checkEmailConflict';
}

export class EmailError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CHECK_EMAIL.GENERAL';
    public readonly name = 'checkEmailGeneral';
}


export class CreateAccountServerError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.SERVER';
    public readonly name = 'createAccountServer';
}

export class CreateAccountDuplicateUsernameError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.DUPLICATE_USERNAME';
    public readonly name = 'createAccountDuplicateUsername';
}

export class CreateAccountOktaPasswordError {
    public readonly name = 'createAccountOktaPassword';
    constructor(public content: string) {}
}

export class CreateAccountDuplicateEmailError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.DUPLICATE_EMAIL';
    public readonly name = 'createAccountDuplicateEmail';
}

export class CreateAccountAccountInfoError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.ACCOUNT_INFO';
    public readonly name = 'createAccountAccountInfo';
}

export class CreateAccountCompanyInfoError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CREATE_ACCOUNT.COMPANY_INFO';
    public readonly name = 'createAccountCompanyInfo';
}


export class EmailVerificationError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.EMAIL_VERIFICATION.INCORRECT';
    public readonly name = 'EmailVerificationError';
}
export class EmailVerificationForbiddenError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.EMAIL_VERIFICATION.FORBIDDEN';
    public readonly name = 'EmailVerificationForbiddenError';
}

export class QuickLinksPersonalizationError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.QUICKLINKS.ERROR';
    public readonly name = 'QuickLinksPersonalizationError';
}

export class RemoveNotificationError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.NOTIFICATIONS.ERROR';
    public readonly name = 'RemoveNotificationError';
}

export class InboxNoContentError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.INBOX.NO_CONTENT';
    public readonly name = 'InboxNoContentError';
}

export class UpdateUserPrefError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.UPDATE_USER_PREFERENCE.ERROR';
    public readonly name = 'UpdateUserPrefError';
}

export class CatalogNullBrandCodeError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.CATALOG.NULL_BRAND_CODE';
    public readonly name = 'catalogNullBrandCode';
}

export class StatusApiError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.STATUS_API.ERROR';
    public readonly name = 'StatusApiError';
}

export class DistributorLocatorApiError extends TranslatableError {
    public readonly content = 'ERROR_CONTENT.DISTRIBUTOR_API.ERROR';
    public readonly name = 'DistributorLocatorApiError';
}


export interface CreateAccountError extends HttpErrorResponse {
    error: {
        details: string[];
        errorCodes: string[];
    };
}

export const accountInfoErrorCodes = ['CreateAccountUser01',
    'CreateAccountUser02',
    'CreateAccountUser03',
    'CreateAccountUser04',
    'CreateAccountUser05'];

export const companyLookupErrorCodes = ['CreateAccountCompany01', 'CreateAccountCompany02'];

export const serverErrorCodes = ['CreateAccountUser06',
    'CreateAccountUser07',
    'E0000002',
    'E0000004',
    'E0000006',
    'E0000007',
    'E0000009',
    'E0000011',
    'E0000013'];

export const oktaErrorCode = ['E0000001'];
export const duplicateEmailErrorCodes = ['CreateAccountUser08'];


