<div class="hvac-toast-wrap" [style]="{'background-color': toast.bgColor}" *ngFor="let toast of toasts">
    <div class="hvac-toast"
        [ngClass]="{
            'hvac-toast-info': toast.theme === 'info',
            'hvac-toast-error': toast.theme === 'error',
            'hvac-toast-success': toast.theme === 'success',
            'hvac-toast-warning': toast.theme === 'warning'
        }"
    >
        <utc-svg [src]="'assets/icons/banner-'+toast.theme+'.svg'"></utc-svg>
        <span *ngIf="toast.content" class="hvac-toast-notice" [innerHtml]="toast.content | safe:'html'"></span>
        <span *ngIf="!toast.content" class="hvac-toast-notice">
            <ng-content></ng-content>
        </span>
        <button *ngIf="toast.closeable" class="close-button" (click)="closeButtonClick(toast.id)" aria-label="close"><utc-svg src="assets/icons/delete-normal.svg"></utc-svg></button>
    </div>
</div>
