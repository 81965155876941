<div class="hvac-check-list">
    <h4 class="hvac-check-list-name">
        <utc-svg class="hvac-check-list-icon" src="assets/icons/circle-check.svg"></utc-svg>
        {{resource.title}}
    </h4>
    <ul class="hvac-check-list-list">
        <li *ngFor="let subItem of resource.items" class="hvac-check-list-item">
            <span class="hvac-check-list-item-text">{{subItem}}</span>
        </li>
    </ul>
</div>
