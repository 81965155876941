import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContentHeadingData } from 'common/models/content-heading';

@Component({
    selector: 'hvac-color-content-header',
    templateUrl: './color-content-header.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ColorContentHeaderComponent {
    @Input() data: ContentHeadingData;
}
