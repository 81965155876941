<div class="hvac-whats-new">
    <div class="hvac-whats-new-title-container">
        <h3 class="hvac-whats-new-title" [innerHTML]="((title$ | async) || '') | richText"></h3>
    </div>
    <ng-template [ngIf]="!(contentLoading$ | async)" [ngIfElse]="spinner">
        <div class="hvac-whats-new-empty" *ngIf="documents.length === 0; else showMessages">
            <p class="hvac-whats-new-empty-content">{{ 'WHATS_NEW.NOTHING_NEW' | translate }}</p>
        </div>
        <ng-template #showMessages>
            <ul class="hvac-whats-new-list">
                <li *ngFor='let content of documents'>
                    <a *ngIf="content?.Content?.links" [href]="content?.Content?.links.linkExternal" class="hvac-whats-new-link" [target]="content?.Content?.links.linkOperation.Key">
                        <div class="hvac-whats-new-icon">
                            <utc-svg *ngIf="content?.Content?.primaryMedia" [src]="content?.Content?.primaryMedia.ExternalContent.Metadata.SecureUri"></utc-svg>
                        </div>
                        <div class="hvac-whats-new-details">
                            <p class="hvac-whats-new-link-title" [innerHTML]="content?.Content?.title | richText"></p>
                            <div *ngIf="content?.Content?.tags" class="hvac-whats-new-properties">
                                <span *ngFor='let property of content?.Content?.tags.$values' [innerHTML]="property | richText"></span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <div class="hvac-whats-new-button-container" *ngIf="whatsNew && whatsNew.Content && whatsNew.Content.links">
                <hvac-link [link]="whatsNew.Content.links" class="hvac-whats-new-button">
                    <span>{{ linkText }}</span>
                    <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
                </hvac-link>
            </div>
        </ng-template>
    </ng-template>
    <ng-template #spinner>
        <utc-loading-spinner></utc-loading-spinner>
    </ng-template>
</div>
