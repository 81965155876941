import { Component, Input, ViewEncapsulation } from '@angular/core';

export interface ProductCard {
    urn: string;
    name?: string;
    image: string;
    fallbackImage?: string;
}


@Component({
    selector: 'utc-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductCardComponent {
    @Input() product: ProductCard;
    @Input() mode = 'grid';
}
