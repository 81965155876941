<section class="hvac-featured-content-item">
    <a class="hvac-featured-content-image-button"
        *ngIf="item.imageUrl; else nonAction"
        [href]="item.imageUrl">
        <ng-container [ngSwitch]="item.media.type">
            <div *ngSwitchCase="'image'" class="hvac-featured-content-media-image-container">
                <img
                    class="hvac-featured-content-media"
                    [src]="item.media.src"
                    alt=""/>
            </div>
            <iframe class="hvac-featured-content-media" *ngSwitchCase="'embed'" [title]="item.title" width="448" height="252" [src]="embedableUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </ng-container>
    </a>
    <ng-template #nonAction>
        <ng-container [ngSwitch]="item.media.type">
            <div *ngSwitchCase="'image'" class="hvac-featured-content-media-image-container">
                <img
                    class="hvac-featured-content-media"
                    [src]="item.media.src"
                    alt=""/>
            </div>
            <iframe class="hvac-featured-content-media" *ngSwitchCase="'embed'" [title]="item.title" width="448" height="252" [src]="embedableUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </ng-container>
    </ng-template>
    <h4 class="hvac-featured-content-item-title hvac-h3">{{ item.title }}</h4>
    <p class="hvac-featured-content-item-description hvac-p">{{ item.description }}</p>
    <a *ngIf="item.cta" class="hvac-shadow-button-with-icon" [href]="item.cta.url" target="_blank">{{ item.cta.text }}</a>
    <span class="hvac-featured-content-extras" *ngIf="item.additionalLinks">
        <a target="_blank" [href]="link.link" *ngFor="let link of item.additionalLinks" class="hvac-featured-content-extra">
            <img [src]="link.image" class="hvac-featured-content-extra-image hvac-app-store-badge" alt="link.alt" />
        </a>
    </span>
</section>
