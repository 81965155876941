import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { buildHttpParams, digest } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { UserStatus } from 'common/models/account-status';
import {
    CategoriesResponse, CategoryProductsResponse, ProductAttributeResponse,
    ProductDataOverviewResponse, ProductDocumentsResponse
} from 'common/models/catalog';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { ContentState } from 'common/store/content/content.state';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';
import { BrandService } from './brand.service';

@Injectable({ providedIn: 'root' })
export class CatalogService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Select(AccountStatusState.getBrands) userBrands$: Observable<any[]>;
    @Select(AccountStatusState.getUserId) userId$: Observable<string>;
    @Select(ContentState.contentBrand) userPrefBrands$: Observable<string>;
    @Select(ContentState.contentFilteringPreference) userPrefCompanyType$: Observable<string>;
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<boolean>;

    divisionCodesResidential = 10;
    divisionCodesCommercial = 20;
    public isTotaline = environment.brand === 'Totaline';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly brandService: BrandService
    ) {}

    fetchPrimaryCategories(skipExtraParams = false) {
        return this.get<CategoriesResponse>('/categories/', skipExtraParams).pipe(map((data) => data || { items: [] }));
    }

    fetchPartsCategories(params: string, skipExtraParams = true) {
        return this.get<CategoriesResponse>(`/categories/allCategories?${params}`, skipExtraParams, true).pipe(map((data) => data || { categories: [] }));
    }

    fetchCategoryProducts(category: string, skipExtraParams = false) {
        return this.get<CategoryProductsResponse>(`/category-products/${category}`, skipExtraParams).pipe(map((data) => data || { items: [] }));
    }

    fetchProductAttributes(product: string, skipExtraParams = false) {
        return this.get<ProductAttributeResponse>(`/product/${product}`, skipExtraParams).pipe(map((data) => data || null));
    }

    fetchProductDataOverview(product: string, skipExtraParams = false) {
        return this.get<ProductDataOverviewResponse>(`/product/${product}/attributes`, skipExtraParams).pipe(map((data) => data || null));
    }

    fetchProductDocuments(product: string, skipExtraParams = false) {
        return this.get<ProductDocumentsResponse>(`/product/${product}/documents`, skipExtraParams).pipe(
            map((documentResponse) => documentResponse || { items: [] })
        );
    }

    getDivisionCode(userPrefCompany: string) {
        return userPrefCompany === 'Residential' ? this.divisionCodesResidential : this.divisionCodesCommercial;
    }

    private get<T>(path: string, skipExtraParams = false, partsCatalog = false): Observable<T> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const extraParams = skipExtraParams ? of({}) : this.buildParams();

        return combineLatest([options$, extraParams]).pipe(
            switchMap(([options, params]) => this.httpClient.get<T>(this.prefixUrl(path, partsCatalog), {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    private prefixUrl(path: string, partsCatalog = false) {
        if (partsCatalog) {
            return `${environment.api.pim.catalog}${path}`;
        }

        return `${environment.api.catalog}${path}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private buildParams(): Observable<any> {
        return combineLatest([this.isPublicPlusUser$, this.userBrands$, this.userId$, this.isActiveUser$, this.userPrefBrands$, this.userPrefCompanyType$]).pipe(
            map(([isPublicPlus, userBrands, userId, activeUser, userPrefBrand, userPrefCompanyType]) => {
                const uidParam = userId ? { uid: digest(userId) } : {};
                // private site, public plus params
                if (isPublicPlus) {
                    return buildHttpParams({
                        brandCodes: userBrands.map((brand) => brand.brandCode).join('+'),
                        documentPermissions: 'Public',
                        ...uidParam
                    });
                }
                // private site, active user params
                if (activeUser) {
                    const brandObj = userBrands.find((brandItem) => brandItem.brandName === userPrefBrand);
                    const brandCode = brandObj ? brandObj.brandCode : '';

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const activeUserParams: any = { ...uidParam };

                    if (userPrefBrand !== 'default') {
                        activeUserParams.brandCodes = brandCode instanceof Array ? brandCode.map((code: string[]) => code).join(' ') : brandCode;
                    }

                    if (userPrefCompanyType !== 'Res&Com') {
                        activeUserParams.divisionCodes = this.getDivisionCode(userPrefCompanyType);
                    }

                    return buildHttpParams(activeUserParams);
                }
                // public icp site params
                if (this.brandService.isICPBrand(environment.brand)) {
                    return buildHttpParams({
                        brand: `${environment.brand}`,
                        ...uidParam
                    });
                }

                // public cbp site params

                return uidParam;
            })
        );
    }
}
