/* eslint-disable max-lines */
/* tslint:disable:max-file-line-count */
/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SearchService } from 'common/services/search.service';
import { Content, Document, ExtendedSearchResults, Filter, FilterCategory, Product, Scored, SearchQuery, SearchResource, SearchResults, SerialNumberResult, ViewMode, Part } from 'common/models';
import { DocumentsState } from '../documents/documents.state';
import { ProductsState } from '../products/products.state';
import { PartsState } from '../parts/parts.state';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { SiteContentState } from '../site-content/site-content.state';
import { getMostRelevantSearchCategory } from '../utils/search.utils';
import { AddFilter, AppendSearchResults, ChangeViewMode, FetchNextPage, HeaderSearchVisible, PerformSearch, ReceiveSearchResults, RedoSearch, RemoveFilter, SetSortMode } from './search.actions';
/* tslint:enable:max-line-length */

export const EMPTY_SEARCH_OBJECT = {
    count: 0,
    results: [],
    metadata: { categories: [] }
};
export interface SortOptions {
    parameter: string;
    options: string[];
}

export interface SearchCategoryModel {
    count: number | null;
    filters: FilterCategory[];
    hasMore: boolean;
    manualCount?: boolean;
    noCount?: boolean;
    results: string[];
    sortMode?: string;
    sortOptions?: SortOptions;
    viewMode: ViewMode;
    publicPlus?: boolean;
}

export interface SearchSerialNumberMatch {
    serialNumber: string;
    model: string;
}

export interface SearchCategoriesModel {
    all: SearchCategoryModel;
    products: SearchCategoryModel;
    documents: SearchCategoryModel;
    content: SearchCategoryModel;
    parts: SearchCategoryModel;
    frameContent?: SearchCategoryModel;
}

export interface SerialNumbersModel {
    matches: SerialNumberResult[];
    reserveProducts: string[];
    productMatchFound: boolean;
    'other-brand-results': Array<{
        serialNumber: string;
        brand: string;
    }>;
}

export interface SearchStateModel {
    query: SearchQuery;
    searchCategories: SearchCategoriesModel;
    additionalLoading: boolean;
    searchLoading: boolean;
    serialNumbers: SerialNumbersModel;
    headerSearchVisible: boolean;
}

const EMPTY_SEARCH: SearchCategoriesModel = {
    all: {
        count: 0,
        filters: [],
        hasMore: false,
        manualCount: true,
        results: [],
        viewMode: 'grid'
    },
    products: {
        count: 0,
        filters: [],
        hasMore: false,
        results: [],
        viewMode: 'grid'
    },
    documents: {
        count: 0,
        filters: [],
        hasMore: false,
        results: [],
        sortOptions: {
            parameter: 'DocSort',
            options: ['Score desc', 'PrintDate desc', 'PrintDate asc']
        },
        viewMode: 'list'
    },
    frameContent: {
        count: null,
        filters: [],
        hasMore: false,
        results: [],
        // eslint-disable-next-line no-undefined
        sortOptions: undefined,
        viewMode: 'list'
    },
    content: {
        count: 0,
        filters: [],
        hasMore: false,
        results: [],
        viewMode: 'list'
    },
    parts: {
        count: 0,
        results: [],
        hasMore: false,
        filters: [],
        viewMode: 'grid'
    }
};

const EMPTY_SERIAL_NUMBERS: SerialNumbersModel = {
    'reserveProducts': [],
    'matches': [],
    'productMatchFound': false,
    'other-brand-results': []

};

@State<SearchStateModel>({
    name: 'search',
    defaults: {
        query: { q: '' },
        searchCategories: EMPTY_SEARCH,
        additionalLoading: false,
        searchLoading: true,
        serialNumbers: {
            'other-brand-results': [],
            'matches': [],
            'reserveProducts': [],
            'productMatchFound': false
        },
        headerSearchVisible: false
    }
})
@Injectable({ providedIn: 'root' })
export class SearchState {
    constructor(private readonly searchService: SearchService) { }

    @Selector()
    static searchLoading({ searchLoading }: SearchStateModel): boolean {
        return searchLoading;
    }

    @Selector()
    static additionalLoading({ additionalLoading }: SearchStateModel): boolean {
        return Boolean(additionalLoading);
    }

    @Selector()
    static query({ query }: SearchStateModel): SearchQuery {
        return query;
    }

    @Selector()
    static searchCategories({ searchCategories }: SearchStateModel): SearchCategoriesModel {
        return searchCategories;
    }

    @Selector()
    static productResults({ searchCategories }: SearchStateModel): string[] {
        return (searchCategories.products || { results: [] }).results;
    }

    @Selector([SearchState.productResults, ProductsState.repository])
    static productResources(_state: SearchStateModel, results: string[], repository: ResourceRepositoryModel<Product>): Product[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((product) => Boolean(product));
    }

    @Selector()
    static partResults({ searchCategories }: SearchStateModel): string[] {
        return (searchCategories.parts || { results: [] }).results;
    }

    @Selector([SearchState.partResults, PartsState.repository])
    static partResources(_state: SearchStateModel, results: string[], repository: ResourceRepositoryModel<Part>): Part[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((part) => Boolean(part));
    }

    @Selector()
    static contentResults({ searchCategories }: SearchStateModel): string[] {
        return (searchCategories.content || { results: [] }).results;
    }

    @Selector([SearchState.contentResults, SiteContentState.repository])
    static contentResources(_state: SearchStateModel, results: string[], repository: ResourceRepositoryModel<Content>): Content[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((content) => Boolean(content));
    }

    @Selector()
    static documentResults({ searchCategories }: SearchStateModel): string[] {
        return (searchCategories.documents || { results: [] }).results;
    }

    @Selector([SearchState.documentResults, DocumentsState.repository])
    static documentResources(_state: SearchStateModel, results: string[], repository: ResourceRepositoryModel<Document>): Document[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((document) => Boolean(document));
    }

    @Selector()
    static serialNumbers({ serialNumbers }: SearchStateModel): SerialNumberResult[] {
        return serialNumbers.matches || [];
    }

    @Selector()
    static misMatchSerialNumbers({ serialNumbers }: SearchStateModel): object {
        return serialNumbers['other-brand-results'] || [];
    }

    @Selector()
    static serialNumberMatchedProducts({ serialNumbers }: SearchStateModel): boolean {
        return serialNumbers.productMatchFound;
    }

    @Action(ChangeViewMode)
    changeViewMode(ctx: StateContext<SearchStateModel>, action: ChangeViewMode) {
        const state = ctx.getState();
        const category = state.searchCategories[action.category];

        if (!category) {
            return state;
        }

        const updatedCategory = {
            ...category,
            viewMode: action.viewMode
        };

        const updatedState = {
            ...state,
            searchCategories: {
                ...state.searchCategories,
                [action.category]: updatedCategory
            }
        };

        return ctx.setState(
            updatedState
        );
    }

    @Action(PerformSearch)
    performSearch(ctx: StateContext<SearchStateModel>, action: PerformSearch) {
        const query = {
            DocStatus: '',
            ProductStatus: '',
            DocSort: 'Score desc',
            skip: 0,
            ...action.query
        };
        ctx.patchState({
            ...this.getEmptySearch(),
            query
        });

        return this.fetchNewSearchResults(query, ctx);
    }

    @Action(RedoSearch)
    redoSearch(ctx: StateContext<SearchStateModel>) {
        const query = {
            DocStatus: '',
            ProductStatus: '',
            DocSort: 'Score desc',
            skip: 0,
            ...ctx.getState().query
        };
        ctx.patchState({
            ...this.getEmptySearch(),
            query
        });

        return this.fetchNewSearchResults(query, ctx);
    }

    @Action(FetchNextPage)
    fetchNextPage(ctx: StateContext<SearchStateModel>) {
        const { query } = ctx.getState();
        const nextPageQuery = {
            ...query,
            skip: (query.skip || 0) + (query.max || 24)
        };

        ctx.patchState({ query: nextPageQuery });

        return this.fetchAdditionalResults(nextPageQuery, ctx);
    }

    @Action(AddFilter)
    addSearchFilter(ctx: StateContext<SearchStateModel>, action: AddFilter) {
        const { query } = ctx.getState();
        const { filter } = action;

        const updatedQuery = {
            ...this.addFilterToQuery(query, filter),
            skip: 0
        };
        ctx.patchState({ query: updatedQuery });

        return this.updateSearchResults(updatedQuery, ctx);
    }

    @Action(RemoveFilter)
    removeSearchFilter(ctx: StateContext<SearchStateModel>, action: RemoveFilter) {
        const { query } = ctx.getState();
        const { filter } = action;

        const sameCategoryFilters = query[filter.category.name];

        if (!sameCategoryFilters) {
            return;
        }

        const updatedQuery = {
            ...this.removeFilterFromQuery(query, filter),
            skip: 0
        };
        ctx.patchState({ query: updatedQuery });

        this.updateSearchResults(updatedQuery, ctx);
    }

    @Action(SetSortMode)
    setSortMode(ctx: StateContext<SearchStateModel>, action: SetSortMode) {
        const { query } = ctx.getState();
        const { sortParameter, sortMode } = action;

        const updatedQuery = {
            ...query,
            [sortParameter]: sortMode,
            skip: 0
        };

        ctx.patchState({ query: updatedQuery });
        this.updateSearchResults(updatedQuery, ctx);
    }

    @Action(ReceiveSearchResults)
    receiveSearchResults(ctx: StateContext<SearchStateModel>, action: ReceiveSearchResults) {
        const { results } = action;
        const initialState = this.getEmptySearch().searchCategories;
        const serialNumbers = results.serialNumbers && results.serialNumbers.results || [];
        const otherBrandResults = results.serialNumbers && results.serialNumbers['other-brand-results'] || [];
        const partitionedProductResults = this.partitionProductResults(results.products, serialNumbers);
        ctx.patchState({
            searchCategories: this.mergeSearchCategories(initialState, {
                ...results,
                products: partitionedProductResults.products
            }),
            serialNumbers: {
                'matches': serialNumbers,
                'reserveProducts': partitionedProductResults.reserveProducts,
                'productMatchFound': Boolean(partitionedProductResults.reserveProducts.length),
                'other-brand-results': otherBrandResults
            }
        });
    }

    @Action(AppendSearchResults)
    appendSearchResults(ctx: StateContext<SearchStateModel>, action: ReceiveSearchResults) {
        const { results } = action;
        const currentState = ctx.getState();

        ctx.patchState({
            searchCategories: this.mergeSearchCategories({
                ...currentState.searchCategories,
                products: {
                    ...currentState.searchCategories.products,
                    results: currentState.serialNumbers.reserveProducts.length ?
                        [...currentState.searchCategories.products.results, ...currentState.serialNumbers.reserveProducts] :
                        currentState.searchCategories.products.results
                }
            }, results),
            serialNumbers: {
                ...currentState.serialNumbers,
                reserveProducts: []
            }
        });
    }

    @Selector()
    static headerSearchVisible({ headerSearchVisible }: SearchStateModel): boolean {
        return headerSearchVisible;
    }

    @Action(HeaderSearchVisible)
    updateHeaderSearchVisible(ctx: StateContext<SearchStateModel>, action: HeaderSearchVisible) {
        ctx.patchState({ headerSearchVisible: action.visible });
    }

    private mergeSearchCategories(current: SearchCategoriesModel, results: ExtendedSearchResults): SearchCategoriesModel {
        const result = {
            all: {
                ...this.mergeResults(
                    current.all,
                    this.mapResults(results.all)
                )
            },
            products: {
                ...this.mergeResults(
                    current.products,
                    this.mapResults(results.products)
                )
            },
            documents: {
                ...this.mergeResults(
                    current.documents,
                    this.mapResults(results.documents)
                )
            },
            content: {
                ...this.mergeResults(
                    current.content,
                    this.mapResults(results.content)
                )
            },
            parts: {
                ...this.mergeResults(
                    current.parts,
                    this.mapResults(results.parts)
                )
            }
        };

        return result;
    }

    private mapResults(results: SearchResource<{ urn: string } | { id: string } | { code: string}> = EMPTY_SEARCH_OBJECT) {
        const metadataCategories = results.metadata ? results.metadata.categories : [];
        const filters = metadataCategories.map((filterCategory) => ({
            ...filterCategory,
            filters: filterCategory.filters.map((filter) => ({
                ...filter,
                category: filterCategory
            }))
        }));

        return {
            count: results?.count,
            results: results?.results?.map((resource) => (resource as { urn: string }).urn || (resource as { id: string }).id || (resource as { code: string }).code),
            filters
        };
    }

    private mergeResults(existingResults: SearchCategoryModel, newResults: Partial<SearchCategoryModel>): SearchCategoryModel {
        const results = newResults.results || [];
        const allResults = existingResults.results.concat(results);
        const count = (existingResults.manualCount
            ? allResults.length
            : (newResults.count || existingResults.count));
        const manualCount = existingResults.manualCount && results.length >= 24;
        const hasMore = manualCount || allResults.length < (count || 0);

        return {
            count,
            filters: newResults.filters || existingResults.filters,
            hasMore,
            manualCount,
            results: allResults,
            sortMode: existingResults.sortMode,
            sortOptions: existingResults.sortOptions,
            viewMode: existingResults.viewMode
        };
    }

    private getEmptySearch(): { searchCategories: SearchCategoriesModel, serialNumbers: SerialNumbersModel } {
        return {
            searchCategories: { ...EMPTY_SEARCH },
            serialNumbers: { ...EMPTY_SERIAL_NUMBERS }
        };
    }

    private addFilterToQuery(query: SearchQuery, filter: Filter): SearchQuery {
        const sameCategoryFilters = query[filter.category.name];

        if (Array.isArray(sameCategoryFilters)) {
            return {
                ...query,
                [filter.category.name]: [...sameCategoryFilters, filter.name]
            };
        }

        if (sameCategoryFilters) {
            return {
                ...query,
                [filter.category.name]: [sameCategoryFilters, filter.name]
            };
        }

        return {
            ...query,
            [filter.category.name]: [filter.name]
        };
    }

    private removeFilterFromQuery(query: SearchQuery, filter: Filter): SearchQuery {
        const sameCategoryFilters = query[filter.category.name];

        if (!sameCategoryFilters) {
            return query;
        }

        if (Array.isArray(sameCategoryFilters)) {
            return {
                ...query,
                [filter.category.name]: sameCategoryFilters.filter((filterName) => filterName !== filter.name)
            };
        }

        return {
            ...query,
            [filter.category.name]: [sameCategoryFilters].filter((filterName) => filterName !== filter.name)
        };
    }

    private fetchAdditionalResults(query: SearchQuery, ctx: StateContext<SearchStateModel>) {
        return this.fetchSearchResults(
            query,
            () => ctx.patchState({ additionalLoading: true }),
            (results: ExtendedSearchResults) => {
                ctx.dispatch(new AppendSearchResults(results));
                ctx.patchState({ additionalLoading: false });
            }
        );
    }

    private fetchNewSearchResults(query: SearchQuery, ctx: StateContext<SearchStateModel>) {
        return this.fetchSearchResults(
            query,
            () => ctx.patchState({ searchLoading: true }),
            (results: ExtendedSearchResults) => {
                ctx.patchState({ searchLoading: false });
                ctx.dispatch(new ReceiveSearchResults(results));
                ctx.dispatch(new Navigate(
                    ['/', 'search', getMostRelevantSearchCategory(results, ctx.getState().query)],
                    {},
                    { queryParamsHandling: 'preserve' }
                ));
            }
        );
    }

    private updateSearchResults(query: SearchQuery, ctx: StateContext<SearchStateModel>) {
        return this.fetchSearchResults(
            query,
            () => ctx.patchState({ searchLoading: true }),
            (results: SearchResults) => {
                ctx.dispatch(new ReceiveSearchResults(results));
                ctx.patchState({ searchLoading: false });
            }
        );
    }

    private fetchSearchResults(query: SearchQuery, preFetch: () => void, postFetch: (results: ExtendedSearchResults) => void) {
        preFetch();

        return this.searchService.fetchSearchResults(query).subscribe(postFetch);
    }

    private partitionProductResults(productResults: SearchResource<Product & Scored> | undefined, serialNumbers: SerialNumberResult[]) {
        if (!productResults || !serialNumbers.length) {
            return {
                products: productResults,
                reserveProducts: []
            };
        }

        const results = {
            products: {
                ...productResults,
                results: [] as (Product & Scored)[]
            },
            reserveProducts: [] as string[]
        };
        const serialNumberModels = serialNumbers.map(({ model }: SerialNumberResult) => model);

        productResults.results.forEach((product) => {
            serialNumberModels.forEach((modelNumber: string) => {
                if (product.urn.startsWith(modelNumber) || modelNumber.startsWith(product.urn)) {
                    results.products.results.push(product);
                }
                else {
                    results.reserveProducts.push(product.urn);
                }
            });
        });

        if (results.products.results.length) {
            return results;
        }

        return {
            products: productResults,
            reserveProducts: []
        };
    }
}

