<a [href]="documentUrl" target="_blank" class="hvac-auto-suggest-link">
    <div class="hvac-auto-suggest-document-row">
        <utc-svg
            class="hvac-auto-suggest-document-icon"
            [ngClass]="{ 'hvac-document-pdf-icon': fileType && fileType.includes('Acrobat') }"
            [src]="fileIconType"></utc-svg>
        <div class="hvac-auto-suggest-text-container">
            <p class="hvac-auto-suggest-text">{{title}}</p>
            <p *ngIf="subtype?.length && subtype?.join" class="hvac-auto-suggest-detail hvac-auto-suggest-text" [innerHTML]="subtype?.join(', ')"></p>
        </div>
    </div>
</a>
