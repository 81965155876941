import { InputComponent } from './../input/input.component';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { createAccountPhoneInputValidator, createExistingPasswordValidator } from 'common/utils/createAccountValidators';
import { AccountStatusService } from 'common/services/account-status.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'common/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

interface registeredUserInfo {
    brandref: string,
    icpDealerCode: string
}

export interface SignupPanelContent {
    HEADLINE: string,
    SUBHEAD: string,
    PHONE_FIELD_PROMPT: string,
    PW_PROMPT: string,
    AGREE_TO_TERMS: string,
    SUBMIT_BUTTON: string
}

const validPhoneRegex = /^[1]?[+]?[ ]?[(]?[0-9]{3}[)]?[ ]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

@Component({
    selector: 'app-signup-panel',
    templateUrl: './signup-panel.component.html',
    styleUrls: ['./signup-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SignupPanelComponent implements OnInit, OnDestroy {
    @Input() content: SignupPanelContent;
    @ViewChild(InputComponent) firstInput: InputComponent;

    ngOnDestroy$ = new Subject();
    goSiteRegistrationForm = new UntypedFormGroup({
        phone: new UntypedFormControl('',
            [createAccountPhoneInputValidator(101, true, validPhoneRegex)]),
        password: new UntypedFormControl('', createExistingPasswordValidator())
    });

    requirementMessages$: Observable<string> = this.translate.get('CREATE_ACCOUNT_INFORMATION.PASSWORD_REQUIREMENTS');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    passwordRequirements$: Observable<any>;
    passwordErrorMessage$: Observable<string>;
    valueHolder: registeredUserInfo;
    signupHeadHtml$= new BehaviorSubject<string>('');
    showError = false;
    errorMessage = null;

    private validUrl = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    constructor(
        private translate: TranslateService,
        private readonly statusService: AccountStatusService
    ) { }

    ngOnInit() {
        this.passwordRequirements$ = this.goSiteRegistrationForm.controls.password.valueChanges.pipe(
            withLatestFrom(this.requirementMessages$),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map(([value, message]: any) => {
                const errors: { [key: string]: boolean } = { ...this.goSiteRegistrationForm.controls.password.errors };

                if (this.goSiteRegistrationForm.controls.userNameControl.value === value) {
                    errors.USERNAME_CANNOT_EQUAL_PASSWORD = true;
                }

                return Object.keys(errors).map((validReq) => ({
                    valid: !errors[validReq],
                    message: message[validReq]
                }));
            })
        );

        this.translate.get('HVACP_SIGNUP.SUBHEAD').subscribe((signupHtml) => {
            const urlString = signupHtml.match(this.validUrl) || [];
            const brand = environment.brand === 'Day & Night' ? 'dayandnight' : environment.brand;
            const finalHtmlString = urlString.map((url: string) => signupHtml.replace(url, `${environment.auth.createAccountRegisterUrl}?brandref=${brand}`))[0];
            this.signupHeadHtml$.next(finalHtmlString);
        });
    }

    changeTermsControl($event: Event) {
        return this.goSiteRegistrationForm.controls.termsControl.setValue(($event.target as HTMLInputElement).checked);
    }

    onSubmit() {
        if (this.goSiteRegistrationForm.status !== 'INVALID') {
            const body = {
                brand: environment.brand[0],
                ...this.goSiteRegistrationForm.value
            };

            this.statusService.postDealerLogin(body).pipe(
                takeUntil(this.ngOnDestroy$),
                catchError((err: HttpErrorResponse) => {
                    this.showError = true;
                    this.errorMessage = this.translate.instant('HVACP_SIGNUP.ERROR_MESSAGE');

                    throw err;
                })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ).subscribe((result: any) => {
                this.valueHolder = result;

                this.statusService.postCompanyId({ payload: result.HVPId }).subscribe((res: { details: string}) => {
                    window.location.href = `${environment.auth.createAccountRegisterUrl}?brandref=${environment.brand}&icpDealerCode=${res.details}`;
                });
            });
        }
    }

    public focusForm() {
        this.firstInput.input.nativeElement.focus();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
