import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import * as uuid from 'uuid';
export type ToastTheme = 'info' | 'error' | 'success' | 'warning';

export interface Toast {
    autoClose?: boolean
    bgColor?: string;
    closeable?: boolean,
    content?: string,
    id: string,
    outletName?: string,
    theme?: ToastTheme,
    timeOut?: number | null,
    isLocal?: boolean
}

export interface AddToast extends Omit<Toast, 'id'> {
    id?: string;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
    @Output() close: EventEmitter<string> = new EventEmitter();

    public toasts$: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

    private timeOuts: {[key: string]: ReturnType<typeof setTimeout>} = {};

    private default: Partial<Toast> = {
        autoClose: false,
        closeable: true,
        theme: 'info'
    };

    public add(toast: AddToast): Toast {
        const toastId = toast.id || uuid.v4();

        const newToast = {
            ...this.default,
            ...toast,
            id: toastId
        };

        const newToasts = this.toasts$.getValue();

        const idx = this.toasts$.getValue().findIndex((foundToast) => foundToast.id === newToast.id);

        if (idx < 0) {
            newToasts.push(newToast);

            if (newToast.autoClose) {
                const clearTimeoutId = setTimeout(() => {
                    this.close.emit(newToast.id);
                }, newToast.timeOut || 10000);

                if (!newToast.isLocal) {
                    this.timeOuts[newToast.id] = clearTimeoutId;
                }
            }
        }

        timer(0, 1000)
            .pipe(take(1))
            .subscribe(() => {
                this.toasts$.next(newToasts);
            });

        return newToast;
    }

    public remove(id: string) {
        this.toasts$.next(this.toasts$.getValue().filter((toast) => toast.id !== id));

        clearTimeout(this.timeOuts[id]);

        delete this.timeOuts[id];
    }

    public removeAll() {
        this.toasts$.next(this.toasts$.getValue().filter((toast) => toast.isLocal));
        this.removeAllTimeOuts();
    }

    public removeAllTimeOuts() {
        Object.entries(this.timeOuts).forEach((item: [string, NodeJS.Timeout]) => {
            clearTimeout(this.timeOuts[item[0]]);
        });

        this.timeOuts = {};
    }

    public raiseDefaultToastMessage(id: string, content: string, theme: ToastTheme) {
        this.add({
            id,
            content,
            autoClose: true,
            closeable: true,
            theme
        });
    }

    public raiseDefaultErrorToast(id: string, content: string) {
        this.raiseDefaultToastMessage(id, content, 'error');
    }
}
