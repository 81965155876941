import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Option } from 'common/components/dropdown/options-menu/options-menu.component';
import { environment } from 'common/environments/environment';
import { UserStatus } from 'common/models/account-status';
import { User } from 'common/models/auth';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AuthState } from 'common/store/auth/auth.state';
import { ContentState } from 'common/store/content/content.state';
import { ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { stringToPascalCase } from 'common/utils/title-util';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResolverService } from '../../content/services/data-resolver.service';

type Translations = {title: string, value: string, type: string}
type Types = Translations['type']
@Component({
    selector: 'hvac-brand-filter',
    templateUrl: './brand-filter.component.html',
    styleUrls: ['./brand-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class BrandFilterComponent implements OnInit {
    @Select(AuthState.user) user$: Observable<User>;
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(ContentState.contentBrandList) availableBrandsList$: Observable<string[]>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;
    @Select(ContentState.contentFilteringPreference) contentFilteringPreference$: Observable<string>;
    @Select(UiState.headerDropdown) headerDropdown$: Observable<string>;
    showFilter = environment.features.brandFilter.brand || environment.features.brandFilter.content;
    showBrandFilter = environment.features.brandFilter.brand;
    showContentFilter = environment.features.brandFilter.content;


    brandList$: Observable<Option[]>;
    contentFilterList$: Observable<Option[]>;

    contentFilterOptions$ = of([
        'Res&Com',
        'Residential',
        'Commercial'
    ]);


    allBrand = {
        title: 'All Brands',
        value: 'All Brands'
    };

    allContentFilter = {
        title: 'Res & Com',
        value: 'Res&Com'
    };

    selectedBrandOption: Option;
    selectedContentFilterOption: Option;

    constructor(
        private readonly translate: TranslateService,
        private dataResolverService: DataResolverService,
        private store: Store
    ) { }

    ngOnInit(): void {
        this.brandList$ = this.availableBrandsList$.pipe(map((brandList) => {
            const optionList: Option[] = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            brandList.forEach((availBrand: any) => {
                if (availBrand.brandName.toLowerCase() !== 'default') {
                    optionList.push(
                        {
                            title: availBrand.brandName,
                            value: availBrand.brandName
                        }
                    );
                }
            });
            optionList.sort((a, b) => a.title.localeCompare(b.title));

            if (optionList.length > 1) {
                optionList.unshift(this.allBrand);
            }

            return optionList;
        }));

        this.contentFilterList$ = combineLatest([
            this.translate.get('HEADER.CONTENT_FILTER_TYPE'),
            this.contentFilterOptions$
        ]).pipe(map(([translations, optionValues]) => {
            const optionList: Option[] = [];
            optionValues.forEach((type: Types) => {
                optionList.push(
                    {
                        title: translations[type],
                        value: type
                    }
                );
            });

            return optionList;
        }));

        if (this.showContentFilter) {
            combineLatest([this.contentFilteringPreference$, this.translate.get('HEADER.CONTENT_FILTER_TYPE')]).subscribe(([filterPreference, translations]) => this.selectedContentFilterOption = {
                title: translations[filterPreference],
                value: filterPreference
            });
        }

        if (this.showBrandFilter) {
            this.brand$.subscribe((brand) => {
                if (brand.toLowerCase() === 'default') {
                    return this.selectedBrandOption = this.allBrand;
                }
                const formattedBrand = stringToPascalCase(brand);

                return this.selectedBrandOption = {
                    title: formattedBrand,
                    value: formattedBrand
                };
            });
        }
    }

    brandChanged(selectedOption: Option) {
        this.openBrandDropdown(false);

        const contentFilteringPreference: string = this.store.selectSnapshot(ContentState.contentFilteringPreference);
        this.dataResolverService.updateUserBrandPreferences(selectedOption.value, contentFilteringPreference);
    }

    contentFilterTypeChanged(selectedOption: Option) {
        this.openBrandTypeDropdown(false);

        this.dataResolverService.updateUserBrandPreferences(this.selectedBrandOption.value, selectedOption.value);
    }

    openBrandDropdown(isOpen: boolean) {
        const dropdown = isOpen ? 'brandFilter' : 'none';

        return this.store.dispatch(new ToggleHeaderDropdown(dropdown));
    }

    openBrandTypeDropdown(isOpen: boolean) {
        const dropdown = isOpen ? 'brandType' : 'none';

        return this.store.dispatch(new ToggleHeaderDropdown(dropdown));
    }
}
