import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from 'common/environments/environment';
import { DocumentField, DocumentFieldName, DocumentQuery } from 'common/models/document';
import { DataTableLayout, DataTableFilterOption } from 'common/models/data-table';
import { QueryBase, QueryInterface } from './query-base';

const filterOptions = [
    {
        title: 'All',
        value: 'All',
        filterFunction: (_document: string) => true
    },
    {
        title: 'Dealer',
        value: 'Dealer',
        filterFunction: (document: {Subtype: string, ContentID: string}) => document.Subtype.includes('Dealer Service') && document.ContentID.toLowerCase().startsWith('dsb')
    },
    {
        title: 'Part',
        value: 'Part',
        filterFunction: (document: {Subtype: string, FormNumber: string}) => document.Subtype.includes('Parts') && document.FormNumber.toLowerCase().startsWith('pmb')
    },
    {
        title: 'Product',
        value: 'Product',
        filterFunction: (document: {Subtype: string}) => document.Subtype.includes('Product')
    },
    {
        title: 'Service',
        value: 'Service',
        filterFunction: (document: {Subtype: string, ContentID: string}) => document.Subtype.includes('Service') && document.ContentID.toLowerCase().startsWith('smb')
    },
    {
        title: 'Technical',
        value: 'Technical',
        filterFunction: (document: {Subtype: string, DocumentTitle: string}) => document.Subtype.includes('Service') && document.DocumentTitle.toLowerCase().startsWith('tic2')
    }
];

@Injectable({ providedIn: 'root' })
export class BulletinQuery extends QueryBase implements QueryInterface {
    constructor(private readonly datepipe: DatePipe) {
        super();
    }

    generateQuery(data: string): DocumentQuery {
        // get current date and the date X days ago
        const date: Date = new Date();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let startDate: any = new Date();
        const age = (environment.documents && environment.documents.bulletinAge)
            ? environment.documents.bulletinAge
            : this.getTagData(data, 'age');
        startDate = startDate.setDate(date.getDate() - age);

        return {
            count: Number(this.getTagData(data, 'max')),
            type: ['Bulletin'],
            subtype: ['Service', 'Parts', 'Product', 'Dealer Service'],
            publishedDate: this.datepipe.transform(startDate, 'MM/dd/yyyy') as string,
            releaseToDate: this.datepipe.transform(date, 'MM/dd/yyyy') as string,
            isParts: environment.brand === 'Totaline'
        } as DocumentQuery;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter(documents: any[]): any[] {
        return documents.filter((document) => (document.Subtype.includes('Product')) || (document.Subtype.includes('Service') && document.ContentID.toLowerCase().startsWith('smb')) || (document.Subtype.includes('Parts') && document.FormNumber.toLowerCase().startsWith('pmb')) || (document.Subtype.includes('Dealer Service') && document.ContentID.toLowerCase().startsWith('dsb')) || (document.Subtype.includes('Service') && document.DocumentTitle.toLowerCase().startsWith('tic2')));
    }

    fields(_data: {DOCUMENT_TITLE: string, FORM_NUMBER: string, PRINT_DATE: string, SUB_TYPE: string}): DocumentField[] {
        const fieldsToDisplay = [
            DocumentFieldName.DOCUMENT_TITLE,
            DocumentFieldName.FORM_NUMBER,
            DocumentFieldName.PRINT_DATE,
            DocumentFieldName.SUB_TYPE
        ];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.fieldFilters.filter((fieldFilter: any) => fieldsToDisplay.includes(fieldFilter.fieldName));
    }

    fetchFilterOptions(documents: string[]): DataTableFilterOption[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return filterOptions.filter((filter) => documents.filter((document: any) => filter.filterFunction(document)).length > 0);
    }

    applyFilterOption(documents: string[], option: DataTableFilterOption): string[] {
        return documents.filter((document: string) => option.filterFunction(document));
    }

    layout(): DataTableLayout {
        return DataTableLayout.STACKED;
    }
}
