import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { SuggestLimiter } from 'common/models';
import { ClearSuggestions, PerformSuggestions } from 'common/store/suggestions/suggestions.actions';

type InputType = 'text' | undefined;
export type InputTheme = 'light' | 'dark' | 'branded' | 'icp';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => SearchInputComponent),
    multi: true
};

@Component({
    selector: 'utc-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements ControlValueAccessor, OnInit {
    @Input() autocomplete?: string;
    @Input() inputId: string;
    @Input() label: string;
    @Input() placeholder?: string;
    @Input() type?: InputType;
    @Input('value') _value?: string;
    @Input() hideLabel = false;
    @Input() theme: InputTheme = 'dark';
    @Input() disabled = false;
    @Input() ariaLabel?: string;
    @Input() formControl: UntypedFormControl;
    @Input() enableClearButton?: Boolean;
    @Input() enableAutoSuggestions?: Boolean;
    @Input() headerSearchInput?: Boolean;
    @Input() limitResults: SuggestLimiter = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() closeSearch: EventEmitter<any> = new EventEmitter();

    allowSuggestionsFeature = environment.features.suggestions;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public typingTimer: any;
    public doneTypingInterval = 500;
    public minSuggestionQueryLength = 3;
    public showAutosuggest = false;


    onChange = noop;
    onTouched = noop;

    className: string;

    constructor(
        private readonly store: Store
    ) {
        this.type = 'text';
        this.inputId = '';
        this.label = '';
        this.placeholder = '';
        this._value = '';
        this.className = '';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get value(): any {
        return this._value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set value(val: any) {
        this._value = val;
    }

    closeSuggestionsWindow() {
        if (this.enableAutoSuggestions && this.showAutosuggest) {
            setTimeout(() => {
                this.showAutosuggest = false;

                return;
            }, 10);
        }
    }

    inputFocus() {
        this.showAutosuggest = true;
    }

    getNativeInputType(type: InputType) {
        // Necessary because IE11 does not know how to handle custom input types
        // https://git.io/fA5h9
        // if (type === 'mono' || type === 'mono-warning' || type === 'mono-readonly') {
        //     return 'text';
        // } else {
        return type;
        // }
    }

    ngOnInit() {
        this.className = `hvac-search-input hvac-search-input-${this.type}`;
        // eslint-disable-next-line no-self-assign
        this.placeholder = this.placeholder;
        this.writeValue(this.formControl.value);
        this.formControl.valueChanges.subscribe((val) => {
            this.writeValue(this.formControl.value);
            val && val.trim() === '' ? this.formControl.setErrors({ emptySearch: true }) : this.formControl.setErrors(null);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(val: string) {
        this._value = val;
    }

    clearSearch() {
        const inputControl = this.formControl;
        if (inputControl) {
            inputControl.reset();
            this.clearSuggestions();
        }

        if (this.headerSearchInput) {
            this.closeSearch.emit(true);
        }
    }

    doneTypingCallback(inputValue: string) {
        if (inputValue.length >= this.minSuggestionQueryLength) {
            return () => this.fetchSuggestions(inputValue);
        }

        if (inputValue.length === 0) {
            return () => this.clearSuggestions();
        }

        return null;
    }

    inputKeyup(event: Event) {
        if (this.enableAutoSuggestions) {
            const inputValue = (event.target as HTMLInputElement).value;
            const doneTyping = this.doneTypingCallback(inputValue);

            if (doneTyping) {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(doneTyping, this.doneTypingInterval);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchSuggestions(val: any) {
        this.store.dispatch(new PerformSuggestions({
            q: val,
            limiter: this.limitResults
        }));
    }

    clearSuggestions() {
        this.store.dispatch(new ClearSuggestions());
    }
}
