<section class="hvac-featured-content" *ngIf="featuredContent">
    <div class="hvac-featured-content-heading">
        <h3 class="hvac-featured-content-title hvac-h3">{{ featuredContent.title }}</h3>
        <p *ngIf="featuredContent.description" class="hvac-featured-content-description">{{ featuredContent.description }}</p>
    </div>
    <ul class="hvac-featured-content-items">
        <li class="hvac-featured-content-item-container" *ngFor="let item of featuredContent.items">
            <utc-featured-content-item [item]="item"></utc-featured-content-item>
        </li>
    </ul>
    <a *ngIf="featuredContent.sectionSingleCTA.url" [href]="featuredContent.sectionSingleCTA.url" class="hvac-marketing-login-button hvac-featured-content-cta hvac-shadow-button-with-icon" target="_blank">{{ featuredContent.sectionSingleCTA.text }}</a>
</section>
