import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';

export interface ContentIndexCard {
    title: string;
    body: string;
    link: string;
    target: string;
    imgSrc: string;
    id: string;
}

@Component({
    selector: 'hvac-index-card-list',
    templateUrl: './index-card-list.component.html',
    styleUrls: ['./index-card-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IndexCardListComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    contentList: ContentIndexCard[] = [];
    title: string;
    jumpBarData: JumpBarLink;
    titleId: string;
    maxWordLength = 117;


    constructor(private readonly jumpBar: JumpbarService,
        private readonly element: ElementRef) { }

    ngOnInit() {
        this.title = this.data.Content && this.data.Content.title ? this.data.Content.title : null;
        this.titleId = this.data.Id;

        if (this.data.Content && this.data.Content.list) {
            if (this.data.Content.list.$values) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.contentList = this.data.Content.list.$values.map((tile: any) => {
                    const tileData = {
                        title: tile.Content.title ? tile.Content.title : '',
                        body: tile.Content.text ? tile.Content.text : '',
                        link: '',
                        target: '_blank',
                        imgSrc: null,
                        id: tile.Id
                    };
                    tileData.imgSrc = tile.Content.primaryMedia ? tile.Content.primaryMedia.BinaryContent.Url : null;
                    if (tile.Content.links && tile.Content.links.$values) {
                        tileData.link = tile.Content.links.$values[0];
                        tileData.target = tile.Content.links.$values[0].linkOperation.Key;
                    }
                    else if (tile.Content.links) {
                        tileData.link = tile.Content.links;
                        tileData.target = tile.Content.links.linkOperation.Key;
                    }

                    return tileData;
                });
            }
            else {
                const tileImage = this.data.Content && this.data.Content.list && this.data.Content.list.Content && this.data.Content.list.Content.primaryMedia ?
                    this.data.Content.list.Content.primaryMedia.BinaryContent.Url
                    : null;
                let tileLink = '';
                let tileTarget = '_blank';
                if (this.data.Content.list.Content.links && this.data.Content.list.Content.links.$values) {
                    tileLink = this.data.Content.list.Content.links.$values[0];
                    tileTarget = this.data.Content.list.Content.links.$values[0].linkOperation.Key;
                }
                else if (this.data.Content.list.Content.links) {
                    tileLink = this.data.Content.list.Content.links;
                    tileTarget = this.data.Content.list.Content.links.linkOperation.Key;
                }

                this.contentList = [{
                    title: this.data.Content.list.Content.title,
                    body: this.data.Content.list.Content.text,
                    link: tileLink,
                    target: tileTarget,
                    imgSrc: tileImage,
                    id: this.data.Id
                }];
            }
            const listOfIds = this.contentList.map((tile: ContentIndexCard) => tile.id);
            this.jumpBarData = {
                link: this.titleId,
                id: [...listOfIds, this.data.Id],
                container: this.element.nativeElement
            };
            this.jumpBar.registerJumpBarLink(this.jumpBarData);
        }
    }

    ngOnDestroy() {
        if (this.jumpBarData) {
            this.jumpBar.deregisterJumpBarLink(this.jumpBarData);
        }
    }
}
