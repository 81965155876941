<a [routerLink]="['/products/detail/', urn]" class="hvac-auto-suggest-link">
    <div class="hvac-auto-suggest-image-container">
        <utc-image-fallback
            fallbackImageSrc="assets/icons/default.svg"
            [suppliedImageSrc]="imageUrl"
            [suppliedImageAlt]="urn"
            classNames="hvac-auto-suggest-product-image"
            size="small"
            ></utc-image-fallback>
    </div>
    <div class="hvac-auto-suggest-text-container">
        <p class="hvac-auto-suggest-text">{{urn}}</p>
    </div>
</a>
