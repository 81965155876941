import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MarketingSection } from 'public/app/services/marketing-product.service';


@Component({
    selector: 'hvac-marketing-links',
    templateUrl: './marketing-links.component.html',
    styleUrls: ['./marketing-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MarketingLinksComponent {
    @Input() results: MarketingSection;
}
