
export const PARTS_RESOURCE_TYPE = 'part';

export interface Part {
    channel: string;
    code: string;
    description: string;
    media?: {
        fileName: string;
        format: string;
        url: string;
    }
    resourceType: 'part';
}

export interface PartsSearchResults {
    facets: {
        category: boolean,
        isOptional: boolean,
        multiSelect: boolean,
        name: string,
        priority: number,
        values: {
            count: number,
            name: string,
            query: {
                query: {
                    value: string
                },
                url: string
            },
            selected: boolean
        }[],
        visible: boolean,
    },
    freeTextSearch: string,
    pagination: {
        currentPage: number,
        pageSize: number,
        sort: string,
        totalPages: number,
        totalResults: number
    },
    rcProducts: Part[],
    sorts: {
        code: string,
        name: string,
        selected: boolean
    }
}

export interface PartDetail {
    categories: { code: string; name: string; url: string }[];
    channel: 'CBP' | 'ICP';
    code: 'string';
    countriesOfOrigin: {isocode: string; name: string}[];
    description: string;
    easeOfInstallAndService: string;
    medias: {
        fileName: string;
        format: string;
        imageType: string;
        url: string;
    }[],
    name: string;
    partClassification: string;
    partType:{code: string; description: string}
    performance: string;
    proprietary: string;
    quality: string;
    ratings: string;
    status:{code: string; description: string}
    warranty: string;
    resourceType: 'part';
}

export type PartDetailQuery = {
    category?: string;
    subcategory?: string;
    part: string
}

export type PartsSearchQueryParms = {
    q: string;
    pageSize: number;
    brands?: string;
    currentPage?: number;
}

