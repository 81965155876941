import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HideLoginPanel, ShowLoginPanel } from './login-panel.actions';

export interface LoginPanelStateModel {
    loginPanelVisible: boolean;
}

@State<LoginPanelStateModel>({
    name: 'loginPanel',
    defaults: { loginPanelVisible: false }
})
@Injectable({ providedIn: 'root' })
export class LoginPanelState {
    @Selector()
    static loginPanelVisible({ loginPanelVisible }: LoginPanelStateModel): boolean {
        return loginPanelVisible;
    }

    @Action(ShowLoginPanel)
    showLoginPanel(ctx: StateContext<LoginPanelStateModel>) {
        ctx.patchState({ loginPanelVisible: true });
    }

    @Action(HideLoginPanel)
    HideLoginPanel(ctx: StateContext<LoginPanelStateModel>) {
        ctx.patchState({ loginPanelVisible: false });
    }
}
