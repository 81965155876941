/* eslint-disable no-param-reassign */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { ContentState } from 'common/store/content/content.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { OpenNewsLetterSignUp } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { LocalStorageService } from '../../../../common/services/localStorage/localStorage.service';

export interface FooterContent {
    template: string;
    title:string;
    subtitle?: string;
    text?: string;
    subtitleLinkText?: string;
    subtitleLink?: string;
    svg?: string | null;
}

export interface Entity {
    ComponentTemplate: {
        Title: string;
    };
    Content: {
        tags: string;
        title: string;
        subTitle: string;
        text: string;
        links?: {
            linkText: string;
            linkExternal: string;
        }
    }
}

export interface LegalLinks{
    title:string;
    link:string
}
@Component({
    selector: 'utc-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Select(ContentState.footer) footerUIContent$: Observable<any>;
    @Select(UiState.footerSignUp) hideFooterSignUp$: Observable<boolean>;

    ngOnDestroy$ = new Subject();

    public footerContent?: FooterContent[];

    public cta1Icon: string;
    public cta2Icon: string;
    public readonly maxRows = 2;
    public readonly maxColumnInRow = 2;
    public menuLinks: {
        title: string;
        link: string;
    }[] | null;

    public legalLinks: LegalLinks[] | null
    public legalCopy: string;
    public socialMenuContent: {
        socialAsset: string;
        text: string;
        link: string;
    }[] | null;

    public showFooter = false;
    isICP = environment.brand !== 'Carrier' && environment.brand !== 'Bryant' && environment.brand !== 'Totaline' && environment.brand !== 'private'
    isHideSignup: string | null;
    public isTotaline = environment.brand === 'Totaline';

    constructor(
        private readonly translate: TranslateService,
        private readonly store: Store,
        private readonly localStorageService: LocalStorageService
    ) { }

    ngOnInit() {
        this.hideFooterSignUp$.subscribe((_footerSignUp) => {
            this.isHideSignup = this.localStorageService.isHideSignup();
        });
        this.footerUIContent$.pipe(
            takeUntil(this.ngOnDestroy$),
            tap((footerContent) => {
                if (footerContent.Regions) {
                    if (this.isICP) {
                        footerContent = this.filterIcpContent(footerContent);
                    }
                    this.formatFooterUIContent(footerContent);
                    this.showFooter = true;
                }
            })
        ).subscribe();
        this.cta1Icon = environment.brand === 'Carrier' || environment.brand === 'Bryant' ? 'assets/icons/phone.svg' : 'assets/icons/envelope.svg';
        this.cta2Icon = (environment.brand !== 'Bryant' && environment.brand !== 'Carrier' && environment.brand !== 'Totaline')
            ? 'assets/icons/newsletter.svg'
            : 'assets/icons/truck.svg';
    }

    formatFooterUIContent(content: {Regions: []}) {
        this.formatMainContent(content.Regions.find((region: {MvcData:{ViewName:string}}) => region.MvcData.ViewName === 'Main'));
        this.socialMenuContent = this.formatMenuContent(content.Regions.find((region: {MvcData:{ViewName:string}}) => region.MvcData.ViewName === 'Menu'));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatMenuContent(content: any) {
        const contentEntity = content.Entities.find((entity: {ComponentTemplate:{Title:string}}) => entity.ComponentTemplate.Title === 'CT_Menu-Basic [Menu]');
        let menuItems = [];
        if (contentEntity.Content.menuItems.$values) {
            menuItems = contentEntity.Content.menuItems.$values.map((menuLink: {menuItemDetails:{Content:{menuLinkDetails:{linkText:string, linkExternal:string}}}}) => {
                const menuLinkContent = menuLink.menuItemDetails.Content;

                return {
                    text: menuLinkContent.menuLinkDetails.linkText,
                    socialAsset: this.findSocial(menuLinkContent.menuLinkDetails.linkText),
                    link: menuLinkContent.menuLinkDetails.linkExternal
                };
            });
        }
        else if (contentEntity.Content.menuItems.menuItemDetails) {
            const menuLinkContent = contentEntity.Content.menuItems.menuItemDetails.Content;

            menuItems = [{
                text: menuLinkContent.menuLinkDetails.linkText,
                socialAsset: this.findSocial(menuLinkContent.menuLinkDetails.linkText),
                link: menuLinkContent.menuLinkDetails.linkExternal
            }];
        }

        return menuItems;
    }

    formatCtaItem1(ctaItem: {Content: { title: string; subTitle: string; text: string}}) {
        return {
            title: ctaItem.Content.title,
            subtitle: ctaItem.Content.subTitle,
            text: ctaItem.Content.text
        };
    }

    formatCtaItem2(ctaItem: {Content:{title:string, links:{linkText:string, linkExternal:string}}}) {
        return {
            title: ctaItem.Content.title,
            subtitleLinkText: ctaItem.Content.links.linkText,
            subtitleLink: ctaItem.Content.links.linkExternal
        };
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatMenuLinks(menuLinks: any) {
        const menuLinkArray = menuLinks.Content.menuItems.$values.map((menuLink: {menuItemDetails:{Content:{menuLinkDetails:{linkText:string, linkExternal:string}}}}) => {
            const menuLinkContent = menuLink.menuItemDetails.Content;

            return {
                title: menuLinkContent.menuLinkDetails.linkText,
                link: menuLinkContent.menuLinkDetails.linkExternal
            };
        });

        return menuLinkArray;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatLegalLinks(legalLinks: any) {
        const legalLinkArray = legalLinks.Content.menuItems.$values.map((menuLink: {menuItemDetails:{
            Content:{menuLinkDetails:{linkText:string, linkExternal:string}}}}) => {
            const menuLinkContent = menuLink.menuItemDetails.Content;

            return {
                title: menuLinkContent.menuLinkDetails.linkText,
                link: menuLinkContent.menuLinkDetails.linkExternal
            };
        });

        return legalLinkArray;
    }

    formatLegalCopy(legalCopy: {Content:{content:string}}) {
        return legalCopy.Content.content;
    }

    findSocial(linkText: string) {
        const temp = linkText.split(' ');

        return `assets/icons/social-${temp[2].toLowerCase()}.svg`;
    }


    hasSocialLink(translateKey: string): boolean {
        return this.translate.instant(translateKey) !== translateKey;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterIcpContent(footerContent: any) {
        const filteredContent = {
            ...footerContent,
            Regions: []
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newRegion: any = {};
        footerContent.Regions.forEach((Region: {Region:string[], Entities:string[]}) => {
            newRegion = {
                ...Region,
                Entities: []
            };
            Region.Entities.forEach((entity: string) => {
                newRegion.Entities.push(entity);
            });
            filteredContent.Regions.push(newRegion);
        });

        return filteredContent;
    }

    signUpNewsLetter() {
        if (this.isICP) {
            this.store.dispatch(new OpenNewsLetterSignUp(true));
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatMainContent(content: any) {
        this.footerContent = content.Entities.map((entity: Entity): FooterContent | null => {
            if (entity.ComponentTemplate.Title === 'CT_Hero-Img' || entity.ComponentTemplate.Title === 'CT_Hero-Img-01') {
                return {
                    template: entity.ComponentTemplate.Title,
                    title: entity.Content.title,
                    subtitle: entity.Content.subTitle,
                    text: entity.Content.text,
                    subtitleLinkText: entity.Content.links?.linkText,
                    subtitleLink: entity.Content.links?.linkExternal,
                    svg: this.getSvg(entity.Content.tags)
                };
            }

            return null;
        }).filter(Boolean);

        this.menuLinks = this.formatMenuLinks(content.Entities.find(
            (entity: {ComponentTemplate:{Title:string}}) => entity.ComponentTemplate.Title === 'CT_Menu-PartialWidth'
        ));
        this.legalLinks = this.formatLegalLinks(content.Entities.find(
            (entity: {ComponentTemplate:{Title:string}}) => entity.ComponentTemplate.Title === 'CT_Menu-PartialWidth-B'
        ));
        this.legalCopy = this.formatLegalCopy(content.Entities.find(
            (entity: {ComponentTemplate:{Title:string}}) => entity.ComponentTemplate.Title === 'CT_Content-Html'
        ));
        this.legalCopy = this.legalCopy.replace('{year}', new Date().getFullYear().toString());
    }

    private getSvg(tags: string): string | null {
        const customSvg = tags?.split(' ').find((tag: string) => tag.includes('svg:'))?.split('svg:')[1];

        return customSvg ? `assets/icons/${customSvg}.svg` : null;
    }
}
