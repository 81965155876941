import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate, RouterStateModel } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { BreakPointService } from 'common/services/breakpoint.service';
import { NestedContentRegion, SearchQuery } from 'common/models';
import { NavigationService } from 'common/services/navigation.service';
import { PagePositionService } from 'common/services/page-position.service';
import { UrlService } from 'common/services/url.service';
import { AuthState } from 'common/store/auth/auth.state';
import { ContentState } from 'common/store/content/content.state';
import { HideLoginPanel, ShowLoginPanel } from 'common/store/login-panel/login-panel.actions';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { HeaderSearchVisible, PerformSearch } from 'common/store/search/search.actions';
import { SearchState } from 'common/store/search/search.state';
import { MobileMenuVisible, ToggleDealerHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, take, takeUntil, tap } from 'rxjs/operators';

const STICKY_HEADER_CLASS = 'utc-header-sticky';
const SHOW_HEADER_CLASS = 'utc-header-sticky-reveal';
const HIDE_HEADER_CLASS = 'utc-header-sticky-hide';

@Component({
    selector: 'utc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [TranslateService],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnDestroy, OnInit {
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Select(SearchState.headerSearchVisible) headerSearchVisible$: Observable<boolean>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<Boolean>;
    @Select(AuthState.isLoggedOut) isLoggedOut$: Observable<Boolean>;
    @Select(ContentState.header) headerUIContent$: Observable<{header:string, Regions:string}>;
    @Select(SearchState.query) searchQuery$!: Observable<SearchQuery>;
    @Select(LoginPanelState.loginPanelVisible) loginPanelVisible$: Observable<boolean>;
    @Select(UiState.dealerHeaderDropdown) headerDropdown$: Observable<boolean>;

    ngOnDestroy$ = new Subject();


    public loginContent: object;
    public menuContent: {
        title: string;
        link: string;
    }[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public navContent: any;

    public isIcp = environment.brand !== 'Bryant' && environment.brand !== 'Carrier' && environment.brand !== 'Totaline';
    public isTotaline = environment.brand === 'Totaline';
    public queryControl = new UntypedFormControl('', Validators.required);
    public loginEnabled = environment.features.auth.login;
    public externalLoginEnabled = environment.features.auth.externalLogin;
    public secureRedirectUri = environment.secureBaseUrl;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public backButtonCurrentMenu$: any;
    public isDesktopDevice: boolean;
    public isHomepage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url === '/home'))
        );

    public isErrorpage$: Observable<boolean> = this.store.select(({ router }): RouterStateModel => router)
        .pipe(
            takeUntil(this.ngOnDestroy$),
            map((router) => Boolean(router.state && router.state.url === '/error'))
        );

    constructor(
        private readonly store: Store,
        private readonly urlService: UrlService,
        private readonly element: ElementRef,
        private readonly pagePositionService: PagePositionService,
        private readonly renderer: Renderer2,
        private readonly viewportScroller: ViewportScroller,
        private readonly router: Router,
        private readonly navigation: NavigationService,
        public breakpointService: BreakPointService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.detectDeviceInfo();
    }


    setLoginUrl() {
        return this.urlService.setLoginUrl();
    }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.navigation.navigationSections$.subscribe((currentNavigation: any) => {
            if (currentNavigation.path.length > 1) {
                this.backButtonCurrentMenu$ = currentNavigation;
            }
            else {
                this.backButtonCurrentMenu$ = null;
            }
        });

        this.pagePositionService.viewportState$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((viewportState) => viewportState.scroll.top >= this.element.nativeElement.firstElementChild.offsetHeight),
            distinctUntilChanged(),
            tap((makeSticky) => {
                if (makeSticky) {
                    this.renderer.addClass(document.documentElement, STICKY_HEADER_CLASS);
                    this.renderer.addClass(document.documentElement, HIDE_HEADER_CLASS);
                }
                else {
                    this.renderer.removeClass(document.documentElement, STICKY_HEADER_CLASS);
                }
            })
        ).subscribe();

        this.pagePositionService.viewportState$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((viewportState) => Boolean(viewportState.scroll.changed && viewportState.scroll.velocity.y !== 0)),
            map((viewportState) => viewportState.scroll.velocity.y <= 0),
            distinctUntilChanged(),
            skip(1),
            tap((revealHeader) => {
                if (revealHeader) {
                    this.renderer.addClass(document.documentElement, SHOW_HEADER_CLASS);
                    this.renderer.removeClass(document.documentElement, HIDE_HEADER_CLASS);
                }
                else {
                    this.renderer.addClass(document.documentElement, HIDE_HEADER_CLASS);
                    this.renderer.removeClass(document.documentElement, SHOW_HEADER_CLASS);
                }
            })
        ).subscribe();

        this.searchQuery$.subscribe((query) => {
            if (query.q) {
                this.queryControl.setValue(query.q, { emitEvent: false });
            }
        });

        this.router.events.pipe(
            filter((evt: Event): evt is NavigationEnd => evt instanceof NavigationEnd)
        ).subscribe((evt: RouterEvent) => {
            if (!evt.url.includes('search')) {
                this.queryControl.setValue('', { emitEvent: true });
            }
        });

        this.headerUIContent$.pipe(
            takeUntil(this.ngOnDestroy$),
            tap((headerContent) => {
                if (headerContent.Regions) {
                    this.formatHeaderUIContent(headerContent);
                }
            })
        ).subscribe();
    }

    detectDeviceInfo() {
        this.isDesktopDevice = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatHeaderUIContent(content: any) {
        this.menuContent = this.formatMenuContent(content.Regions.find((region: {MvcData:{ViewName:string}}) => region.MvcData.ViewName === 'Menu'));
        this.navContent = this.formatNavContent(content.Regions.find((region: {MvcData:{ViewName:string}}) => region.MvcData.ViewName === 'Nav'));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatMenuContent(content: any) {
        const contentEntity = content.Entities.find((entity: {ComponentTemplate:{Title:string}}) => entity.ComponentTemplate.Title === 'CT_Menu-Basic [Menu]');
        const menuItems = contentEntity.Content.menuItems.$values
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ? contentEntity.Content.menuItems.$values.map((menuLink: any) => {
                const menuLinkContent = menuLink.menuItemDetails.Content.menuLinkDetails.linkInternal;

                return {
                    text: menuLinkContent.Content.text,
                    title: menuLinkContent.Content.title,
                    link: menuLinkContent.LinkUrl
                };
            })
            : [];

        return menuItems;
    }

    formatNavContent(content: NestedContentRegion) {
        const navUIContent = content?.Entities[0]?.Content;

        return { searchBoxPlaceholderText: navUIContent?.searchBoxPlaceholderText };
    }

    handleMobileNavigateBack() {
        this.navigation.mobileNavigateBack();
    }

    showLoginPanel() {
        this.isLoggedOut$.subscribe((loggedOut) => {
            if (loggedOut) {
                this.store.dispatch(new ShowLoginPanel());
            }
        });
    }

    scrollToTop() {
        this.viewportScroller.scrollToPosition([0, 0]);
    }

    skipToMainContent() {
        this.urlService.skipToContent('main-content');
    }

    displaySearch() {
        this.headerSearchVisible$.pipe(
            take(1)
        ).subscribe((searchActive) => {
            if (!searchActive) {
                this.store.dispatch(new HeaderSearchVisible(true));
            }
            else if (searchActive && this.queryControl.valid) {
                this.onSubmit();
            }
        });
    }

    hideSearch() {
        this.store.dispatch(new HeaderSearchVisible(false));
    }

    onSubmit() {
        const query = { q: this.queryControl.value };
        this.store.dispatch(new PerformSearch(query));
        this.store.dispatch(new Navigate(['/search'], query));
    }

    openMobileMenu() {
        this.store.dispatch(new MobileMenuVisible(true));
    }

    closeMobileMenu() {
        this.store.dispatch(new MobileMenuVisible(false));
    }

    logout() {
        this.store.dispatch(new HideLoginPanel());
        this.document.location.href = environment.logoutUrl;
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    openDealerDropdown(isOpen: boolean) {
        return isOpen ? this.store.dispatch(new ToggleDealerHeaderDropdown(true)) : this.store.dispatch(new ToggleDealerHeaderDropdown(false));
    }
}
