<div class="hvac-notifications" *ngIf="(visibleNotifications$ | async)?.length && (showNotification$ | async)">
    <span class="hvac-notifications-symbol">
        <utc-svg src="assets/icons/bell.svg"></utc-svg>
    </span>
    <ul class="hvac-notification-list">
        <li class="hvac-notifications-list-item" *ngFor="let notification of (visibleNotifications$ | async)">
            <button class="hvac-notifications-button" (click)="hasClick(notification)">
                <span class="hvac-notifications-text-notification"
                    [innerHtml]="notification?.Content?.title | richText"></span>
            </button>
            <button class='hvac-notifications-remove-button' (click)="hideNotification()">
                <utc-svg class='hvac-notifications-remove-icon' src="assets/icons/close.svg"></utc-svg>
            </button>
        </li>
    </ul>
</div>
