import { Component, HostBinding, Input } from '@angular/core';

export type Status = 'off' | 'inactive' | 'success';
@Component({
    selector: 'hvac-data-block',
    templateUrl: './data-block.component.html',
    styleUrls: ['./data-block.component.scss']
})

export class DataBlockComponent {
    @Input() status: Status;
    @Input() width: string;
    @Input() align: string;

    @HostBinding('class.hvac-data-block') get hostClass() {
        return true;
    }

    @HostBinding('class.hvac-h6') get typeStyle() {
        return true;
    }

    @HostBinding('class.hvac-success') get success() {
        return this.status === 'success';
    }

    @HostBinding('class.hvac-inactive') get inactive() {
        return this.status === 'inactive';
    }
}
