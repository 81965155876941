<div class="hvac-auto-suggest-window" [ngClass]="{ 'hvac-auto-suggest-active': show && ((suggestionsLoading$ | async) || (hasAnyResults$ | async)) }">
    <utc-loading-spinner *ngIf="(suggestionsLoading$ | async)" [spinnerSize]="'small'"></utc-loading-spinner>
    <section *ngFor="let section of (resultCategories$ | async)" class="hvac-auto-suggest-section">
        <div *ngIf="section.results.length">
            <header class="hvac-auto-suggest-header">
                <utc-svg class="hvac-auto-suggest-header-icon" [src]="setAutoSuggestSectionIcon(section.title)"></utc-svg>
                <span class="hvac-auto-suggest-header-text">{{ section.title === 'Content' ? ('SEARCH_PAGE.SITE_CONTENT' | translate) : section.title }}</span>
            </header>
            <ul class="hvac-auto-suggest-list">
                <li class="hvac-auto-suggest-list-item" *ngFor="let productSuggestion of section.results">
                    <hvac-content-suggestion
                        [title]="productSuggestion.title"
                        [routeUrl]="productSuggestion.url"
                        *ngIf="section.title === 'Content'"></hvac-content-suggestion>
                    <hvac-document-suggestion
                        [title]="productSuggestion.title"
                        [documentUrl]="productSuggestion.url"
                        [subtype]="productSuggestion.subtype"
                        [fileType]="productSuggestion.fileType"
                        *ngIf="section.title === 'Documents'"></hvac-document-suggestion>
                    <hvac-product-suggestion
                        [urn]="productSuggestion.urn"
                        [imageUrl]="productSuggestion.url"
                        *ngIf="section.title === 'Products'"></hvac-product-suggestion>
                    <hvac-part-suggestion
                        [partNumber]="productSuggestion.urn"
                        [imageUrl]="productSuggestion.url"
                        *ngIf="section.title === 'Parts' && productSuggestion.urn"></hvac-part-suggestion> 
                </li>
            </ul>
        </div>
    </section>
</div>
