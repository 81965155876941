/* eslint-disable no-continue */
import { Injectable } from '@angular/core';
import { MenuContentEntity, MenuItemDetails, MultiMenuItemModel, SingleMenuItemModel } from 'common/models';
import { createTitle } from 'common/utils/title-util';

export interface NavMenu {
    title: string;
    key: string;
    children: NavMenu[];
    parent?: NavMenu;
    path: string[];
    url: string;
    hrefTarget?: string;
    tag: string;
}

const ROOT: NavMenu = {
    title: '',
    key: '',
    url: '/',
    children: [],
    path: [''],
    tag: ''
};
const NOPARENT: NavMenu = {
    title: '',
    path: [],
    children: [],
    url: '/',
    key: '',
    tag: ''
};


@Injectable({ providedIn: 'root' })
export class NavigationContentService {
    findNavMenuByPath(navPath: string, navRoot: NavMenu): NavMenu | undefined {
        const path = decodeURIComponent(navPath.toLowerCase());

        // breadth first traversal of the nav tree - better for pages
        // closer to the root of the tree
        const searchNavMenu = (menu: NavMenu): NavMenu | undefined => {
            const queue: NavMenu[] = [menu];

            while (queue[0]) {
                const item = queue.shift();

                if (!item) {
                    continue;
                }

                if (item.url.toLowerCase() === path) {
                    return item;
                }

                if (item.children) {
                    queue.push(...item.children);
                }
            }

            if (navPath.includes('Pages')) {
                queue.push({
                    title: createTitle(navPath),
                    path: menu.path,
                    children: menu.children,
                    url: navPath,
                    parent: NOPARENT,
                    key: '',
                    tag: menu.tag
                });

                return queue[0];
            }

            return;
        };

        return searchNavMenu(navRoot);
    }

    buildNavigation(content: MenuContentEntity[]): NavMenu {
        const navRoot: NavMenu = {
            ...ROOT,
            children: []
        };
        const rootChildren = content.reduce<NavMenu[]>((children: NavMenu[], entity): NavMenu[] => {
            const { menuItems } = entity.Content;
            if (this.isSingleLink(menuItems)) {
                const navMenu = this.menuItemToNavMenu(menuItems.menuItemDetails);

                if (navMenu === null) {
                    return children;
                }

                return [...children, navMenu];
            }

            return [...children, ...this.buildNavSection(menuItems.$values).children];
        }, []);

        rootChildren.forEach((child) => {
            child.parent = navRoot;

            return;
        });
        navRoot.children = rootChildren;

        return navRoot;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildLegacyRouteMap(content: any) {
        const map: { [key: string]: string } = {};
        if (content[0] && content[0].Content) {
            const links = content[0].Content;
            if (links.links && links.links.$values) {
                links.links.$values.forEach((link: {[key:string]:string}) => {
                    map[link.linkText] = link.linkInternalParameter;

                    return;
                });
            }
        }

        return map;
    }

    private isSingleLink(menuItems: SingleMenuItemModel | MultiMenuItemModel): menuItems is SingleMenuItemModel {
        return menuItems.$type === 'ContentModelData';
    }

    private buildNavSection(menuItems: SingleMenuItemModel[]): NavMenu {
        const navMenuItems = menuItems
            .map((item) => this.menuItemToNavMenu(item.menuItemDetails))
            .filter((item) => item !== null) as NavMenu[];

        const nav = navMenuItems
            // Sort shorter paths before longer paths so parent nav is inserted before child nav.
            // Native Array.sort is stable, so order of sibling nav will be consistent with
            // what was set in the CMS
            .sort((a, b) => a.path.length - b.path.length)
            .reduce((tree: NavMenu, item: NavMenu) => {
                const parent = this.findItemParent(item, tree);

                item.parent = parent;
                parent.children.push(item);

                return tree;
            }, {
                ...ROOT,
                children: []
            });

        return nav;
    }

    private menuItemToNavMenu(menuItemDetails: MenuItemDetails): NavMenu | null {
        if (!(menuItemDetails.Content && menuItemDetails.Content.menuLinkDetails)) {
            return null;
        }

        const details = menuItemDetails.Content.menuLinkDetails;
        const path = details.linkInternalParameter ? details.linkInternalParameter.split('/') : [''];

        const navContent = {
            title: details.linkText || '',
            key: path[path.length - 1],
            children: [],
            path,
            tag: menuItemDetails && menuItemDetails.Content && menuItemDetails.Content.tags ? menuItemDetails.Content.tags.split(':')[1] : ''
        };
        const navUrls = details.linkExternal
            ? {
                url: `${decodeURIComponent(details.linkExternal)}`,
                hrefTarget: details.linkOperation && details.linkOperation.Key
            }
            : {
                url: details.linkInternalParameter ? details.linkInternalParameter.toLowerCase() : '',
                hrefTarget: '_self'
            };

        return {
            ...navContent,
            ...navUrls
        };
    }

    private findItemParent(item: NavMenu, root: NavMenu) {
        let parent = root;

        for (let i = 0; i < item.path.length; i++) {
            const segment = item.path[i];

            if (parent.key === segment) {
                continue;
            }

            const nextParent = parent.children.find(({ key }) => key === segment);

            if (nextParent) {
                parent = nextParent;
                continue;
            }

            break;
        }

        return parent;
    }
}
