export class SetBrand {
    static readonly type = '[Branding] Set Brand';
    constructor(public brand: string) {}
}

export class SetBrandRedirect {
    static readonly type = '[Branding] SetBrandRedirect';
    constructor(public brand: string) {}
}

export class ClearBranding {
    static readonly type = '[Branding] Clear Branding';
}
