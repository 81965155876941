<div class="hvac-table-list-pagination">
    <div *ngIf="useUIData && UIData.length && totalPages <= 1 && currentPage > 0" class="hvac-table-list-pagination-simple">{{
        'TABLE.RESULT_COUNT_MESSAGE' | translate: { begin: (currentPage), end: UIData.length, total:
        globalDataHolder.length
        } }}
    </div>

    <div *ngIf="useUIData && (currentPage === 0 || !UIData.length)" class="hvac-table-list-pagination-simple">
        {{ 'TABLE.NO_RECORDS_FOUND' | translate }}
    </div>

    <div *ngIf="totalPages > 1" class="hvac-table-list-pagination-advanced">
        <button class="hvac-table-list-nav-button hvac-table-list-first"
            [disabled]="currentPage <= 1"
            (click)="onPageChange(1)"
        >
            <div class="hvac-table-list-page-button">
                <utc-svg src="assets/icons/chevron-bar-right.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'TABLE.FIRST' | translate }}</span>
            </div>
        </button>

        <button class="hvac-table-list-nav-button hvac-table-list-prev"
            [disabled]="currentPage <= 1"
            (click)="onPageChange(currentPage - 1)"
        >
            <utc-svg src="assets/icons/chevron-right.svg"></utc-svg>
            <span class="hvac-accessible-text">{{ 'TABLE.NEXT' | translate }}</span>
        </button>

        <span class="hvac-table-list-pagination-text">
            <span><span>{{ 'TABLE.PAGINATION_NAV_MESSAGE' | translate }}</span>
                {{ 'TABLE.PAGINATION_NAV_MESSAGE_COUNT' | translate: { currentPage: currentPage,
                totalPages: totalPages } }}</span>
        </span>

        <button class="hvac-table-list-nav-button hvac-table-list-next"
            [disabled]="currentPage >= totalPages"
            (click)="onPageChange(currentPage + 1)"
        >
            <utc-svg src="assets/icons/chevron-right.svg"></utc-svg>
            <span class="hvac-accessible-text">{{ 'TABLE.PREV' | translate }}</span>
        </button>
        <button class="hvac-table-list-nav-button hvac-table-list-last"
            [disabled]="currentPage >= totalPages"
            (click)="onPageChange(totalPages)"
        >
            <div class="hvac-table-list-page-button">
                <utc-svg src="assets/icons/chevron-bar-right.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'TABLE.LAST' | translate }}</span>
            </div>
        </button>
    </div>
</div>
