import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BreakPointService } from 'common/services/breakpoint.service';

@Component({
    selector: 'utc-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {
    @Input() content = '';
    @Input() disabled = false;

    isDesktopDevice: boolean;

    constructor(private breakpointService: BreakPointService) {
        this.detectDeviceInfo();
    }

    detectDeviceInfo() {
        this.isDesktopDevice = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
    }
}
