<utc-tooltip class="hvac-download-button">
    <utc-tooltip-content>
        <p class='hvac-tooltip-description'>
            {{'DOWNLOAD' | translate}}
        </p>
    </utc-tooltip-content>
    <utc-tooltip-anchor-content>
        <a
            (click)="onClick()"
            href="{{url}}"
            [class.hvac-download-link-success]="successToggle$ | async"
            class="hvac-button hvac-download-link"
            target="_blank">
            <div *ngIf="!(successToggle$ | async)" class="hvac-button-initial-state">
                <span class="hvac-accessible-text">{{ 'DOWNLOAD' | translate }}</span>

                <utc-svg class="hvac-icon-button-icon" src="assets/icons/download.svg"></utc-svg>
            </div>

            <div *ngIf="successToggle$ | async" class="hvac-button-success-state">
                <span class="hvac-accessible-text">{{ 'COMPLETE' | translate }}</span>

                <utc-svg class="hvac-icon-button-icon" src="assets/icons/check.svg"></utc-svg>
            </div>
        </a>
    </utc-tooltip-anchor-content>
</utc-tooltip>
