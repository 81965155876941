import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SessionStorageService } from 'common/services/sessionStorage/sessionStorage.service';
import { ClearBranding, SetBrand, SetBrandRedirect } from './branding.actions';

const availableThemes = ['carrier', 'bryant', 'default'];
export interface BrandingStateModel {
    // theme of the application
    themeBrand: string;
    // public site user logged in from, will redirect back to it on sign out
    brandRedirect: string;
}

export enum BrandTypes {
    Bryant = 'Bryant',
}

@State<BrandingStateModel>({
    name: 'branding',
    defaults: {
        themeBrand: 'default',
        brandRedirect: 'default'
    }
})
@Injectable({ providedIn: 'root' })
export class BrandingState {
    constructor(private readonly sessionStorage: SessionStorageService) { }

    @Selector()
    static themeBrand({ themeBrand }: BrandingStateModel): string {
        return themeBrand;
    }

    @Selector()
    static brandRedirect({ brandRedirect }: BrandingStateModel): string {
        return brandRedirect;
    }

    @Action(SetBrand)
    setBrand(ctx: StateContext<BrandingStateModel>, action: SetBrand) {
        const updateBrand = this.filterUnusedBrands(action.brand);

        this.sessionStorage.saveBrand(updateBrand);

        return ctx.patchState({ themeBrand: updateBrand });
    }

    @Action(SetBrandRedirect)
    setBrandRedirect(
        ctx: StateContext<BrandingStateModel>,
        action: SetBrandRedirect
    ) {
        const updateBrand = this.filterUnusedBrands(action.brand);

        this.sessionStorage.saveBrandref(updateBrand);

        return ctx.patchState({ brandRedirect: updateBrand });
    }

    @Action(ClearBranding)
    logout(ctx: StateContext<BrandingStateModel>) {
        this.sessionStorage.removeBrand();
        this.sessionStorage.removeBrandref();
        ctx.patchState({ themeBrand: 'default' });
    }

    // utility function to only return available options
    private filterUnusedBrands(inputBrand: string) {
        if (availableThemes.includes(inputBrand.toLowerCase())) {
            return inputBrand;
        }

        return 'default';
    }
}
