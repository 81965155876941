<div *ngIf="showEmailSignUp && !(emailSignUpStatus === 'true')" div class="hvac-signup-newsletter-container">
    <button class="newsletter-close-button" hvacButton="secondary" (click)="closeSignUpNewsletter()">
        <utc-svg src="assets/icons/close.svg"></utc-svg>
        <span class="hvac-accessible-text">{{ 'SIGNUP_NEWSLETTER.CLOSE' | translate }}</span>
    </button>
    <h2 class="newsletter-title">{{ 'SIGNUP_NEWSLETTER.TITLE' | translate }}</h2>
    <p class="newsLetter-detail">{{ 'SIGNUP_NEWSLETTER.DETAIL' | translate }}</p>
    <utc-input inputId="create-account-email-input" type="text" [inputControl]="emailValidationControl"
               [label]="'SIGNUP_NEWSLETTER.HINT_EMAIL' | translate"
               [error]="emailValidationControl.dirty && !emailValidationControl.valid"
               [errorMessage]="emailErrorMessage$ | async"></utc-input>
    <hvac-options-menu [describedBy]="'#sort-by'" (change)="filterQuery($event)" [hintText]="'SIGNUP_NEWSLETTER.HINT_DISTRIBUTOR' | translate"
        [menuOptions]="distributorOptionData">
    </hvac-options-menu>
    <button hvacButton="primary" (click)="onEmailSubscription()">{{ 'SIGNUP_NEWSLETTER.SIGNUP' | translate }}</button>
</div>
