import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { BrandService } from 'common/services/brand.service';
import { buildHttpParams } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { DocumentQuery, DocumentResult } from 'common/models/document';
import { ContentState } from 'common/store/content/content.state';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { BrandItem, UserStatus } from 'common/models/account-status';

export interface DMISQuery {
    Skip: number,
    ResultCount: number;
    PrintDate: string;
    ReleaseToDate: string;
    PublishedDate: string;
    SampleMail: string;
    Brands: string;
    Country: string;
    Type: string;
    SubType: string;
    ModelNumbers: string;
    Status: string;
    DocumentTitle: string;
    ContentID: string;
    PartNumber: string;
    DocumentGroup: string;
    Category: string;
    SubCategory: string;
}

@Injectable({ providedIn: 'root' })
export class DocumentService {
    @Select(ContentState.contentBrand) userSelBrand$: Observable<string>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<UserStatus>;
    @Select(AccountStatusState.getBrands) userBrands$: Observable<BrandItem[]>;
    isPublicPlus: UserStatus;
    documentCategories$ = new BehaviorSubject('')
    documentSubCategories$ = new BehaviorSubject([''])

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly brandService: BrandService
    ) {
        this.isPublicPlusUser$.subscribe((data) => {
            this.isPublicPlus = data;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDocuments(query: DocumentQuery): Observable<any> {
        const isPartsSearch = query.isParts;
        const options$ = this.apiOptions.getAuthedHttpOptions();
        if (environment.brand === 'private' && !this.isPublicPlus) {
            return combineLatest([options$, this.userSelBrand$]).pipe(
                switchMap(([options, userSelBrand]) => {
                    const params = buildHttpParams({ ...this.generateParams(query, userSelBrand) });
                    if (isPartsSearch) {
                        return this.httpClient.get<DocumentResult>((environment.api && environment.api.documentPublicParts as string),
                            {
                                params,
                                ...options
                            }).pipe(
                            map((results) => {
                                this.setDocumentCategoryOptions(results, query);

                                return results;
                            })
                        );
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return this.httpClient.get<any>((environment.api && environment.api.document as string),
                        {
                            params,
                            ...options
                        });
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404 || err.status === 403) {
                        return of({});
                    }
                    throw err;
                })
            );
        }
        else if (environment.brand === 'private' && this.isPublicPlus) {
            return this.userBrands$.pipe(
                switchMap((userBrands) => {
                    const filteredBrands = userBrands.filter((brand) => (brand.parentBrand.toLowerCase() !== 'icl'));
                    const userBrandsVal = filteredBrands.map((brand) => brand.parentBrand).join(',');
                    const params = buildHttpParams({ ...this.generateParams(query, userBrandsVal) });

                    if (isPartsSearch) {
                        return this.httpClient.get<DocumentResult>((environment.api && environment.api.documentPublicParts as string),
                            { params }).pipe(
                            map((results) => {
                                this.setDocumentCategoryOptions(results, query);

                                return results;
                            })
                        );
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return this.httpClient.get<any>((environment.api && environment.api.documentPublic as string),
                        { params });
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404 || err.status === 403) {
                        return of({});
                    }
                    throw err;
                })
            );
        }

        return options$.pipe(
            switchMap((options) => {
                const params = buildHttpParams({ ...this.generateParams(query) });

                if (isPartsSearch) {
                    return this.httpClient.get<DocumentResult>((environment.api && environment.api.documentPublicParts as string),
                        {
                            params,
                            ...options
                        }).pipe(
                        map((results) => {
                            this.setDocumentCategoryOptions(results, query);

                            return results;
                        })
                    );
                }

                return this.httpClient.get<string>((environment.api && environment.api.document as string),
                    {
                        params,
                        ...options
                    }).pipe(
                    tap((results) => {
                        this.documentCategories$.next(results);
                    })
                );
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404 || err.status === 403) {
                    return of({});
                }
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generateParams(query: DocumentQuery, userSelBrand?: string): any {
        let brands = null;
        let category = query.category;

        if (userSelBrand === 'default' || !environment.features.brandFilter) {
            if (query.brand) {
                brands = query.brand.join(',');
            }
            else {
                brands = '';
            }
        }
        else {
            brands = userSelBrand;
        }

        if (query.isParts) {
            if (userSelBrand === 'default') {
                brands = query.brand?.join(',');
            }
            else if (Boolean(userSelBrand) && userSelBrand !== 'default') {
                brands = userSelBrand;
            }
            else {
                brands = environment.brand;
            }
            category = query.category?.map((categoryName) => `;${categoryName};`);
        }

        const status = (!query.status || query.status.length === 0 || query.status[0] === '') ? 'All' : query.status[0];

        return {
            Skip: query.skip || '',
            ResultCount: query.count,
            PrintDate: query.printDate ? query.printDate : '',
            ReleaseToDate: query.releaseToDate ? query.releaseToDate : '',
            PublishedDate: query.publishedDate ? query.publishedDate : '',
            SampleMail: query.sampleMail ? 'Yes' : '',
            Brands: brands,
            brand: this.brandService.isICPBrand(environment.brand) ? environment.brand : '',
            Country: query.country ? query.country.join(',') : '',
            Type: query.type ? query.type.join(',') : '',
            SubType: query.subtype ? query.subtype.join(',') : '',
            ModelNumbers: query.modelNumbers ? query.modelNumbers.join(',') : '',
            Status: status,
            DocumentTitle: query.title ? query.title.join(',') : '',
            ContentID: query.contentId ? query.contentId.join(',') : '',
            PartNumber: query.partNumber ? query.partNumber.join(',') : '',
            DocumentGroup: query.documentGroup ? query.documentGroup.join(',') : '',
            Category: category ? category.join(',') : '',
            SubCategory: query.subCategory ? query.subCategory.join(',') : '',
            Division: query.division ? query.division.join(',') : ''
        };
    }

    private setDocumentCategoryOptions(results: DocumentResult, initialQuery: DocumentQuery) {
        const allResultCategories = results?.Documents.reduce((categories, document) => {
            categories.push(document.Category);

            return categories;
        }, [] as string[]);
        const uniqueCategories = [...new Set(allResultCategories)];
        const resultsDocumentCategoryArr = uniqueCategories.map((categoryList) => {
            const categoryNames = categoryList.split(';');

            return categoryNames;
        }).flat(1);
        const uniqueDocumentCategories = [...new Set(resultsDocumentCategoryArr)];
        const finalDocumentCategoryList = uniqueDocumentCategories.filter((subcat) => {
            const initialCategoryQuery = initialQuery.category && initialQuery.category[0];

            return subcat.length > 0 && subcat !== initialCategoryQuery;
        });
        this.documentSubCategories$.next(finalDocumentCategoryList);
    }
}

