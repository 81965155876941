import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum RefType {
    href = 'href',
    routerLink = 'routerLink'
}

export enum LinkType {
    Button,
    Link
}

@Component({
    selector: 'hvac-link',
    templateUrl: './link.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LinkComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() link: any;
    @Input() linkType: LinkType | RefType;
    href: string;
    typeClass: string;
    refType: RefType;

    ngOnInit() {
        this.href = this.link.linkInternalParameter || this.link.linkExternal;
        this.typeClass = this.linkType === LinkType.Button ? 'hvac-shadow-button-with-icon' : '';
        this.refType = this.detectRefType(this.link);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private detectRefType(link: any): RefType {
        if (link.linkOperation && link.linkOperation.Key === '_blank') {
            return RefType.href;
        }

        const url = link.linkInternalParameter || link.linkExternal;

        // check for absolute URL
        if ((/^(?:[a-z]+:)?\/\//).test(url)) {
            return RefType.href;
        }

        return RefType.routerLink;
    }
}
