import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    Bannerinfo, BookmarksSlideVisible, BusinessLocator, FrameLoaded, HeaderDropdown, HideBusinessLocator, MobileMenuVisible, ShowBusinessLocator,
    TabletMenuVisible, ToggleBookmarkSlide, ToggleDealerHeaderDropdown, ToggleHeaderDropdown, UiStateModel
} from 'common/store/ui/ui.actions';
import { ResetBusinessLocator } from '../../../private/app/store/create-account.actions';
import { DisableFooterSignUp, OpenNewsLetterSignUp } from './ui.actions';


@State<UiStateModel>({
    name: 'ui',
    defaults: {
        businessLocator: {
            businessLocatorVisible: false,
            businessLocatorType: 'ZipCode'
        },
        banner: {
            visible: false,
            theme: 'info',
            message: ''
        },
        frameLoaded: false,
        mobileMenuVisible: false,
        tabletMenuVisible: false,
        headerDropdown: 'none',
        bookMark: false,
        dealerHeaderDropdown: false,
        newsLetterSignUp: false,
        footerSignUp: false
    }
})
@Injectable({ providedIn: 'root' })
export class UiState {
    @Selector()
    static bussinessLocator({ businessLocator }: UiStateModel): BusinessLocator {
        return businessLocator;
    }

    @Selector()
    static banner({ banner }: UiStateModel): Bannerinfo {
        return banner;
    }

    @Selector()
    static mobileMenuVisible({ mobileMenuVisible }: UiStateModel): boolean {
        return mobileMenuVisible;
    }

    @Selector()
    static tabletMenuVisible({ tabletMenuVisible }: UiStateModel): boolean {
        return tabletMenuVisible;
    }

    @Selector()
    static headerDropdown({ headerDropdown }: UiStateModel): HeaderDropdown {
        return headerDropdown;
    }

    @Selector()
    static frameLoaded({ frameLoaded }: UiStateModel): boolean {
        return frameLoaded;
    }

    @Selector()
    static bookmarkSlide({ bookMark }: UiStateModel): boolean {
        return bookMark;
    }

    @Selector()
    static dealerHeaderDropdown({ dealerHeaderDropdown }: UiStateModel): boolean {
        return dealerHeaderDropdown;
    }

    @Selector()
    static newsLetterSignUp({ newsLetterSignUp }: UiStateModel): boolean {
        return newsLetterSignUp;
    }

    @Selector()
    static footerSignUp({ footerSignUp }: UiStateModel): boolean {
        return footerSignUp;
    }


    @Action(MobileMenuVisible)
    updateMobileMenuVisible(ctx: StateContext<UiStateModel>, action: MobileMenuVisible) {
        ctx.patchState({ mobileMenuVisible: action.visible });
    }

    @Action(TabletMenuVisible)
    updateTabletMenuVisible(ctx: StateContext<UiStateModel>, action: TabletMenuVisible) {
        ctx.patchState({ tabletMenuVisible: action.visible });
    }

    @Action(ShowBusinessLocator)
    showBusinessLocator(ctx: StateContext<UiStateModel>, action: ShowBusinessLocator) {
        ctx.patchState({
            businessLocator: {
                businessLocatorType: action.type,
                businessLocatorVisible: true
            }
        });
    }

    @Action(HideBusinessLocator)
    hideBusinessLocator(ctx: StateContext<UiStateModel>) {
        ctx.patchState({
            businessLocator: {
                ...ctx.getState().businessLocator,
                businessLocatorVisible: false
            }
        });
        ctx.dispatch(new ResetBusinessLocator());
    }

    @Action(FrameLoaded)
    frameLoaded(ctx: StateContext<UiStateModel>) {
        ctx.patchState({ frameLoaded: true });
    }

    @Action(ToggleHeaderDropdown)
    toggleHeaderDropdown(ctx: StateContext<UiStateModel>, action: ToggleHeaderDropdown) {
        ctx.patchState({ headerDropdown: action.dropdown });
    }

    @Action(ToggleBookmarkSlide)
    toggleBookmarkSlide(ctx: StateContext<UiStateModel>, action: BookmarksSlideVisible) {
        ctx.patchState({ bookMark: action.visible });
    }

    @Action(OpenNewsLetterSignUp)
    openNewsLetterSignUp(ctx: StateContext<UiStateModel>, action: boolean) {
        ctx.patchState({ newsLetterSignUp: action });
    }

    @Action(ToggleDealerHeaderDropdown)
    updateDealerHeaderDropdown(ctx: StateContext<UiStateModel>, action: ToggleDealerHeaderDropdown) {
        ctx.patchState({ dealerHeaderDropdown: action.dropdown });
    }

    @Action(DisableFooterSignUp)
    disableFooterSignUp(ctx: StateContext<UiStateModel>, action: boolean) {
        ctx.patchState({ footerSignUp: action });
    }
}
