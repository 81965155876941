import { AccountStatusResponse, BaseResponse } from 'common/models/account-status';
import { ContentFilteringPreferenceType } from '../content/content.state';

export class GetUserStatus {
    static readonly type = '[Status] GetUserStatus';
}

export class AcceptCoolCalcTerms {
    static readonly type = '[CoolCalcTerms] AcceptCoolCalcTerms';
}

export class ReceiveUserStatus {
    static readonly type = '[Status] ReceiveUserStatus';
    constructor(public readonly userStatus: AccountStatusResponse) {}
}

export class SendLastLogin {
    static readonly type = '[auth] SendLastLogin';
}

export class UpdateUserBrandPreference {
    static readonly type = '[Status] UpdateUserBrandPreference';
    constructor(public readonly brand: string) {}
}

export class UpdateUserContentFilteringPreference {
    static readonly type = '[Status] UpdateUserContentFilteringPreference';
    constructor(public readonly contentFilteringPreference: ContentFilteringPreferenceType) {}
}

export class ReceiveLastLogin {
    static readonly type = '[auth] ReceiveLastLogin';
    constructor(public readonly lastLogin: BaseResponse) {}
}
