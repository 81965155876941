import { PublicConfig, PublicEnvironment } from '../../models/environment.public';
import { commonEnvVars, stagingEnv } from 'src/common/environments/environment.public.common';

const config: PublicConfig = {
    appId: 'l7xx28e99e7502df4528bf25553697566765',
    appziSrc: `https://w.appzi.io/w.js?token=0SMn4`,
    brand: 'Heil',
    brandPath: 'icp',
    publicBaseUrl: 'https://staging-heil.hvacpartners.com',
    siteCode: 803,
    ...stagingEnv
};

export const environment: PublicEnvironment = { ...commonEnvVars(config) };
