import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

type Variant = '' | 'overlay' | 'solid';
type BackgroundMode = 'solid' | 'transparent' | 'none';

@Component({
    selector: 'utc-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'hvac-loading-overlay' }
})
export class LoadingSpinnerComponent {
    @Input() spinnerSize = '';
    /* @deprecated, use backgroundMode instead */
    @Input() variant: Variant = '';
    @Input() backgroundMode: BackgroundMode = 'none';

    @HostBinding('class.hvac-spinner-solid')
    get backgroundModeIsSolid() {
        return this.backgroundMode === 'solid';
    }

    @HostBinding('class.hvac-spinner-transparent')
    get backgroundModeIsTransparent() {
        return this.backgroundMode === 'transparent';
    }
}
