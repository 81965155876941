import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class CookieService {
    // Function to get the value of a specific cookie by name
    getCookie(name: string): string | null {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(`${name}=`)) {
                return cookie.substring(1);
            }
        }

        return null;
    }
}

