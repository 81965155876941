import {
    AccountInfoUserInfo, UniqueAccountInfoStatus,
    CreateAccountStatus, CompanyDetailInfo, InvalidCompanyDetailInfo, ZipCodeSearchResponse, EmailVerificationStatus, ICPDealerRegistrationDetails
} from '../models/accountInfo';

export class ReceiveUniqueUserName {
    static readonly type = '[Account] ReceiveUniqueUserName';
    constructor(public readonly uniqueUserName: UniqueAccountInfoStatus) { }
}

export class FetchUniqueUserName {
    static readonly type = '[Account] FetchUniqueUserName';
    constructor(public readonly userNameQuery: string) { }
}

export class ResetUniqueUserName {
    static readonly type = '[Account] ResetUniqueUserName';
}

export class ResetEmailVerification {
    static readonly type = '[Account] ResetEmailVerificaton';
}


export class ReceiveUniqueEmail {
    static readonly type = '[Account] ReceiveUniqueEmail';
    constructor(public readonly uniqueEmail: UniqueAccountInfoStatus) { }
}

export class FetchUniqueEmail {
    static readonly type = '[Account] FetchUniqueEmail';
    constructor(public readonly emailQuery: string) { }
}
export class RequestEmailVerificationCode {
    static readonly type = '[Account] RequestEmailVerificationCode';
    constructor(public readonly email: string, public readonly userId: string) { }
}

export class RequestPasswordReset {
    static readonly type = '[Account] RequestPasswordReset';
    constructor(public readonly oldPassword: string, public readonly newPassword: string) { }
}

export class ResetPasswordState {
    static readonly type = '[Account] ResetPasswordState';
}


export class SubmitEmailVerificationCode {
    static readonly type = '[Account] SubmitEmailVerificationCode';
    constructor(public readonly email: string,
        public readonly userId: string,
        public readonly code: string) { }
}

export class ReceiveEmailVerificationCode {
    static readonly type = '[Account] ReceiveEmailVerificationCode';
    constructor(public readonly codeStatus: EmailVerificationStatus) { }
}

export class SubmitEmailVerificationStatus {
    static readonly type = '[Account] SubmitEmailVerificationStatus';
    constructor(public readonly accountInfor: AccountInfoUserInfo) { }
}


export class ResetUniqueEmail {
    static readonly type = '[Account] ResetUniqueEmail';
}

export class ResetValidAccountInfo {
    static readonly type = '[Account] ResetValidAccountInfo';
}


export class ReceiveCompanyCode {
    static readonly type = '[Account] ReceiveCompanyCode';
    constructor(public readonly companyCode: Array<CompanyDetailInfo>) { }
}

export class ReceiveCompanyCodeErrors {
    static readonly type = '[Account] ReceiveCompanyCodeErrors';
    constructor(public readonly companyCodeErrors: InvalidCompanyDetailInfo) { }
}

export class FetchCompanyCode {
    static readonly type = '[Account] FetchCompanyCode';
    constructor(public readonly companyCodeQuery: string) { }
}

export class ResetCompanyCodeInfo {
    static readonly type = '[Account] ResetCompanyCodeInfo';
}

export class SetCompanyCodeInfo {
    static readonly type = '[Account] SetCompanyCodeInfo';
    constructor(
        public readonly companyCodeQuery: string,
        public readonly companyCodeInfo: CompanyDetailInfo
    ) { }
}

export class SetPrimaryDistributorId {
    static readonly type = '[Account] SetPrimaryDistributorId';
    constructor(
        public readonly primaryDistributorId: string
    ) { }
}

export class ResetCompanyCodeQuery {
    static readonly type = '[Account] ResetCompanyCodeQuery';
}

export class ResetBusinessLocator {
    static readonly type = '[Account] ResetBusinessLocator';
}

export class ReceiveSearchCompanyZipCode {
    static readonly type = '[Account] ReceiveSearchCompanyZipCode';
    constructor(public readonly searchResults: ZipCodeSearchResponse) { }
}

export class ReceiveSearchCompanyCode {
    static readonly type = '[Account] ReceiveSearchCompanyCode';
    constructor(public readonly searchResults: Array<CompanyDetailInfo>) { }
}

export class FetchSearchCompanyZipCode {
    static readonly type = '[Account] FetchSearchCompanyZipCode';
    constructor(public readonly zipCode: string) { }
}

export class ReceiveCreateAccount {
    static readonly type = '[Account] ReceiveCreateAccount';
    constructor(public readonly createAccountStatus: CreateAccountStatus) { }
}

export class FetchCreateAccount {
    static readonly type = '[Account] FetchCreateAccount';
}

export class SetAccountUserInformation {
    static readonly type = '[Account] SetAccountUserInformation';
    constructor(public readonly accountInfo: AccountInfoUserInfo) { }
}

export class ResetAccountUserInformation {
    static readonly type = '[Account] ResetAccountUserInformation';
}

export class LockUserAccountForm {
    static readonly type = '[Account] LockUserAccountForm';
}

export class UnlockUserAccountForm {
    static readonly type = '[Account] UnlockUserAccountForm';
}

export class ICPDealerRegistrationCode {
    static readonly type = '[Account] ICPDealerRegistrationCode';
    constructor(public readonly code: ICPDealerRegistrationDetails) { }
}
