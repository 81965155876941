/* eslint-disable no-bitwise */
import { HttpParams } from '@angular/common/http';

// Simple, fast hash of a string to a numerical hash
// Based on Java's String.hashCode
export const digest = (data: string): string => {
    let hash = 0;

    for (let i = 0; i < data.length; i++) {
        const char = data.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        // Convert to 32bit integer
        hash &= hash;
    }

    return Math.abs(hash).toString();
};
function wrapParams<T>(paramValues: T|T[]|undefined): T[] {
    if (!paramValues) {
        return [];
    }

    return Array.isArray(paramValues) ? paramValues : [paramValues];
}

export const buildHttpParams = (query: { [paramName: string]: string|number|undefined|(string|number)[]|boolean }) => Object.entries(query)
    .filter(([_name, value]) => typeof value !== 'undefined')
    .reduce((
        params,
        [paramName, paramValue]
    ) => wrapParams(paramValue).reduce((appendParams: HttpParams, value) => appendParams.append(paramName, `${value}`), params),
    new HttpParams());


