import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputType } from './input.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;

export interface Requirement {
    valid: boolean;
    message: string;
}

@Component({
    selector: 'utc-password-input',
    templateUrl: './passwordInput.component.html',
    styleUrls: ['./passwordInput.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PasswordInputComponent implements OnInit {
    @Input() inputId: string;
    @Input() label: string;
    @Input() placeholder?: string;
    @Input('value') _value?: string;
    @Input() hideLabel = false;
    @Input() disabled = false;
    @Input() ariaLabel?: string;
    @Input() passwordControl: AbstractControl;
    @Input() errorMessage: string;
    @Input() error: boolean;
    @Input() infoMessage: string;
    @Input() requirements: Requirement[];


    passwordIcon = 'assets/icons/eye.svg';

    onChange = noop;
    onTouched = noop;
    type: InputType = 'password';

    className: string;
    minimizeLabel: boolean;

    constructor() {
        this.type = 'password';
        this.inputId = '';
        this.label = '';
        this.placeholder = '';
        this._value = '';
        this.className = '';
    }

    ngOnInit() {
        this.passwordControl.valueChanges.subscribe((value) => {
            this.passwordControl.setValue(value.trim(), { emitEvent: false });
        });
    }

    trimPasswordValue(value: string) {
        if (value) {
            this.passwordControl.setValue(value.trim(), { emitEvent: true });
        }
    }

    togglePasswordVisibility() {
        if (this.type === 'password') {
            this.type = 'text';
            this.passwordIcon = 'assets/icons/eye-cross.svg';
        }
        else {
            this.type = 'password';
            this.passwordIcon = 'assets/icons/eye.svg';
        }
    }
}
