/* eslint-disable no-undef */
// eslint-disable-next-line spaced-comment
/// <reference types="@types/googlemaps" />
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Label, Marker, DEFAULT_ZOOM } from '../service/distributor-locator.service';

@Component({
    selector: 'hvac-distributor-map',
    templateUrl: './hvac-map.component.html',
    styleUrls: ['./hvac-map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HvacMapComponent implements AfterViewInit {
    @ViewChild('hvacMap', { static: false }) gmapElement: ElementRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() markerClick = new EventEmitter<any>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedMarker$ = new BehaviorSubject<any>([]);
    map: google.maps.Map;
    myLongitude: number;
    myLatitude: number;
    markersArray: google.maps.Marker[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapData$ = new BehaviorSubject<any>([]);
    get markerSelection() {
        return this.selectedMarker$.getValue();
    }

    get mapData() {
        return this.mapData$.getValue();
    }

    @Input()
    set markerSelection(value: Marker) {
        this.selectedMarker$.next(value);
    }

    @Input()
    set mapData(value: Marker[]) {
        this.mapData$.next(value);
    }

    ngAfterViewInit() {
        this.mapInitializer();

        this.mapData$.subscribe((mapData) => {
            if (this.markersArray.length > 0) {
                this.clearMarkers();
            }
            if (mapData?.length > 0) {
                this.loadAllMarkers(mapData);
            }
        });

        this.selectedMarker$.subscribe((selectedMarker) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.markersArray.forEach((markerItem: any) => {
                if (markerItem.id === selectedMarker?.id) {
                    markerItem?.setLabel({
                        color: '#0079AD',
                        text: selectedMarker?.label?.text,
                        fontSize: '1.6rem',
                        fontWeight: 'bold',
                        className: 'map-icon-style'
                    } as Label);
                    this.map.setZoom(DEFAULT_ZOOM);
                    markerItem?.setZIndex(100);
                    markerItem?.setMap(this.map);
                    markerItem?.setPosition(markerItem?.getPosition() as google.maps.LatLng);
                    if (markerItem) {
                        this.map.panTo(markerItem?.getPosition() as google.maps.LatLng);
                    }
                }
                else {
                    markerItem?.setZIndex(10);
                    markerItem?.setLabel(null);
                    markerItem?.setMap(this.map);
                }
            });
        });
    }

    mapInitializer(): void {
        const mapProp = {
            center: new google.maps.LatLng(this.myLatitude, this.myLongitude),
            zoom: DEFAULT_ZOOM,
            maxZoom: 15,
            mapTypeControlOptions: { mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID] },
            disableDefaultUI: false,
            mapTypeControl: true,
            scaleControl: true,
            zoomControl: true,
            zoomControlOptions: { style: google.maps.ZoomControlStyle.LARGE },
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    }

    clearMarkers() {
        this.markersArray.forEach((marker) => {
            marker.setMap(null);
        });
    }

    loadAllMarkers(mapData: Marker[]): void {
        if (!mapData) {
            return;
        }
        const bounds = new google.maps.LatLngBounds();

        mapData.forEach((markerInfo, index) => {
            markerInfo.icon.url = 'assets/icons/location_Icon.svg';
            markerInfo.label.color = 'white';
            markerInfo.label.fontSize = '1.6rem';
            markerInfo.label.fontWeight = 'semi-bold';
            markerInfo.className = 'hvac-map-multiline';
            markerInfo.icon.labelOrigin = new google.maps.Point(16, 50);

            const marker = new google.maps.Marker({ ...markerInfo });

            marker.addListener('click', () => {
                this.map.setZoom(DEFAULT_ZOOM);
                marker.setPosition(marker.getPosition() as google.maps.LatLng);
                this.map.panTo(marker.getPosition() as google.maps.LatLng);
                this.markerClick.next({
                    marker: marker,
                    index: index
                });
            });
            bounds.extend(marker.getPosition() as google.maps.LatLng);
            marker.setMap(this.map);
            this.map?.fitBounds(bounds);
            this.markersArray.push(marker);
            this.map.setZoom(DEFAULT_ZOOM);
        });
    }
}
