<main class="hvac-main-content-container">
    <ng-template hvac-content-header-host></ng-template>
    <hvac-content-jump-bar *ngIf="jumpBarData" [possibleJumpLinks]="jumpBarData"></hvac-content-jump-bar>

    <utc-base-layout [ngClass]="{'hide-content':(contentLoading$ | async)}" class="hvac-landing-page {{layoutStyle}}">
        <ng-template utc-content-host></ng-template>
        <hvac-nav-landing *ngIf="!(errorService.errorStatus$ | async) && (dataResolverService.showNav$ | async) "></hvac-nav-landing>

        <error-page *ngIf="!!(errorService.errorStatus$ | async)" [status]="errorService.errorStatus$.value">
        </error-page>
    </utc-base-layout>
</main>
