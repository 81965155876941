import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RecentActivityModel } from 'common/models/recent-activity';


@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    constructor(
        public datepipe: DatePipe
    ) {}

    storeRecentActivity(data: RecentActivityModel) {
        if (!data.pageTitle || !data.pageUrl) {
            return;
        }


        const recentActivityItems: RecentActivityModel[] = localStorage.recent_activity ? JSON.parse(localStorage.getItem('recent_activity') || '[]') : [];
        recentActivityItems.reverse();
        if (recentActivityItems.length >= 20) {
            recentActivityItems.shift();
        }
        let crumbList: {title:string, url: string;}[] = [];
        if (data.navMenu) {
            crumbList = [{
                title: data.pageTitle,
                url: data.pageUrl
            }];
            let parent = data.navMenu ? data.navMenu.parent : null;
            while (parent) {
                crumbList.push(
                    {
                        title: parent.title === '' ? 'Dashboard' : parent.title,
                        url: parent.url
                    }
                );
                parent = parent.parent ? parent.parent : null;
            }
            crumbList.shift();
            crumbList.reverse();
        }
        if (!recentActivityItems[0]) {
            recentActivityItems.push({
                timeStamp: this.datepipe.transform(new Date(), 'MMM d, y'),
                pageTitle: data.pageTitle,
                pageUrl: data.pageUrl,
                breadcrumb: crumbList
            });
        }
        else if (recentActivityItems[0].pageTitle !== data.pageTitle && recentActivityItems[0].pageUrl !== data.pageUrl) {
            recentActivityItems.push({
                timeStamp: this.datepipe.transform(new Date(), 'MMM d, y'),
                pageTitle: data.pageTitle,
                pageUrl: data.pageUrl,
                breadcrumb: crumbList
            });
        }

        return localStorage.setItem('recent_activity', JSON.stringify(recentActivityItems.reverse()));
    }

    setEmailSignUp(newsLetterStatus: boolean) {
        localStorage.setItem(`email_signup`, `${newsLetterStatus}`);
    }

    hideSignup(newsLetterSKip: boolean) {
        localStorage.setItem(`hide_signup`, `${newsLetterSKip}`);
    }

    isEmailSignUpCompleted() {
        return localStorage.getItem(`email_signup`);
    }

    isHideSignup() {
        return localStorage.getItem(`hide_signup`);
    }
}
