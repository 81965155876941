import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'common/models';
import { MediaService } from 'common/services/media.service';

@Component({
    selector: 'hvac-browse-product-tile',
    templateUrl: './product-tile.component.html',
    styleUrls: ['./tile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductTileComponent {
    @Input() product!: Product;

    constructor(
        private readonly mediaService: MediaService,
        private readonly sanitizer: DomSanitizer
    ) {}

    get productName() {
        return this.sanitizer.bypassSecurityTrustHtml(this.product.name);
    }

    getProductImage(product: Product): string {
        const defaultImage = this.mediaService.getDefaultImage(product.media, '100x100');

        return defaultImage ? defaultImage.url : '';
    }

    getDefaultProductIcon(product: Product): string {
        const defaultCategoryIcon = this.mediaService.getDefaultCategoryIcon(product);

        return defaultCategoryIcon;
    }
}
