/* eslint-disable no-use-before-define */
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true
};

@Component({
    selector: 'utc-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    encapsulation: ViewEncapsulation.None
})
export class RadioButtonComponent implements ControlValueAccessor {
    @Input() controlName = '';
    @Input() disabled: boolean;
    @Input() iconSrc = '';
    @Input() dataId = '';
    @Input() label = '';
    @Input() name = '';
    @Input() required ? = false;
    @Input() value = '';
    @Input() checked = false;

    isFocused: boolean;

    onChange = noop;
    onTouched = noop;

    constructor() {
        this.checked = false;
    }

    writeValue(val: string) {
        this.value = val;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    radioFocus() {
        this.isFocused = true;
    }

    radioBlur() {
        this.isFocused = false;
    }
}
