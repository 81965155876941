<div class="hvac-highlights-media-highlight">
    <ng-container *ngIf="data.Content.links.linkOperation; else multiLinkItem">
        <a *ngIf="data.Content.links.linkOperation.Key === '_blank'; else internalLink"
            class="hvac-highlights-list-item-media-item hvac-highlights-list-item-link"
            [href]="data.Content.links.linkExternal | decodeUriComponent"
            rel="noopener noreferrer"
            target="_blank">
            <ng-container *ngTemplateOutlet="mediaLinkContent"></ng-container>
        </a>

        <ng-template #internalLink>
            <a class="hvac-highlights-list-item-media-item hvac-highlights-list-item-link"
                [routerLink]="[data.Content.links.linkExternal | decodeUriComponent]">
                <ng-container *ngTemplateOutlet="mediaLinkContent"></ng-container>
            </a>
        </ng-template>
    </ng-container>

    <ng-template #multiLinkItem>
        <div class="hvac-highlights-list-item-media-item">
            <img class="hvac-highlights-list-item-media" [src]="data.Content.primaryMedia.BinaryContent.Url"/>
            <div class="hvac-highlights-list-item-text">
                <h4 class="hvac-highlights-list-item-title" [innerHTML]="data.Content.title | richText"></h4>
                <p class="hvac-highlights-list-item-description" *ngIf="data.Content.text" [innerHtml]="data.Content.text | richText"></p>
                <div class="hvac-highlights-list-item-additional-links">
                    <ng-container *ngFor="let link of additionalLinks">
                        <a *ngIf="link.linkOperation.target === '_blank'; else additionalLinkInternal"
                            class="hvac-highlights-list-item-addtional-links-link"
                            [href]="(link.linkExternal || '') | decodeUriComponent"
                            [title]="link.linkText"
                            [ngClass]="{ 'hvac-button hvac-button hvac-button-small': !link.icon }"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img class="hvac-highlights-list-item-link-badge" *ngIf="link.icon" [src]="link.icon" [alt]="link.linkText"/>
                            <span [ngClass]="{ 'hvac-accessible-text': link.icon }">{{ link.linkText }}</span>
                        </a>

                        <ng-template #additionalLinkInternal>
                            <a class="hvac-highlights-list-item-addtional-links-link"
                                [routerLink]="[(link.linkExternal || '') | decodeUriComponent]"
                                [title]="link.linkText"
                                [ngClass]="{ 'hvac-button hvac-button hvac-button-small': !link.icon }">
                                <img class="hvac-highlights-list-item-link-badge" *ngIf="link.icon" [src]="link.icon" [alt]="link.linkText"/>
                                <span [ngClass]="{ 'hvac-accessible-text': link.icon }">{{ link.linkText }}</span>
                            </a>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #mediaLinkContent>
        <img class="hvac-highlights-list-item-media" [src]="data.Content.primaryMedia.BinaryContent.Url" [alt]="data.Content.primaryMedia.ExternalContent.Metadata.Alt"/>
        <div class="hvac-highlights-list-item-text">
            <h4 class="hvac-highlights-list-item-title" [innerHTML]="data.Content.title | richText"></h4>
            <p class="hvac-highlights-list-item-description" *ngIf="data.Content.text" [innerHtml]="data.Content.text | richText"></p>
        </div>
    </ng-template>
</div>
