<a class="hvac-browse-tile hvac-browse-product-tile" [routerLink]="['/products', 'detail', product.urn]">
    <utc-image-fallback
        class="hvac-browse-tile-image"
        [suppliedImageAlt]="product.urn"
        [suppliedImageSrc]="getProductImage(product)"
        [fallbackImageSrc]="getDefaultProductIcon(product)"></utc-image-fallback>
    <div>
        <h4 class="hvac-browse-tile-title">{{ product.urn }}</h4>
        <p class="hvac-browse-tile-name" *ngIf="product.name" [innerHTML]="productName"></p>
    </div>
</a>
