import { ContentComponent } from 'common/content/services/content-component.service';
import { Input, Component, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'utc-content-list',
    templateUrl: './content-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContentListComponent implements ContentComponent {
    @Input() data: BaseContentEntity;
}
