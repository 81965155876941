<section class="hvac-hero"
    [class.hvac-hero-search-page]="(isSearchPage$ | async)"
    [class.hvac-hero-landing-page]="(isLandingPage$ | async)"
    [class.hvac-hero-detail-page]="(isDetailPage$ | async)"
    [class.hvac-hero-covid-page]="(isCovidPage$ | async)">
    <div class="hvac-hero-pattern-mask">
        <utc-svg class="hvac-hero-pattern" src="assets/images/home-pattern.svg"></utc-svg>
    </div>
    <div class="hvac-hero-content">
        <ng-content></ng-content>
    </div>
</section>
