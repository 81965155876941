<div class="hvac-private-navigation" [ngClass]="{'hvac-panel-nav-active': (mobileMenuVisible$ | async) || (tabletMenuVisible$ | async)}"
    (mouseleave)="handleMouseLeave()">
    <div class="hvac-navigation-container-inner">
        <button class="hvac-navigation-tablet-menu-button" buttonType="button"
        (click)="openTabletNavMenu()" title="{{ 'OPEN_NAV_MENU' | translate }}">
            <utc-svg src="assets/icons/menu.svg"></utc-svg>
        </button>
        <nav class="hvac-private-navigation-panel-menu">
            <div class="hvac-panel-menu-tablet-logo">
                <a routerLink="/" class="hvac-panel-menu-logo-link" (click)="closeTabletNavMenu()">
                    <span class="hvac-accessible-text">{{ 'BRAND' | translate }}</span>
                    <utc-svg class="hvac-private-header-logo" src="assets/images/hvac-partners.svg"></utc-svg>
                </a>
            </div>
            <div class="hvac-private-navigation-tablet-menu-button-row">
                <button class="hvac-private-navigation-tablet-menu-button hvac-private-navigation-tablet-menu-close" buttonType="button" (click)="closeTabletNavMenu()" title="{{ 'OPEN_MENU' | translate }}">
                    <utc-svg class="hvac-private-navigation-tablet-menu-icon-close" src="assets/icons/close.svg"></utc-svg>
                </button>
                <div class='hvac-private-navigation-tablet-back-button' *ngIf="(backButtonCurrentMenu$)">
                    <span class='hvac-private-navigation-tablet-back-text' >{{(backButtonCurrentMenu$)?.title}}</span>
                    <button class="hvac-private-navigation-tablet-menu-button hvac-private-navigation-tablet-menu-back" buttonType="button" (click)="handleTabletNavigateBack()" title="{{ 'NAVIGATE_BACK' | translate }}">
                        <utc-svg class="hvac-private-navigation-tablet-menu-icon-back" src="assets/icons/chevron-down.svg"></utc-svg>
                    </button>
                </div>
            </div>
            <div *ngIf="brandFilter" class="hvac-private-brand-filter-menu">
                <hvac-brand-filter></hvac-brand-filter>
            </div>
            <hvac-navigation-panel-container></hvac-navigation-panel-container>
            <div *ngIf="showUserDetailDropdown$ | async" class='hvac-private-navigation-mobile-menu'>
                <!-- <hvac-user-detail-dropdown></hvac-user-detail-dropdown> -->
                <!-- <div class='hvac-private-navigation-brand-badge' *ngIf="(brand$ | async) !== 'default' ">
                    {{brand$ | async}}
                </div> -->
            </div>
        </nav>
        <hvac-navigation-nested-container class='hvac-private-navigation-desktop' [firstLevel]="true" [navContent]="(navContent$ | async)!"></hvac-navigation-nested-container>
        <div class="hvac-header-search" [ngClass]="{ 'hvac-search-filled': queryControl.value }">
            <form class="hvac-header-search-form hvac-form-element" (submit)="onSubmit()">
                <utc-search-input
                    [formControl]="queryControl"
                    name="q"
                    inputId="utc-nav-search"
                    autocomplete="off"
                    label="{{ 'SEARCH' | translate }}"
                    [title]="'SEARCH' | translate"
                    [placeholder]="'SEARCH' | translate"
                    type="text"
                    [hideLabel]="true"
                    theme="branded"
                    [enableAutoSuggestions]="queryControl.valid">
                </utc-search-input>

                <div class="hvac-input-action-wrap">
                    <button hvacButton="icon" type="submit" [title]="'SEARCH' | translate">
                        <utc-svg src="assets/icons/search.svg" [ngClass]="{ 'hvac-totaline-search-color': isTotaline }"></utc-svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div (click)="closeTabletNavMenu()" class='hvac-private-navigation-overlay' [ngClass]="{'hvac-private-navigation-overlay-active': (tabletMenuVisible$ | async) || (mobileMenuVisible$ | async)}"></div>
