import { Component, OnInit, Inject, HostListener, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'hvac-scroll-to-top-button',
    templateUrl: './scroll-to-top-button.component.html',
    styleUrls: ['./scroll-to-top-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScrollToTopButtonComponent implements OnInit {
    windowScrolled: boolean;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        }
        else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.document.body.blur();
    }
}

