<div class="hvac-error" [hidden]="dataResolverService.resolving$ | async">
    <div class="hvac-error-body hvac-content-container">
        <div class="hvac-error-body">
            <div class="hvac-error-text-content">
                <h1 class="hvac-error-header-text">{{ 'ERROR.ERROR_TITLE'+statusString | translate}}</h1>

                <p class="hvac-error-body-copy">{{ 'ERROR.ERROR_BODY'+statusString | translate }}</p>

                <a *ngIf="statusString !== '_403_REMOVE_BRAND'" hvacButton="primary" class='hvac-back-to-dashboard' routerLink="/">
                    <span>{{ 'ERROR.ERROR_CONTINUE' | translate }}</span>

                    <utc-svg [src]="'assets/icons/browser-frame.svg'"></utc-svg>
                </a>
                <utc-button *ngIf="statusString === '_403_REMOVE_BRAND'" buttonType="button" classNames="hvac-primary-button hvac-back-to-dashboard" label="{{ 'ERROR.RESET_ALL_FILTERS' | translate }}" title="{{ 'ERROR.RESET_ALL_FILTERS' | translate }}" (click)="resetFilters()"></utc-button>
            </div>

            <utc-svg class="hvac-error-illustration" src="assets/icons/error.svg"></utc-svg>
        </div>
    </div>
</div>
