import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'common/environments/environment';
import { Injectable } from '@angular/core';
import { switchMap, catchError } from 'rxjs/operators';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { buildHttpParams } from 'common/api/utils';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NewsLetterParam {
    brand?: string;
    distributor?: string;
    email: string;
}

export interface DistributorData{
    Name:string;
    ID:string;
}
export interface EmailSubscriptionInputErrorObject {
    'LENGTH'?: boolean;
    'REQUIRED'?: boolean;
    'EMAIL_FORMAT'?: boolean;
}


export function EmailValidator(maxLength: number, isRequired: boolean, patternRegex: RegExp): ValidatorFn {
    return (control: AbstractControl): EmailSubscriptionInputErrorObject | null => {
        const errorObject: EmailSubscriptionInputErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined') {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length >= maxLength) {
                errorObject.LENGTH = true;
            }
            if (patternRegex && !patternRegex.test(controlValue)) {
                errorObject.EMAIL_FORMAT = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(errors: any, messages: any) {
    if (errors) {
        const error = Object.keys(errors)[0];

        return messages[error];
    }

    return null;
}

@Injectable({ providedIn: 'root' })
export class SignUpNewsLetterService {
    apiNewsletterSubscribe = 'https://toddsit.com/gonews/api';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {

    }

    fetchDistributorData() {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = this.buildParams();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string>(`${this.apiNewsletterSubscribe}/distributors`, {
                params,
                ...options
            })),
            catchError((_err: HttpErrorResponse) => _err.message)
        );
    }

    parseData(distributorData: DistributorData[]) {
        return distributorData.map((distributor) => ({
            title: distributor.Name,
            value: distributor.ID
        }));
    }

    buildParams() {
        return buildHttpParams({ brand: this.getBrandParamCode(environment.brand) });
    }

    updatenewsletterSubscription(param: NewsLetterParam) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        param.brand = this.getBrandParamCode(environment.brand);

        return options$.pipe(
            switchMap((options) => this.httpClient.post<string>(`${this.apiNewsletterSubscribe}/newsletter`, param, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getBrandParamCode(brand: string) {
        switch (brand) {
            case 'Airquest':
                return 'Q';
            case 'Arcoaire':
                return 'A';
            case 'Comfortmaker':
                return 'C';
            case 'Day & Night':
                return 'D';
            case 'Heil':
                return 'H';
            case 'Keeprite':
                return 'K';
            case 'Tempstar':
                return 'T';
            default:
                return '';
        }
    }
}
