import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface SectionSelectOption {
    name: string;
    value: string;
}

@Component({
    selector: 'hvac-section-select',
    templateUrl: './section-select.component.html',
    styleUrls: ['./section-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SectionSelectComponent {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public control: string;
    @Input() public selectId: string;
    @Input() public options: SectionSelectOption[];
}
