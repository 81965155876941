import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IncentivesModel } from 'common/models/marketing';

@Component({
    selector: 'utc-check-list',
    templateUrl: './check-list.component.html',
    styleUrls: ['./check-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckListComponent {
    @Input() resource: IncentivesModel;
}
