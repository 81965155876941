import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { ContentComponent } from 'common/content/services/content-component.service';
import { environment } from 'common/environments/environment';
import { AuthState } from 'common/store/auth/auth.state';
import { Observable } from 'rxjs';

@Component({
    selector: 'utc-sign-in-teaser',
    templateUrl: './sign-in-teaser.component.html',
    styleUrls: ['./sign-in-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignInTeaserComponent implements ContentComponent, OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    @Input() signInLink: string;
    @Input() hook: string = 'SIGN_IN_TEASER_HOOK';
    @Input() teaser: string = 'SIGN_IN_TEASER';
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
    showSigninTeaser = false;
    showFromData = false;

    public get dataHook() {
        return this.data.Content.title;
    }

    public get dataTeaser() {
        return this.data.Content.text;
    }

    public get link() {
        return this.data.Content.links.linkExternal;
    }

    public get linkText() {
        return this.data.Content.links.linkText;
    }

    public get buttonOnLeft() {
        return (this.data.Content.tags && this.data.Content.tags === 'button:left');
    }

    ngOnInit() {
        this.showFromData = Boolean(this.data);
        this.showSigninTeaser = environment.features.signInTeaser || this.data;
    }
}
