import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Product } from 'common/models';
import { FetchCatalogCategoryProducts } from 'common/store/catalog/catalog.actions';
import { CatalogState, CategoryProducts } from 'common/store/catalog/catalog.state';
import { ProductsState } from 'common/store/products/products.state';
import { ResourceRepositoryModel } from 'common/store/repository/repository.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, first, map, takeUntil, tap } from 'rxjs/operators';
import { BrowseNavigationService } from '../../services/browse-navigation.service';

@Component({
    selector: 'hvac-catalog-products',
    templateUrl: './catalog-products.component.html',
    styleUrls: ['./catalog-products.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogProductsComponent implements OnInit, OnDestroy {
    @Select(CatalogState.categoryProducts) categoryProducts$!: Observable<CategoryProducts>;
    @Select(ProductsState.repository) productRepository$!: Observable<ResourceRepositoryModel<Product>>;

    ngOnDestroy$ = new Subject();
    noActiveProducts: boolean = false;
    noActiveProductsTimeout: ReturnType<typeof setTimeout>

    categoryUrn$ = this.browseNav.navigation$.pipe(
        takeUntil(this.ngOnDestroy$),
        map((nav) => [nav.category, nav.subcategory].filter((path) => Boolean(path)).join('/'))
    );

    products$: Observable<Product[]> = combineLatest([this.categoryUrn$, this.categoryProducts$, this.productRepository$]).pipe(
        takeUntil(this.ngOnDestroy$),
        map(([categoryUrn, categoryProducts, productRepository]) => {
            if (!categoryProducts) {
                return [];
            }

            const productList = categoryProducts[categoryUrn] || [];

            return productList.map((urn: string) => productRepository[urn]).filter((product) => Boolean(product));
        })
    );

    fetch$ = combineLatest([this.categoryUrn$, this.products$]).pipe(
        first(),
        tap(([categoryUrn, products]) => {
            if (!products.length) {
                this.store.dispatch(new FetchCatalogCategoryProducts(categoryUrn));
            }
        })
    );

    constructor(private readonly browseNav: BrowseNavigationService, private readonly store: Store) {}

    ngOnInit() {
        this.fetch$.subscribe();

        this.products$.pipe(takeUntil(this.ngOnDestroy$), distinctUntilChanged(), delay(500)).subscribe((products) => {
            const tempNoActive = Boolean(!products.length || products.length === 0);
            if (tempNoActive) {
                this.noActiveProductsTimeout = setTimeout(() => {
                    this.noActiveProducts = true;
                }, 7000);
            }
            else {
                clearTimeout(this.noActiveProductsTimeout);
            }
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
