import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductCard } from 'common/components/product/product-card.component';
import { Product, ViewMode } from 'common/models';
import { PRODUCT_RESOURCE_TYPE } from 'common/models/product';
import { MediaService } from 'common/services/media.service';

@Component({
    selector: 'utc-product-list-item',
    templateUrl: './product-list-item.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProductListItemComponent implements OnInit {
    @Input() mode: ViewMode = 'grid';
    @Input() resource: Product = {
        resourceType: PRODUCT_RESOURCE_TYPE,
        urn: '',
        categories: [],
        active: false,
        brandNames: [],
        name: '',
        overviewTexts: {},
        featuresTexts: {},
        media: {}
    };

    productCard: ProductCard;

    constructor(
        private readonly mediaService: MediaService
    ) {}

    ngOnInit() {
        this.productCard = {
            urn: this.resource.urn,
            name: this.resource.name,
            image: this.getProductImage(),
            fallbackImage: this.getDefaultProductIcon(this.resource)
        };
    }

    getProductImage() {
        const defaultImage = this.mediaService.getDefaultImage(this.resource.media, '200x200');

        return defaultImage ? defaultImage.url : '';
    }

    getDefaultProductIcon(product: Product): string {
        const defaultCategoryIcon = this.mediaService.getDefaultCategoryIcon(product);

        return defaultCategoryIcon;
    }
}
