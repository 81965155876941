<div class="hvac-section-select">
    <form class="hvac-small-only" [formGroup]="formGroup">
        <utc-select
            variant="primary"
            [group]="formGroup"
            [control]="control"
            [selectId]="selectId"
            [options]="options"
        >
        </utc-select>
    </form>

    <ul class="hvac-large-only hvac-section-select-list">
        <li *ngFor="let option of options" class="hvac-rhythm-1">
            <a [routerLink]="[option.value]" routerLinkActive="hvac-active" title="{{option.name}}" class="hvac-section-select-link hvac-p-sm">{{option.name}}</a>
        </li>
    </ul>
</div>
