import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { BrowseCategoriesIconsService } from 'common/services/browse-categories-icons.service';
import { PartsCatalogImageService } from 'common/services/parts-catalog-image.service';
import { CatalogCategory } from 'common/store/catalog/catalog.state';
import { BrowseNavigation, BrowseNavigationService } from '../../services/browse-navigation.service';
import { ClearCatalogCategories } from 'common/store/catalog/catalog.actions';

@Component({
    selector: 'hvac-browse-category-tile',
    templateUrl: './category-tile.component.html',
    styleUrls: ['./tile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CategoryTileComponent implements OnInit {
    @Input() category!: CatalogCategory;
    icon!: string;
    public isTotaline = environment.brand === 'Totaline';

    constructor(
        private readonly iconService: BrowseCategoriesIconsService,
        private readonly browseNav: BrowseNavigationService,
        private readonly partsIconService: PartsCatalogImageService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store
    ) {}

    ngOnInit() {
        if (this.category.isPartCategory) {
            this.icon = this.partsIconService.getPartsCategoryIcon(this.category.urn);
        }
        else {
            this.icon = this.iconService.getCategoryIcon(this.category.urn);
        }
    }

    browseTo(catalogCategory: CatalogCategory) {
        if (catalogCategory.isPartCategory) {
            this.navigateToCategoryDetails(catalogCategory);

            return;
        }
        else if (catalogCategory.urn === 'parts-catalog') {
            this.store.dispatch(new ClearCatalogCategories());
            this.navigateToPartsCatalog();

            return;
        }
        const [category] = catalogCategory.urn.split('/');

        const section: BrowseNavigation = {
            category,
            pushToHistory: true
        };

        if (!catalogCategory.children.length) {
            section.subcategory = null;
        }

        this.browseNav.navigate(section);
    }

    navigateToCategoryDetails(catalogCategory: CatalogCategory) {
        const isHomepageTile = this.router.url.includes('home');
        if (isHomepageTile) {
            this.router.navigate([`products/parts-catalog/browse/${catalogCategory.urn}`]);
        }
        else {
            this.router.navigate([`browse/${catalogCategory.urn}`], { relativeTo: this.route });
        }
    }

    navigateToPartsCatalog() {
        if (this.isTotaline) {
            this.router.navigate(['products/parts-catalog']);
        }
        else {
            this.router.navigate(['products/parts/parts-catalog']);
        }
    }
}
