import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { BreakPointService } from 'common/services/breakpoint.service';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationService } from 'common/services/navigation.service';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ContentState } from 'common/store/content/content.state';

@Component({
    selector: 'hvac-navigation-nested-container',
    templateUrl: './navigation-nested-container.component.html',
    styleUrls: ['./navigation-nested-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationNestedContainerComponent {
    @Select(NavigationState.currentPage) currentPage$: Observable<NavMenu>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;
    @Input() firstLevel: boolean;
    @Input() secondLevel: boolean;
    @Input() navContent: NavMenu;
    shouldShowBrandSelect: boolean;

    currentMenu$ = this.navigationService.navigationSections$.pipe(
        filter((deepestMenu: {path:unknown}) => Boolean(deepestMenu && deepestMenu.path))
    );

    showMenu$ = this.currentMenu$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((deepestMenu: any) => deepestMenu.path.some((menuKey: any) => menuKey === this.navContent.key))
    );

    isCurrentMenu$ = this.currentMenu$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((menu: any) => menu.path.join('/') === this.navContent.path.join('/'))
    );

    constructor(
        private readonly navigationService: NavigationService,
        private readonly breakpoint: BreakPointService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    handleMouseEnter(menuToOpen: NavMenu) {
        if (this.breakpoint.isDesktop()) {
            this.openMenu(menuToOpen);
        }
    }

    openMenu(menuToOpen: NavMenu) {
        this.navigationService.openNextMenu(menuToOpen);
        this.document.body.blur();
    }

    navMenuCurrent(child: NavMenu): Observable<boolean> {
        return this.currentPage$.pipe(
            filter((currentNavMenu) => Boolean(currentNavMenu)),
            first(),
            map((currentNavMenu) => currentNavMenu.path.some((pathKey) => pathKey === child.key))
        );
    }
}
