/* eslint-disable @typescript-eslint/no-empty-function */

import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class OktaBaseService {
    removeWidget(): void { }
    setupWidget(_elementSelector: string, _options: string): void { }
    logout(_redirect?: string): void { }
    login(_username: string, _password: string): Promise<void> {
        return Promise.resolve();
    }

    resumeSecureSession(): Promise<void> {
        return Promise.resolve();
    }

    resumePublicSession(): Promise<void> {
        return Promise.resolve();
    }

    parseToken(_routerState: RouterStateSnapshot): Promise<void> {
        return Promise.resolve();
    }

    initiateAuthRedirect(): void { }
    validateToken(_token: string): boolean {
        return false;
    }

    getTokenBrands(): Promise<string[]> {
        return Promise.resolve([]);
    }
}

export const HVAC_REDIRECT_KEY = 'hvac-redirect-path';
export const HVAC_SESSION_EVENT = 'hvac-session-event';
export enum SessionEvent {
    LOGOUT = 'logout'
}
