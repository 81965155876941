import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FileIcons } from 'common/models/fileIcons';

@Component({
    selector: 'hvac-document-suggestion',
    templateUrl: './document-suggestion.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DocumentSuggestionComponent implements OnInit {
    @Input() title: string;
    @Input() documentUrl: string;
    @Input() subtype?: string[]|null;
    @Input() fileType: string | undefined;

    public fileIconType = '';

    ngOnInit() {
        this.fileIconType = this.fileType ? FileIcons[this.fileType] : FileIcons['Default'];
    }
}
