// Deprecated. Do not use.

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type ButtonType = 'action' | 'submit' | 'button';

@Component({
    selector: 'utc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnInit {
    @Input() title = '';
    @Input() buttonType?: ButtonType = 'action';
    @Input('value') _value = '';
    @Input() iconSrc = '';
    @Input() label = '';
    @Input() disabled: boolean;
    @Input() buttonId = '';
    @Input() classNames = {};
    @Input() useSuccess = false;
    @Input() successIconSrc = '';
    @Input() successLabel = '';
    @Input('showSuccess') showSuccess$: Observable<boolean> = of(false);
    successToggle$: Observable<boolean> = of(false);

    ngOnInit() {
        this.successToggle$ = this.showSuccess$.pipe(
            map((showSuccess) => Boolean(showSuccess && (this.successIconSrc || this.successLabel)))
        );
    }
}
