<div class="hvac-min-max-steppers-container">
    <div class="hvac-number-stepper">
        <button hvacButton="secondary" [disabled]="disabled" class="hvac-button-icon"
            (click)="!disabled && decrementMin()">-</button>
        <input class="hvac-stepper-input" type="number" [(ngModel)]="minValue" [attr.disabled]="disabled ? disabled : null" (ngModelChange)="onMinValueChanged()">

        <button hvacButton="secondary" [disabled]="disabled" class="hvac-button-icon"
            (click)="!disabled && incrementMin()">+</button>
    </div>
    <div class="hvac-number-stepper">
        <button hvacButton="secondary" [disabled]="disabled" class="hvac-button-icon"
            (click)="!disabled && decrementMax()">-</button>
        <input class="hvac-stepper-input" type="number" [(ngModel)]="maxValue" [attr.disabled]="disabled ? disabled : null" (ngModelChange)="onMaxValueChanged()">

        <button hvacButton="secondary" [disabled]="disabled" class="hvac-button-icon"
            (click)="!disabled && incrementMax()">+</button>
    </div>
</div>
