import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ViewMode, CONTENT_RESOURCE_TYPE } from 'common/models';
import { environment } from 'common/environments/environment';

@Component({
    selector: 'utc-site-content-list-item',
    templateUrl: './site-content-list-item.component.html',
    styleUrls: ['./site-content-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SiteContentListItemComponent implements OnInit {
    @Input() mode: ViewMode = 'grid';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() resource: any = {
        resourceType: CONTENT_RESOURCE_TYPE,
        brand: '',
        id: '',
        title: '',
        url: '',
        content: '',
        score: 0,
        highlighting: ''
    };

    baseURL = environment.secureBaseUrl;
    brand = environment.brand;
    urlKey = '';
    isExternal = false;

    ngOnInit() {
        if (this.brand === 'Carrier' || this.brand === 'Bryant' || this.brand === 'Totaline') {
            this.urlKey = 'url';
        }
        else {
            this.urlKey = 'breadcrumb_url';
        }

        this.isExternal = this.resource[this.urlKey].indexOf('https:') > -1;
    }
}
