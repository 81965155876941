<div class='hvac-alert-container'>
    <div class='hvac-alert' *ngFor="let alert of parsedAlerts">
        <span *ngIf="alert.show">
        <div class='hvac-alert-info-badge'>
            <utc-svg class='hvac-alert-info-badge-svg' src="assets/images/info-badge.svg">
            </utc-svg>
        </div>
        <a *ngIf="alert.link && alert.link.link; else noLink" [href]="alert.link.link" [target]="alert.link.target" class='hvac-alert-content'>
            <p class="hvac-alert-text">{{alert.title}}</p>
        </a>
        <ng-template #noLink>
            <p class="hvac-alert-content hvac-alert-text">{{alert.title}}</p>
        </ng-template>
        <button *ngIf="alert.close" title="hvac alert button" class="hvac-alert-button" (click)="closeAlert(alert)"><utc-svg src="assets/icons/delete-normal.svg"></utc-svg></button>
    </span>
</div>
</div>
