import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hvac-product-suggestion',
    templateUrl: './product-suggestion.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProductSuggestionComponent {
    @Input() urn: string | undefined = '';
    @Input() imageUrl: string ='';
}
