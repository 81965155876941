<utc-button buttonType="button" class="hvac-filter-close" classNames="hvac-primary-button" label="{{ 'SEARCH_PAGE.DONE' | translate }}" title="{{ 'SEARCH_PAGE.DONE' | translate }}" (click)="closeFilterMenu()"></utc-button>
<div class="hvac-document-results-filter-content">
    <div class="hvac-document-filter-search">
        <form class='hvac-document-search-form'>
            <div class="hvac-document-search-form-title">Quick Search</div>

            <div class="hvac-document-search-input-wrap">
                <input class="hvac-document-search-input" id="hvac-document-search" name="hvac-document-search" type="text"
                    [placeholder]="'SEARCH' | translate" [attr.aria-label]="'SEARCH' | translate"
                    [title]="'SEARCH' | translate" #searchInput (keyup)="inputKeyup($event)" autocomplete="off" />

                <div class="hvac-input-action-wrap">
                    <button *ngIf="searchInputData.length > 0" type="button" hvacButton="default" class="hvac-clear-button" (click)="clearSearch()">
                        {{ 'CLEAR' | translate }}
                    </button>

                    <button type="submit" hvacButton="icon" (click)="onSearch($event)" [title]="'SEARCH' | translate">
                        <utc-svg src="assets/icons/search.svg"></utc-svg>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <form>
        <div *ngFor="let filterCategory of filterCategories$ | async">
            <div class="hvac-document-filter-group" *ngIf="filterCategory.filters.length > 0 && showBrandForNonICP(filterCategory.title)"
                [ngClass]="{ 'hvac-document-filter-group-collapsed': filterCategory.collapsed }">
                <div class="hvac-document-filter-group-title" (click)="toggleFilterGroup(filterCategory)">
                    <utc-button buttonType="button" classNames="hvac-text-button hvac-collapse-button"
                        [ngClass]="{ 'hvac-collapse-button-collapsed': filterCategory.collapsed }"
                        [label]="filterCategory.title" [title]="filterCategory.title" [buttonId]="filterCategory.title">
                    </utc-button>
                </div>
                <div class="hvac-document-filter-group-filters">
                    <ul class="hvac-document-filter-list">
                        <li *ngFor="let filter of filterCategory.filters | slice:0:(filterCategory.showAll ? undefined : 5)"
                            class="hvac-document-filter-list-item">
                            <utc-checkbox [checked]="filter.selected || false" [inputId]="filter.name" [value]="filter.value"
                                (change)="toggleFilter($event, filter, filterCategory)" [inputName]="filter.name">
                                <span class="hvac-document-filter-name">{{ filter.label }}</span><span
                                    class="hvac-document-filter-count">{{ filter.count }}</span>
                            </utc-checkbox>
                        </li>
                    </ul>
                    <utc-button *ngIf="filterCategory.filters.length > 5" buttonType="button"
                        class="hvac-document-filter-show-all"
                        classNames="hvac-text-button hvac-collapse-button hvac-shadow-button"
                        [ngClass]="{ 'hvac-collapse-button-collapsed': !filterCategory.showAll }"
                        [label]="(filterCategory.showAll ? 'SEARCH_PAGE.LESS' : 'SEARCH_PAGE.MORE') | translate"
                        [title]="(filterCategory.showAll ? 'SEARCH_PAGE.LESS' : 'SEARCH_PAGE.MORE') | translate"
                        (click)="toggleShowAll(filterCategory)"></utc-button>
                </div>
            </div>
            <button type="submit" class="hvac-invisible-submit" disabled>
                <span class="hvac-accessible-text">{{'SUBMIT' | translate }}</span>
            </button>
        </div>
    </form>
</div>
