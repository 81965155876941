<div class="hvac-select-new" [ngClass]="{
    'hvac-input-min-label': labelMinimized,
    'hvac-select-primary': variant==='primary',
    'hvac-select-small': size === 'small',
    'hvac-select-no-shadow': shadow === 'none',
    'hvac-select-multiple': multiple,
    'hvac-open': isOpen
}">
    <label [attr.for]="selectId" class="hvac-input-label">{{ label }}<abbr *ngIf="required" title="required" class="hvac-select-label-required">{{ required ? '*' : ''}}</abbr></label>

    <div class="hvac-select-mask" [ngClass]="{ 'hvac-select-error': error }" tabindex="0" (click)="handleClick()">
        <span class="hvac-select-mask-text">{{ valueText }}</span>
        <utc-svg class="hvac-select-dropdown-icon" src="assets/icons/chevron-down.svg"></utc-svg>
    </div>

    <select *ngIf="multiple === false; else multiSelect" class="hvac-select-control" [attr.id]="selectId" [attr.required]="required || null" [formControl]="formControl">
        <option [selected]="true" *ngIf="placeholder" [ngValue]="placeholderValue" [disabled]="placeholderDisabled">{{ placeholder }}</option>
        <option *ngFor="let option of options" [disabled]="option.disabled" [ngValue]="option.value">{{ option.name }}</option>
    </select>

    <ng-template #multiSelect>
        <select class="hvac-select-control" [attr.id]="selectId" multiple [attr.required]="required || null" [formControl]="formControl">
            <option [selected]="true" *ngIf="placeholder" [ngValue]="placeholderValue" [disabled]="placeholderDisabled">{{ placeholder }}</option>
            <option *ngFor="let option of options" [disabled]="option.disabled" [ngValue]="option.value">{{ option.name }}</option>
        </select>
    </ng-template>
</div>
