import { Option } from 'common/components/dropdown/options-menu/options-menu.component';

export enum DataTableSortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export enum DataTableLayout {
    STACKED = 'stacked',
    TABLE = 'table'
}

export interface DataTableFilterOption extends Option {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterFunction: any;
}

export interface DataTableSort {
    sortFieldName: string;
    sortDirection: DataTableSortDirection;
}
