import { Component, Input } from '@angular/core';

@Component({
    selector: 'hvac-stats-widget',
    templateUrl: './stats-widget.component.html',
    styleUrls: ['./stats-widget.component.scss']
})
export class StatsWidgetComponent {
    @Input() label: string;
    @Input() value?: number | string;
}
