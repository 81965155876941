/* eslint-disable camelcase */

export enum UserStatus {
    Active = 'Active',
    Pending = 'Pending'
}

export interface BrandItem {
    brandName: string;
    brandCode: string;
    parentBrand: string;
}

export interface companyCategory {
    code: string;
    name: string;
}
export interface companyType {
    code: string;
    name: string;
}
export interface EmployerDtails {
    id: string;
    name: string;
    companyType: companyType;
    companyCategory: companyCategory;
}

export interface AccountStatusModel {
    firstName: string;
    userStatus: UserStatus;
    companyRoles: [];
    company: EmployerDtails;
    brands: [];
    brandFamily: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    companyTypes: any[];
    isAdmin: boolean;
    coolCalcTerms: boolean;
    userId: string;
    lastname: string;
    validateAccount: boolean;
    email: string;
    phone: string;
}

export interface CompanyIdPostBody {
    payload: string;
    decrypt?: boolean;
}

export interface CoolCalcPostBody {
    accept: boolean;
}

export interface CoolCalcSupportRequestBody {
    userPhone: string;
    issueDescription: string;
    userAltEmail: string;
}

export interface DealerLoginBody {
    brand: string;
    password: string;
    username: string;
}

export interface DealerLoginRes {
    HVPId: string;
    DealerName: string;
}

export interface AccountStatusResponse extends AccountStatusModel {
    brand_user_preference: string;
}

export interface UserPref {
    companyTypes: string;
    brandName: string;
    requested?: boolean;
}

export interface BaseResponse {
    message: string;
    status: string;
    details: string;
}
