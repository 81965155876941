<ol class="hvac-browse-breadcrumb">
    <li class="hvac-browse-breadcrumb-category"
        [class.hvac-browse-breadcrumb-active]="categoryActive$ | async"
        [class.hvac-browse-breadcrumb-current]="!(categoryActive$ | async)">
        <utc-svg class="hvac-browse-breadcrumb-check" src="assets/icons/check.svg"></utc-svg>
        <button *ngIf="(category$ | async) as category; else categoryLabel"
            (click)="browseToParent(category)"
            class="hvac-browse-breadcrumb-label">
                <span class="hvac-browse-breadcrumb-label-text">
                    <span>{{ category.name }}</span>
                </span>
            </button>
        <ng-template #categoryLabel>
            <span class="hvac-browse-breadcrumb-label">
                <span class="hvac-browse-breadcrumb-label-text">
                    <span>{{ "BROWSE.BREADCRUMB_CATEGORY" | translate }}</span>
                </span>
            </span>
        </ng-template>
    </li>
    <li class="hvac-browse-breadcrumb-subcategory"
        [class.hvac-browse-breadcrumb-active]="subcategoryActive$ | async"
        [class.hvac-browse-breadcrumb-current]="subcategoryCurrent$ | async"
        [class.hvac-browse-breadcrumb-disabled]="!(subcategoryEnabled$ | async)">
        <utc-svg class="hvac-browse-breadcrumb-check" src="assets/icons/check.svg"></utc-svg>
        <button *ngIf="(subcategory$ | async) as subcategory; else subcategoryLabel"
            (click)="browseToParent(subcategory)"
            class="hvac-browse-breadcrumb-label">
                <span class="hvac-browse-breadcrumb-label-text">
                    <span>{{ subcategory.name }}</span>
                </span>
            </button>
        <ng-template #subcategoryLabel>
            <span aria-disabled="true" class="hvac-browse-breadcrumb-label">
                <span class="hvac-browse-breadcrumb-label-text">
                    <span>{{ "BROWSE.BREADCRUMB_SUBCATEGORY" | translate }}</span>
                </span>
            </span>
        </ng-template>
    </li>
    <li class="hvac-browse-breadcrumb-product"
        [class.hvac-browse-breadcrumb-current]="productsCurrent$ | async"
        [class.hvac-browse-breadcrumb-disabled]="!(productsCurrent$ | async)">
        <utc-svg class="hvac-browse-breadcrumb-check" src="assets/icons/check.svg"></utc-svg>
        <span aria-disabled="true" class="hvac-browse-breadcrumb-label">
            <span class="hvac-browse-breadcrumb-label-text">
                <span>{{ "BROWSE.BREADCRUMB_MODEL" | translate }}</span>
            </span>
        </span>
    </li>
</ol>
