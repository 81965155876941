<section class="hvac-landing-page-section hvac-page-section hvac-content-container" *ngIf="!noCatalogData; else noCatalogMessage">
    <h2 *ngIf="data.Content.title" class="hvac-landing-page-heading hvac-h3" [innerHTML]="data.Content.title | richText"></h2>
    <p *ngIf="data.Content.text" class="hvac-landing-page-paragraph" [innerHTML]="data.Content.text | richText"></p>
    <section class="hvac-browse">
        <hvac-browse-breadcrumb *ngIf="!isTotaline"></hvac-browse-breadcrumb>
        <ng-container [ngSwitch]="view$ | async">
            <hvac-browse-categories *ngSwitchCase="'category'"></hvac-browse-categories>
            <hvac-browse-subcategories *ngSwitchCase="'subcategory'"></hvac-browse-subcategories>
            <hvac-catalog-products *ngSwitchCase="'product'"></hvac-catalog-products>
        </ng-container>
    </section>
</section>

<ng-template #noCatalogMessage>
    <section class="hvac-landing-page-section">
        <div class="hvac-error">
            <div class="hvac-error-body hvac-content-container">
                <div class="hvac-error-body">
                    <div class="hvac-error-text-content" *ngIf="removableBrand; else noAccess">
                        <h1 class="hvac-error-header-text">{{'PRODUCTS.NO_ACCESS.TITLE' | translate}}</h1>
                        <p class="hvac-error-body-copy">{{'PRODUCTS.NO_ACCESS.REMOVE_BRAND_BODY' | translate}}</p>
                        <button hvacButton="primary" class='hvac-back-to-dashboard' (click)="removeBrandFilter()">{{'PRODUCTS.NO_ACCESS.REMOVE_BRAND_BUTTON' | translate}}</button>
                    </div>
                    <ng-template #noAccess>
                        <div class="hvac-error-text-content">
                        <h1 class="hvac-error-header-text">{{'PRODUCTS.NO_ACCESS.TITLE' | translate}}</h1>
                        <p class="hvac-error-body-copy">{{'PRODUCTS.NO_ACCESS.BODY' | translate}}</p>
                        <a hvacButton="primary" class='hvac-back-to-dashboard' href="/">
                            <utc-svg [src]="'assets/icons/browser-frame.svg'"></utc-svg>

                            <span>{{ 'ERROR.ERROR_CONTINUE' | translate }}</span>
                        </a>
                        </div>
                    </ng-template>
                    <utc-svg class="hvac-error-illustration" src="assets/icons/error.svg"></utc-svg>
                </div>
            </div>
        </div>
    </section>
</ng-template>
