/* eslint-disable no-use-before-define */
export const PRODUCT_RESOURCE_TYPE = 'product';

export interface ProductMedia {
    urn: string;
    type: string;
    url: string;
    brand: string;
    width: number;
    height: number;
}

export interface VideoMedia {
    urn: string;
    type: string;
    url: string;
    brand: string;
    width: number;
    height: number;
    videoPreviewImageUrl: string;
}

export interface MediaGallery {
    [mediaSize: string]: ProductMedia[];
}

export type HTMLString = string;

export interface Attribute {
    urn: string;
    type: {
        urn: string;
        name: string;
        group: string;
        valueType: string;
        sortOrder: number;
        filterBy: boolean;
    };
    value: string;
}

export interface AttributeMap {
    [attributeGroup: string]: Attribute[];
}

export interface Product {
    urn: string;
    categories: string[];
    active: boolean;
    brandNames: string[];
    name: string;
    overviewTexts?: {
        [brand: string]: HTMLString;
    };
    featuresTexts?: {
        [brand: string]: HTMLString
    };
    optionsTexts?: {
        [brand: string]: HTMLString
    };
    physicalDataTexts?: {
        [brand: string]: HTMLString
    };
    names?: {
        [brand: string]: string;
    };
    relationships?: ProductRelationships;
    media?: MediaGallery;
    resourceType: 'product';
    image?: string;
    fallbackImage?: string;
}

export interface ProductRelationships {
    'Controls'?: string[];
    'Works Well With'?: string[];
    'Similar Products'?: string[];
}

export interface ProductVariant {
    urn: string;
    active: boolean;
    brandNames: string[];
    attributes?: AttributeMap;
}

export interface TrainingCourse {
    brands: string[];
    description: string;
    title: string;
    url: string;
    public?: boolean;
}

export interface ProductAttributes extends Product {
    attributes?: AttributeMap;
    badges?: Badges[];
    division?: string;
    familyProducts?: ProductVariant[];
    trainingCourses: TrainingCourse[];
    videos?: VideoMedia[];
}

export interface ProductDataOverview extends Product {
    attributes?: AttributeMap;
    division: string;
    familyProducts?: ProductVariant[];
    videos: VideoMedia[];
}


export interface Badges {
    urn: string;
    heading: string;
    imageUrl: string;
    description?: string;
}

export interface ProductDocument {
    id: string;
    contentId: string;
    title: string;
    current: boolean;
    type: string;
    subtype: string;
    brands: string[];
    securityGroup: {
        name: string;
    };
    locales: string[];
    mimeType: string;
    fileExtension: string;
    publishedDate: Date;
    releaseDate: Date;
    printDate: Date;
    url: string;
}

export interface ProductDetails {
    attributes?: ProductAttributes;
    documents?: ProductDocument[];
    dataOverview?: ProductVariant[];
}

export type PublicLinksType = 'public';

export type MediaAsset = 'image'|'video'|'embed';
export interface MarketingDataExtraLinks {
    LINK: string;
    IMAGE: string;
    ALT: string;
}

export interface MarketingDataAsset {
    TITLE: string;
    CTA: {
        CTA_TEXT: string;
        CTA_URL: string;
    };
    DESCRIPTION: string;
    ASSET_LINK: string;
    MEDIA_SOURCE: string;
    MEDIA_SOURCE_2X: string;
    MEDIA_TYPE: MediaAsset;
    ADDITIONAL_LINKS: {[key: string]: MarketingDataExtraLinks};
}

export interface MarketingDataAssetSection {
    TITLE: string;
    DESCRIPTION?: string;
    ITEMS: {[key: string]: MarketingDataAsset};
    SECTION_LINK?: string;
    SECTION_LINK_URL?: string;
}
