import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Part, PARTS_RESOURCE_TYPE, ViewMode } from 'common/models';
import { PartsService } from 'common/views/parts/service/parts.service';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'utc-part-list-item',
    templateUrl: './part-list-item.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PartListItemComponent implements OnInit, OnDestroy {
    @Input() mode: ViewMode = 'grid';
    @Input() resource: Part = {
        resourceType: PARTS_RESOURCE_TYPE,
        channel: '',
        code: '',
        description: '',
        media: {
            fileName: '',
            format: '',
            url: ''
        }
    };

    ngOnDestroy$ = new Subject();
    partItem: {code: string, description: string; image: string; fallbackImage: string;};

    constructor(
        private readonly partsService: PartsService,
        private readonly router: Router
    ) {}


    ngOnInit() {
        this.partItem = {
            code: this.resource.code,
            description: this.resource.description,
            image: this.resource.media?.url || '',
            fallbackImage: 'assets/icons/default.svg'
        };
    }

    choosePart() {
        this.partsService.getPartsDetails(this.partItem.code).pipe(
            takeUntil(this.ngOnDestroy$),
            catchError((error) => {
                this.partsService.partsDetails$.next(null);
                throw error;
            })
        ).subscribe((data) => {
            this.partsService.partsDetails$.next(data);
            const catalogRoute = this.partsService.getCatalogRoute();
            const { part, category, subcategory } = this.partsService.getPartDetailUrl(data);
            if (category && subcategory) {
                this.router.navigate([catalogRoute, category, subcategory], { queryParams: { part: part } });
            }
            else {
                this.router.navigate([`${catalogRoute}/parts-details`], { queryParams: { part: part } });
            }
        });
    }


    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
