import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { BaseContentEntity } from 'common/models';
import { ContentState } from 'common/store/content/content.state';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hvac-whats-new',
    templateUrl: './whats-new.component.html',
    styleUrls: ['./whats-new.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WhatsNewComponent implements OnInit {
    @Input() whatsNew: BaseContentEntity;

    @Select(ContentState.contentLoading) contentLoading$: Observable<true>;

    documents: BaseContentEntity[] = [];
    linkText: string;
    defaultTitle$: Observable<string> = this.translate.get('WHATS_NEW.TITLE');
    contentTitle$: BehaviorSubject<string> = new BehaviorSubject('');
    title$: Observable<string> = combineLatest([this.defaultTitle$, this.contentTitle$]).pipe(
        map(([defaultTitle, contentTitle]): string => contentTitle || defaultTitle)
    );

    constructor(private readonly translate: TranslateService) {}

    ngOnInit() {
        this.documents = this.whatsNew && this.whatsNew.Content.list && this.whatsNew.Content.list.$values ?
            this.whatsNew.Content.list.$values.slice(0, 5)
            : [];

        this.linkText = this.whatsNew && this.whatsNew.Content && this.whatsNew.Content.links ?
            this.whatsNew.Content.links.linkText
            : '';
    }
}
