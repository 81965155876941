<div class="hvac-dropdown-content-container">
    <div class='hvac-dropdown-navigation-trigger-container' [ngClass]="{'hvac-dropdown-menu-expanded': menuActive}">
        <button *ngIf="!isTotaline" [title]="'Find a Distributor'" class="hvac-dropdown-menu-selected hvac-dropdown-navigation-icon-trigger" (click)="toggleMenu()">
            <utc-svg *ngIf="iconSrc" [src]="iconSrc"></utc-svg>
        </button>
        <button *ngIf="isTotaline" hvacButton="secondary" class="hvac-secondary-locator-button"  size="small" buttonType="button" (click)="toggleMenu()">
            <span class="hvac-header-action-button-text">{{ 'FIND_DISTRIBUTOR' | translate }}</span>
            <utc-svg class="hvac-header-locator-icon" src="assets/icons/locator.svg"></utc-svg>
        </button>
    </div>
    <div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{'hvac-dropdown-menu-expanded': menuActive, 'hvac-dropdown-menu-no-show':!menuActive}">
        <div class="hvac-dropdown-menu-options">
            <div class="hvac-dropdown-menu-option-item dropdown-title">{{ 'FIND_DISTRIBUTOR' | translate }}</div>
            <button hvacButton="secondary" (click)="onCurrentLocationClick()" class="hvac-location-button" href="">{{ 'USE_CURRENT_LOCATION' | translate }}</button>
            <div class="hvac-table-list-search hvac-form-element">
                <form class='hvac-table-list-search-form-control'>
                    <input class="hvac-table-list-search-input" id="hvac-table-list-search-dealer-locator" [formControl]="zipCodeSearchInput"
                        name="hvac-table-list-search" type="text" [placeholder]="'ZIP_CODE_STATE' | translate"
                        [attr.aria-label]="'ZIP_CODE_STATE' | translate" (keydown.enter)="onSearch()" [title]="'ZIP_CODE_STATE' | translate"
                        autocomplete="off" required maxlength="7" minlength="2"/>

                    <div class="hvac-input-action-wrap">
                        <button *ngIf="showClear" type="button" hvacButton="default" class="hvac-clear-button" (click)="clearSearch()">
                            {{ 'CLEAR' | translate }}
                        </button>

                        <button hvacButton="icon" (click)="onSearch()" [title]="'SEARCH' | translate">
                            <utc-svg src="assets/icons/search.svg"></utc-svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

