import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RadioGroupComponent {
    @Input() name = '';
    @Input() legend = '';
}
