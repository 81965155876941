import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ControlValueAccessor
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;

export interface Option {
    name: string;
    value: string;
    disabled?: boolean;
}

const DEFAULT_EMPTY_VALUE = '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => SelectNewComponent),
    multi: true
};

@Component({
    selector: 'hvac-select-new',
    templateUrl: './select-new.component.html',
    styleUrls: ['./select-new.component.scss'],
    providers: [SELECT_VALUE_ACCESSOR]
})
export class SelectNewComponent implements ControlValueAccessor, OnInit {
    @Input() placeholder?: string
    @Input() placeholderDisabled = true;
    @Input() selectId: string;
    @Input() multiple: boolean = false;
    @Input() required: boolean = false;
    @Input() formControl: UntypedFormControl | FormControl;
    @Input() variant: 'primary' | 'default' = 'default';
    @Input() size: 'default' | 'small' = 'default';
    @Input() shadow: 'default' | 'none' = 'default';
    @Input() label?: string;
    @Input() error: boolean;
    @Input() options: Option[];
    @Input() emptyValue? = DEFAULT_EMPTY_VALUE

    public labelMinimized: boolean = false;
    public isOpen: boolean = false;
    public options$ = new BehaviorSubject<Option[]>([]);
    public placeholderValue: string = DEFAULT_EMPTY_VALUE;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public value: any;
    public valueText: string;

    onChange = noop;
    onTouched = noop;

    ngOnInit() {
        this.formControl.valueChanges.subscribe((value) => {
            this.writeValue(value);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(selectValue: any | any[]) {
        this.labelMinimized = Boolean(selectValue);

        if (Array.isArray(selectValue)) {
            this.valueText = this.options
                .filter((option) => selectValue.includes(option.value))
                .map((option) => option.name)
                .join(', ');
            this.value = selectValue;

            return;
        }

        this.value = selectValue.value;
        this.valueText = this.options.find((option) => option.value === selectValue)?.name || '';
    }

    handleClick(close?: boolean) {
        if (close) {
            this.isOpen = false;
        }
        else {
            this.isOpen = !this.isOpen;
        }
    }
}
