import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-svg',
    templateUrl: './svg.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SvgComponent {
    @HostBinding('class.hvac-svg') parentClass: boolean = true;

    @Input() src: string;
    @Input() svgId = '';
}
