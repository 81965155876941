import { ClearCurrentPage } from './../../store/navigation/navigation.actions';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { SearchQuery } from 'common/models';
import { BreakPointService } from 'common/services/breakpoint.service';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationService } from 'common/services/navigation.service';
import { ContentState } from 'common/store/content/content.state';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { PerformSearch } from 'common/store/search/search.actions';
import { SearchState } from 'common/store/search/search.state';
import { MobileMenuVisible, TabletMenuVisible } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';


@Component({
    selector: 'navigation-container',
    templateUrl: './navigation-container.component.html',
    styleUrls: ['./navigation-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationContainerComponent implements OnInit {
    @Select(NavigationState.navigation) navContent$: Observable<NavMenu>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Select(UiState.tabletMenuVisible) tabletMenuVisible$: Observable<boolean>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;
    @Select(UiState.headerDropdown) headerDropdown$: Observable<string>;
    @Select(SearchState.query) searchQuery$!: Observable<SearchQuery>;
    public isTotaline = environment.brand === 'Totaline';
    menuId: number;
    public queryControl = new UntypedFormControl('', Validators.required);
    brandFilter = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showUserDetailDropdown$ = this.navigation.navigationSections$.pipe(map((currentNavigation: any) => currentNavigation.path.length === 1));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    backButtonCurrentMenu$: any;

    constructor(
        private router: Router,
        private readonly store: Store,
        private readonly navigation: NavigationService,
        private readonly breakpoint: BreakPointService
    ) { }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.navigation.navigationSections$.subscribe((currentNavigation: any) => {
            if (currentNavigation.path.length > 1) {
                this.backButtonCurrentMenu$ = currentNavigation;
            }
            else {
                this.backButtonCurrentMenu$ = null;
            }
        });

        this.brandFilter = Boolean(environment.brand === 'private' && (environment.features.brandFilter && (environment.features.brandFilter.brand || environment.features.brandFilter.content)));
        this.mobileMenuVisible$.subscribe((visible) => {
            if (visible) {
                this.closeMenus();
            }
        });
        this.tabletMenuVisible$.subscribe((visible) => {
            if (visible) {
                this.closeMenus();
            }
        });
        this.navContent$.subscribe((navRoot) => {
            this.navigation.setRootMenu(navRoot);
            this.navigation.openNextMenu(navRoot);
        });

        this.searchQuery$.subscribe((query) => {
            if (query.q) {
                this.queryControl.setValue(query.q, { emitEvent: false });
            }
        });

        this.router.events.pipe(
            filter((evt: Event): evt is NavigationEnd => evt instanceof NavigationEnd)
        ).subscribe((evt: RouterEvent) => {
            if (!evt.url.includes('search')) {
                this.queryControl.setValue('', { emitEvent: true });
            }
        });
    }

    onSubmit() {
        const query = { q: this.queryControl.value };
        this.store.dispatch(new PerformSearch(query));
        this.store.dispatch(new Navigate(['/search'], query));
        this.store.dispatch(new ClearCurrentPage());
    }

    openTabletNavMenu() {
        this.store.dispatch(new TabletMenuVisible(true));
    }

    closeTabletNavMenu() {
        this.store.dispatch(new TabletMenuVisible(false));
        this.store.dispatch(new MobileMenuVisible(false));
    }

    handleTabletNavigateBack() {
        this.navigation.mobileNavigateBack();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routeTo(url: string, queryParams: any) {
        this.router.navigate([url], { queryParams: queryParams });
    }

    handleMouseLeave() {
        if (this.breakpoint.isDesktop()) {
            this.closeMenus();
        }
    }

    closeMenus() {
        this.navigation.closeAllMenu();
    }
}
