<section *ngIf="!(isLoggedIn$ | async) && showSigninTeaser" class="hvac-sign-in-teaser">
    <div *ngIf="showFromData; else inputTranslations" class="hvac-sign-in-teaser-container">
        <a *ngIf="buttonOnLeft" class="hvac-sign-in-teaser-login-link hvac-sign-in-teaser-login-link-left hvac-primary-button" [href]="link" target="_blank">{{ linkText }}</a>
        <p class="hvac-sign-in-teaser-message">
            <strong innerHTML='{{ dataHook }}'></strong> {{ dataTeaser }}
        </p>
        <a *ngIf="!buttonOnLeft" class="hvac-sign-in-teaser-login-link hvac-primary-button" [href]="link" target="_blank">{{ linkText }}</a>
    </div>
    <ng-template #inputTranslations>
        <div class="hvac-sign-in-teaser-container">
            <p class="hvac-sign-in-teaser-message">
                <strong *ngIf="hook" innerHTML="{{ hook | translate }}"></strong>{{ teaser | translate }}
            </p>
            <a class="hvac-sign-in-teaser-login-link hvac-primary-button" [href]="signInLink" target="_blank">{{ 'LOGIN' | translate }}</a>
        </div>
    </ng-template>
</section>
