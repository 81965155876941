/* eslint-disable no-undef */
// eslint-disable-next-line spaced-comment
/// <reference types="@types/googlemaps" />
import { environment } from 'common/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { buildHttpParams, digest } from 'common/api/utils';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { ApiOptionsService } from '../../../services/api-options/api-options.service';
import { DistributorLocatorApiError } from '../../../models/create-account-errors';
import { DistLocatorQuery, DistributorLocatorData, FilterOptions, SortOptions } from '../../../models/distributor-locator';

export const DEFAULT_ZOOM = 8;

export class Label {
    text: string;
    color?: string;
    fontWeight: string;
    fontSize: string;
}

export class Icon {
    labelOrigin: google.maps.Point;
    url: string;
}
export class Marker {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map: any;
    title: string;
    icon: Icon;
    label: Label;
    className: string;
}

const copyFilterOptions: FilterOptions[] = [
    {
        title: '10 Miles',
        value: 10
    },
    {
        title: '50 Miles',
        value: 50
    },
    {
        title: '100 Miles',
        value: 100
    }
];

const copySortOptions: SortOptions[] = [
    {
        title: 'Alphabetical',
        value: 'Alphabetical'
    },
    {
        title: 'Distance',
        value: 'Distance'
    }
];

@Injectable({ providedIn: 'root' })
export class DistributorLocatorService {
    @Select(AccountStatusState.getUserId) userId$: Observable<string>;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {

    }

    getFilterOptions() {
        return copyFilterOptions;
    }

    getSortOption() {
        return copySortOptions;
    }

    filterLocationData(distributorLocatorData: DistributorLocatorData[]): Marker[] {
        const mapData: Marker[] = [];
        distributorLocatorData.forEach((locationData) => {
            if (locationData) {
                const marker = new Marker();
                marker.id = locationData.companyId;
                marker.position = {
                    lat: Number(locationData.latitude),
                    lng: Number(locationData.longitude)
                };
                marker.label = new Label();
                marker.icon = new Icon();
                mapData.push(marker);
            }
        });

        return mapData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseData(locatorResponse: any[]) {
        if (locatorResponse?.length > 0) {
            const data = locatorResponse.map((locatorData) => {
                if (locatorData.locatorName === null) {
                    locatorData.locatorName = locatorData.companyName;
                }

                return locatorData;
            });

            return data;
        }

        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findMarker(companyId: any, distributorLocatorData: DistributorLocatorData[]): Marker {
        const markerdata = distributorLocatorData.find((data) => data.companyId === companyId);
        if (markerdata) {
            const marker = new Marker();
            marker.id = markerdata?.companyId;
            marker.position = new google.maps.LatLng(Number(markerdata?.latitude), Number(markerdata?.longitude));
            marker.label = new Label();
            marker.label.text = markerdata?.locatorName ? markerdata?.locatorName : markerdata?.companyName;
            marker.icon = new Icon();

            return marker;
        }

        return new Marker();
    }

    fetchDistributorLocationData(locatorQuery: DistLocatorQuery) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return combineLatest([options$, this.buildParams(locatorQuery)]).pipe(
            switchMap(([options, params]) => this.httpClient.get<string>(`${environment.api.distributorLocator}${this.getTypeOfQuery(locatorQuery)}`, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400 || err.status === 404) {
                    throw new DistributorLocatorApiError(err.error.details[0]);
                }

                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildParams({ query }: DistLocatorQuery): Observable<any> {
        return combineLatest([this.userId$]).pipe(
            map(([userId]) => {
                const uidParam = userId ? { uid: digest(userId) } : {};

                return buildHttpParams({
                    ...query,
                    ...uidParam
                });
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTypeOfQuery(locatorQuery: any) {
        if (Object.prototype.hasOwnProperty.call(locatorQuery.query, 'state')) {
            return 'state';
        }

        if (Object.prototype.hasOwnProperty.call(locatorQuery.query, 'zipCode')) {
            return 'zipcode';
        }

        return 'location';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((resp) => {
                resolve({
                    lng: resp.coords.longitude,
                    lat: resp.coords.latitude
                });
            },
            (err) => {
                reject(err);
            });
        });
    }
}
