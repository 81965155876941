import { Component, ElementRef, Input, Inject, ViewEncapsulation } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, merge } from 'rxjs';
import { delay, filter, map, debounceTime } from 'rxjs/operators';
import { WINDOW } from 'common/window.provider';

@Component({
    selector: 'utc-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShareButtonComponent {
    @Input() url = '';
    @Input() title = '';
    @Input() label = '';
    @Input() iconSrc = '';

    showSuccess$ = new BehaviorSubject<boolean>(false);
    hideSuccess$ = this.showSuccess$.pipe(
        filter((show) => show),
        debounceTime(1000),
        delay(1000),
        map(() => false)
    );

    successState$ = merge(this.showSuccess$, this.hideSuccess$);

    constructor(
        private readonly clipboardService: ClipboardService,
        private readonly elementRef: ElementRef,
        @Inject(WINDOW) private readonly window: Window
    ) {}

    onClick() {
        this.clipboardService.copyFromContent(this.url, this.elementRef.nativeElement);
        this.showSuccess$.next(true);
        this.window.location.href = `mailto:?subject=${encodeURIComponent(this.title)}&body=${encodeURIComponent(this.title)}: ${encodeURIComponent(this.url)}`;
    }
}
