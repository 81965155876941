<div class="hvac-upload-file-field hvac-rhythm-1" [class.disabled]=!displayInputField>
    <label class="hvac-p-sm" [for]="fileUploadControl"> <span *ngIf="!fileName">Choose File</span> {{fileName}}</label>
    <input #fileInputControl type="file" (change)="uploadFile($event);" [id]="fileUploadControl" [accept]="acceptableFileType">
    <div class="hvac-upload-file-buttons">
        <label [hvacButton]="isContentVisible ? 'primary' : 'default'" size="small" class="hvac-upload-btn" [for]="fileUploadControl">
            <span>Browse</span>
        </label>
        <button type="button" [hvacButton]="'default'" size="small" class="hvac-upload-btn" (click)="importFile()">
            <span>Import</span>
        </button>
    </div>
</div>
