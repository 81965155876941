<div class="hvac-system-product-card">
    <div class="hvac-system-product-image-container">
        <utc-image-fallback
            [suppliedImageSrc]="image"
            fallbackImageSrc="assets/images/no-image-icon.svg"></utc-image-fallback>
    </div>

    <div *ngIf="noDataMessage; else displayProductData">
        <h4 class="hvac-system-product-name hvac-h4" [innerHtml]="type"></h4>
        <hvac-data-block status="inactive">{{ noDataMessage }}</hvac-data-block>
    </div>

    <ng-template #displayProductData>
        <h4 class="hvac-system-product-name hvac-h4" [ngClass]="{ 'hvac-fault-indicator': showFaultIndicator }" [innerHtml]="name"></h4>
        <dl class="hvac-system-product-descriptors">
            <ng-container  *ngFor="let descriptor of descriptors">
                <hvac-product-descriptor [label]="descriptor.label" [value]="descriptor.value" [infoLink]="descriptor.infoLink"></hvac-product-descriptor>
            </ng-container>
        </dl>

        <a *ngIf="productLink" class="hvac-system-product-link" hvacButton="primary" size="small" [routerLink]="productLink.url" [queryParams]="productLink.queryParams">{{ productLink.title }}</a>

        <button *ngIf="isUpdateEnabled" class="hvac-system-product-link" hvacButton="primary" size="small">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.UPDATE' | translate }}</button>
    </ng-template>
</div>
