<section class="hvac-featured-content hvac-page-section" *ngIf="data">
    <div class="hvac-featured-content-heading">
        <h3 class="hvac-featured-content-title hvac-h3">{{ 'PRODUCTS.FEATURED_PRODUCT_LINKS.TITLE' | translate }}</h3>
    </div>
    <ul class="hvac-results-list hvac-results-list-grid">
        <li class="hvac-results-list-item" *ngFor="let product of data.Content.products.$values">
            <div class="hvac-results-list-item-container">
                <utc-featured-products-product [product]="product"></utc-featured-products-product>
            </div>
        </li>
    </ul>
</section>
