/* eslint-disable no-use-before-define */
export type ModelDataListType = 'EntityModelData[]'|'ContentModelData[]';
export type ModelDataTypes = 'EntityModelData'|'ContentModelData'|'KeywordModelData'|'RichTextData'|ModelDataListType;

// Generic Content Types
// Common structures used to represent different types of content

export interface ModelData {
    $type: string;
}

export interface ModelDataList extends ModelData {
    $values: ModelData[];
    $type: ModelDataListType;
}

export interface EntityModelData<T> extends ModelData {
    $type: 'EntityModelData';
    Content?: T;
    ExternalContent?: T;
    LinkUrl: string;
}

export interface KeywordModelData extends ModelData {
    $type: 'KeyworkdModelData';
    Key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Metadata: any;
}

export interface EntityModelDataList extends ModelData {
    $type: 'EntityModelData[]';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $values: EntityModelData<any>[];
}

export interface ContentModelContent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [any: string]: any;
}

export interface ContentModelData extends ContentModelContent, ModelData {
    $type: 'ContentModelData';
}

export interface ContentModelDataList extends ModelData {
    type: 'ConentModelData[]';
    $values: ContentModelContent[];
}

// More Specific Content Types
// Derived from Generic types, have specific key/value pairs to represent data

export interface MenuLinkDetails extends ContentModelData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    linkOperation: any;
    linkText?: string;
    linkExternal?: string;
    linkInternalParameter?: string;
}

export interface MenuItemDetailsContent {
    menuLinkDetails: MenuLinkDetails;
    tags: string;
}

export interface MenuItemDetails extends EntityModelData<MenuItemDetailsContent> {}

export interface MenuItem extends ContentModelData {
    columns: Columns;
    menuItemDetails: MenuItemDetails;
}

export interface Columns {
    $values?: {
        columnItem: ColumnItem;
    }[];
    columnItem?: ColumnItem;
}

export interface ColumnItem extends EntityModelData<SubmenuColumnContent> {}

export interface SubmenuColumnContent extends ContentModelData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submenuTitle: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submenuItems: any;
}
export interface ComponentTemplate {
    Id: string;
    Namespace: string;
    Title: string;
    RevisionDate: string;
    OutputFormat: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Metadata: any;
}

export interface MvcData {
    AreaName?: string;
    ViewName: string;
}

export interface ContentEntity<T> {
    MvcData: MvcData;
    ComponentTemplate?: ComponentTemplate;
    Content: T;
    SchemaId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Metadata?: any;
    Id: string;
    ContextId: string;
    Namespace: string;
    LinkUrl: string;
}

export type BaseContentEntity = ContentEntity<ContentModelContent> | null | undefined;

export type MenuContentEntity = ContentEntity<MenuContent>;

export interface MenuContent {
    menuItems: SingleMenuItemModel|MultiMenuItemModel;
}

export interface MenuItemModel {
    $type?: string;
}

export interface SingleMenuItemModel extends MenuItemModel {
    menuItemDetails: MenuItemDetails;
}

export interface MultiMenuItemModel extends MenuItemModel {
    $values: SingleMenuItemModel[];
}

export interface NestedContentRegion {
    MvcData: MvcData;
    Name: string;
    Entities: BaseContentEntity[];
}

export interface ContentPage {
    Regions: NestedContentRegion[];
    Title: string;
}

export interface ContentRegion {
    MvcData: MvcData,
    Name: string;
    IncludePageId?: string;
    Regions?: NestedContentRegion[]
    Entities?: BaseContentEntity[];
}

export interface UIContentResponse {
    Id: string;
    StructureGroupId: string;
    Namespace: string;
    Meta: {
        [metaTag: string]: string;
    };
    PageTemplate: {
        Id: string,
        Title: string,
        FileExtension: string,
        RevisionDate: string,
        Namespace: string,
        Metadata: {
            view: string,
            includes: {
                $type: string,
                $values: {Fragments?: (string)[]}[]
            }
        }
    };
    Title: string;
    Regions: ContentRegion[];
    UrlPath: string;
    MvcData: MvcData
}

export interface UIContentDataContent {
    title?: string;
    list?: string[];
}

export interface UIContentDataEntities {
    content: UIContentDataContent;
}

export interface UIContentDataRegions {
    name: string;
    entities?: UIContentDataEntities[];
}

export interface UIContentData {
    regions: UIContentDataRegions[];
}
