import { Injectable, Type } from '@angular/core';
import { BrowseComponent } from 'common/views/browse/browse.component';
import { AccordianListComponent } from '../components/accordian/accordian-list/accordian-list.component';
import { AccordianComponent } from '../components/accordian/accordian.component';
import { CheckListComponent } from '../components/check-list/check-list.component';
import { ColorContentHeaderComponent } from '../components/content-header/color-content-header.component';
import { ContentHeaderComponent } from '../components/content-header/content-header.component';
import { ContentListComponent } from '../components/content-list/content-list.component';
import { DocumentListComponent } from '../components/document-list/document-list.component';
import { FeaturedProductsComponent } from '../components/featured-products/featured-products.component';
import { HighlightsContentComponent } from '../components/highlights-content/highlights-content.component';
import { IndexCardListComponent } from '../components/index-card-list/index-card-list.component';
import { IndexIconCardListComponent } from '../components/index-card-list/index-icon-card-list/index-icon-card-list.component';
import { PageHeroComponent } from '../components/page-hero/page-hero.component';
import { PublicLinksComponent } from '../components/public-links/public-links.component';
import { RichTextContainerComponent } from '../components/rich-text-container/rich-text-container.component';
import { SignInTeaserComponent } from '../components/sign-in-teaser/sign-in-teaser.component';
import { TabLinkContainerComponent } from '../components/tab-link-container/tab-link-container.component';
import { VideoListComponent } from '../../content/components/video-list/video-list.component';
export interface ContentComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export interface EntityTemplate {
    template: Type<ContentComponent>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entity: any;
}

const components: { [template: string]: Type<ContentComponent> } = {
    // 'CT_Hero-Img': HeroImageComponent,
    // 'CT_Blks1-w100-Basic': BasicHtmlComponent,
    // 'CT_Header-CTA-Mobile': { data: {} },
    // 'CT_Header-SecondaryMenu [Nav]': { data: {} },
    // 'Search Box [Nav]': { data: {} },
    // 'CT_Header-Logo': { data: {} },
    // 'CT_Menu-Basic [Menu]': NavigationComponent,
    // 'CT_Footer-Power-Basic': { data: {} },
    // 'CT_Footer-Deep-Basic': { data: {} },
    // 'CT_Cookie-Message': { data: {} },
    // 'CT_RenderCommonFiles': { data: {} },
    // 'Breadcrumb [Breadcrumb]': { data: {} }
    'CT_Product-List-Basic': FeaturedProductsComponent,
    'CT_Blks1-w100-03': SignInTeaserComponent,
    'CT_Blks1-w100-04': AccordianComponent,
    'CT_Blks1-w100-05': RichTextContainerComponent,
    'CT_Blks1-w100-07': DocumentListComponent,
    'CT_Blks1-w100-Animation': BrowseComponent,
    'CT_Blks1-w100-Video': VideoListComponent,
    'CT_Hero-Basic': PageHeroComponent,
    'CT_CardCollection-w1-01': ContentListComponent,
    'CT_CardCollection-w1-03': TabLinkContainerComponent,
    'CT_CardCollection-w1-04': PublicLinksComponent,
    'CT_CardCollection-w2-01': CheckListComponent,
    'CT_CardCollection-w2-02': HighlightsContentComponent,
    'CT_CardCollection-w3-01': IndexCardListComponent,
    'CT_CardCollection-w3-03': AccordianListComponent,
    'CT_CardCollection-w3-04': IndexIconCardListComponent,
    'CT_Hero-Img-01': ColorContentHeaderComponent,
    'CT_Hero-Img-02': ContentHeaderComponent,
    'CT_Blank': DocumentListComponent
};
// Do not use template CT_CardCollection-w3-02
// Do not use template CT_CardCollection-w1-11
// Do not use template CT_Blks4-w100-News
@Injectable({ providedIn: 'root' })
export class ContentComponentService {
    public resolveRegions(regions: string[]): EntityTemplate[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mapped = regions.map((region: any) => {
            if (region.Entities) {
                return this.resolveEntities(region.Entities);
            }

            if (region.Regions) {
                return ([] as EntityTemplate[]).concat(...this.resolveRegions(region.Regions));
            }

            return [] as EntityTemplate[];
        });

        return ([] as EntityTemplate[]).concat(...mapped);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public resolveEntities(entities: any[]): EntityTemplate[] {
        return entities.filter((entity) => !this.isMetadataComponent(entity) && (!entity.Metadata || (!this.isSecurityProfile(entity))))
            .map((entity) => this.resolveContentComponent(entity));
    }

    public resolveContentComponent(entity: {ComponentTemplate:{Title: string}}): EntityTemplate {
        const componentTemplate = entity.ComponentTemplate && entity.ComponentTemplate.Title || '';
        const template: Type<ContentComponent> | undefined = components[componentTemplate];

        return {
            template,
            entity
        };
    }

    public isSecurityProfile = (entity: {Metadata:{securityProfile: string}}) => (entity.Metadata && entity.Metadata.securityProfile);
    public isMetadataComponent = (entity: {SchemaId: string}) => (entity.SchemaId === '967');
}
