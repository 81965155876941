export class AppConstants {
    public static debounceTime200: number = 200;
    public static defaultTypeAheadDebounceTime: number = 400;
    public static defaultInputDelay: number = 1000;
    public static bannerTimeOut: number = 10000;
    public static timeOutNavigation: number = 3000;
    public static resendButtonTimer: number = 120000;
    public static dateFormat = 'M/d/yyyy';
    public static timeFormat = 'h:mm a';
    public static timeTzFormat = 'h:mm a zzz';
    public static dateFormatMed = 'yyyy-MM-dd';
    public static dateFormatMed2 = 'MM-dd-yyyy';
    public static dateFormatShort = 'MMM dd';
    public static twoWeeksRange: number = 14;
    public static twoMonthsRange: number = 2;
    public static hours: number = 23;
    public static MIN_AND_SEC: number = 59;
    public static milliseconds: number = 999;
    public static currentPage: number = 1;
    public static pageSize: number = 10;
    public static enrollmentsSearchDelay: number = 1000;
    public static dateTimeFormat = 'EEEE, MMMM d, y, h:mm a';
    public static dateTimeTzFormat = 'EEEE, MMMM d, y, h:mm a zzz';
    public static dateTimeFormatShort = 'dd/MM/yy, h:mm a'
    public static dateTimeFormatShort2 = 'MM/dd/yy, h:mm a'
    public static dateTimeFormatForRuntimeReport = 'MM/dd/yy'
    public static validUrlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    public static maxEmailLength: number = 101;
    public static validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static validPhoneRegex = /^[1]?[+]?[ ]?[(]?[0-9]{3}[)]?[ ]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    public static validNumberRegex = /^-?(0|[1-9]\d*)?$/;
    public static dateFormatMedWithTime = 'yyyy-MM-ddTHH:mm';
    public static dateFormatMedWithTimeEscaped = 'yyyy-MM-dd\'T\'HH:mm';
    public static PrivatePartsLiteratureSearchURL = 'support/literature-search/parts';
    public static CONSENT_COOKIE = 'OptanonConsent';
    public static CONSENT_COOKIE_VALUE = 'C0004:1';
}

export enum API_RESPONSE {
    SUCCESS = 200,
    NOT_FOUND = 404,
    FORBIDDEN = 403,
    CONFLICT = 409
}

export enum EnvName {
    Local = 'local',
    Dev = 'dev',
    Staging = 'staging',
    Production = 'production',
    Future = 'future',
    Test = 'test'
}

