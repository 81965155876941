<div class="hvac-video-widget">
    <ul class="hvac-row hvac-video-list">
        <li *ngFor="let video of data?.Content?.links.$values;trackBy: trackByFn" class="hvac-rhythm-4">
            <a href="{{video.linkInternal.Content.links?.linkExternal}}"
                rel="noopener noreferrer" target="_blank"
                class="hvac-video-link">
                <div class="hvac-thumb-constraint">
                    <img [src]="video.linkInternal.Content.primaryMedia.BinaryContent.Url" alt="{{video.linkInternal.Content.primaryMedia.ExternalContent.Metadata.Alt}}" class="hvac-video-thumbnail">
                </div>
                <div class="hvac-video-details">
                    <span class="hvac-video-play-symbol">
                        <utc-svg src="assets/icons/play.svg" class="hvac-play-icon"></utc-svg>
                    </span>
                    <p class="hvac-video-title" [innerHTML]="video.linkInternal.Content.title | richText"></p>
                </div>
            </a>
        </li>
    </ul>
</div>
