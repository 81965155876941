import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DataResolverService } from 'common/content/services/data-resolver.service';
import { environment } from 'common/environments/environment';
import { UpdateUserContentFilteringPreference } from 'common/store/account-status/account-status.actions';
import { ContentFilteringPreferenceType, ContentState } from 'common/store/content/content.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorPageComponent implements OnInit {
    @Input() public status: number;
    @Select(ContentState.contentFilteringPreference) userPrefCompanyType$: Observable<ContentFilteringPreferenceType>;
    @Select(ContentState.contentBrand) brand$ : Observable<string>;
    @Select(ContentState.contentBrandList) availableBrandsList$: Observable<string[]>;

    public statusString = '';
    ngOnDestroy$ = new Subject();

    private canResetBrand = false;

    constructor(
        private store:Store,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public dataResolverService: DataResolverService
    ) { }

    ngOnInit() {
        const statusCodes = [
            403,
            404
        ];

        if (environment.brand === 'private') {
            this.availableBrandsList$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((res) => {
                if (res.length && res.length > 1) {
                    this.canResetBrand = true;
                }
            });
        }

        combineLatest([this.brand$, this.userPrefCompanyType$])
            .pipe(takeUntil(this.ngOnDestroy$)).subscribe(([brandType, inputCompanyType]) => {
                if (this.status === 403) {
                    if (environment.brand !== 'private') {
                        this.statusString = `_404`;
                    }
                    else if (inputCompanyType === 'Res&Com' && brandType === 'default') {
                        this.statusString = `_403`;
                    }
                    else {
                        this.statusString = `_403_REMOVE_BRAND`;
                    }
                }
                else {
                    this.statusString = statusCodes.includes(this.status) ? `_${this.status}` : '';
                }
            });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public resetFilters() {
        this.resetBrandTypeFilter();
        this.resetBrandFilter();
        this.dataResolverService.resolve(this.activatedRoute.snapshot, this.router.routerState.snapshot).subscribe();
    }

    private resetBrandTypeFilter() {
        this.store.dispatch(new UpdateUserContentFilteringPreference('Res&Com'));
    }

    private resetBrandFilter() {
        if (this.canResetBrand) {
            return this.dataResolverService.updateUserBrandPreferences('default', 'Res&Com');
        }
    }
}
