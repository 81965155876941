import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataTableFilterOption, DataTableLayout, DataTableSort, DataTableSortDirection } from '../../models/data-table';
import { FilterPipe } from 'common/pipes/filter.pipe';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SegmentedOption } from '../segmented-button/segmented-button';
import { DefaultPage, HvacDataTableService, PageEvent } from './service/hvac-data-table.service';
export interface DefaultStatus {
    title: string;
    key: string;
    checked: boolean;
}

@Component({
    selector: 'hvac-data-table',
    templateUrl: './hvac-data-table.component.html',
    styleUrls: ['./hvac-data-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HvacDataTable implements OnDestroy {
    @Input() dataHeaders: string[];
    @Input() contextMenu: string[];
    @Input() translationPrefix: string;
    @Input() filterOptions: DataTableFilterOption[];
    @Input() languageFilter: boolean;
    @Input() pageSizeFilter: boolean;
    @Input() status: boolean;
    @Input() layout: DataTableLayout = DataTableLayout.TABLE;
    @Input() search: Boolean = true;
    @Input() headerNote: string;
    @Input() dataSort: DataTableSort;
    @Input() pageSize: number = 10;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public onClick = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public handleExpandedRowEvent = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public dismissBanner = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public documentQuery = new EventEmitter<any>();

    @ViewChild('dataTableSearchInput', { static: false }) dataTableSearchInput: ElementRef;

    languageFilterData$: Observable<SegmentedOption[]>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data$ = new BehaviorSubject<any>([]);
    contextMenuActive = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UIData: any[] = [];
    start = 0;
    end: number;
    totalPages: number;
    currentPage: number = 0;
    dataTableSearchText = '';
    searchKeyUpSubject$: BehaviorSubject<string> = new BehaviorSubject('');
    sortDirection = DataTableSortDirection.ASCENDING;
    ngOnDestroy$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedContextRow: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globalDataHolder: any[] = [];
    resetPagination: string | boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortFieldName: any;
    currentFilter: DataTableFilterOption;
    currentSearch: string;
    languageFilterData: SegmentedOption[] = [];
    statusFilterData: SegmentedOption[] = [];;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    languageFilterOption: any;
    statusFilterOption: string;
    statusDefaultSelection: DefaultStatus;
    pageDefaultSelection: DefaultPage;
    pageFilterData: SegmentedOption[] = [];

    constructor(public readonly dataTableService: HvacDataTableService) { }

    get data() {
        return this.data$.getValue();
    }

    @Input()
    set data(value: string[]) {
        this.data$.next(value);
    }

    ngOnInit() {
        this.data$.subscribe((data) => {
            if (data) {
                if (this.dataTableSearchInput) {
                    this.dataTableSearchInput.nativeElement.value = '';
                    this.dataTableSearchText = '';
                    this.currentSearch = '';
                }
                if (!this.currentPage) {
                    this.currentPage = 0;
                }
                this.resetPagination = '';
                this.globalDataHolder = data;
                this.initLanguageFilter();
                this.applySort();
            }
        });
        if (this.dataSort) {
            this.sortFieldName = this.dataSort.sortFieldName;
            this.sortDirection = this.dataSort.sortDirection;
            this.applySort();
        }

        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(400)
        ).subscribe((searchTextValue) => {
            if (this.dataTableSearchText !== searchTextValue.toLowerCase()) {
                this.dataTableSearchText = searchTextValue.toLowerCase();
                this.resetToPage(0);
                this.performSearch(this.dataTableSearchText);
            }
        });
        this.statusFilterData = this.status ? this.dataTableService.statusFilterData() : [];
        if (this.status) {
            this.statusDefaultSelection = this.dataTableService.statusSelection(this.status);
        }

        this.pageFilterData = this.dataTableService.pageFilterData();
        this.pageDefaultSelection = this.dataTableService.pageSelection(this.pageSize.toString());
    }

    initLanguageFilter() {
        this.languageFilterData$ = this.languageFilter ? this.dataTableService.languageFilterData(this.globalDataHolder) : [];
    }

    setCurrentPage(currentPage = 0) {
        this.currentPage = currentPage;

        this.totalPages = Math.ceil(this.globalDataHolder.length / this.pageSize);
        this.UIData = this.globalDataHolder.slice(currentPage * this.pageSize, (currentPage * this.pageSize) + this.pageSize);

        if (this.UIData.length === 0) {
            this.resetToPage(0);
        }
    }

    onSearch(event: Event) {
        event.preventDefault();

        this.dataTableSearchText = this.dataTableSearchInput.nativeElement.value.toLowerCase();
    }

    clearSearch() {
        if (this.dataTableSearchInput) {
            this.resetToPage(0);
            this.dataTableSearchInput.nativeElement.value = '';
            this.dataTableSearchText = '';
            this.resetPagination = '';
            this.currentSearch = '';
            this.globalDataHolder = this.data;
            this.appyLanguageFilter();
            this.applyFilter();
            this.applySort();
            this.setCurrentPage();
        }
    }

    resetToPage(currentPage: number) {
        this.currentPage = currentPage;
    }

    inputKeyup(event: KeyboardEvent) {
        this.searchKeyUpSubject$.next((event.target as HTMLInputElement).value);
    }

    sort(fieldName: string) {
        this.dismissBanner.emit();
        this.disableContextMenu();
        if (this.sortFieldName !== fieldName) {
            this.sortDirection = DataTableSortDirection.ASCENDING;
            this.sortFieldName = fieldName;
            this.applySort();

            return;
        }
        this.sortFieldName = fieldName;
        this.sortDirection = (this.sortDirection === DataTableSortDirection.DESCENDING) ? DataTableSortDirection.ASCENDING : DataTableSortDirection.DESCENDING;
        this.applySort();
    }

    applySort() {
        this.globalDataHolder = this.globalDataHolder.slice().sort((a, b) => {
            const valueA = a[this.sortFieldName] || '';
            const valueB = b[this.sortFieldName] || '';

            if (this.dataTableService.isDate(valueA) && this.dataTableService.isDate(valueB)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return <any> new Date(valueA) - <any> new Date(valueB);
            }

            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        });

        if (this.sortDirection === DataTableSortDirection.DESCENDING) {
            this.globalDataHolder = this.globalDataHolder.slice().reverse();
        }
        this.setCurrentPage();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    onPageChange(pageNo: number) {
        this.dismissBanner.emit();

        this.currentPage = pageNo - 1;
        this.setCurrentPage(this.currentPage);
    }

    onToggleMenuClick(event: Event) {
        this.toggleContextMenu(event);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleContextMenu(row: any) {
        this.dismissBanner.emit();
        if (this.selectedContextRow && row !== this.selectedContextRow) {
            this.contextMenuActive = true;
            this.selectedContextRow = this.contextMenuActive ? row : '';

            return;
        }
        this.contextMenuActive = !this.contextMenuActive;
        this.selectedContextRow = this.contextMenuActive ? row : '';
    }

    disableContextMenu() {
        this.contextMenuActive = false;
        this.selectedContextRow = '';
    }

    getMenuActionRow(row: boolean) {
        if (!row) {
            return false;
        }

        return row === this.selectedContextRow;
    }

    getsortField(header: string) {
        return this.sortFieldName === header;
    }

    closeSuggestionsWindow() {
        this.contextMenuActive = false;
        this.selectedContextRow = null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChildContextClick(event: any) {
        this.onClick.emit({
            menu: event.menu,
            value: event.value
        });
    }

    onContextClick(row: string, menuItem: string) {
        this.onClick.emit({
            menu: menuItem,
            value: row
        });
    }

    performSearch(term: string) {
        this.currentSearch = term;
        this.resetPagination = term;
        this.globalDataHolder = this.data;
        this.appyLanguageFilter();
        this.disableContextMenu();
        this.applyFilter();
        this.applySearch();
        this.applySort();
        this.setCurrentPage();
    }

    applyFilter() {
        if (this.currentFilter) {
            this.globalDataHolder = this.globalDataHolder.filter((element: string) => this.currentFilter.filterFunction(element));
        }
    }

    applySearch() {
        if (this.currentSearch && this.currentSearch.length > 0) {
            this.globalDataHolder = new FilterPipe().transform(this.globalDataHolder, this.dataHeaders, this.currentSearch);
        }
    }

    // TODO: Fix type for Option
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterChanged(event: any) {
        this.currentFilter = event;
        this.resetPagination = true;
        this.globalDataHolder = this.data;
        this.appyLanguageFilter();
        this.disableContextMenu();
        this.applyFilter();
        this.applySearch();
        this.applySort();
        this.currentPage = 0;
        this.setCurrentPage();
    }

    emitExpandedRowClick(event: Event) {
        this.dismissBanner.emit();
        this.handleExpandedRowEvent.emit(event);
    }

    filterLanguage(event: Event) {
        this.languageFilterOption = event;
        this.resetPagination = true;
        this.globalDataHolder = this.data;
        this.appyLanguageFilter();
        this.disableContextMenu();
        this.applyFilter();
        this.applySearch();
        this.applySort();
        this.currentPage = 0;
        this.setCurrentPage();
    }

    filterStatus(event: string) {
        this.documentQuery.next(event);
    }

    filterPage(event: PageEvent) {
        this.pageSize = parseInt(Object.keys(event)[0], 10);
        this.setCurrentPage();
    }

    appyLanguageFilter() {
        if (!this.languageFilter || !this.languageFilterOption) {
            return;
        }
        if (this.languageFilterOption) {
            if (this.languageFilterOption['all']) {
                this.globalDataHolder = this.data;

                return;
            }
            let filterArray: string[] = [];
            Object.keys(this.languageFilterOption).filter((filter) => Boolean(this.languageFilterOption[filter])).forEach((filtertitle: string) => {
                filterArray = [...filterArray, ...new FilterPipe().transform(this.globalDataHolder, ['Language'], filtertitle)];
            });
            this.globalDataHolder = filterArray;
        }
        else {
            this.globalDataHolder = [{}];
        }
    }
}
