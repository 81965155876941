import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as tornis from 'tornis/dist/tornis.es5.js';

export type ViewportState = tornis.TornisUpdateValues;

@Injectable({ providedIn: 'root' })
export class PagePositionService {
    viewportState$: Observable<ViewportState>;

    private viewportStateSubject$: Subject<ViewportState> = new Subject();

    constructor() {
        this.viewportState$ = this.viewportStateSubject$.asObservable();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tornis.watchViewport((state: any) => this.publishViewportState(state));
    }

    publishViewportState(viewportState: ViewportState) {
        this.viewportStateSubject$.next(viewportState);
    }

    getAbsoluteElementPosition(element: HTMLElement) {
        const elementRect = element.getBoundingClientRect();
        const position = {
            top: elementRect.top,
            left: elementRect.left
        };
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        position.top = elementRect.top + scrollTop;
        position.left = elementRect.left + scrollLeft;

        return position;
    }
}
