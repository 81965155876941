import { HttpHeaders } from '@angular/common/http';
import { Select } from '@ngxs/store';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { IdToken } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiOptionsServicePublic extends ApiOptionsService {
    @Select(AuthState.idToken) tokens$: Observable<IdToken|undefined>;

    getAuthedHttpOptions(): Observable<ApiOptions> {
        return this.tokens$.pipe(
            first(),
            map((token) => {
                const auth = token ? { authorization: `Bearer ${token.idToken}` } : {};
                const headers = {
                    appid: environment.appId,
                    ...auth
                } as { [key: string]: string };

                return { headers: new HttpHeaders(headers) };
            })
        );
    }

    getHttpOptions(): ApiOptions {
        return { headers: new HttpHeaders({ appid: environment.appId }) };
    }
}
