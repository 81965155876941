import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { OktaBaseService } from 'common/services/okta/okta-base.service';
import { CaptureSignInError, LogOut } from 'common/store/auth/auth.actions';
import { WINDOW } from 'common/window.provider';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CallbackComponent implements OnInit {
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;

    ngOnDestroy$ = new Subject();

    constructor(
        private readonly okta: OktaBaseService,
        private readonly store: Store,
        @Inject(WINDOW) private readonly window: Window
    ) { }


    ngOnInit() {
        this.routerState$.pipe(
            first(),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((routerState) => this.okta.parseToken(routerState).then(() => {
            // redirect to secure site
            this.window.location.href = environment.secureCallbackUrl as string;
        })
            .catch((err) => {
                if (err.message === 'login_required') {
                    // login_required errors are typically expected if the user isn't
                    // logged in. Don't add it to error banner but log to aid debugging.
                    // eslint-disable-next-line no-console
                    console.warn(err);
                }
                else {
                    // Redirect to secure site. Allows login error to be surfaced to the user
                    this.window.location.href = environment.secureBaseUrl;
                    this.store.dispatch(new CaptureSignInError(err));
                }
                if (err.message === 'access_denied') {
                    return;
                }

                this.store.dispatch(new LogOut());

                return this.store.dispatch(new Navigate(['/login']));
            }));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}

