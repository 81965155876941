import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Toast, ToastService } from 'common/services/toast.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnDestroy, AfterViewInit {
    @HostBinding('style.background-color') bgColor = '#FFF';

    @Input() name: string;
    public toasts: Toast[];

    private ngOnDestroy$ = new Subject();
    private buttonTimer: ReturnType<typeof setTimeout>;

    constructor(
        public toastService: ToastService,
        private cdr: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.toastService.toasts$.pipe(
            map((toasts) => {
                if (this.name) {
                    return toasts.filter((toast) => toast.outletName === this.name);
                }

                return toasts.filter((toast) => !toast.outletName);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((res: Toast[]) => {
            this.toasts = res;
            this.cdr.detectChanges();
        });

        this.toastService.close.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((id: string) => {
            this.toastService.remove(id);
        });
    }

    closeButtonClick(id: string) {
        this.toastService.remove(id);
    }

    ngOnDestroy() {
        if (this.buttonTimer) {
            clearTimeout(this.buttonTimer);
        }

        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
