import { Component } from '@angular/core';
import questionnaire from './2023-regulatory-questionnaire.json';

export interface optionObject {
    questionId: string;
    question: string;
    answer: string;
    options: Array<string>;
}

@Component({
    selector: 'hvac-icp-interactive-questionnaire',
    templateUrl: './icp-interactive-questionnaire.component.html',
    styleUrls: ['./icp-interactive-questionnaire.component.scss']
})

export class IcpInteractiveQuestionnaireComponent {
    public selectedOption = '';
    public randomQuestion: optionObject;
    public answerSubmitted: boolean;


    ngOnInit() {
        const questions = questionnaire.questions;
        this.randomQuestion = questions[Math.floor(Math.random() * questions.length)];
    }

    onRadioButtonClick(value: string) {
        this.selectedOption = value;
    }

    submitAction() {
        if (this.selectedOption !== '') {
            this.answerSubmitted = true;
        }
    }
}
