import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { AccountStatusResponse, DealerLoginBody, DealerLoginRes, UserPref, BaseResponse, CoolCalcPostBody, CoolCalcSupportRequestBody } from 'common/models/account-status';
import { Observable } from 'rxjs';
import { catchError, switchMap, first, map } from 'rxjs/operators';
import { StatusApiError } from '../models/create-account-errors';
import { ApiOptions, ApiOptionsService } from './api-options/api-options.service';
import { CompanyIdPostBody } from '../models/account-status';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { IdToken } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { Select } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class AccountStatusService {
    @Select(AuthState.idToken) tokens$: Observable<IdToken | undefined>;
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) { }

    postDealerLogin(body: DealerLoginBody): Observable<DealerLoginRes> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<DealerLoginRes>(environment.api.legacyLogin || '', body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getUserStatus(): Observable<AccountStatusResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<AccountStatusResponse>(environment.api.accountStatusApi, { ...options })),
            catchError((err: HttpErrorResponse) => {
                this.toastService.add({
                    content: this.translateService.instant(new StatusApiError().content),
                    id: new StatusApiError().name,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
                throw err;
            })
        );
    }

    postCompanyId(body: CompanyIdPostBody): Observable<BaseResponse> {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<BaseResponse>(environment.api.companyId, body, options).pipe(
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateUserPref(userPref: UserPref): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(environment.api.accountUserPrefApi, userPref, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    postCoolCalcTermsStatus(body: CoolCalcPostBody): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(environment.api.coolCalcTerms || '', body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    postCoolCalcSupportRequest(body: CoolCalcSupportRequestBody): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<BaseResponse>(`${environment.api.coolCalcSupportRequest}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getBearerIdToken() {
        let bearerIdToken : string = '';

        return this.tokens$.pipe(
            first(),
            map((token) => {
                if (token) {
                    bearerIdToken = token.idToken.toString();
                }

                return { bearerIdToken };
            })
        );
    }
}
