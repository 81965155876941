import { Component, Input, ViewEncapsulation } from '@angular/core';
@Component({
    selector: 'hvac-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressComponent {
    @Input() name: string;
    @Input() line1: string;
    @Input() line2: string;
    @Input() line3: string;
    @Input() city: string;
    @Input() state: string;
    @Input() zipCode: string;
    @Input() country: string;
}
