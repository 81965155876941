import { SuggestionsQuery, ExtendedSuggestionsResults, SuggestLimiter } from 'common/models';

export class PerformSuggestions {
    static readonly type = '[Suggestions] PerformSuggestions';
    constructor(public query: {q: SuggestionsQuery, limiter: SuggestLimiter}) {}
}

export class ReceiveSuggestionsResults {
    static readonly type = '[Suggestions] ReceiveSuggestionsResults';
    constructor(public results: ExtendedSuggestionsResults) {}
}

export class ClearSuggestions {
    static readonly type = '[Suggestions] ClearSuggestions';
}
