<ul class="hvac-results-list hvac-results-list-{{ mode }}">
    <li class="hvac-results-list-item" *ngFor="let resource of results" [ngSwitch]="resourceType(resource)">
        <div class="hvac-results-list-item-container">
            <utc-product-list-item *ngSwitchCase="'product'" [resource]="resource" [mode]="mode"></utc-product-list-item>
            <utc-part-list-item *ngSwitchCase="'part'" [resource]="resource" [mode]="mode"></utc-part-list-item>
            <utc-site-content-list-item *ngSwitchCase="'content'" [resource]="resource" [mode]="mode"></utc-site-content-list-item>
            <utc-document-list-item *ngSwitchCase="'document'" [resource]="resource" [mode]="mode"></utc-document-list-item>
            <utc-placeholder-list-item *ngSwitchCase="'placeholder'" [mode]="mode"></utc-placeholder-list-item>
            <hvac-system-product-list-item *ngSwitchCase="'systemProduct'" [resource]="resource"></hvac-system-product-list-item>
        </div>
    </li>
</ul>
