import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionStorageService } from '../../services/sessionStorage/sessionStorage.service';
export interface Alert {
    title: string;
    link?: {
        target: string;
        link: string;
    };
    close?: boolean;
    show: boolean;
    id: string;
}

@Component({
    selector: 'hvac-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {
    @Input() alerts: Alert[];

    parsedAlerts: Alert[] = [];

    constructor(private readonly sessionStorageService: SessionStorageService) {}

    ngOnInit() {
        this.parsedAlerts = this.parseAlerts(this.alerts);
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseAlerts(inputAlerts: any): any {
        if (inputAlerts.Content && inputAlerts.Content.links) {
            const links = inputAlerts.Content.links;

            if (links.$values) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return links.$values.map((link: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const alert: any = {};

                    if (link.linkText) {
                        alert.title = link.linkText;
                    }
                    if (link.linkInternalParameter || link.linkExternal) {
                        alert.link = {
                            link: link.linkInternalParameter || link.linkExternal,
                            target: links.linkOperation && links.linkOperation.Key ? links.linkOperation.Key : '_blank'
                        };
                    }
                    alert.id = link.linkOperation ? link.linkOperation.Id : '';
                    alert.close = Boolean(link.linkDescription && link.linkDescription === 'add_close_button');
                    alert.show = !this.sessionStorageService.tridionAlertIsClosed(alert.id);

                    return alert;
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const singleAlert: any = {};
            if (links.linkText) {
                singleAlert.title = links.linkText;
            }
            if (links.linkInternalParameter || links.linkExternal) {
                singleAlert.link = {
                    link: links.linkInternalParameter || links.linkExternal,
                    target: links.linkOperation && links.linkOperation.Key ? links.linkOperation.Key : '_blank'
                };
            }
            singleAlert.close = links.linkDescription && links.linkDescription === 'add_close_button';
            singleAlert.id = links.linkOperation ? links.linkOperation.Id : '';
            singleAlert.show = !this.sessionStorageService.tridionAlertIsClosed(singleAlert.id);

            return [singleAlert];
        }
    }

    closeAlert(alert: Alert) {
        this.sessionStorageService.closeTridionAlert(alert.id);

        return alert.show = false;
    }
}
