<utc-tooltip class="hvac-share-button">
    <utc-tooltip-content>
        <p class='hvac-tooltip-description'>
            {{'SHARE_LINK' | translate}}
        </p>
    </utc-tooltip-content>
    <utc-tooltip-anchor-content><utc-button classNames="hvac-share-button-wrapper"
        iconSrc="assets/icons/share.svg"
        [label]="'SHARE_LINK' | translate"
        [title]="'SHARE_LINK' | translate"
        [useSuccess]="true"
        [showSuccess]="successState$"
        [successLabel]="'SHARED' | translate"
        successIconSrc="assets/icons/check.svg"
        (click)="onClick(); false"></utc-button>
    </utc-tooltip-anchor-content>
</utc-tooltip>
