/* eslint-disable max-lines */
/* eslint-disable no-undefined */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { CharSymbol } from 'common/enums/char-symbol';
import { takeUntil } from 'rxjs/operators';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { BehaviorSubject } from 'rxjs';
import { DateRangePickerService } from 'common/services/date-range-picker-service';
import { DateRange } from 'src/private/app/models/account-admin-program.model';
import { TranslateService } from '@ngx-translate/core';

export type DatePickerStyle = 'retro' | 'default';
export type DatePickerDateFormat = 'date' | 'datetime';
export enum enumDatePickerField {
    'START_DATE_FIELD' = 'Start Date Field',
    'END_DATE_FIELD' = 'End Date Field'
}

@Component({
    selector: 'hvac-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() dateType: DatePickerDateFormat = 'date';
    @Input() submitText?: string;
    @Input() startDateEndDateOffsetMinutes = 0;
    @Input() labelStartDate?: string;
    @Input() labelEndDate?: string;
    @Input() theme: DatePickerStyle = 'default'
    @Input() error?: string;
    @Input() startDateFieldError?: string;
    @Input() endDateFieldError?: string;
    @Input() invalidInputMessage?: string;
    @Input() disabled?: boolean;
    @Input() disableLastValidDateRestore = false;
    @Input() isRequired = false;
    @Input() fieldNameOrId?: string;

    @Output() onSelection = new EventEmitter<DateRange>();
    @Output() onErrorResult = new EventEmitter<[enumDatePickerField, string, string]>();

    readonly CharSymbol = CharSymbol;
    readonly AppConstants = AppConstants;

    public startDateInput = new UntypedFormControl();
    public endDateInput = new UntypedFormControl();
    public minEndDate: number | undefined;
    public maxStartDate: number | undefined;
    public isInvalidInput$ = new BehaviorSubject(false);
    public areControlsSupplied: boolean;
    private lastValidStartDate: number | undefined;
    private lastValidEndDate: number | undefined;
    private _startDate: number | undefined;
    private _endDate: number | undefined;
    private _minStartDate: number | undefined;
    private _maxEndDate: number | undefined;

    constructor(
        public dateRangePickerService: DateRangePickerService,
        public translate: TranslateService
    ) {
        super();
    }

    get startDate(): number | undefined {
        return this._startDate;
    }

    get endDate(): number | undefined {
        return this._endDate;
    }

    get minStartDate(): number | undefined {
        return this._minStartDate;
    }

    get maxEndDate(): number | undefined {
        return this._maxEndDate;
    }

    get controls(): [UntypedFormControl, UntypedFormControl] {
        return [this.startDateInput, this.endDateInput];
    }

    @Input()
    set controls([startDateInputControl, endDateInputControl]: [UntypedFormControl, UntypedFormControl]) {
        this.startDateInput = startDateInputControl;
        this.endDateInput = endDateInputControl;
    }

    @Input()
    set startDate(value: number | undefined) {
        if (value) {
            this._startDate = this.dateType === 'date'
                ? startOfDay(value).getTime()
                : new Date(value).getTime();

            return;
        }

        this._startDate = value;
    }

    @Input()
    set endDate(value: number | undefined) {
        if (value) {
            this._endDate = this.dateType === 'date'
                ? endOfDay(value).getTime()
                : new Date(value).getTime();

            return;
        }

        this._endDate = value;
    }

    @Input()
    set minStartDate(value: number | undefined) {
        if (!value) {
            return;
        }

        this._minStartDate = this.dateType === 'date'
            ? startOfDay(value).getTime()
            : new Date(value).setSeconds(0, 0);
    }

    @Input()
    set maxEndDate(value: number | undefined) {
        if (!value) {
            return;
        }

        this._maxEndDate = this.dateType === 'date'
            ? endOfDay(value).getTime()
            : new Date(value).setSeconds(0, 0);
    }

    ngOnInit(): void {
        this.lastValidStartDate = this.startDate;
        this.lastValidEndDate = this.endDate;
        this.minEndDate = this.startDate;
        this.maxStartDate = this.endDate;

        if (!this.areControlsSupplied) {
            this.startDate && this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.startDate, this.dateType));
            this.startDate && this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.endDate, this.dateType));
            this.startDateInput.valueChanges
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(this.onStartDateChange.bind(this));
            this.endDateInput.valueChanges
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(this.onEndDateChange.bind(this));
        }

        this.ngOnDestroy$.subscribe(() => {
            this.startDateInput.setValue(undefined);
            this.endDateInput.setValue(undefined);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.dateType?.currentValue && this.startDate && this.endDate) {
            this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.startDate, this.dateType));
            this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.endDate, this.dateType));
        }

        if (changes?.startDate?.currentValue && this.startDate) {
            this.lastValidStartDate = this.startDate;
            this.minEndDate = this.startDate;
            this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.startDate, this.dateType));
        }

        if (changes?.endDate?.currentValue && this.endDate) {
            this.lastValidEndDate = this.endDate;
            this.maxStartDate = this.endDate;
            this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.endDate, this.dateType));
        }

        if (changes?.controls?.currentValue) {
            this.areControlsSupplied = true;
            this.startDate && this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.startDate, this.dateType));
            this.startDate && this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.endDate, this.dateType));
            this.startDateInput.valueChanges
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(this.onStartDateChange.bind(this));
            this.endDateInput.valueChanges
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(this.onEndDateChange.bind(this));
        }
    }

    dateTypeSelector(dateType: typeof this.dateType) {
        return (dateType === 'datetime' ? 'datetime-local' : 'date');
    }

    onDateRangeSubmit() {
        let startDate: number | undefined;
        let endDate: number | undefined;

        if (this.startDateInput.value) {
            startDate = this.dateType === 'date'
                ? startOfDay(this.dateRangePickerService.getTimezoneCorrectedDate(new Date(this.startDateInput.value), this.dateType)).getTime()
                : new Date(this.startDateInput.value).getTime();
        }

        if (this.endDateInput.value) {
            endDate = this.dateType === 'date'
                ? endOfDay(this.dateRangePickerService.getTimezoneCorrectedDate(new Date(this.endDateInput.value), this.dateType)).getTime()
                : new Date(this.endDateInput.value).getTime();
        }

        const { isValidResult: startDateValidationResult, errorReason: startDateError } =
            this.dateRangePickerService.getStartDateValidationResult(startDate, this.minStartDate, this.endDate, this.startDateEndDateOffsetMinutes, this.isRequired);
        const { isValidResult: endDateValidationResult, errorReason: endDateError } =
            this.dateRangePickerService.getEndDateValidationResult(endDate, this.maxEndDate, this.startDate, this.startDateEndDateOffsetMinutes, this.isRequired);

        if (!startDateValidationResult && !this.startDateFieldError) {
            this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, startDateError || '');
        }

        if (!endDateValidationResult && !this.endDateFieldError) {
            this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, endDateError || '');
        }

        const selectedDateRange: DateRange = {
            startDate,
            endDate
        };

        this.onSelection.emit(selectedDateRange);
    }

    onStartDateInputBlur() {
        const date = new Date(this.startDateInput.value);

        if (this.dateRangePickerService.areDatesEqualInStringFormat(this.startDate, this.startDateInput.value)) {
            return;
        }

        if (this.startDateInput.value) {
            const offsetCorrectedDateTime = this.dateRangePickerService.getTimezoneCorrectedDate(date, this.dateType);
            const { isValidResult, errorReason } =
                this.dateRangePickerService.getStartDateValidationResult(offsetCorrectedDateTime, this.minStartDate, this.endDate, this.startDateEndDateOffsetMinutes, this.isRequired);

            if (isValidResult) {
                this.minEndDate = offsetCorrectedDateTime;
                this.lastValidStartDate = offsetCorrectedDateTime;
                this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
            }
            else if (!this.disableLastValidDateRestore && this.lastValidStartDate) {
                this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.lastValidStartDate, this.dateType));
                this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
            }
            else {
                this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, errorReason || '');
            }
        }
        else if (!this.disableLastValidDateRestore && this.lastValidStartDate) {
            this.startDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.lastValidStartDate, this.dateType));
            this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
        }
        else if (this.isRequired) {
            this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, this.translate.instant('ERROR_CONTENT.DATE_RANGE_PICKER_CONTROL.START_DATE_FIELD_MANDATORY'));
        }
        else {
            this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
            this.startDate = undefined;
            this.startDateInput.setValue('');
        }
    }

    onEndDateInputBlur() {
        const date = new Date(this.endDateInput.value);

        if (this.dateRangePickerService.areDatesEqualInStringFormat(this.endDate, this.endDateInput.value)) {
            return;
        }

        if (this.endDateInput.value) {
            const offsetCorrectedDateTime = this.dateRangePickerService.getTimezoneCorrectedDate(date, this.dateType);
            const { isValidResult, errorReason } =
                this.dateRangePickerService.getEndDateValidationResult(offsetCorrectedDateTime, this.maxEndDate, this.startDate, this.startDateEndDateOffsetMinutes, this.isRequired);

            if (isValidResult) {
                this.maxStartDate = this.dateRangePickerService.getTimeFromDateString(this.endDateInput.value, this.dateType);
                this.lastValidEndDate = offsetCorrectedDateTime;
                this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
            }
            else if (!this.disableLastValidDateRestore && this.lastValidEndDate) {
                this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.lastValidEndDate, this.dateType));
                this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
            }
            else {
                this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, errorReason || '');
            }
        }
        else if (!this.disableLastValidDateRestore && this.lastValidEndDate) {
            this.endDateInput.setValue(this.dateRangePickerService.getDateStringFromTime(this.lastValidEndDate, this.dateType));
            this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
        }
        else if (this.isRequired) {
            this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, this.translate.instant('ERROR_CONTENT.DATE_RANGE_PICKER_CONTROL.END_DATE_FIELD_MANDATORY'));
        }
        else {
            this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
            this.endDate = undefined;
            this.endDateInput.setValue('');
        }
    }

    onStartDateChange(dateString: string) {
        let endDate: number | undefined;
        if (dateString) {
            const date = new Date(dateString);

            if (isValid(date)) {
                const offsetCorrectedDateTime = this.dateRangePickerService.getTimezoneCorrectedDate(date, this.dateType);
                const { isValidResult, errorReason } =
                    this.dateRangePickerService.getStartDateValidationResult(offsetCorrectedDateTime, this.minStartDate, this.endDate, this.startDateEndDateOffsetMinutes, this.isRequired);

                if (isValidResult && (this.theme === 'retro' || !this.submitText)) {
                    this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
                    this.onDateRangeSubmit();
                }
                else {
                    this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, errorReason || '');
                }
            }
            else {
                this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.INVALID_DATE_VALUE', { fieldName: enumDatePickerField.START_DATE_FIELD.toString() }));
            }
        }
        else if (this.isRequired) {
            this.sendErrorSignal(enumDatePickerField.START_DATE_FIELD, this.translate.instant('ERROR_CONTENT.DATE_RANGE_PICKER_CONTROL.START_DATE_FIELD_MANDATORY'));
        }
        else {
            this.revokeErrorSignalIfAny(enumDatePickerField.START_DATE_FIELD);
            this.startDate = undefined;
            if (this.endDateInput.value) {
                endDate = this.dateType === 'date'
                    ? endOfDay(this.dateRangePickerService.getTimezoneCorrectedDate(new Date(this.endDateInput.value), this.dateType)).getTime()
                    : new Date(this.endDateInput.value).getTime();
            }
            this.onSelection.emit({
                startDate: undefined,
                endDate: endDate
            });
        }
    }

    onEndDateChange(dateString: string) {
        let startDate: number | undefined;
        if (dateString) {
            const date = new Date(dateString);

            if (isValid(date)) {
                const offsetCorrectedDateTime = this.dateRangePickerService.getTimezoneCorrectedDate(date, this.dateType);
                const { isValidResult, errorReason } =
                    this.dateRangePickerService.getEndDateValidationResult(offsetCorrectedDateTime, this.maxEndDate, this.startDate, this.startDateEndDateOffsetMinutes, this.isRequired);

                if (isValidResult && (this.theme === 'retro' || !this.submitText)) {
                    this.onDateRangeSubmit();
                    this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
                }
                else {
                    this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, errorReason || '');
                }
            }
            else {
                this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.INVALID_DATE_VALUE', { fieldName: enumDatePickerField.END_DATE_FIELD.toString() }));
            }
        }
        else if (this.isRequired) {
            this.sendErrorSignal(enumDatePickerField.END_DATE_FIELD, this.translate.instant('ERROR_CONTENT.DATE_RANGE_PICKER_CONTROL.END_DATE_FIELD_MANDATORY'));
        }
        else {
            this.revokeErrorSignalIfAny(enumDatePickerField.END_DATE_FIELD);
            this.endDate = undefined;
            if (this.startDateInput.value) {
                startDate = this.dateType === 'date'
                    ? startOfDay(this.dateRangePickerService.getTimezoneCorrectedDate(new Date(this.startDateInput.value), this.dateType)).getTime()
                    : new Date(this.startDateInput.value).getTime();
            }
            this.onSelection.emit({
                startDate: startDate,
                endDate: undefined
            });
        }
    }

    private sendErrorSignal(errorField: enumDatePickerField, errorMessage: string) {
        this.sendErrorUtil(errorField, true, errorMessage);
    }

    private revokeErrorSignalIfAny(errorField: enumDatePickerField) {
        this.sendErrorUtil(errorField, false, '');
    }

    private sendErrorUtil(errorField: enumDatePickerField, isError: boolean, errorMessage: string) {
        this.onErrorResult.emit([errorField, this.fieldNameOrId || '', errorMessage]);
        this.isInvalidInput$.next(isError);
    }
}
