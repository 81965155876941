<label class="hvac-checkbox-container">
    <input *ngIf="!inputControl"
        class="hvac-checkbox-input"
        type="checkbox"
        [attr.id]="inputId"
        [attr.name]="inputName"
        [attr.value]="value"
        [disabled]="disabled"
        [checked]="checked" />

    <input *ngIf="inputControl"
        class="hvac-checkbox-input"
        type="checkbox"
        [attr.id]="inputId"
        [attr.name]="inputName"
        [attr.disabled]="disabled ? true : null"
        [formControl]="inputControl" />
    <span class="hvac-checkbox-input-text"><ng-content></ng-content></span>
</label>
