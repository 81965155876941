import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Part, PARTS_RESOURCE_TYPE } from 'common/models';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { buildRepository } from '../repository/utils';
import { AppendSearchResults, ReceiveSearchResults } from '../search/search.actions';

export interface PartsStateModel {
    repository: ResourceRepositoryModel<Part>;
}

@State<PartsStateModel>({
    name: 'parts',
    defaults: { repository: {} }
})
@Injectable({ providedIn: 'root' })
export class PartsState {
    @Selector()
    static repository({ repository }: PartsStateModel): ResourceRepositoryModel<Part> {
        return repository;
    }

    @Action([ReceiveSearchResults, AppendSearchResults])
    receiveSearchResults(ctx: StateContext<PartsStateModel>, action: ReceiveSearchResults|AppendSearchResults) {
        const parts = action.results.parts || { results: [] };
        const partResults: Part[] = parts.results;
        const repository = {
            ...ctx.getState().repository,
            ...buildRepository<Part, 'code'>(partResults, 'code', PARTS_RESOURCE_TYPE)
        };

        return ctx.patchState({ repository });
    }
}
