<div
    (hvacClickOutside)="closeSuggestionsWindow()"
    class="hvac-search-input-field"
    [ngClass]="{ 'hvac-search-input-theme-light': theme === 'light', 'hvac-search-input-theme-dark': theme === 'dark', 'hvac-search-input-theme-branded': theme === 'branded', 'hvac-search-input-theme-icp': theme === 'icp' }"
    >
    <label class="hvac-label" [ngClass]="{ 'hvac-label-hidden': hideLabel }" for="_{{ inputId }}">{{ label }}</label>
    <div class="hvac-search-input-control-container">
        <input
            [ngClass]="{ 'hvac-search-filled': _value, 'hvac-search-input-clear-button-active': enableClearButton }"
            class="hvac-search-input"
            [className]="className"
            id="_{{ inputId }}"
            name="_{{ inputId }}"
            [type]="getNativeInputType(type)"
            [placeholder]="placeholder"
            [autocomplete]="autocomplete"
            [value]="value"
            [attr.aria-label]="ariaLabel"
            [formControl]="formControl"
            (focus)="inputFocus()"
            (keyup)="inputKeyup($event)"
            #input />
            <div class="hvac-input-action-wrap">
                <button type="button"
                    *ngIf="enableClearButton"
                    hvacButton="icon"
                    class="hvac-search-input-clear-button"
                    [title]="'CLEAR_INPUT' | translate"
                    (click)="clearSearch()">
                    <utc-svg src="assets/icons/clear.svg"></utc-svg>
                </button>
            </div>
        <button
            *ngIf="theme === 'branded' && _value"
            type="button"
            class="hvac-search-input-clear-button--branded"
            (click)="clearSearch()">
            {{ 'CLEAR' | translate }}
        </button>
        <hvac-auto-suggest *ngIf="enableAutoSuggestions && allowSuggestionsFeature" [show]="showAutosuggest"></hvac-auto-suggest>
    </div>
</div>
