import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../data-block/data-block.component';

@Component({
    selector: 'hvac-day-picker',
    templateUrl: './day-picker.component.html',
    styleUrls: ['./day-picker.component.scss']
})
export class DayPickerComponent implements OnInit {
    @Input() days: number[] = [];

    public dayMap = ['s', 'm', 't', 'w', 't', 'f', 's'];

    public statusMap: Status[] = [];

    ngOnInit() {
        this.statusMap = this.days.map((day: number) => (day ? 'success' : 'off'));
    }
}
