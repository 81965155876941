<utc-base-layout class="hvac-payment-page">
    <utc-hero id="main-content">
        <h1 class="hvac-hero-title hvac-hero-title-covid">
            {{ 'PAYMENTS.HEADING' | translate }}
        </h1>
    </utc-hero>
    <section class="hvac-payment-content">
        <utc-modal
        [visible]="paymenModalVisible"
        size="dynamic-height"
        (closeAction)="closeModal()">
            <h1 class="payment-modal-header">
                Our top priority is to ensure your information is safe.
            </h1>
            <p>
                Using the Chase Paymentech Hosted Payment Solution via JP Morgan Chase, your credit card request is processed securely with SSL and PCI Compliant encryption and immediately sent to the banking merchant. Their site has been validated as PCI SSC certified, which requires rigorous security protocols and testing. The data you provide cannot be viewed, and we do not share your information with anyone else. No card information is maintained in our system after the transaction is processed, and we use a secure conection to prevent external sources from gaining access to your information. This insures full security of information both during and after the transaction.
            </p>
            <p>
                You can do your part to protect your identity by ensuring you always log out of this system and close all browser windows - especially when using a public computer station. For additional assistance regarding this payment option please contact CFSREMIT@CARRIER.COM.
            </p>
        </utc-modal>
        <div class="hvac-payment-main-content" [class.hvac-payment-confirmation]="isSubmitted">
            <div *ngIf="!isSubmitted; else confirmation">
            <form [formGroup]="paymentForm">
                <p class="hvac-p">{{ 'PAYMENTS.P1.FIRST' | translate }} <a href="" (click)="launchModal($event)" class="hvac-link payment-form-modal-trigger" type="button">{{ 'PAYMENTS.P1.MODAL_BUTTON_TEXT' | translate }}</a>&nbsp;{{ 'PAYMENTS.P1.SECOND' | translate }}</p>
                <p class="hvac-p">
                    {{ 'PAYMENTS.P2' | translate }}
                </p>
                <p class="hvac-p">
                    {{ 'PAYMENTS.P3' | translate }}
                </p>
                <fieldset>
                    <p><abbr title="required" class="hvac-contact-required-note">*</abbr>{{ 'CONTACT.REQUIRED' | translate }}</p>
                    <div  class="hvac-contact-form-columns hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-md-6">
                            <utc-input
                                [disabledInput]="!!iframeSrc"
                                class="hvac-payment-form-field"
                                inputId="hvac-contact-first-name"
                                type="number"
                                [required]="true"
                                [inputControl]="paymentForm.controls.amount"
                                [label]="'PAYMENTS.TOTAL_AMOUNT' | translate"
                                [error]="paymentForm.controls.amount.dirty && !paymentForm.controls.amount.valid">
                            </utc-input>
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-md-6">
                            <utc-input
                                [disabledInput]="!!iframeSrc"
                                class="hvac-payment-form-field"
                                inputId="hvac-contact-last-name"
                                type="text"
                                [required]="true"
                                [inputControl]="paymentForm.controls.invoiceNumber"
                                [label]="'PAYMENTS.INVOICE_NUM' | translate"
                                [error]="paymentForm.controls.invoiceNumber.dirty && !paymentForm.controls.invoiceNumber.valid">
                            </utc-input>
                        </div>
                    </div>
                </fieldset>
            </form>
            <button
                [disabled]="paymentForm.invalid"
                *ngIf="!iframeSrc"
                class="payment-form-submit-button"
                (click)="getForm()"
                hvacButton="primary">{{ 'PAYMENTS.BEGIN_PAYMENT' | translate }}</button>
            <div id="payment-form-wrapper">
                <iframe id="secureFrame"
                    [ngClass]="{'payment-form-form-active' : iframeSrc}"
                    class="payment-form-iframe secureFrame"
                    #paymentFormFrame
                    style="width: 100%;"
                    id="secureFrame"
                    [src]="iframeSrc">
                </iframe>
            </div>
                <utc-loading-spinner *ngIf="loading" size="small"></utc-loading-spinner>
            </div>
            <ng-template #confirmation>
                <p class="hvac-p">
                    {{ 'PAYMENTS.CONFIRMATION' | translate }}
                </p>
                <h3 class="hvac-h3">
                    {{ 'PAYMENTS.TRANSACTION_SUMMARY' | translate }}
                </h3>
                <ul class="hvac-payment-confirmation-details">
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.PAYMENT_TYPE' | translate }}</span>
                        {{paymentDetails.cardBrand}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.NAME' | translate }}</span>
                        {{paymentDetails.billingName}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.INVOICE_NUM' | translate }}:</span>
                        {{paymentDetails.orderId}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.AMOUNT_PAID' | translate }}</span>
                        {{paymentDetails.totalAmount}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.APPROVAL_CODE' | translate }}</span>
                        {{paymentDetails.approvalCode}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.ACCOUNT_NUM' | translate }}</span>
                        {{paymentDetails.accountNum}}
                    </li>
                    <li>
                        <span class="hvac-fw-md">{{ 'PAYMENTS.TRANS_DATE_TIME' | translate }}</span>
                        {{transactionDate}}
                    </li>
                </ul>
            </ng-template>
        </div>
</section>
</utc-base-layout>
