import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'hvac-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
    @Input() chipId: string;
    @Input() text: string;
    @Input() disabled: boolean | false;
    @Output() onChipClose = new EventEmitter<string>();

    removeChip() {
        this.onChipClose.emit(this.chipId);
    }
}
