import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';
import { BaseContentEntity } from 'common/models';
import { environment } from 'common/environments/environment';


@Component({
    selector: 'hvac-rich-text-container',
    templateUrl: './rich-text-container.component.html',
    styleUrls: ['./rich-text-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RichTextContainerComponent implements OnInit, OnDestroy {
    @Input() data: BaseContentEntity;
    jumpBarData: JumpBarLink;
    titleId: string;
    public env = environment.env;

    constructor(private readonly jumpBar: JumpbarService, private readonly element: ElementRef) { }

    ngOnInit() {
        this.titleId = this.data?.Id || '';

        this.jumpBarData = {
            link: this.titleId,
            id: [this.data?.Id || ''],
            container: this.element.nativeElement
        };
        this.jumpBar.registerJumpBarLink(this.jumpBarData);
    }

    ngOnDestroy() {
        if (this.jumpBarData) {
            this.jumpBar.deregisterJumpBarLink(this.jumpBarData);
        }
    }
}
