<div class="hvac-criteria-sub-group hvac-rhythm-3">
    <query-builder [(ngModel)]='query' [config]='config' (ngModelChange)="onQueryBuilderChange($event)" class="query-builder">
        <div *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
            <div class="hvac-criteria-section-buttons">
                <div class="hvac-criteria-rule-group-buttons">
                    <button type="button" hvacButton="primary" size="small" (click)="addRule()">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.ADD_RULE' | translate }}</span>
                    </button>
                    <button type="button" hvacButton="primary" size="small" (click)="addRuleSet()">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.ADD_GROUP' | translate }}</span>
                    </button>
                    <button type="button" hvacButton="primary" size="small" (click)="removeRuleSet()" class="query-builder-delete-button">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.DELETE' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div *querySwitchGroup="let ruleset; let onChange=onChange">
            <hvac-toggle-button *ngIf="ruleset" [(ngModel)]="ruleset.condition" (onToggle)="onChange($event)" [isToggled]="ruleset.condition === toggleTypeOr" name="fieldName" ngDefaultControl onLabel="{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.OR' | translate }}"
            offLabel="{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.AND' | translate }}"></hvac-toggle-button>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input-container" *queryField="let rule; let fields=fields; let onChange=onChange">
            <select class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input" [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
                <option *ngFor="let field of fields" [value]="field.value">{{field.name}}</option>
            </select>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input-container" *queryOperator="let rule; let operators=operators; let onChange=onChange">
            <select class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input" [(ngModel)]="rule.operator" (ngModelChange)="onChange($event, rule)">
                <option *ngFor="let value of operators" [value]="value">{{value}}</option>
            </select>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input-container" *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
            <hvac-autocomplete *ngIf="rule.field === 'ProgramId';else queryInputCategory" name="fieldName" ngDefaultControl
                [(ngModel)]="rule.value"
                (onSelect)="onChanges($event, rule)"
                (ngModelChange)="onChange($event, rule)"
                [inputControl]='$any(programNameForm.controls.programName)'
                [data]="( ProgramNameData$ | async ) || []"
                (onLoadMore)="pagination()"
                [event]="(autoComplete$ | async ) || {loading: false}"
                [placeholder]="ProgramNameData$.value.length === 1 ? ProgramNameData$.value[0].name : 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.SEARCH_BY_PROGRAM_NAME' | translate">
            </hvac-autocomplete>
            <ng-template #queryInputCategory>
            <select class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input" [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)">
                <option *ngFor="let opt of options" [value]="opt.value">{{ opt.name }}</option>
            </select>
        </ng-template>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input-container" *queryInput="let rule; type: 'string'; let field=field; let onChange=onChange">
            <input class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input" type="text" [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)">
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input-container" *queryInput="let rule; type: 'number'; let field=field; let onChange=onChange">
            <input class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg hvac-criteria-input" type="number" [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)">
        </div>

        <div class="hvac-criteria-delete" *queryRemoveButton="let rule; let removeRule=removeRule">
            <button type="button" hvacButton="default" size="small" (click)="removeRule(rule)">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.DELETE' | translate }}</span>
            </button>
        </div>

    </query-builder>
</div>
