import { Platform } from '@angular/cdk/platform';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PublicLinkIconService } from 'common/content/services/public-link-icon.service';
import { MenuLinkDetails } from 'common/models';

@Component({
    selector: 'hvac-public-links-item',
    templateUrl: './public-links-item.component.html',
    styleUrls: ['./public-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PublicLinksItemComponent {
    @Input() item: MenuLinkDetails;
    content: MenuLinkDetails;
    links: MenuLinkDetails[] = [];
    hasSingleLink: boolean;
    primaryLink: MenuLinkDetails;
    hasMultipleLinks: boolean;

    constructor(private readonly publicLinkIcon: PublicLinkIconService, public platform: Platform) {}

    ngOnInit() {
        this.content = this.item.Content;
        this.links = this.getLinks(this.content.links);
        this.hasSingleLink = this.links.length === 1;
        this.hasMultipleLinks = this.links.length > 1;
        this.primaryLink = this.links[0];
    }

    hasLinks(): boolean {
        return this.links.some((link: MenuLinkDetails) => Boolean(link.linkExternal || link.linkInternalParameter));
    }

    getLinks(links: MenuLinkDetails): [MenuLinkDetails] {
        if (links.$values) {
            return links.$values;
        }

        return [links];
    }

    getLinkImage(link: MenuLinkDetails): string|null {
        return this.publicLinkIcon.getLinkIcon(link);
    }

    public isPlatformMatch(link: MenuLinkDetails) {
        let value = false;
        if (this.platform.IOS && link.linkExternal?.includes('https://apps.apple.com')) {
            value = true;
        }
        else if (this.platform.ANDROID && link.linkExternal?.includes('https://play.google.com')) {
            value = true;
        }

        return value;
    }
}
