import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
};

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
const noop: any = () => { };

@Component({
    selector: 'hvac-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class DatePickerComponent implements ControlValueAccessor {
    @Input() inputId: string;
    @Input() label: string;
    @Input() size: 'default' | 'small' = 'default';
    @Input() shadow: boolean;
    @Input() lightShadow: boolean;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() min: string;
    @Input() max: string;
    @Input() readonly: boolean;
    @Input() formControl: UntypedFormControl;
    @Input('value') _value?: string;
    minimizeLabel: boolean;

    onChanged = noop;
    onTouched = noop;

    constructor() {
        this._value = this.value;
        this.minimizeLabel = Boolean(this._value) && this._value !== '';
    }

    get value(): string | undefined {
        return this._value;
    }

    set value(val: string | undefined) {
        if (val !== this._value) {
            this.minimizeLabel = Boolean(val) && val !== '';
            this._value = val;
            this.onChanged(val);
        }
    }

    writeValue(value: string) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChanged = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}
