import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToggleType } from '../dynamic-nested-filter/dynamic-nested-filter.component';

@Component({
    selector: 'hvac-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})

export class ToggleButtonComponent {
    @Input() isToggled: boolean;
    @Input() onLabel: string;
    @Input() offLabel: string;
    @Output() onToggle = new EventEmitter<string>();

    changeToggle(event: Event) {
        const input = event.target as HTMLInputElement;
        this.isToggled = input.checked;
        (this.isToggled) ? this.onToggle.emit(ToggleType.Or) : this.onToggle.emit(ToggleType.And);
    }
}
