import { Component, EventEmitter, Input, Output, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

export interface ImportFile {
    file: File;
    isContentVisible: boolean;
    control: string;
}

export interface UploadFile {
    isContentVisible: boolean;
    control: string;
}

@Component({
    selector: 'hvac-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit, AfterViewInit {
    @Input() acceptableFileType: string;
    @Input() displayInputField: boolean;
    @Input() fileUploadControl: string;
    @Input() getCleanUpActionRef?: (cleanUpFunc: () => void) => void;
    @Output() onUploadFile = new EventEmitter<UploadFile>();
    @Output() onImportFile = new EventEmitter<ImportFile>();
    @ViewChild('fileInputControl') fileInputControlReference: ElementRef;

    public uploadedFile: File | null;
    public fileName: string;
    public isContentVisible: boolean = false;

    constructor() {
        this.reset = this.reset.bind(this);
    }

    ngOnInit() {
        this.acceptableFileType = `.${this.acceptableFileType}`;
    }

    ngAfterViewInit(): void {
        if (this.getCleanUpActionRef) {
            this.getCleanUpActionRef(this.reset);
        }
    }

    reset() {
        if (this.fileInputControlReference && this.fileInputControlReference.nativeElement) {
            this.fileInputControlReference.nativeElement.value = '';
        }

        this.fileName = '';
        this.uploadedFile = null;
        this.isContentVisible = false;
    }

    uploadFile(event: Event) {
        const input = event.target as HTMLInputElement;
        if (!input.files?.length) {
            return;
        }
        if (input && input.files.length > 0) {
            this.uploadedFile = input.files[0];
            this.fileName = input.files[0].name;
            this.isContentVisible = false;
            const uploadFileData: UploadFile = {
                isContentVisible: this.isContentVisible,
                control: this.fileUploadControl
            };
            this.onUploadFile.emit(uploadFileData);
        }
    }

    importFile() {
        this.isContentVisible = true;

        if (this.uploadedFile) {
            const importFileData: ImportFile = {
                file: this.uploadedFile,
                isContentVisible: this.isContentVisible,
                control: this.fileUploadControl
            };
            this.onImportFile.emit(importFileData);
        }
    }
}
