import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface SegmentedOption {
    title: string;
    key: string;
    checked: boolean;
}

export type SegmentedControlType = 'radio' | 'checkBox';

@Component({
    selector: 'hvac-segmented-button',
    templateUrl: './segmented-button.html',
    styleUrls: ['./segmented-button.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SegmentedButtonComponent implements OnInit {
    @Input() controlType: SegmentedControlType = 'checkBox';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() segmentedSelection = new EventEmitter<any>();

    selectedOption$: BehaviorSubject<{ [title: string]: boolean }> = new BehaviorSubject({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultSelection$ = new BehaviorSubject<any>({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    segmentedOptions$ = new BehaviorSubject<any>([]);
    segmentedControlData: SegmentedOption[];

    get segmentedOptions() {
        return this.segmentedOptions$.getValue();
    }

    get defaultSelection() {
        return this.defaultSelection$.getValue();
    }

    @Input()
    set segmentedOptions(value: SegmentedOption[]) {
        this.segmentedOptions$.next(value);
    }

    @Input()
    set defaultSelection(value: SegmentedOption) {
        this.defaultSelection$.next(value);
    }

    ngOnInit() {
        this.segmentedOptions$.pipe(
            debounceTime(500)
        ).subscribe((data) => {
            this.segmentedControlData = data;
        });
        this.selectedOption$.pipe(
            debounceTime(500)
        ).subscribe((data) => {
            if (data) {
                this.segmentedSelection.next(this.selectedOption$.value);
            }
        });

        this.defaultSelection$.pipe(
            debounceTime(500)
        ).subscribe((data) => {
            if (data) {
                this.setOption(data);
            }
        });
    }

    setOption(option: SegmentedOption) {
        if (option?.title === 'default') {
            this.segmentedControlData.forEach((data) => {
                const selectedOption: { [category: string]: boolean } = this.selectedOption$.value;
                this.selectedOption$.next({
                    ...selectedOption,
                    [data.key]: true
                });
            });

            return;
        }
        if (this.controlType === 'radio') {
            this.selectedOption$.next({ [option.key]: true });

            return;
        }
        const selectedOption: { [category: string]: boolean } = this.selectedOption$.value;
        this.selectedOption$.next({
            ...selectedOption,
            [option.key]: !selectedOption[option.key]
        });
    }

    getSelection(option: SegmentedOption) {
        const selectedOption: { [category: string]: boolean } = this.selectedOption$.value;

        return selectedOption[option.key];
    }
}
