import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

type ModalSize = 'small' | 'medium' | 'full' | 'full-business-locator' | 'dynamic-height';

const fixedClass = 'hvac-state-fixed';
const modalOpenClass = 'hvac-state-modal-open';

@Component({
    selector: 'utc-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent {
    @Input() visible: boolean;
    @Input() size: ModalSize = 'full';
    @Input() showHeader = true;
    @Input() theme: 'default' | 'rounded' = 'default';
    @Input() displayWarning: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() closeAction: EventEmitter<any> = new EventEmitter();
    @ViewChild('modal') modal: ElementRef;

    private ngOnDestroy$ = new Subject();

    constructor(
        private renderer: Renderer2,
        router: Router
    ) {
        this.visible = false;

        // Close modal on navigation change
        router.events.pipe(
            filter((event: NavigationEvent) => event instanceof NavigationStart)
        ).subscribe(() => {
            this.closeDialog();
        });
    }

    @HostBinding('class.hvac-modal-rounded') get isRoundedTheme() {
        return this.theme === 'rounded';
    }

    @HostListener('keydown', ['$event'])
    public escapeListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code?.toLowerCase() === 'escape') {
            this.closeDialog();
        }
    }

    @HostListener('keydown', ['$event'])
    public focusRestrict(event: KeyboardEvent) {
        if (event.key?.toLowerCase() === 'tab') {
            const focusableEls = this.modal.nativeElement
                .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const firstFocusableEl = focusableEls[0];
            const lastFocusableEl = focusableEls[focusableEls.length - 1];

            /* shift + tab */
            if (event.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();

                    return event.preventDefault();
                }
            }
            /* regular tab */
            else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();

                    return event.preventDefault();
                }

                return;
            }
        }
    }

    public closeDialog() {
        if (this.displayWarning === true) {
            if (this.closeAction.observers.length > 0) {
                this.closeAction.emit();
            }
        }
        else {
            this.visible = false;
            if (this.closeAction.observers.length > 0) {
                this.closeAction.emit();
            }
            this.renderer.removeClass(document.documentElement, fixedClass);
            this.renderer.removeClass(document.documentElement, modalOpenClass);
        }
    }

    ngOnChanges() {
        if (this.visible) {
            this.renderer.addClass(document.documentElement, fixedClass);
            this.renderer.addClass(document.documentElement, modalOpenClass);
        }
        else {
            this.renderer.removeClass(document.documentElement, fixedClass);
            this.renderer.removeClass(document.documentElement, modalOpenClass);
        }
    }

    ngOnDestroy() {
        this.closeDialog();
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.unsubscribe();
    }
}
