import { Injectable } from '@angular/core';

const CATEGORIES: { code: string, icon: string }[] = require('./parts-catalog-image.json');
const defaultCategory = CATEGORIES.find((category) => category.code === 'default') || {
    code: '',
    icon: ''
};

@Injectable({ providedIn: 'root' })
export class PartsCatalogImageService {
    getPartsCategoryIcon(code: string): string {
        const selectedCategory = CATEGORIES.find((category) => category.code === code);

        return selectedCategory ? selectedCategory.icon : defaultCategory.icon;
    }
}
