<section class="hvac-featured-content-item">
    <a class="hvac-featured-content-image-button"
        *ngIf="imageLink; else nonAction"
        [href]="links.linkExternal">
        <utc-media class="hvac-featured-content-media-container" [content]="content"></utc-media>
    </a>
    <ng-template #nonAction>
        <utc-media class="hvac-featured-content-media-container" [content]="content"></utc-media>
    </ng-template>
    <h4 class="hvac-featured-content-item-title hvac-h3" [innerHTML]="content.title | richText"></h4>
    <p class="hvac-featured-content-item-description hvac-p" [innerHTML]="content.text | richText"></p>
    <hvac-link class="hvac-featured-content-item-link" *ngIf="buttonLink" [linkType]="linkType" [link]="links">{{ links.linkText }}</hvac-link>
    <span class="hvac-featured-content-extras" *ngIf="item.additionalLinks">
        <a target="_blank" [href]="link.link" *ngFor="let link of item.additionalLinks" class="hvac-featured-content-extra">
            <img [src]="link.image" class="hvac-featured-content-extra-image hvac-app-store-badge" alt="link.alt" />
        </a>
    </span>
</section>
