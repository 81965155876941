<div class="hvac-autocomplete" (hvacClickOutside)="handleClick(true)">
    <div class="hvac-input-container" [ngClass]="{
        'hvac-input-min-label': minimizeLabel,
        'hvac-input-error':error,
        'hvac-input-focused': isFocused,
        'hvac-input-light-shadow': shadow === 'light',
        'utc-disabled': disabled
    }">
        <input class="hvac-input"
            [className]="className"
            id="_{{ inputId }}"
            name="_{{ inputId }}"
            [value]="_value" [attr.aria-label]="ariaLabel"
            [formControl]="inputControl"
            [placeholder]="placeholder"
            [attr.disabled]="disabledInput ? '' : null" #input/>
            <utc-loading-spinner *ngIf="(event$ | async)?.loading || false"></utc-loading-spinner>
            <label *ngIf="label" class="hvac-input-label" for="_{{ inputId }}">
                {{ label }}<abbr *ngIf="required" title="required" class="hvac-input-label-required">{{ required ? '*' : ''}}</abbr>
            </label>
    </div>

    <div *ngIf="(errorMessage && error) || infoMessage" class='hvac-input-message-container hvac-input-show-message'>
        <div *ngIf="error">
            <utc-svg *ngIf="errorMessage" src="assets/icons/info.svg"></utc-svg>
            <span class='hvac-input-message' [innerHTML]="errorMessage | safe:'html'"></span>
        </div>
        <div *ngIf="infoMessage">
            <span class='hvac-input-message' [innerHTML]="infoMessage | safe:'html'"></span>
        </div>
    </div>
    <ul class="hvac-autocomplete-group" *ngIf="!disabled && !disabledInput" [ngClass]="{
        'hvac-open': isOpen }">
        <li class="hvac-autocomplete-item"  *ngFor="let option of (dataList$ | async)">
            <button class="hvac-autocomplete-button"
            (click)="setSelection(option.value)">
                <span *ngIf="show ==='nameValue'">{{ option.name }} - {{ option.value }}</span>
                <span *ngIf="show=== 'name'">{{ option.name }}</span>
                <span *ngIf="show=== 'value'">{{ option.value }}</span>
            </button>
        </li>
        <li class="hvac-autocomplete-item load-more" *ngIf="((event$ | async)?.loadMore || false) && (dataList$ | async)?.length ">
            <button hvacButton="text" (click)="onLoadMore.emit()">
                {{"LOAD_MORE" | translate }}
            </button>
        </li>

    </ul>
</div>
