import { Pipe, PipeTransform } from '@angular/core';

// Add a suffix to a number like value.
// Values that cannot be converted to a number are returned without the suffix
@Pipe({ name: 'numberSuffix' })
export class NumberSuffixPipe implements PipeTransform {
    transform(value: string | number | undefined, suffix: string, ignoreValue: boolean = false): string | number | undefined {
        if (ignoreValue) {
            return `${value}${suffix}`;
        }

        if (typeof value !== 'undefined' && value !== null) {
            const parsed = parseFloat(value.toString());

            return isNaN(parsed) ? value : `${value}${suffix}`;
        }

        return value;
    }
}
