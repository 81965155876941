import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface BrowseNavigation {
    category?: string;
    subcategory?: string | null;
    pushToHistory?: boolean;
}

const applyNavigation = (current: BrowseNavigation, next: BrowseNavigation) => {
    if (next.subcategory) {
        return {
            ...current,
            ...next
        };
    }

    return next;
};

@Injectable({ providedIn: 'root' })
export class BrowseNavigationService {
    private navigationState$: BehaviorSubject<BrowseNavigation> = new BehaviorSubject({});

    get navigation$(): Observable<BrowseNavigation> {
        return this.navigationState$.asObservable();
    }

    navigate(section: BrowseNavigation): BrowseNavigation {
        const current = this.navigationState$.value;
        const updated = applyNavigation(current, section);
        this.navigationState$.next(updated);

        return updated;
    }
}
