<main class="hvac-main-content-container">
    <section class="hvac-nav-landing-page">
        <div class="hvac-subtext">{{ 'DISTRIBUTOR_LOCATOR.BREADCRUMB' | translate }}</div>
        <h2 class="hvac-content-header-title">{{ 'DISTRIBUTOR_LOCATOR.TITLE' | translate }}</h2>
        <div class="hvac-content-header-sub-title" *ngIf="!isTotaline">{{ 'DISTRIBUTOR_LOCATOR.SUB_TITLE' | translate: { brand: brand} }}</div>
        <div class="hvac-content-header-sub-title" *ngIf="isTotaline">{{ 'DISTRIBUTOR_LOCATOR.SUB_TITLE_TOTALINE' | translate }}</div>
    </section>
    <section class="distributor-locator-content">
        <div class="distributor-locator-mob-tab-container">
            <div class="distributor-locator-mob-list" [ngClass]="{ 'distributor-locator-mob-tab-selected' : activeTab.tab === 'distributor' }">
                <button class="mob-list" (click)="activateDistributorList()">
                    <span class="hvac-accessible-text">{{ 'DISTRIBUTOR_LOCATOR.TAB_LIST' | translate }}</span>
                    <utc-svg src="assets/icons/Distributor_Search_List_Icon.svg"></utc-svg>
                </button>

            </div>
            <div class="distributor-locator-mob-map" [ngClass]="{ 'distributor-locator-mob-tab-selected' : activeTab.tab === 'map' }">
                <button class="map-view" (click)="activateMapView()">
                    <span class="hvac-accessible-text">{{ 'DISTRIBUTOR_LOCATOR.TAB_MAP' | translate }}</span>
                    <utc-svg src="assets/icons/Distributor_Search_Map_Icon.svg"></utc-svg>
                </button>
            </div>
        </div>
        <div class="distributor-locator-controls">
            <div class="distributor-locator-filter-control">
                <button class="hvac-text-button" type="button" (click)="toggleFilterMenu()"><span
                        class="distributor-locator-show-text">{{ 'DISTRIBUTOR_LOCATOR.SHOW' | translate }}</span> {{
                    'DISTRIBUTOR_LOCATOR.FILTERS' | translate }}</button>
            </div>
            <div class="distributor-locator-displayed-text-container" >
                <p class="distributor-locator-displayed-text"
                    *ngIf="(locationData$ | async)?.length;else noLocation">
                    {{ 'DISTRIBUTOR_LOCATOR.RESULT_COUNT_MESSAGE' | translate: { total:
                    (locationData$ | async)?.length } }}
                </p>
                <ng-template #noLocation>
                    <p class="distributor-locator-displayed-text" >
                        {{ errorMessage }}
                    </p>
                </ng-template>

            </div>
            <div class="distributor-locator-sort" *ngIf="sortOption && sortOption.length > 1">
                <div class="distributor-locator-sort-title">Sort By</div>
                <hvac-options-menu [describedBy]="'#sort-by'" (change)="filterChanged($event)"
                    [selectedOption]="sortOption[1]" [menuOptions]="sortOption">
                </hvac-options-menu>
            </div>
        </div>
        <div class="distributor-locator-main-content">
            <div class="distributor-locator-filter-and-list">
                <div class="distributor-locator-filter" [ngClass]="{ 'hvac-filters-toggled': (filterView$ | async) }">
                    <button buttonType="button" class="hvac-filter-close" hvacButton="primary" size="small"
                    (click)="toggleFilterMenu()">{{ 'SEARCH_PAGE.DONE' | translate }}</button>
                    <div class="distributor-locator-filter-search" *ngIf="isTotaline">
                        <form class="distributor-locator-search-form">
                            <div class="hvac-brand-filter-group" [ngClass]="{ 'hvac-brand-filter-group-collapsed': collapsed }">
                                <utc-button buttonType="button"
                                    classNames="hvac-text-button hvac-collapse-button" [ngClass]="{ 'hvac-collapse-button-collapsed': collapsed }"
                                    [label]="'DISTRIBUTOR_LOCATOR.BRAND' | translate"
                                    [title]="'DISTRIBUTOR_LOCATOR.BRAND' | translate"
                                    [buttonId]="'DISTRIBUTOR_LOCATOR.BRAND' | translate" (click)="collapsed = !collapsed"></utc-button>
                                <div class="hvac-brand-filter-group-filters">
                                    <ul class="hvac-brand-filter-list">
                                        <li *ngFor="let brand of allBrands | slice:0:(showAll ? undefined : 5)" class="hvac-brand-filter-list-item">
                                            <utc-checkbox
                                                [checked]="brand.selected || false"
                                                [inputId]="brand.name"
                                                [value]="brand.name"
                                                (change)="toggleFilter(brand)"
                                                [disabled]="brand.disabled"
                                                [inputName]="brand.name">
                                                <span class="hvac-brand-filter-name">{{ brand.name | titlecase }}</span>
                                            </utc-checkbox>
                                        </li>
                                    </ul>
                                    <utc-button *ngIf="allBrands.length > 5" buttonType="button"
                                        class="hvac-brand-filter-show-all"
                                        [ngClass]="{ 'hvac-collapse-button-collapsed': !showAll }"
                                        classNames="hvac-text-button hvac-collapse-button hvac-shadow-button"
                                        (click)="showAll = !showAll"
                                        [label]="(showAll ? 'SEARCH_PAGE.LESS' : 'SEARCH_PAGE.MORE') | translate"
                                        [title]="(showAll ? 'SEARCH_PAGE.LESS' : 'SEARCH_PAGE.MORE') | translate"></utc-button>
                                </div>
                            </div>
                            <button type="submit" class="hvac-invisible-submit" disabled>
                                <span class="hvac-accessible-text">{{'SUBMIT' | translate }}</span>
                            </button>
                        </form>
                    </div>
                    <div class="distributor-locator-filter-search">
                        <form class='distributor-locator-search-form' [formGroup]="searchControl">
                            <input class="distributor-locator-search-input" id="distributor-locator-search"  formControlName="search"
                                name="distributor-locator-search" type="text" [placeholder]="'DISTRIBUTOR_LOCATOR.ZIP_CODE_STATE' | translate"
                                [attr.aria-label]="'SEARCH' | translate" [title]="'DISTRIBUTOR_LOCATOR.ZIP_CODE_STATE' | translate" #searchInput
                                (keyup)="inputKeyup($event)" autocomplete="off" (keydown.enter)="inputKeyup($event)" required maxlength="7" minlength="2"/>

                            <div class="hvac-input-action-wrap">
                                <button *ngIf="searchText.length > 0" type="button" hvacButton="default" class="hvac-clear-button" (click)="clearSearch()">
                                    {{ 'CLEAR' | translate }}
                                </button>

                                <button hvacButton="icon" (click)="onSearch()" [title]="'SEARCH' | translate">
                                    <utc-svg src="assets/icons/search.svg"></utc-svg>
                                </button>
                            </div>
                        </form>
                        <utc-button buttonType="button"
                            [disabled]="usingCurrentLocation" classNames="{{usingCurrentLocation ? 'current-location-button disabled' : 'current-location-button'}}"
                            title="{{usingCurrentLocation ? ('DISTRIBUTOR_LOCATOR.USING_CURRENT_LOCATION' | translate) : ('DISTRIBUTOR_LOCATOR.USE_CURRENT_LOCATION' | translate)}}"
                            label="{{usingCurrentLocation ? ('DISTRIBUTOR_LOCATOR.USING_CURRENT_LOCATION' | translate) : ('DISTRIBUTOR_LOCATOR.USE_CURRENT_LOCATION' | translate)}}"
                            (click)="queryCurrentLocation()">
                            {{'DISTRIBUTOR_LOCATOR.USE_CURRENT_LOCATION' | translate}}</utc-button>
                    </div>

                    <div class="distributor-locator-radius" *ngIf="filterOptions && filterOptions.length > 1">
                        <div class="distributor-locator-sort-title">Search Within</div>
                        <hvac-options-menu [describedBy]="'#sort-by'" (change)="filterQuery($event)"
                            [selectedOption]="filterOptions[filterOptions.length - 1]" [menuOptions]="filterOptions">
                        </hvac-options-menu>
                    </div>
                </div>
                <div
                    class="distributor-locator-list-container distributor-locator-list-container-empty-state "
                    *ngIf="isTotaline && !brandQuery; else mapSection">
                        <p>{{ 'DISTRIBUTOR_LOCATOR.CHOOSE_BRAND' | translate }}</p>
                </div>
                <ng-template #mapSection>
                    <div class="distributor-locator-list-container" [ngClass]="{'hide': activeTab.tab !== 'distributor'}">
                        <utc-loading-spinner class="distributor-api-loader" *ngIf="loader$ | async " [spinnerSize]="'small'"></utc-loading-spinner>
                        <div class="locator-list" [ngClass]="{ 'hide': loader$ | async }" >
                            <p class="distributor-locator-title-text" *ngIf="(locationData$ | async)?.length">
                                <span *ngIf="isTotaline; else products">
                                    {{'DISTRIBUTOR_LOCATOR.SEARCH_CRITERIA_TITLE_TOTALINE' | translate }}
                                </span>
                                <ng-template #products>
                                    {{ 'DISTRIBUTOR_LOCATOR.SEARCH_CRITERIA_TITLE' | translate: { brand: brand } }}
                                </ng-template>
                            </p> 
                            <div class="distributor-locator-card">

                                <div *ngFor="let data of locationData$ | async">
                                    <div class="distributor-locator-list-title"
                                        [ngClass]="{ 'selected': data.companyId === selectedCompanyIdFromMap }">
                                        <div class="distributor-locator-list-left" (click)="plotToMap(data)">
                                            <label class="distributor-locator-name">{{ data.locatorName || data.companyName
                                                }}</label>
                                            <p class="distributor-locator-list-address">
                                                {{
                                                'DISTRIBUTOR_LOCATOR.ADDRESS' | translate: { address: data.address1 }
                                                }}
                                            </p>
                                            <p class="distributor-locator-list-postal">
                                                {{
                                                'DISTRIBUTOR_LOCATOR.POSTALCODE' | translate: { city: data.city, state:
                                                data.state, postalCode: data.postalCode }
                                                }}
                                            </p>
                                            <div class="distributor-locator-phonenumber">
                                                <div class="locator-list-phone">
                                                    <utc-svg src="assets/icons/phone.svg">
                                                    </utc-svg>
                                                </div>
                                                <a *ngIf="data.phone" class="locator-list-phone-number" [href]="'tel:' + data.phone">{{ data.phone}}</a>
                                            </div>
                                        </div>
                                        <div class="distributor-locator-list-right">
                                            <button type="button" hvacButton="icon"
                                                class="locator-navigate-to-map"
                                                buttonType="submit"
                                                [title]="'SEARCH' | translate"
                                                (click)="navigateToMap(data)">
                                                <utc-svg src="assets/icons/direction_Icon.svg"></utc-svg>
                                            </button>

                                            <label class="distributor-locator-miles">{{
                                                    'DISTRIBUTOR_LOCATOR.MILES' | translate: { miles: data.distance | number : '1.1-1' }
                                                }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="locator-map"  *ngIf="mapData.length > 0" [ngClass]="{ 'hide': loader$ | async }" >
                            <hvac-distributor-map [mapData]="mapData" (markerClick)="mapSelection($event)"
                                [markerSelection]="selectedMarker"></hvac-distributor-map>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="mob-locator-map" [ngClass]="{'hide': activeTab.tab !== 'map'}" >
                <hvac-distributor-map [mapData]="mapData" (markerClick)="mapSelection($event)"
                    [markerSelection]="selectedMarker"></hvac-distributor-map>
                    <div *ngIf="selectionData !== undefined" class="mob-map-selection-container">
                        <div class="selected-locator-title">
                                    <div class="selected-locator-left">
                                        <div class="distributor-name">{{ selectionData.locatorName || selectionData.companyName
                                            | translate}}</div>
                                        <p class="distributor-list-address">
                                            {{
                                            'DISTRIBUTOR_LOCATOR.ADDRESS' | translate: { address: selectionData.address1 }
                                            }}
                                        </p>
                                        <p class="distributor-postal">
                                            {{
                                            'DISTRIBUTOR_LOCATOR.POSTALCODE' | translate: { city: selectionData.city, state:
                                                selectionData.state, postalCode: selectionData.postalCode }
                                            }}
                                        </p>
                                        <div class="distributor-phonenumber">
                                            <div class="locator-phone">
                                                <utc-svg src="assets/icons/phone.svg">
                                                </utc-svg>
                                            </div>
                                            <a class="locator-phone-number" [href]="'tel:' + selectionData.phone">{{ selectionData.phone  | translate}}</a>
                                        </div>
                                    </div>
                                    <div class="selected-locator-right">
                                        <button type="button" hvacButton="icon"
                                            class="locator-navigate-to-map"
                                            buttonType="submit"
                                            [title]="'SEARCH' | translate"
                                            (click)="navigateToMap(selectionData)">
                                            <utc-svg src="assets/icons/direction_Icon.svg"></utc-svg>
                                        </button>

                                        <div class="distributor-locator-miles">
                                            {{
                                                'DISTRIBUTOR_LOCATOR.MILES' | translate: { miles: selectionData.distance | number : '1.1-1' }
                                            }}
                                        </div>
                                    </div>
                                </div>
                    </div>

            </div>
        </div>
    </section>
</main>
