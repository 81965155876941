import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { environment } from 'common/environments/environment';
import { Document, ViewMode } from 'common/models';
import { DOCUMENT_RESOURCE_TYPE } from 'common/models/document';
import { FileIcons } from 'common/models/fileIcons';

@Component({
    selector: 'utc-document-list-item',
    templateUrl: './document-list-item.component.html',
    styleUrls: ['./document-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentListItemComponent implements OnInit {
    @Input() mode: ViewMode = 'list';
    @Input() resource: Document = {
        resourceType: DOCUMENT_RESOURCE_TYPE,
        SecurityGroup: '',
        securityGroup: { name: '' },
        brand: [],
        catalogueNumber: '',
        current: true,
        fileExtension: '',
        fileType: '',
        formNumber: '',
        id: '',
        mimeType: '',
        printDate: '',
        prodModelNumber: [],
        score: 0,
        subtype: '',
        title: '',
        type: '',
        url: ''
    };

    @ViewChild('documentListItem', { static: true }) documentListItem: ElementRef;

    public iconPath = '';
    public resourceSubtype: string;
    public isoDate: string;
    public environmentTranslateKey = environment.brand === 'private' ? 'PRIVATE' : 'PUBLIC';


    ngOnInit() {
        // eslint-disable-next-line no-self-assign
        this.resource = this.resource;
        this.resourceSubtype = Array.isArray(this.resource.subtype) ? this.resource.subtype.join(', ') : this.resource.subtype;
        this.isoDate = this.resource.printDate ? this.resource.printDate.replace(/\s/, 'T') : '';

        const iconType = this.resource.fileType || this.resource.fileExtension;
        this.iconPath = FileIcons[iconType] || FileIcons.Default;
    }
}
