import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MobileMenuVisible } from 'common/store/ui/ui.actions';
import { NavOption } from '../dropdown-navigation/dropdown-navigation.component';

@Component({
    selector: 'hvac-dropdown-icon-menu',
    templateUrl: './dropdown-icon-menu.component.html',
    styleUrls: ['../dropdown-styles.component.scss', './dropdown-icon-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DropdownIconMenuComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() menuOptions: any[] = [];
    @Input() selectedOption: NavOption | null = null;
    @Input() iconSrc: string;
    @Input() menuActive = false;
    @Output() openMenuEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(private readonly store: Store) { }

    toggleMenu() {
        this.openMenuEmitter.emit(!this.menuActive);
    }

    setOption(option: NavOption) {
        this.toggleMenu();
        if (!this.selectedOption || option.value !== this.selectedOption.value) {
            this.selectedOption = option;
        }
        this.store.dispatch(new MobileMenuVisible(false));
    }
}
