import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-full-page-loader',
    templateUrl: './full-page-loader.component.html',
    styleUrls: ['./full-page-loader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FullPageLoaderComponent {
    @Input() branded = false;
}
