<ng-container [ngSwitch]="mediaType">
    <img
        *ngSwitchCase="'image'"
        class="hvac-media-image"
        [src]="media.BinaryContent.Url"
        alt="media.title"
    />

    <utc-svg
        *ngSwitchCase="'svg'"
        class="hvac-media-svg"
        [src]="media.BinaryContent.Url">
    </utc-svg>

    <iframe class="hvac-featured-content-media"
        *ngSwitchCase="'video'"
        class="hvac-media-video"
        [title]="content.title"
        width="448" height="252"
        [src]="video.Content.videoUrl | safe: 'resourceUrl'"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
    </iframe>
</ng-container>
