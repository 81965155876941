import { OnInit, Component, ComponentFactoryResolver, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentHostDirective } from '../../directives/content-host/content-host.directive';
import { ContentComponent, ContentComponentService, EntityTemplate } from '../../services/content-component.service';

@Component({
    selector: 'utc-content-section',
    templateUrl: './content-section.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContentSectionComponent implements OnInit {
    @ViewChild(ContentHostDirective, { static: true }) contentHost: ContentHostDirective;

    @Input('sectionData') sectionData$: Observable<BaseContentEntity[]>;
    title$: Observable<string>;
    components$: Observable<EntityTemplate[]>;

    constructor(
        private readonly contentComponentService: ContentComponentService,
        private readonly componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit() {
        this.components$ = this.sectionData$.pipe(
            map((sectionData) => this.contentComponentService.resolveEntities(sectionData))
        );
        this.components$.subscribe((components: EntityTemplate[]) => {
            const { viewContainerRef } = this.contentHost;
            viewContainerRef.clear();
            components.forEach((entityTemplate: EntityTemplate) => {
                if (entityTemplate.template) {
                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(entityTemplate.template);
                    const componentRef = viewContainerRef.createComponent(componentFactory);
                    (<ContentComponent>componentRef.instance).data = entityTemplate.entity;
                }
            });
        });
    }
}
