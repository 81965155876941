import { Injectable } from '@angular/core';
import { NavMenu } from 'common/services/navigation-content.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

const BASE_ENTRY: NavMenu = {
    title: '',
    key: '',
    url: '',
    children: [],
    path: [''],
    tag: ''
};

@Injectable({ providedIn: 'root' })

export class NavigationService {
    navigationSections$;

    private navigationSubject = new BehaviorSubject<NavMenu>(BASE_ENTRY);
    private rootMenu: NavMenu | null;
    private closeTimer: NodeJS.Timeout;

    constructor() {
        this.navigationSections$ = this.navigationSubject.asObservable();
    }

    setRootMenu(menu: NavMenu) {
        this.rootMenu = menu;
    }

    openNextMenu(menu: NavMenu) {
        clearTimeout(this.closeTimer);
        this.navigationSubject.next(menu);
    }

    mobileNavigateBack() {
        this.navigationSections$.pipe(take(1)).subscribe((currentMenu) => {
            if (currentMenu.parent) {
                this.navigationSubject.next(currentMenu.parent);
            }
        });
    }

    closeAllMenu() {
        this.closeTimer = setTimeout(() => {
            this.navigationSubject.next(this.rootMenu || BASE_ENTRY);
        }, 500);
    }
}
