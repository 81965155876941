import { Pipe, PipeTransform } from '@angular/core';

type Modifier = 'ZONING';
@Pipe({ name: 'snakeCaseToSentence' })
export class SnakeCaseToSentencePipe implements PipeTransform {
    transform(value: unknown, modifier?: Modifier): string | unknown {
        if (!value || typeof value !== 'string' || value.length === 0 || value.trim().length === 0) {
            return value;
        }

        const words = value.split(' ') || [];

        if (modifier === 'ZONING') {
            const sentence = this.transformSpecialWords(words);

            if (sentence) {
                return sentence;
            }
        }


        return value.split('_').map((val, index) => {
            if (index === 0) {
                return (val[0]?.toUpperCase() + val.substring(1)).trim();
            }

            return val.trim();
        }).join(' ').trim();
    }

    private transformSpecialWords(words: string[]): string | unknown {
        if (words[0]?.toLowerCase() === 'user' && words[1]?.toLowerCase() === 'interface') {
            return 'User Interface';
        }

        if (words.length === 1 && words[0]?.toLowerCase() === 'ui') {
            return 'UI';
        }

        return '';
    }
}
