import { Component, EventEmitter, HostBinding, Input, Output, SimpleChanges, ViewEncapsulation, OnChanges } from '@angular/core';

@Component({
    selector: 'hvac-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaginationComponent implements OnChanges {
    @Input() currentPage: number;
    @Input() globalDataHolder: string[] = [];
    @Input() totalPages: number;
    @Input() UIData: string[] = [];
    @Input() useUIData: boolean = false;
    @Input() theme: 'default' | 'branded' = 'branded';

    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    @HostBinding('class.hvac-default-theme') get isDefaultTheme() {
        return this.theme === 'default';
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentPage = changes?.currentPage?.currentValue || this.currentPage;
        const totalPages = changes?.totalPages?.currentValue || this.totalPages;

        if (currentPage && totalPages) {
            if (currentPage > totalPages) {
                this.pageChange.emit(totalPages);
            }
        }
    }

    onPageChange(event: number) {
        this.pageChange.emit(event);
    }
}
