import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';

@Component({
    selector: 'hvac-accordian-list',
    templateUrl: './accordian-list.component.html',
    styleUrls: ['./accordian-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccordianListComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    contentList: [] = [];
    title: string;
    jumpBarData: JumpBarLink;
    titleId: string;
    colourTheme:boolean;

    constructor(private readonly jumpBar: JumpbarService,
        private readonly element: ElementRef) { }

    ngOnInit() {
        this.title = this.data.Content && this.data.Content.title ? this.data.Content.title : null;
        this.titleId = this.data.Id;

        if (this.data.Content && this.data.Content.list) {
            this.contentList = this.data.Content.list.$values
                ? this.data.Content.list.$values.map((accordian: {Content:string, Id: string}) => ({
                    Content: accordian.Content,
                    id: accordian.Id
                }))
                : [{
                    Content: this.data.Content.list.Content,
                    id: this.data.Content.list.Id
                }];
            const listOfIds = this.contentList.map((accordian: {id:string}) => accordian.id);
            this.jumpBarData = {
                link: this.titleId,
                id: [...listOfIds, this.data.Id],
                container: this.element.nativeElement
            };
            this.jumpBar.registerJumpBarLink(this.jumpBarData);
        }
        this.contentList.forEach((element: {Content:{tags:string}}) => {
            if (element.Content.tags === 'color_theme:light') {
                this.colourTheme = true;
            }
        });
    }

    ngOnDestroy() {
        if (this.jumpBarData) {
            this.jumpBar.deregisterJumpBarLink(this.jumpBarData);
        }
    }
}
