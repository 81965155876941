import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Content, Document, Placeholder, Product, ViewMode } from 'common/models';
import { SystemProduct } from 'common/models/system-product';

type Resource = Product | Document | Content | Placeholder | SystemProduct;
type ResultsListTheme = 'default' | 'borderless';

@Component({
    selector: 'utc-results-list',
    templateUrl: './results-list.component.html',
    styleUrls: ['./results-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResultsListComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() results: any[] = [];
    @Input() mode: ViewMode;
    @Input() theme: ResultsListTheme = 'default';

    @HostBinding('class.hvac-results-list-borderless') get isBorderlessTheme() {
        return this.theme === 'borderless';
    }

    resourceType(resource: Resource = {
        id: '',
        resourceType: 'placeholder'
    }): string {
        return resource.resourceType;
    }
}
