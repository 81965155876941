<header
    class="hvac-header"
    [class.hvac-header-homepage]="(isHomepage$ | async)"
    [class.hvac-header-error]="(isErrorpage$ | async)"
    [class.hvac-header-search-active]="headerSearchVisible$ | async"
    [class.hvac-mobile-menu-icp]="isIcp"
    [class.hvac-mobile-menu-totaline]="isTotaline"
    (handleMobileMenu)="closeMobileMenu()">
    <a
        [routerLink]="[]"
        fragment="main-content"
        queryParamsHandling="preserve"
        [preserveFragment]="true"
        class="hvac-skip-to-main-content"
        (click)="skipToMainContent()"
        (focus)="scrollToTop()">{{'SKIP_TO_MAIN_CONTENT' | translate }}</a>
    <section class="hvac-header-content">
        <button *ngIf="menuContent.length > 0 || isIcp || isTotaline" class="hvac-mobile-menu-button hvac-mobile-menu-open" buttonType="button" (click)="openMobileMenu()" title="{{ 'OPEN_MENU' | translate }}">
            <utc-svg class="hvac-mobile-menu-icon-open" src="assets/icons/menu.svg"></utc-svg>
        </button>
        <div class="hvac-header-brand hvac-header-logo-container">
            <a routerLink="/" class="hvac-header-logo-link">
                <span class="hvac-accessible-text">{{ 'BRAND' | translate }}</span>
                <utc-svg class="hvac-header-logo" src="assets/images/hvac-partners.svg"></utc-svg>
            </a>
        </div>
    </section>
    <section class="hvac-header-dashboard" *ngIf='(isLoggedIn$ | async)'>
        <a hvacButton="primary" [href]="secureRedirectUri">
            <span>{{ 'RETURN_TO_DASHBOARD' | translate }}</span>
            <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
        </a>
    </section>
    <nav class="hvac-header-nav" [ngClass]="{'hvac-mobile-menu-active': mobileMenuVisible$ | async}">
        <div class="hvac-mobile-menu-header" [ngClass]="{'hvac-mobile-menu-icp-header': isIcp}">
            <button class="hvac-mobile-menu-button hvac-mobile-menu-close" buttonType="button" (click)="closeMobileMenu()" title="{{ 'OPEN_MENU' | translate }}">
                <utc-svg class="hvac-mobile-menu-icon-close" [ngClass]="{'hvac-mobile-menu-icp-icon-close': isIcp}" src="assets/icons/close.svg"></utc-svg>
            </button>
            <div class="right-menu-container">
                <hvac-dealer-dropdown-menu *ngIf="(isIcp || isTotaline) && !isDesktopDevice"
                    class="hvac-dealer-search-icon-icp-mobile"
                    [menuActive]="(headerDropdown$ | async) === true"
                    (openMenuEmitter)="openDealerDropdown($event)"
                    iconSrc="assets/icons/truck.svg"></hvac-dealer-dropdown-menu>
                <div *ngIf="isIcp && !(isLoggedIn$ | async)" class="hvac-mobile-login-container hvac-mobile-dealer-login-container">
                    <button hvacButton="primary" buttonType="button" *ngIf="loginEnabled" (click)="showLoginPanel()" class="hvac-primary-dealer-login-button">{{ 'DEALER_LOGIN' | translate }}</button>
                    <a hvacButton="primary" *ngIf="externalLoginEnabled" [href]="(setLoginUrl() | async)" class="reduced-login-button" target="_blank">{{ 'DEALER_LOGIN' | translate }}</a>
                </div>
                <div *ngIf="isTotaline && !(isLoggedIn$ | async)" class="hvac-mobile-login-container hvac-mobile-distributor-login-container">
                    <a href="/support/resources/mobile-apps" hvacButton="icon" class="hvac-secondary-download-button" size="small" buttonType="button">
                        <utc-svg class="hvac-header-download-icon" src="assets/icons/mobile.svg"></utc-svg>
                    </a>
                    <button hvacButton="secondary" class="hvac-primary-distributor-login-button" size="small" buttonType="button"
                        (click)="showLoginPanel()">{{ 'LOGIN' | translate }}</button>
                </div>
            </div>
        </div>
        <ng-template [ngIf]="!isIcp && !isTotaline" [ngIfElse]="icpHeader">
            <ul class="hvac-header-nav-links">
                <li *ngFor="let menuUILink of menuContent">
                    <div class="hvac-list-container">
                        <a class="hvac-nav-link-text" routerLinkActive="hvac-header-nav-link-active" routerLink="{{ menuUILink.link | contentUrl }}" (click)="closeMobileMenu()">
                            <span [innerHTML]="menuUILink.title | richText"></span>
                        </a>
                        <div class="right-arrow" routerLink="{{ menuUILink.link | contentUrl }}">
                            <utc-svg src="assets/icons/chevron-right.svg"></utc-svg>
                        </div>
                    </div>
                </li>
                <li *ngIf="(mobileMenuVisible$ | async) && (isLoggedIn$ | async)">
                    <a class="hvac-nav-link-text" routerLinkActive="hvac-header-nav-link-active" [href]="secureRedirectUri" (click)="closeMobileMenu()">
                        <span>{{ 'RETURN_TO_DASHBOARD' | translate }}</span>
                    </a>
                </li>
            </ul>
        </ng-template>
        <ng-template #icpHeader>
            <div class='hvac-header-mobile-back-button' *ngIf="backButtonCurrentMenu$">
                <span class='hvac-header-mobile-back-text'>{{(backButtonCurrentMenu$)?.title}}</span>
                <button class="hvac-header-mobile-menu-back" buttonType="button" (click)="handleMobileNavigateBack()" title="{{ 'NAVIGATE_BACK' | translate }}">
                    <utc-svg class="hvac-header-mobile-menu-icon-back" src="assets/icons/chevron-down.svg"></utc-svg>
                </button>
            </div>
        </ng-template>
    </nav>
    <div *ngIf="!isIcp && !isTotaline" class="hvac-header-search">
        <div class="hvac-input-action-wrap">
            <button hvacButton="icon" (click)="displaySearch()" buttonId="open-search">
                <utc-svg src="assets/icons/search.svg"></utc-svg>
            </button>
        </div>
        <form class="hvac-header-search-form" (submit)="onSubmit()">
            <utc-search-input
                [formControl]="queryControl"
                name="q"
                inputId="hvac-header-search"
                autocomplete="off"
                label="{{ 'SEARCH' | translate }}"
                [title]="'SEARCH' | translate"
                [placeholder]="navContent?.searchBoxPlaceholderText"
                type="text"
                [hideLabel]="true"
                theme="light"
                [enableClearButton]="true"
                [enableAutoSuggestions]="queryControl.valid"
                [headerSearchInput]="true"
                (closeSearch)="hideSearch()">
            </utc-search-input>
            <button [disabled]="!queryControl.valid" type="submit" class="hvac-invisible-submit" tabindex="-1">
                <span class="hvac-accessible-text">{{'SEARCH' | translate }}</span>
            </button>
        </form>
    </div>
    <hvac-dealer-dropdown-menu *ngIf="(isIcp || isTotaline) && isDesktopDevice"
        [menuActive]="(headerDropdown$ | async) === true" (openMenuEmitter)="openDealerDropdown($event)"
        iconSrc="assets/icons/truck.svg"></hvac-dealer-dropdown-menu>
    <section *ngIf="loginEnabled; else externalLogin" class="hvac-header-login">
        <a href="/support/resources/mobile-apps" hvacButton="secondary" class="hvac-secondary-download-button" *ngIf="isTotaline" size="small" buttonType="button">
            <span class="hvac-header-action-button-text">{{ 'DOWNLOAD_MOBILE_APPS' | translate }}</span>
            <utc-svg class="hvac-header-download-icon" src="assets/icons/mobile.svg"></utc-svg>
        </a>
        <section *ngIf="isIcp && !(isLoggedIn$ | async)" class="hvac-dealer-login">
            <a hvacButton="primary" (click)="showLoginPanel()" class="hvac-primary-dealer-login-button">{{ 'DEALER_LOGIN' | translate }}</a>
        </section>
        <section *ngIf="isTotaline && !(isLoggedIn$ | async)" class="hvac-distributor-login">
            <button hvacButton="secondary" class="hvac-primary-distributor-login-button" size="small" buttonType="button" (click)="showLoginPanel()">{{ 'LOGIN' | translate }}</button>
        </section>
        <section *ngIf="loginEnabled && (!isIcp && !isTotaline); else externalLogin" class="hvac-header-login">
            <button hvacButton="primary" *ngIf='!(isLoggedIn$ | async)' (click)="showLoginPanel()">{{ 'LOGIN' | translate }}</button>
        </section>
        <button hvacButton="primary" *ngIf='(isLoggedIn$ | async) && !isTotaline' (click)="logout()">{{ 'LOGOUT' | translate }}</button>
    </section>
    <ng-template #externalLogin>
        <section *ngIf="externalLoginEnabled" class="hvac-dealer-login">
            <a hvacButton="primary" [href]="(setLoginUrl() | async)" class="hvac-primary-dealer-login-button"
                target="_blank">{{ 'DEALER_LOGIN' | translate }}</a>
        </section>
    </ng-template>
</header>
