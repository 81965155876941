import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hvac-radio-button',
    templateUrl: './hvac-radio-button.component.html',
    styleUrls: ['./hvac-radio-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HvacRadioButtonComponent {
    @Input() checked: boolean = false;
    @Input() disabled ? = false;
    @Input() inputId = '';
    @Input() inputName = '';
}
