/* eslint-disable default-param-last */
import { Injectable } from '@angular/core';
import { MediaGallery, Product, ProductMedia } from 'common/models';

@Injectable({ providedIn: 'root' })
export class MediaService {
    getMediaImages(gallery: MediaGallery = {}, dimensions: string): ProductMedia[] {
        if (gallery[dimensions]) {
            return gallery[dimensions];
        }

        return [];
    }

    getDefaultImage(gallery: MediaGallery = {}, dimensions: string): ProductMedia|null {
        const images = this.getMediaImages(gallery, dimensions);

        if (images.length) {
            return images[0];
        }

        return null;
    }

    getDefaultCategoryIcon(product: Product): string {
        const defaultCategory = product && product.categories && product.categories[0].split('/')[0].toLowerCase();

        return defaultCategory ? `assets/icons/${defaultCategory}.svg` : 'assets/icons/default.svg';
    }
}
