import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'FilterPipe' })
export class FilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, keys: string[], args?: any): any {
        if (!value) {
            return null;
        }
        if (!args) {
            return value;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return value.filter((item: any) => keys.find((key: any) => (item[key] && item[key].toString().toLowerCase().includes(args.toLowerCase()))));
    }
}
