import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { AppConstants } from 'common/app-constants';
import { DataResolverService } from 'common/content/services/data-resolver.service';
import { ErrorService } from 'common/content/services/error.service';
import { JumpbarService } from 'common/content/services/jump-bar.service';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ContentHeadingBreadCrumbs, ContentHeadingData, TagsContentData } from '../../../models/content-heading';

@Component({
    selector: 'hvac-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContentHeaderComponent implements OnInit {
    @Select(NavigationState.currentPage) currentPage$: Observable<NavMenu>;

    @Input() data: ContentHeadingData;
    @Input() colorTheme: boolean;
    @Input() isLandingPage: boolean = false;
    @Input() rightSideContent: boolean;
    @Input() hasProBadge: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    breadCrumbList$: Observable<any>;
    breadCrumbsToShow: ContentHeadingBreadCrumbs[];
    emptyJumpbar$: Observable<Boolean>;
    ngOnDestroy$ = new Subject();
    title$: Observable<string>;
    titleToShow: string;
    subtitle: string | undefined;
    titleTimer: NodeJS.Timeout;

    constructor(
        public dataResolverService: DataResolverService,
        public errorService: ErrorService,
        private readonly jumpbarService: JumpbarService,
        private readonly title: Title

    ) { }

    ngOnInit() {
        this.emptyJumpbar$ = this.jumpbarService.jumpBarIsEmpty$;
        this.breadCrumbList$ = this.currentPage$.pipe(takeUntil(this.ngOnDestroy$), map((navMenu: NavMenu) => {
            if (!navMenu) {
                return [];
            }
            const crumbList = [{
                title: navMenu.title,
                url: navMenu.url
            }];
            let parent = navMenu.parent;
            while (parent) {
                crumbList.push(
                    {
                        title: parent.title === '' ? 'My Dashboard' : parent.title,
                        url: parent.url
                    }
                );
                // eslint-disable-next-line no-undefined
                parent = parent.parent ? parent.parent : undefined;
            }
            crumbList.shift();
            crumbList.pop();

            return crumbList.reverse();
        }));

        this.title$ = this.currentPage$.pipe(takeUntil(this.ngOnDestroy$), map((navMenu: NavMenu) => {
            if (!navMenu || !navMenu.title) {
                return '';
            }

            return navMenu.title;
        }));

        this.title$.subscribe((navTitle) => {
            this.titleToShow = this.data && this.data.Content && this.data.Content.title ? this.data.Content.title : navTitle;
        });

        this.subtitle = this.data?.Content?.subTitle;

        this.breadCrumbList$.subscribe((breadCrumbs) => {
            this.breadCrumbsToShow = this.data?.breadCrumbs || breadCrumbs;
        });

        if (this.data?.Content?.tags) {
            const tags = this.parseContentTags(this.data.Content?.tags);
            if (Object.prototype.hasOwnProperty.call(tags, 'breadcrumb')) {
                tags.breadcrumb.split('||').forEach((crumb: string) => {
                    const crumbData = crumb.split('|');
                    this.breadCrumbsToShow.push(
                        {
                            title: crumbData[0].trim(),
                            url: crumbData[1].trim()
                        }
                    );
                });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!(changes.data?.firstChange)) {
            const { Content, breadCrumbs } = changes.data?.currentValue || {};

            if (Content?.title) {
                this.titleToShow = Content.title;
            }

            if (breadCrumbs) {
                this.breadCrumbsToShow = breadCrumbs;
            }
            if (Content?.pageTitle) {
                this.titleTimer = setTimeout(() => {
                    this.title.setTitle(Content.pageTitle as string);
                }, AppConstants.timeOutNavigation);
            }
        }
    }

    ngOnDestroy() {
        if (this.titleTimer) {
            clearTimeout(this.titleTimer);
        }
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private parseContentTags(include: string | TagsContentData) {
        const attributes: { [key: string]: string } = {};
        if ((include as TagsContentData).$values) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (include as TagsContentData).$values.forEach((attribute: any) => {
                const attributePair = attribute.Fragments[0].split(':');
                attributes[attributePair[0]] = attributePair[1];
            });
        }
        else {
            const attributePair = (include as string).split(':');
            attributes[attributePair[0]] = attributePair[1];
        }

        return attributes;
    }
}
