<a *ngIf="refType === 'href'; else routerLink"
    [class]="typeClass"
    [href]="href"
    [attr.rel]="link.linkOperation.Key === '_blank' ? 'noopener noreferrer' : null"
    [target]="link.linkOperation.Key"><ng-container *ngTemplateOutlet="content"></ng-container></a>
<ng-template #routerLink>
    <a [class]="typeClass"
        [routerLink]="href"><ng-container *ngTemplateOutlet="content"></ng-container></a>
</ng-template>
<ng-template #content><ng-content></ng-content></ng-template>
