import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-image-fallback',
    templateUrl: './image-fallback.component.html',
    styleUrls: ['./image-fallback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageFallbackComponent {
    @Input() fallbackImageSrc = '';
    @Input() suppliedImageSrc = '';
    @Input() classNames = {};
    @Input() suppliedImageAlt: string | undefined = '';
    @Input() size = '';

    public isImageLinkBroken = false;

    checkImageStatus(event: Event) {
        this.isImageLinkBroken = this.suppliedImageSrc === '' || event.type === 'error';

        return;
    }
}
