import { DocumentFieldName, DocumentQuery } from 'common/models/document';
import { DataTableSort, DataTableSortDirection } from 'common/models/data-table';
import { QueryBase, QueryInterface } from './query-base';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicQuery extends QueryBase implements QueryInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generateQuery(data: any): DocumentQuery {
        return {
            count: Number(data.Content.maxItems),
            country: this.parseKeywordModelDetail(data.Content.country),
            status: this.parseRichTextData(data.Content.status),
            ...this.parseSearchFacets(data.Content.searchFacets),
            ...this.parseDocumentType(data.Content.documentType)
        } as DocumentQuery;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sort(data: any): DataTableSort {
        if (data.Content.sortOrder && data.Content.sortOrder.$values) {
            return {
                sortFieldName: data.Content.sortOrder.$values[0].fieldName.Key,
                sortDirection: data.Content.sortOrder.$values[0].sortDirection === 'Ascending' ?
                    DataTableSortDirection.ASCENDING
                    : DataTableSortDirection.DESCENDING
            } as DataTableSort;
        }
        if (data.Content.sortOrder && data.Content.sortOrder.fieldName) {
            return {
                sortFieldName: data.Content.sortOrder.fieldName.Key,
                sortDirection: data.Content.sortOrder.sortDirection === 'Ascending' ?
                    DataTableSortDirection.ASCENDING
                    : DataTableSortDirection.DESCENDING
            } as DataTableSort;
        }

        return {
            sortFieldName: DocumentFieldName.PRINT_DATE,
            sortDirection: DataTableSortDirection.DESCENDING
        } as DataTableSort;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterBrands(data: any, brands: string[]): string[] {
        if (data.Content.brands && data.Content.brands.$values) {
            const filteredBrands = data.Content.brands.$values.map((brand: {Title:string}) => brand.Title);

            return brands.filter((brand: string) => filteredBrands.includes(brand));
        }
        if (data.Content.brands && data.Content.brands.Title) {
            return brands.filter((brand: string) => brand === data.Content.brands.Title);
        }

        return brands;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseKeywordModelDetail(data: any): string[] {
        if (data && data.$values) {
            return data.$values.map((value: {Title:string}) => value.Title);
        }
        if (data && data.Title) {
            return [data.Title];
        }

        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseRichTextData(data: any): string[] {
        if (data && data.$values) {
            return data.$values.map((value: {Fragments:string}) => value.Fragments);
        }
        if (data && data.length > 0) {
            return [data];
        }

        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseSearchFacets(data: any): any {
        if (data && data.$values) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return Object.assign({}, ...data.$values.map((item: any) => ({ [item.name.Key]: item.value.split(',') })));
        }
        if (data && data.name && data.value) {
            return { [data.name.Key]: data.value.split(',') };
        }

        return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseDocumentType(data: any): any {
        if (data && data.$values) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const type = data.$values.map((inputType: any) => inputType.specificType.Title);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const subType = data.$values.map((inputSubtype: {subTypes:{Title: string, $values:any}}) => {
                if (inputSubtype.subTypes && inputSubtype.subTypes.$values) {
                    return inputSubtype.subTypes.$values.map((subtype: {Title:string}) => subtype.Title);
                }
                if (inputSubtype.subTypes) {
                    return inputSubtype.subTypes.Title;
                }

                return null;
            }).flat().filter((item: string) => item);

            return {
                type: type,
                subtype: subType
            };
        }
        if (data && data.specificType && data.subTypes && data.subTypes.$values) {
            return {
                type: data.specificType.Title.split(','),
                subtype: data.subTypes.$values.map((subtype: {Title:string}) => subtype.Title)
            };
        }
        if (data && data.specificType && data.subTypes) {
            return {
                type: data.specificType.Title.split(','),
                subtype: data.subTypes.Title.split(',')
            };
        }
        if (data && data.specificType) {
            return { type: data.specificType.Title.split(',') };
        }

        return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageSize(data: any): number {
        return data.Content.itemsPerPage;
    }
}
