import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { SvgLoaderService } from './svg-loader.service';

@Pipe({ name: 'loadSvg' })
export class LoadSvgPipe implements PipeTransform {
    constructor(
        private readonly svgLoaderService: SvgLoaderService,
        private readonly domSanitizer: DomSanitizer
    ) {}

    transform(svgUrl: string) {
        return this.svgLoaderService
            .loadSvg(svgUrl)
            .pipe(
                map((resp) => this.domSanitizer.bypassSecurityTrustHtml(resp))
            );
    }
}
