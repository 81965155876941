import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textFallback' })
export class TextFallbackPipe implements PipeTransform {
    transform(value?: string, fallbackText?: string): string {
        if (!value) {
            return fallbackText ? fallbackText : '-';
        }

        return value;
    }
}
