
<div [attr.id]="titleId" class='hvac-accordian' [ngClass]="{'hvac-accordian-open': accordianOpen}">
    <button [ngClass]="{'hvac-custom-accordian-trigger': colourTheme === true }" class='hvac-accordian-trigger' (click)="toggleAccordian()">
        <span [ngClass]="{'hvac-custom-accordian-trigger-title': colourTheme === true }" class='hvac-accordian-trigger-title'>{{title}}</span>
        <img class='hvac-accordian-list-image' *ngIf="listImage" [src]="listImage" [alt]="title + ' tiny thumbnail'" />
        <utc-svg *ngIf="!colourTheme; else arrowIcon" class="hvac-accordian-trigger-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
        <ng-template #arrowIcon>
            <utc-svg class="hvac-accordian-down-icon" src="assets/icons/arrow.svg"></utc-svg>
        </ng-template>
    </button>
    <div class='hvac-accordian-body'>
        <hvac-rich-text *ngIf="body" [fragments]="body.Fragments"></hvac-rich-text>
        <ul *ngIf="linkList" class="hvac-accordian-list">
            <li class="hvac-accordian-list-item" *ngFor="let link of linkList" [ngClass]="{'hvac-accordian-list-item-header': link.isHeader, 'hvac-accordian-list-link-item': !link.isHeader }" >
                <hvac-link *ngIf="!link.isHeader; else title"
                class='hvac-accordian-list-item-link'
                [link]="link.link" [linkType]="link.linkType">
                    <div *ngIf="!colourTheme" class="hvac-accordian-list-item-icon">
                        <img *ngIf="link.icon && !link.image" class='hvac-accordian-list-item-icon' [src]="link.icon" alt="image icon" />

                        <img class='hvac-accordian-list-item-image' *ngIf="link.image" [src]="link.image" [alt]="link.title + ' thumbnail'" />
                    </div>
                    <div class="hvac-accordian-list-item-details">
                        <p [ngClass]="{'hvac-custom-accordian-list-item-title': colourTheme === true }" class="hvac-accordian-list-item-title" [innerHTML]="link.title | richText"></p>
                        <p *ngIf="link.subtitle" class="hvac-accordian-list-item-subtitle" [innerHTML]="link.subtitle | richText"></p>

                    </div>
                </hvac-link>
                <ng-template #title>
                    <h3 [innerHTML]="link.title | richText"></h3>
                </ng-template>
            </li>
        </ul>
    </div>
</div>
