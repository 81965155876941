import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-base-layout',
    templateUrl: './layout-base.component.html',
    styleUrls: ['./layout-base.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaseLayoutComponent {
}
