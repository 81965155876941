import { EnvConfig, PublicConfig, PublicEnvironment } from '../models/environment.public';

export const localEnv: EnvConfig = {
    envPath: '/dev',
    env: 'local',
    features: {
        auth: {
            login: true,
            externalLogin: false
        },
        brandFilter: {
            brand: false,
            content: false
        },
        covid19Information: false,
        goSiteRegistration: true,
        newsLetterSignUp: true,
        regulatoryQuestionnaire: true,
        product: {
            worksWellWith: false,
            options: false
        },
        searchContent: true,
        signInTeaser: false,
        socialLogin: true,
        suggestions: true,
        partsCatalog: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    iframePath: 'https://dev-legacy2.hvacpartners.com',
    privateBaseUrl: 'http://localhost:5050',
    production: false,
    auth: {
        baseUrl: 'https://devextcarrier.oktapreview.com',
        clientId: '0oas5xhe12yGhPHeV0h7',
        identityProviders: [{
            type: 'GOOGLE',
            id: '0oa1n6sz328RUncj30h8'
        }, {
            type: 'APPLE',
            id: '0oa1n6u9b3eKAE3SL0h8'
        }]
    }
};

export const devEnv: EnvConfig = {
    envPath: '/dev',
    env: 'dev',
    features: {
        auth: {
            login: true,
            externalLogin: false
        },
        brandFilter: {
            brand: false,
            content: false
        },
        covid19Information: false,
        goSiteRegistration: true,
        newsLetterSignUp: true,
        regulatoryQuestionnaire: true,
        product: {
            worksWellWith: false,
            options: false
        },
        searchContent: true,
        signInTeaser: false,
        socialLogin: true,
        suggestions: true,
        partsCatalog: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    iframePath: 'https://dev-legacy2.hvacpartners.com',
    privateBaseUrl: 'https://dev.hvacpartners.com',
    production: false,
    auth: {
        baseUrl: 'https://devextcarrier.oktapreview.com',
        clientId: '0oas5xhe12yGhPHeV0h7',
        identityProviders: [{
            type: 'GOOGLE',
            id: '0oa1n6sz328RUncj30h8'
        }, {
            type: 'APPLE',
            id: '0oa1n6u9b3eKAE3SL0h8'
        }]
    }
};

export const stagingEnv: EnvConfig = {
    envPath: '',
    env: 'stage',
    features: {
        auth: {
            login: false,
            externalLogin: true
        },
        brandFilter: {
            brand: false,
            content: false
        },
        covid19Information: false,
        goSiteRegistration: false,
        newsLetterSignUp: true,
        regulatoryQuestionnaire: true,
        product: {
            worksWellWith: false,
            options: false
        },
        searchContent: true,
        signInTeaser: false,
        socialLogin: false,
        suggestions: true,
        partsCatalog: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    iframePath: 'https://staging-legacy.hvacpartners.com',
    privateBaseUrl: 'https://staging.hvacpartners.com',
    production: false,
    auth: {
        baseUrl: 'https://qaextcarrier.oktapreview.com',
        clientId: '0oasgz76pjL9rCB7E0h7',
        identityProviders: [{
            type: 'GOOGLE',
            id: '0oa1nkyxwcnH0oTHV0h8'
        }, {
            type: 'APPLE',
            id: '0oa1nkz6sqoJPZ7v80h8'
        }]
    }
};

export const futureEnv: EnvConfig = {
    envPath: '/dev',
    env: 'dev',
    features: {
        auth: {
            login: true,
            externalLogin: false
        },
        brandFilter: {
            brand: false,
            content: false
        },
        covid19Information: false,
        goSiteRegistration: false,
        newsLetterSignUp: true,
        regulatoryQuestionnaire: true,
        product: {
            worksWellWith: false,
            options: false
        },
        searchContent: true,
        signInTeaser: false,
        socialLogin: false,
        suggestions: true,
        partsCatalog: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    iframePath: 'https://dev-legacy2.hvacpartners.com',
    privateBaseUrl: 'https://dev4.hvacpartners.com',
    production: false,
    auth: {
        baseUrl: 'https://devextcarrier.oktapreview.com',
        clientId: '0oas5xhe12yGhPHeV0h7'
    }
};

export const prodEnv: EnvConfig = {
    envPath: '',
    env: 'prod',
    features: {
        auth: {
            login: false,
            externalLogin: true
        },
        brandFilter: {
            brand: false,
            content: false
        },
        covid19Information: false,
        goSiteRegistration: false,
        newsLetterSignUp: true,
        regulatoryQuestionnaire: true,
        product: {
            worksWellWith: false,
            options: false
        },
        searchContent: true,
        signInTeaser: false,
        socialLogin: false,
        suggestions: true,
        partsCatalog: true
    },
    gatewayPath: 'https://servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    iframePath: 'https://legacy.hvacpartners.com',
    privateBaseUrl: 'https://hvacpartners.com',
    production: true,
    auth: {
        baseUrl: 'https://sso.digital.carrier.com',
        clientId: '0oalsgs99gectsifj5d5',
        identityProviders: [{
            type: 'GOOGLE',
            id: '0oa9u35d72oRzNPng5d7'
        }, {
            type: 'APPLE',
            id: '0oa9u37jdtH9467NK5d7'
        }]
    }
};

export const commonEnvVars = (environment: PublicConfig): PublicEnvironment => ({
    appId: environment.appId,
    env: environment.env,
    appziSrc: environment.appziSrc,
    brand: environment.brand,
    loginErrorUrl: `${environment.privateBaseUrl}/login-error`,
    logoutFrameUrl: `${environment.iframePath}/Pages/devpages/Logout.aspx`,
    logoutUrl: `${environment.privateBaseUrl}/sign-out?redirect=${environment.publicBaseUrl}/logout`,
    production: environment.production,
    secureBaseUrl: environment.privateBaseUrl,
    secureCallbackUrl: `${environment.privateBaseUrl}/secure-callback?brandref=${environment.brand}`,
    api: {
        accountStatusApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/v1/status`,
        accountUserApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/`,
        accountUserPrefApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/settings`,
        catalog: `${environment.gatewayPath}/public/${environment.brandPath}/catalog`,
        content: `${environment.gatewayPath}/public/${environment.brandPath}/content/pages${environment.envPath}`,
        contact: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/contactus`,
        legacyLogin: 'https://toddsit.com/gocredentials/api/login',
        companyId: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/company-id`,
        distributorLocator: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/search/`,
        document: `${environment.gatewayPath}/public/${environment.brandPath}/documents${environment.envPath}`,
        documentPublicParts: `${environment.gatewayPath}/public/totaline/documents${environment.envPath}`,
        lastLoginApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/lastlogin`,
        search: `${environment.gatewayPath}/public/${environment.brandPath}/search${environment.envPath}`,
        siteMap: `${environment.gatewayPath}/public/hvac/content/sitemap${environment.envPath}/${environment.siteCode}`,
        suggestions: `${environment.gatewayPath}/public/${environment.brandPath}/suggestions${environment.envPath}`,
        jpMorganApi: `${environment.jpMorganPath}.hostedpaymentservice.net/hpf/?uid=`,
        totalineContent: `${environment.gatewayPath}/public/totaline/content/pages${environment.envPath}`,
        payment: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/payment/init`,
        paymentConfirmation: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/payment/transaction?uid=`,
        pim: {
            suggestions: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products/suggestion`,
            partDetail: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products`,
            search: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products/search`,
            catalog: `${environment.gatewayPath}/public/apps/hvac/pim/rc`
        }
    },
    auth: {
        baseUrl: environment.auth.baseUrl,
        clientId: environment.auth.clientId,
        createAccountRegisterUrl: `${environment.privateBaseUrl}/create-account-information`,
        createAccountUrl: `${environment.privateBaseUrl}/login`,
        postLogoutRedirectUri: `${environment.publicBaseUrl}/logout`,
        redirectUri: `${environment.publicBaseUrl}/callback`,
        identityProviders: environment.auth.identityProviders
    },
    features: environment.features,
    socialLogin: { idpDisplay: 'SECONDARY' }
});
