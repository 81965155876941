import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export type FeaturedMediaType = 'image'|'video'|'embed';

export interface FeaturedMedia {
    src: string;
    type: FeaturedMediaType;
}

export interface FeaturedItem {
    title: string;
    description: string;
    ctaText?: string;
    ctaUrl: SafeUrl | '';
    cta: {
        text: string;
        url: string;
    }
    imageUrl?: SafeUrl | '';
    external: boolean;
    media: FeaturedMedia;
    additionalLinks?: {
        link: SafeUrl | '';
        image: string;
        alt: string;
    }[];
}

export interface FeaturedContent {
    title: string;
    description?: string;
    items: [FeaturedItem, FeaturedItem];
    sectionLink?: string;
    sectionLinkUrl?: string;
    sectionSingleCTA: {
        url: string;
        text: string;
    }
}

@Component({
    selector: 'utc-featured-content',
    templateUrl: './featured-content.component.html',
    styleUrls: ['./featured-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FeaturedContentComponent {
    @Input() featuredContent!: FeaturedContent;
}
