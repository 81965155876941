import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'militaryToStandard' })
export class MilitaryToStandard implements PipeTransform {
    transform(value: string): string {
        const time = value.split(':');
        const hours = Number(time[0]);
        const minutes = Number(time[1]);

        let timeValue: string = '';

        if (hours > 0 && hours <= 12) {
            timeValue = `${hours}`;
        }
        else if (hours > 12) {
            timeValue = `${hours - 12}`;
        }
        else if (hours === 0) {
            timeValue = '12';
        }

        timeValue += (minutes < 10) ? `:0${minutes}` : `:${minutes}`;
        timeValue += (hours >= 12) ? ' PM' : ' AM';

        return timeValue;
    }
}
