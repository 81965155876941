import { Component, ViewEncapsulation } from '@angular/core';
import { IndexCardListComponent } from '../index-card-list.component';

@Component({
    selector: 'hvac-index-icon-card-list',
    templateUrl: './index-icon-card-list.component.html',
    styleUrls: ['../index-card-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IndexIconCardListComponent extends IndexCardListComponent { }
