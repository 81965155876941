import { Component, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { Observable } from 'rxjs';


@Component({
    selector: 'hvac-nav-landing',
    templateUrl: './nav-landing.component.html',
    styleUrls: ['./nav-landing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavLandingComponent {
    @Select(NavigationState.currentPage) navItem$!: Observable<NavMenu>;
}
