import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hvac-content-suggestion',
    templateUrl: './content-suggestion.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContentSuggestionComponent {
    @Input() title: string ='';
    @Input() routeUrl: string = '';

    ngOnInit() {
        if (!this.routeUrl) {
            this.routeUrl = '';
        }
    }

    isExternalURL() {
        if (this.routeUrl.indexOf('http') === 0) {
            return true;
        }

        return false;
    }
}

