import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MarketingAsset } from 'public/app/services/marketing-product.service';

@Component({
    selector: 'hvac-public-link',
    templateUrl: './public-link.component.html',
    styleUrls: ['./public-link.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PublicLinkComponent {
    @Input() resource: MarketingAsset;
}
