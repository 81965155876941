<div class='hvac-password-input-container'>
    <utc-input
        [error]="passwordControl.dirty && !passwordControl.valid"
        [errorMessage]="errorMessage"
        [infoMessage]="infoMessage"
        [inputId]="inputId"
        [inputControl]="passwordControl"
        [label]="label"
        [type]="type"
        class='hvac-password-input'
        [labelIcon]="passwordIcon"
        iconPosition="right"
        iconLabel="{{ 'INPUT.PASSWORD.SHOW' | translate }}"
        [hasIconFunction]="true"
        (iconClickFunction)="togglePasswordVisibility()"
        (blurFunction)="trimPasswordValue($event)"
        [disabledInput]="disabled">
    </utc-input>

    <div class='hvac-password-input-requirements-container'>
        <div *ngFor="let requirement of requirements" class='hvac-password-input-requirements-row'>
            <div class='hvac-password-input-requirement-icon'  [ngClass]="{'hvac-password-input-requirement-icon-valid':requirement.valid,'hvac-password-input-requirement-icon-invalid':!requirement.valid}">
                <utc-svg *ngIf="requirement.valid" src="assets/icons/requirement-check.svg"></utc-svg>
                <utc-svg *ngIf="!requirement.valid" src="assets/icons/requirement-minus.svg"></utc-svg>
            </div>
            <p class='hvac-password-input-requirement-message'>{{requirement.message}}</p>
        </div>
    </div>
</div>
