import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AcceptGdpr } from './gdpr.actions';

export interface GdprStateModel {
    accepted: boolean;
}

@State<GdprStateModel>({
    name: 'gdpr',
    defaults: { accepted: false }
})
@Injectable({ providedIn: 'root' })
export class GdprState {
    @Selector()
    static gpdrAccpted(state: GdprStateModel): boolean {
        return state.accepted;
    }

    @Action(AcceptGdpr)
    acceptGdpr(ctx: StateContext<GdprStateModel>) {
        return ctx.patchState({ accepted: true });
    }
}
