import { ContentChild, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { SvgComponent } from '../svg/svg.component';

export type ButtonTheme = 'default' | 'primary' | 'secondary' | 'tertiary' | 'text' | 'text-alt' | 'icon' | 'warning';

@Directive({ selector: '[hvacButton]' })
export class ButtonDirective {
    @Input() hvacButton: ButtonTheme = 'default';
    @Input() width: 'fit' | 'full' = 'fit';
    @Input() size: 'default' | 'small' = 'default';
    @Input() hvacButtonDark: undefined | unknown;

    @ContentChild(SvgComponent, { read: ElementRef }) readonly hasSvg: boolean;

    @HostBinding('class.hvac-button') get isDefault() {
        return !this.hvacButton || this.hvacButton === 'default';
    }

    @HostBinding('class.hvac-button-primary') get isPrimary() {
        return this.hvacButton === 'primary';
    }

    @HostBinding('class.hvac-button-secondary') get isSecondary() {
        return this.hvacButton === 'secondary';
    }

    @HostBinding('class.hvac-button-tertiary') get isTertiary() {
        return this.hvacButton === 'tertiary';
    }

    @HostBinding('class.hvac-button-warning') get isWarning() {
        return this.hvacButton === 'warning';
    }

    @HostBinding('class.hvac-button-icon') get isIconOnly() {
        return this.hvacButton === 'icon';
    }

    @HostBinding('class.hvac-button-small') get getSize() {
        return this.size === 'small';
    }

    @HostBinding('class.hvac-button-text') get isText() {
        return this.hvacButton === 'text' || this.hvacButton === 'text-alt';
    }

    @HostBinding('class.hvac-button-text-alt') get isTextAlt() {
        return this.hvacButton === 'text-alt';
    }

    @HostBinding('class.hvac-button-full') get isFullWidth() {
        return this.width === 'full';
    }

    @HostBinding('class.hvac-button-has-icon') get hasIcon() {
        return Boolean(this.hasSvg);
    }
}
