import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { PaymentDetails, PaymentService } from 'public/app/services/payments.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'common/environments/environment';
import { DatePipe } from '@angular/common';
import { AppConstants } from 'common/app-constants';

@Component({
    selector: 'utc-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PaymentsComponent {
    @ViewChild('paymentFormFrame') paymentFormFrame: HTMLIFrameElement;
    public iframeSrc: SafeResourceUrl = '';
    public uid: string = '';
    public paymenModalVisible: boolean;
    public loading: boolean;
    public isSubmitted: boolean;
    public paymentDetails: PaymentDetails
    public transactionDate: string;

    paymentForm: FormGroup = new FormGroup({
        amount: new FormControl('', Validators.required),
        invoiceNumber: new FormControl('', Validators.required)
    })

    constructor(
        private readonly paymentService: PaymentService,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe
    ) {}

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent) {
        if ('jpMorganApi' in environment.api) {
            const { origin } = new URL(environment.api.jpMorganApi as string);

            if (event.origin !== origin) {
                return;
            }
            const messageSubject = event.data.subject;
            if (messageSubject === 'startPayment') {
                this.loading = true;
            }

            else if (messageSubject === 'completePayment') {
                this.loading = false;
                this.paymentService.getPaymentDetails(this.uid).subscribe((res) => {
                    this.isSubmitted = true;
                    this.paymentDetails = res;
                    this.transactionDate = this.datePipe.transform(new Date(), AppConstants.dateTimeFormatShort2)!;
                });
            }

            else if (messageSubject === 'handlePaymentErrors') {
                this.loading = false;
            }
        }
    }

    launchModal(event: MouseEvent) {
        // this is an anchor so the text element can be displayed inline with the paragraph
        event.preventDefault();
        this.paymenModalVisible = true;
    }

    closeModal() {
        this.paymenModalVisible = false;
    }

    getForm() {
        const { amount, invoiceNumber } = this.paymentForm.value;

        this.paymentService.getUid(amount, invoiceNumber).subscribe((response: {details: string}) => {
            this.uid = response.details;
            this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.api.jpMorganApi}${response.details}`);
        });
    }
}
