<section *ngIf="visible" class="hvac-modal hvac-modal-{{size}}" [ngClass]="{'hvac-modal-visible': visible}" aria-role="dialog" #modal>
    <div tabindex="-1" #modalBody class="hvac-modal-wrapper">
        <header class="hvac-modal-header" *ngIf="showHeader">
            <ng-content select="hvac-modal-header"></ng-content>

            <button (click)="closeDialog()" class="hvac-modal-close">
                <utc-svg class="hvac-modal-close-icon" src="assets/icons/close.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'CLOSE' | translate }}</span>
            </button>
        </header>
        <div class="hvac-modal-content" [ngClass]="{ 'hvac-modal-content-no-header' : !showHeader }">
            <button *ngIf="!showHeader"  (click)="closeDialog()" class="hvac-modal-close">
                <utc-svg class="hvac-modal-close-icon" src="assets/icons/close.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'CLOSE' | translate }}</span>
            </button>
            <ng-content></ng-content>
        </div>
    </div>
</section>

<div *ngIf="visible" (click)="closeDialog()" class="hvac-modal-overlay"></div>
