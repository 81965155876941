import { Injectable, Inject } from '@angular/core';
import { WINDOW, AppWindow } from 'common/window.provider';

@Injectable({ providedIn: 'root' })
export class TrackerService {
    constructor(@Inject(WINDOW) private readonly window: AppWindow) {}

    capturePageview(path: string, data = {}) {
        this.window.dataLayer.push({
            ...data,
            ...{
                pagePath: `${window.location.protocol}//${window.location.host}${path}`,
                event: 'Pageview'
            }
        });
    }

    captureEvent(event: string, data = {}) {
        this.window.dataLayer.push({
            ...data,
            event
        });
    }
}
