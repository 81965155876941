import { Directive, HostBinding, Input } from '@angular/core';

export type CardBehavior = 'interactive' | 'static' | '';

@Directive({ selector: '[hvacCard]' })
export class CardDirective {
    @Input() hvacCard: CardBehavior = 'interactive';

    @HostBinding('class.hvac-card') readonly _card = true;
    @HostBinding('class.hvac-card-interactive') get isInteractive() {
        return this.hvacCard === 'interactive' || this.hvacCard === '';
    }
}
