<div class="hvac-radio-button-wrapper">
    <label class="hvac-radio-button-label" [attr.for]="dataId" [ngClass]="{ 'hvac-radio-focus': isFocused }">
        <input
            class="hvac-radio-button"
            type="radio"
            [attr.name]="name"
            [attr.id]="dataId"
            [attr.disabled]="disabled"
            [attr.value]="value"
            (change)="checked = !checked;"
            (focus)="radioFocus()"
            (blur)="radioBlur()">
        <div class="hvac-radio-button-background"></div>
        <ng-container *ngIf="iconSrc">
            <div class="hvac-radio-button-icon">
                <utc-svg [src]="iconSrc"></utc-svg>
            </div>
        </ng-container>
        <ng-container *ngIf="!iconSrc">
            <span class="hvac-radio-button-label-text">{{ label }}</span>
        </ng-container>
    </label>
</div>
