<ul class="hvac-private-navigation-panel-link-list">
    <li (mouseenter)="handleMouseEnter(child)"
        (focus)="handleMouseEnter(child)"
        class='hvac-private-navigation-panel-list-item'
        *ngFor="let child of (currentMenu$ | async)?.children; trackBy:trackByNavPanel">
        <ng-container *ngIf="child">
            <a *ngIf="child.hrefTarget === '_self'; else externalLink"
                [routerLink]="[child.url]"
                [target]="child.hrefTarget"
                (click)="closeMenus()"
                hvacButton='text'
                hvacButtonIcon
                class="hvac-private-navigation-panel-link">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>

            <ng-template #externalLink>
                <a [href]="child.url"
                    [target]="child.hrefTarget"
                    (click)="closeMenus()"
                    hvacButton='text'
                    hvacButtonIcon
                    class="hvac-private-navigation-panel-link">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>
            </ng-template>

            <ng-template #content>
                <span class="hvac-private-navigation-panel-link-title">{{child.title}}</span>
                <utc-svg class="hvac-private-navigation-panel-icon" *ngIf="child.children.length"
                    src='assets/icons/chevron-down.svg'></utc-svg>
            </ng-template>

            <button
                (click)="handleLinkClick(child)"
                class="hvac-private-navigation-panel-explore-category"
                title="{{ 'NAVIGATION_CONTAINER.PANEL.OPEN' | translate }}"
                *ngIf="child.children.length">
                <utc-svg class="hvac-private-navigation-panel-icon"
                    src='assets/icons/chevron-down.svg'></utc-svg>
            </button>
        </ng-container>
    </li>
</ul>
