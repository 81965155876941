import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildHttpParams } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { PartDetailQuery } from 'common/models';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PartsCatalogResponse } from '../modal/parts-catalog';
import { PartsDetailsResponse } from '../modal/parts-details';
import { TOTALINE_CATALOG_ROUTE, HVACP_CATALOG_ROUTE } from 'common/views/parts/modal/parts-catalog';

@Injectable({ providedIn: 'root' })
export class PartsService {
    public partsDetails$ = new BehaviorSubject<PartsDetailsResponse | null>(null)
    public activePart$ = new BehaviorSubject<string>('')
    public partDetailLoading$ = new BehaviorSubject<boolean>(false)
    public isTotaline = environment.brand === 'Totaline';

    pageSize = '10';
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {}

    public getCatalogRoute() {
        return this.isTotaline ? TOTALINE_CATALOG_ROUTE : HVACP_CATALOG_ROUTE;
    }

    public getPartsListForSubcategory(
        subCategory: string,
        searchValue: string,
        pagination: number | null
    ): Observable<PartsCatalogResponse> {
        const currentPage = ((pagination || 1) - 1).toString();
        const params = buildHttpParams({
            pageSize: this.pageSize,
            currentPage: currentPage,
            brand: environment.brand
        });
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.get<PartsCatalogResponse>(`${environment.api.pim.catalog}/products/search?q=${searchValue}:relevance:extSuperCategoryCode:${subCategory}`, {
            params,
            ...options
        })
            .pipe(catchError((err) => of(err)));
    }


    getPartDetailUrl(details: PartsDetailsResponse): PartDetailQuery {
        const partQuery = { part: details.code };

        const subCategory = details.categories && details.categories[0].code;
        /* get the parent category by capturing the first 'XX-##' */
        const categoryRegex = /(PIMRC-\d+)-\d/;

        const mainCategory = subCategory?.match(categoryRegex);

        if (mainCategory && mainCategory.length > 0) {
            partQuery['category' as keyof typeof partQuery] = mainCategory[1];
            partQuery['subcategory' as keyof typeof partQuery] = subCategory;
        }

        return { ...partQuery };
    }

    public getPartsDetails(
        part: string
    ): Observable<PartsDetailsResponse> {
        const formatPartNumber = part.replace('/', '|');
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.get<PartsDetailsResponse>(`${environment.api.pim.catalog}/products/${formatPartNumber}?fields=FULL&includeMedia=true`, { ...options })
            .pipe(catchError((err) => {
                throw err;
            }));
    }
}
