import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'richText' })
export class RichTextPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }

    transform(text: string, bypassSecurity: boolean = true) {
        const fullText = this.getText(text);

        return bypassSecurity ? this.sanitizer.bypassSecurityTrustHtml(fullText) : fullText;
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getText(text: any) {
        if (text && text.Fragments && text.Fragments.join) {
            return text.Fragments.join('');
        }

        return `${text}`;
    }
}
