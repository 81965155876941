import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';
import { DataService } from 'common/services/data.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hvac-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
    @Input() notification: BaseContentEntity | null | undefined;
    visibleNotifications$: Observable<BaseContentEntity[] | undefined>;
    showNotification$ = new BehaviorSubject<boolean>(true);


    constructor(private dataService: DataService) {

    }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.visibleNotifications$ = of(this.notification).pipe(map((notifications:any) => {
            if (notifications && notifications.Content && notifications.Content.list) {
                const unformattedQuickLinks = [{ ...notifications.Content.list }];

                return unformattedQuickLinks;
            }

            return [];
        }));
    }

    hasClick(notification: BaseContentEntity) {
        if (notification?.Content.tags === 'scroll_to_signup') {
            setTimeout(() => {
                this.dataService.scrollToSignUpForm();
            }, 200);

            return;
        }
        const { links } = notification?.Content || {};
        const link = links.$values ? links.$values[0] : links;

        return Boolean(link.linkExternal || link.linkInternalParameter || link.linkInternal);
    }

    hideNotification() {
        this.showNotification$.next(false);
    }
}
