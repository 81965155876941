<div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{
        'hvac-dropdown-menu-expanded': menuActive
    }">
    <button class="hvac-dropdown-menu-selected"
        [attr.aria-describedby]="describedBy"
        (click)="toggleMenu()">
        <span class="hvac-dropdown-menu-selected-label">{{ selectedOption?.title || hintText}}</span>
        <utc-svg class="hvac-dropdown-menu-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
    </button>
    <ol class="hvac-dropdown-menu-options">
        <li class="hvac-dropdown-menu-option-item" *ngFor="let option of menuOptions">
            <button class="hvac-dropdown-menu-option" (click)="setOption(option)"
                [ngClass]="{ 'hvac-dropdown-menu-option-current': option.value === selectedOption?.value }">
                {{ option.title }}
                <utc-svg class="hvac-dropdown-menu-option-selected-icon" src="assets/icons/check.svg"></utc-svg>
            </button>
        </li>
    </ol>
</div>
