import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { SuggestionsQuery, SuggestionsResults, PartsSuggestionsResults, ExtendedSuggestionsResults, PartSuggestionItem, PartSuggestionResult, Scored, PartsSearchQueryParms, SuggestLimiter } from 'common/models';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';
import { BrandService } from './brand.service';
import { BrandFamily } from 'common/models/brand.model';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { BrandItem, UserStatus } from 'common/models/account-status';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { BrandOverwriteType, UserService } from './user.service';

const SUGGESTION_SIZE = 5;


@Injectable({ providedIn: 'root' })
export class SuggestionsService {
    @Select(ContentState.contentBrand) userSelBrand$: Observable<string>;
    @Select(AccountStatusState.getBrands) userBrands$: Observable<BrandItem[]>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<UserStatus>;
    isPublicPlus: UserStatus;
    userBrandFamily$: Observable<BrandOverwriteType> = this.userService.getBrandFamily();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly brandService: BrandService,
        private readonly userService: UserService
    ) {}

    formatParts(suggestions: PartsSuggestionsResults): (PartSuggestionItem & Scored)[] {
        if (Object.prototype.hasOwnProperty.call(suggestions, 'rcProduct')) {
            return suggestions.rcProduct.map((part: PartSuggestionResult) => ({
                urn: part.code,
                url: part.media?.url || '',
                title: part.description,
                score: 0
            }));
        }

        return [];
    }

    fetchSuggestionsResults(query: SuggestionsQuery, suggestLimiter: SuggestLimiter) {
        const suggestionsQuery = Object.values(query).join('');
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const requiresBrandNameParam = this.brandService.isICPBrand(environment.brand) || environment.brand === 'Totaline';
        const params = requiresBrandNameParam ?
            new HttpParams({ fromString: `brand=${encodeURIComponent(environment.brand)}&q=${suggestionsQuery}` }) :
            new HttpParams({ fromString: `q=${suggestionsQuery}` });

        return combineLatest([options$, this.userBrandFamily$]).pipe(
            switchMap(([options, userBrandFamily]) => {
                const pimQueryParams: PartsSearchQueryParms = {
                    q: suggestionsQuery,
                    pageSize: SUGGESTION_SIZE
                };

                // Do not pass anything to filter parts if user has both CBP & ICP
                if (userBrandFamily !== BrandFamily.GENERIC) {
                    pimQueryParams['brands'] = userBrandFamily;
                }

                const $pimSuggestions = this.httpClient.get<PartsSuggestionsResults>(environment.api.pim.suggestions, {
                    params: pimQueryParams,
                    ...options
                }).pipe(map((response) => response), catchError(() => of({ rcProduct: [] })));

                const $standardSuggestions = this.httpClient.get<SuggestionsResults>(environment.api.suggestions, {
                    params,
                    ...options
                }).pipe(map((response) => response), catchError(() => of({})));

                if (suggestLimiter === 'parts') {
                    return $pimSuggestions.pipe(
                        map((result: PartsSuggestionsResults) => {
                            const partsSuggestions = this.formatParts(result);

                            return { parts: { results: partsSuggestions || [] } };
                        }),
                        catchError((error) => throwError(error))
                    );
                }
                else if (suggestLimiter === 'products') {
                    return $standardSuggestions.pipe(
                        map((result: SuggestionsResults) => ({ ...result })),
                        catchError((error) => throwError(error))
                    );
                }

                return forkJoin([$pimSuggestions, $standardSuggestions]).pipe(
                    map((result): ExtendedSuggestionsResults => {
                        const partsSuggestions = this.formatParts(result[0]);

                        return {
                            ...result[1],
                            parts: { results: partsSuggestions || [] }
                        };
                    }),
                    catchError((error) => throwError(error))
                );
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404 || err.status === 403) {
                    return of({});
                }
                throw err;
            })
        );
    }
}
