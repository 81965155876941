<div class="hvac-date-range-picker hvac-fw-md" [ngClass]="{ 'hvac-date-range-picker-retro': theme === 'retro' }">
    <div>
        <div class="hvac-date-range-picker-container">
            <div class="hvac-date-range-picker-left">
                <label for="hvac-date-range-picker-start-date" *ngIf="theme === 'retro'" class="hvac-p-sm hvac-label">{{ labelStartDate || ('DATE_PICKER.START' | translate) }}</label>
                <input
                    id="hvac-date-range-picker-start-date"
                    aria-label="'DATE_PICKER.START' | translate"
                    class="hvac-date-range-picker-input-start-date"
                    [ngClass]="{ 'hvac-date-range-picker-input' : theme === 'default', 'hvac-date-range-picker-input-retro': theme === 'retro' }"
                    [type]="dateTypeSelector(dateType)"
                    [min]="minStartDate | datePickerInputDate:dateType"
                    [max]="maxStartDate | datePickerInputDate:dateType"
                    [formControl]="startDateInput"
                    [attr.disabled]="disabled ? '' : null"
                    (blur)="onStartDateInputBlur()">
            </div>

            <div *ngIf="theme === 'default'" class="hvac-date-range-picker-divider">{{ CharSymbol.EmDash }}</div>

            <div class="hvac-date-range-picker-right">
                <label for="hvac-date-range-picker-end-date" *ngIf="theme === 'retro'" class="hvac-p-sm hvac-label">{{ labelEndDate || ('DATE_PICKER.END' | translate) }}</label>
                <input
                    id="hvac-date-range-picker-end-date"
                    aria-label="'DATE_PICKER.END' | translate"
                    class="hvac-date-range-picker-input-end-date"
                    [ngClass]="{ 'hvac-date-range-picker-input' : theme === 'default', 'hvac-date-range-picker-input-retro': theme === 'retro' }"
                    [type]="dateTypeSelector(dateType)"
                    [min]="minEndDate | datePickerInputDate:dateType"
                    [max]="maxEndDate | datePickerInputDate:dateType"
                    [formControl]="endDateInput"
                    [attr.disabled]="disabled ? '' : null"
                    (blur)="onEndDateInputBlur()">
            </div>
        </div>
    </div>
    <button [disabled]="isInvalidInput$ | async" *ngIf="theme === 'default' && submitText" hvacButton="default" size="small"
        (click)="onDateRangeSubmit()">{{ submitText }}</button>
</div>
<div class="hvac-date-range-errors">
    <p *ngIf="invalidInputMessage && (isInvalidInput$ | async)" class="hvac-date-range-invalid-input">{{ invalidInputMessage }}</p>
    <p *ngIf="error" class="hvac-date-range-error">{{ error }}</p>
    <p *ngIf="startDateFieldError && startDateFieldError.length > 0" class="hvac-date-range-error" id="start-date-field-error">{{ startDateFieldError }}</p>
    <p *ngIf="endDateFieldError && endDateFieldError.length > 0" class="hvac-date-range-error" id="end-date-field-error">{{ endDateFieldError }}</p>
</div>
