
export interface Pagination {
    currentPage: number;
    pageSize: number;
    sort: string;
    totalPages: number;
    totalResults: number;
}

export interface PartsMedia {
    fileName: string;
    format: string;
    url: string;
}

export interface Parts {
    channel: number;
    code: string;
    description: string;
    media: PartsMedia;
}

export interface PartsSort {
    code: string;
    name: string;
    selected: boolean;
}
export interface PartsCatalogResponse {
    freeTextSearch: string;
    pagination: Pagination
    rcProducts: Parts[]
    sorts: PartsSort[];
}

export interface PartsCatalogMetaData {
    [key: string]: {
        Page: string;
        Title: string;
        Description: string;
    }
}

export const TOTALINE_CATALOG_ROUTE = '/products/parts-catalog/browse';
export const HVACP_CATALOG_ROUTE = '/products/parts/parts-catalog/browse';
