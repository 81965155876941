import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaBaseService } from 'common/services/okta/okta-base.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'common/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UnauthenticatedInterceptor implements HttpInterceptor {
    private carrierBrandPath = '/carrier';
    private totalineBrandPath = '/totaline';
    constructor(private readonly okta: OktaBaseService,
        private router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.router.url.includes('/commercial-applied-parts') && req.url.includes('/documents')) {
            this.replaceToTotalinePath(this.carrierBrandPath, req);
        }

        return next.handle(req).pipe(
            catchError((err) => {
                const logoutBlackList = [
                    environment.api.legacyLogin
                ];

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (err.status === 401 && !logoutBlackList.find((url: any) => url === req.url)) {
                    this.okta.logout();
                }

                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replaceToTotalinePath(brandPath: string, req: HttpRequest<any>) {
        /* eslint-disable no-param-reassign */
        const newUrl = { url: req.url.replace(brandPath, this.totalineBrandPath) };
        req = Object.assign(req, newUrl);
    }
}
