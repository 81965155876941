import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BreakPointService } from 'common/services/breakpoint.service';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationService } from 'common/services/navigation.service';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { MobileMenuVisible, TabletMenuVisible } from 'common/store/ui/ui.actions';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Component({
    selector: 'hvac-navigation-panel-container',
    templateUrl: './navigation-panel-container.component.html',
    styleUrls: ['./navigation-panel-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationPanelContainerComponent {
    @Select(NavigationState.currentPage) currentPage$: Observable<NavMenu>;
    @ViewChild('panelContainerList') panelContainerList: ElementRef;

    currentMenu$ = this.navigationService.navigationSections$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((deepestMenu: any) => Boolean(deepestMenu && deepestMenu.path))
    );

    constructor(
        private readonly navigationService: NavigationService,
        private readonly store: Store,
        private readonly breakpoint: BreakPointService,
        private readonly elementRef: ElementRef
    ) { }

    handleMouseEnter(menuToOpen: NavMenu) {
        if (this.breakpoint.isDesktop()) {
            this.openMenu(menuToOpen);
        }
    }

    closeMenus() {
        this.navigationService.closeAllMenu();
        this.store.dispatch(new MobileMenuVisible(false));
        this.store.dispatch(new TabletMenuVisible(false));
    }

    openMenu(menuToOpen: NavMenu) {
        this.navigationService.openNextMenu(menuToOpen);
    }

    handleLinkClick(menu: NavMenu) {
        if (menu.children.length > 0) {
            this.elementRef.nativeElement.firstChild.scrollTop = 0;
            this.openMenu(menu);
        }
        else {
            this.closeMenus();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackByNavPanel(item: any) {
        return item.key;
    }

    navMenuCurrent(child: NavMenu): Observable<boolean> {
        return this.currentPage$.pipe(
            filter((currentNavMenu) => Boolean(currentNavMenu)),
            first(),
            map((currentNavMenu) => currentNavMenu.path.some((pathKey) => pathKey === child.key))
        );
    }
}
