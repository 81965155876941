import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ViewMode } from 'common/models';

@Component({
    selector: 'utc-placeholder-list-item',
    templateUrl: './placeholder-list-item.component.html',
    styleUrls: ['../../../common/components/product/product-card.component.scss', './placeholder-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlaceholderListItemComponent {
    @Input() mode!: ViewMode;
}
