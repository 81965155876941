import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Product, ProductDetails, ProductVariant } from 'common/models';
import { PRODUCT_RESOURCE_TYPE } from 'common/models/product';
import { ReceiveCatalogCategoryProducts, ReceiveCatalogProductAttributes, ReceiveCatalogProductDataOverview, ReceiveCatalogProductDocuments } from '../catalog/catalog.actions';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { buildRepository } from '../repository/utils';
import { AppendSearchResults, ReceiveSearchResults } from '../search/search.actions';
import { lastChildRoute } from '../utils/routing.utils';
import { ConcealProductDetails, RevealProductDetails } from './products.actions';

export interface ProductsStateModel {
    repository: ResourceRepositoryModel<Product>;
    detailRepository: ResourceRepositoryModel<ProductDetails>;
    productDetails: {
        reveal: boolean | null;
    };
}

@State<ProductsStateModel>({
    name: 'products',
    defaults: {
        repository: {},
        detailRepository: {},
        productDetails: { reveal: true }
    }
})
@Injectable({ providedIn: 'root' })
export class ProductsState {
    @Selector()
    static repository({ repository }: ProductsStateModel): ResourceRepositoryModel<Product> {
        return repository;
    }

    @Selector()
    static detailsRepository({ detailRepository }: ProductsStateModel): ResourceRepositoryModel<ProductDetails> {
        return detailRepository;
    }

    @Selector([RouterState.state])
    static activeProduct({ detailRepository }: ProductsStateModel, routerState: RouterStateSnapshot|undefined): ProductDetails|null {
        if (!routerState) {
            return null;
        }

        const route = lastChildRoute(routerState);
        const productParam = route.paramMap.get('product');

        if (productParam) {
            return detailRepository[productParam] || null;
        }

        return null;
    }

    @Selector()
    static revealProductDetails({ productDetails }: ProductsStateModel): boolean | null {
        return productDetails.reveal;
    }

    @Action([ReceiveSearchResults, AppendSearchResults])
    receiveSearchResults(ctx: StateContext<ProductsStateModel>, action: ReceiveSearchResults|AppendSearchResults) {
        const products = action.results.products || { results: [] };
        const productResults: Product[] = products.results;
        const repository = {
            ...ctx.getState().repository,
            ...buildRepository(productResults.map((product) => ({ ...product })), 'urn', PRODUCT_RESOURCE_TYPE)
        };

        return ctx.patchState({ repository });
    }

    @Action(ReceiveCatalogCategoryProducts)
    receiveCatalogCategoryProducts(ctx: StateContext<ProductsStateModel>, action: ReceiveCatalogCategoryProducts) {
        if (action.categoryProducts.items.length <= 0) {
            ctx.patchState({ productDetails: { reveal: null } });

            return;
        }
        const products = action.categoryProducts.items || [];
        const repository = {
            ...ctx.getState().repository,
            ...buildRepository(products.map((product) => ({ ...product })), 'urn', PRODUCT_RESOURCE_TYPE)
        };

        return ctx.patchState({ repository });
    }

    @Action(ReceiveCatalogProductAttributes)
    receiveCatalogProductAttributes(ctx: StateContext<ProductsStateModel>, action: ReceiveCatalogProductAttributes) {
        if (!action.productAttributes) {
            ctx.patchState({ productDetails: { reveal: null } });

            return;
        }
        const product = action.productAttributes;
        const repoProduct = this.getExistingDetails(ctx.getState(), product.urn);
        const detailRepository = {
            ...ctx.getState().detailRepository,
            [product.urn]: {
                ...repoProduct,
                attributes: { ...product }
            }
        };

        return ctx.patchState({ detailRepository });
    }

    @Action(ReceiveCatalogProductDataOverview)
    receiveCatalogProductDataOverview(ctx: StateContext<ProductsStateModel>, action: ReceiveCatalogProductDataOverview) {
        if (!action.product) {
            ctx.patchState({ productDetails: { reveal: null } });

            return;
        }
        const { product } = action;
        let dataOverview = [] as ProductVariant[];
        if (!product.familyProducts && !product.attributes) {
            dataOverview = [];
        }
        else if (product.familyProducts) {
            dataOverview = product.familyProducts.filter((attr: ProductVariant) => attr.attributes && attr.active);
        }
        else if (product.attributes) {
            dataOverview = [product];
        }
        const repoProduct = this.getExistingDetails(ctx.getState(), product.urn);
        const detailRepository = {
            ...ctx.getState().detailRepository,
            [product.urn]: {
                ...repoProduct,
                dataOverview: dataOverview
            }
        };

        return ctx.patchState({ detailRepository });
    }

    @Action(ReceiveCatalogProductDocuments)
    receiveCatalogProductDocuments(ctx: StateContext<ProductsStateModel>, action: ReceiveCatalogProductDocuments) {
        if (!action.productDocuments.items) {
            ctx.patchState({ productDetails: { reveal: null } });

            return;
        }
        const documents = action.productDocuments.items;
        const repoProduct = this.getExistingDetails(ctx.getState(), action.productUrn);
        const detailRepository = {
            ...ctx.getState().detailRepository,
            [action.productUrn]: {
                ...repoProduct,
                documents: [...documents]
            }
        };

        return ctx.patchState({ detailRepository });
    }

    @Action(ConcealProductDetails)
    concealProductDetails(ctx: StateContext<ProductsStateModel>) {
        ctx.patchState({ productDetails: { reveal: false } });
    }

    @Action(RevealProductDetails)
    revealProductDetails(ctx: StateContext<ProductsStateModel>) {
        ctx.patchState({ productDetails: { reveal: true } });
    }

    private getExistingDetails(state: ProductsStateModel, product: string) {
        return state.detailRepository[product] || {
            attributes: {},
            documents: []
        };
    }
}
