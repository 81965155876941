import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

type IconPosition = 'left' | 'right';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: (...args: any[]) => any = () => null;


@Component({
    selector: 'utc-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TextAreaComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() label: string;
    @Input() placeholder?: string;
    @Input('value') _value?: string;
    @Input() hideLabel = false;
    @Input() disabled = false;
    @Input() ariaLabel?: string;
    @Input() required = false;
    // TODO: refactor forms
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() inputControl: any;
    @Input() labelIcon?: string;
    @Input() iconLabel?: string;
    @Input() iconPosition?: IconPosition;
    @Input() infoMessage: string;
    @Input() errorMessage: string | null;
    @Input() error: boolean;
    @Input() hasIconFunction: boolean;
    @Input() disableInputIcon?: boolean;
    @Input() disabledInput?: boolean;
    @Input() shadow: 'light' | 'default' | 'none' = 'default';
    @Input() maxLength: number = 524288;

    @Output() enterKeyFunction = new EventEmitter<string>();
    @Output() keystrokeFunction = new EventEmitter<Event>();
    @Output() blurFunction = new EventEmitter<string>();
    @Output() focusFunction = new EventEmitter<Event>();
    @Output() iconClickFunction = new EventEmitter<string>();

    @ViewChild('input') input: ElementRef;
    onChange = noop;
    onTouched = noop;

    isFocused = false;
    minimizeLabel: boolean;
    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.inputId = '';
        this.label = '';
        this.placeholder = '';
        this._value = '';
    }

    get value(): string | undefined {
        return this._value;
    }

    set value(val: string | undefined) {
        this._value = val;
        this.minimizeLabel = this._value !== '';
    }

    @HostListener('keydown', ['$event'])
    public keyListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code.toLowerCase() === 'enter') {
            this.enterKeyFunction.emit(this.input.nativeElement.value);
        }
        else {
            this.keystrokeFunction.emit(this.input.nativeElement.value);
        }
        this.minimizeLabel = this._value !== '';

        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.disabled = this.inputControl?.disabled;
        this.inputControl.registerOnDisabledChange((val: boolean) => {
            this.disabled = val;
        });

        // eslint-disable-next-line no-self-assign
        this.placeholder = this.placeholder;
        this.writeValue(this.inputControl.value);
        this.inputControl.valueChanges.subscribe((value: string) => {
            this.writeValue(value);
            this.minimizeLabel = this._value !== '';
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    inputBlur() {
        this.isFocused = false;
        this.blurFunction.emit(this.input.nativeElement.value);
    }

    inputFocus() {
        this.isFocused = true;
        this.focusFunction.emit(this.input.nativeElement.value);
    }

    inputButtonClick() {
        this.iconClickFunction.emit(this.input.nativeElement.value);
    }

    writeValue(val: string) {
        this._value = val;
        this.minimizeLabel = this._value !== '';
    }
}
