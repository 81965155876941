import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { InputTheme } from 'common/components/search-input/search-input.component';
import { environment } from 'common/environments/environment';
import { BaseContentEntity } from 'common/models';
import { PerformSearch } from 'common/store/search/search.actions';

@Component({
    selector: 'hvac-search-hero',
    templateUrl: './search-hero.component.html',
    styleUrls: ['./search-hero.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchHeroComponent {
    @Input() searchConfig: BaseContentEntity | null | undefined;
    queryControl = new UntypedFormControl('', Validators.required);
    isIcpSite = environment.brand !== 'Bryant' && environment.brand !== 'Carrier'
    isTotaline = environment.brand === 'Totaline'
    searchTipKey = this.isIcpSite ? 'HOMEPAGE.SEARCH_TIPS_ICP' : 'HOMEPAGE.SEARCH_TIPS';
    searchTheme: InputTheme = this.isIcpSite ? 'icp' : 'light';
    placeholder = ''
    constructor(private readonly store: Store) {
    }

    ngOnInit() {
        this.placeholder = this.searchConfig?.Content?.searchBoxPlaceholderText || '';
    }

    onSubmit() {
        const query = { q: this.queryControl.value };
        this.store.dispatch(new PerformSearch(query));
    }
}
