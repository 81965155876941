import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContentComponent } from 'common/content/services/content-component.service';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'utc-featured-products',
    templateUrl: './featured-products.component.html',
    styleUrls: ['../../../components/results-list/results-list.component.scss', './featured-products.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FeaturedProductsComponent implements ContentComponent {
    @Input() data: BaseContentEntity;
}
