<div class="hvac-table-list">
    <div class="hvac-table-list-title-container" [ngClass]="{ 'hvac-table-title-border': layout==='stacked' }">
        <span class="hvac-table-list-info">{{ headerNote }}</span>
        <div class="hvac-table-controls">
            <div class="hvac-table-controls-filters">
                <div class="hvac-table-segmented-filter" *ngIf="languageFilter && (languageFilterData$ | async)!.length > 1">
                    <hvac-segmented-button [controlType]="'radio'" [defaultSelection]='(languageFilterData$ | async)![0]'
                        [segmentedOptions]="(languageFilterData$ | async)!" (segmentedSelection)=filterLanguage($event)>
                    </hvac-segmented-button>
                </div>
                <div class="hvac-table-segmented-filter" *ngIf="status">
                    <hvac-segmented-button [controlType]="'checkBox'" [defaultSelection]="statusDefaultSelection"
                        [segmentedOptions]="statusFilterData" (segmentedSelection)=filterStatus($event)>
                    </hvac-segmented-button>
                </div>
                <div class="hvac-table-filter" *ngIf="filterOptions && filterOptions.length > 1">
                    <hvac-options-menu [describedBy]="'#sort-by'" (change)="filterChanged($event)"
                        [selectedOption]="filterOptions[0]" [menuOptions]="filterOptions">
                    </hvac-options-menu>
                </div>
                <div class="hvac-table-segmented-filter" *ngIf="pageSizeFilter && globalDataHolder.length > 5">
                    <hvac-segmented-button [controlType]="'radio'" [defaultSelection]="pageDefaultSelection"
                        [segmentedOptions]="pageFilterData" (segmentedSelection)=filterPage($event)>
                    </hvac-segmented-button>
                </div>
            </div>
            <div class="hvac-table-list-search" *ngIf="search===true">
                <form class='hvac-table-list-search-form hvac-form-element'>
                    <input class="hvac-table-list-search-input" id="hvac-table-list-search-general-search"
                        name="hvac-table-list-search" type="text" [placeholder]="'SEARCH' | translate"
                        [attr.aria-label]="'SEARCH' | translate" [title]="'SEARCH' | translate" #dataTableSearchInput
                        (keyup)="inputKeyup($event)" autocomplete="off" />

                    <div class="hvac-input-action-wrap">
                        <button *ngIf="dataTableSearchText.length > 0" type="button" hvacButton="default" class="hvac-clear-button" (click)="clearSearch()">
                            {{ 'CLEAR' | translate }}
                        </button>

                        <button hvacButton="icon" type="button" (click)="onSearch($event)" [title]="'SEARCH' | translate">
                            <utc-svg src="assets/icons/search.svg"></utc-svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="hvac-table-list" *ngIf="dataHeaders && dataHeaders.length > 0">
        <div class="hvac-stacked-container">
            <ul class="hvac-stacked-list" *ngIf="layout === 'stacked'">
                <li *ngFor='let data of UIData'>
                    <div (click)="onContextClick( data , 'row' );$event.stopPropagation()"
                        class="hvac-stacked-list-link">
                        <div class="hvac-stacked-list-icon">
                            <utc-svg [src]="dataTableService.fileIcon(data.fileType)"></utc-svg>
                        </div>
                        <div class="hvac-stacked-list-details">
                            <p class="hvac-stacked-list-link-title" [innerHTML]="data[dataHeaders[0]] | richText"></p>
                            <div class="hvac-stacked-list-properties">
                                <span *ngFor="let header of dataHeaders | slice: 1: 6 index as i"
                                    [innerHTML]="data[header] | richText">
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <table cellspacing='0' *ngIf="layout === 'table'">
            <thead class="hvac-table-list-header">
                <td class="hvac-table-list-column-icon"></td>
                <td *ngFor='let header of dataHeaders'>
                    <div class="hvac-table-list-column-header" (click)="sort(header)">
                        <span class="hvac-table-list-column-title"
                            [ngClass]="{ 'hvac-table-title-active': getsortField(header)}">
                            {{ (translationPrefix ? (translationPrefix + header) : header) | translate }}
                        </span>
                        <span class="hvac-table-list-sort"
                            [ngClass]="{ 'column-ascending': sortDirection === 'asc', 'hvac-table-list-sort-show': getsortField(header) }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </div>
                </td>
                <td class="hvac-table-list-column-menu" *ngIf="contextMenu"></td>
            </thead>
            <ng-container *ngFor='let data of UIData'>
                <tr *ngIf="!data.isExpandable; else expandableRow">
                    <td class="hvac-table-list-column-icon"
                        (click)="onContextClick( data , 'row' );$event.stopPropagation()">
                        <div class="hvac-table-list-icon"
                            [ngClass]="{'document-light-icon': data.status !== 'Completed'}">
                            <utc-svg [src]="dataTableService.fileIcon(data.fileType)"></utc-svg>
                        </div>
                    </td>
                    <td *ngFor='let header of dataHeaders'
                        (click)="onContextClick( data , 'row' );$event.stopPropagation()"
                        [ngClass]="{'hvac-table-column-error': data[header] === 'Error'}">
                        <div class="no-date-time"
                            *ngIf="header === 'completed' && (data.status === 'In Progress' || data.status === 'CANCELLED' || data.status === 'Error'); else otherColumns">
                            --
                        </div>
                        <ng-template #otherColumns>
                            <div
                                [ngClass]="{ 'hvac-table-column-active': (data['isRead'] === false || data.status === 'In Progress') && header=== 'name', 'inline-content': data[header] === 'Error' }">
                                {{ data[header] }}
                            </div>
                        </ng-template>
                        <div *ngIf="header === 'name' && data.isExpandable" class="hvac-item-count">
                            {{data.details.length}}
                        </div>
                        <utc-svg class="error-icon" *ngIf="data[header] === 'Error'"
                            src="assets/icons/banner-error.svg"></utc-svg>
                    </td>
                    <td class="hvac-table-list-column-menu" *ngIf="contextMenu"
                        (click)="toggleContextMenu(data);$event.stopPropagation()">
                        <div class="hvac-table-dropdow-three-dots"
                            [ngClass]="{'hvac-table-dropdow-three-dots-z-index': getMenuActionRow(data) }">
                        </div>
                        <div class="hvac-table-dropdown-content"
                            [ngClass]="{'hvac-table-list-column-menu-expanded': getMenuActionRow(data) }">
                            <div></div>
                            <ol *ngIf="data.inboxViewer; else fullMenu">
                                <li *ngFor='let menu of contextMenu' (click)="onContextClick( data , menu )">
                                    {{ menu }}
                                </li>
                            </ol>
                            <ng-template #fullMenu>
                                <ol>
                                    <li *ngFor='let menu of contextMenu | slice:1'
                                        (click)="onContextClick( data , menu )">{{ menu
                                        }}
                                    </li>
                                </ol>
                            </ng-template>
                        </div>
                    </td>
                </tr>
                <ng-template #expandableRow>
                    <app-hvac-data-table-expandable-row (headerRowEvent)="emitExpandedRowClick($event)"
                        [selectedContextRow]="selectedContextRow" (toggleMenuEvent)="onToggleMenuClick($event)"
                        (childEvent)="onChildContextClick($event)" [expandableRowData]="data"
                        [dataHeaders]="dataHeaders" [contextMenu]="contextMenu" [enableView]="data.inboxViewer">
                    </app-hvac-data-table-expandable-row>
                </ng-template>

            </ng-container>
        </table>
    </div>
    <hvac-pagination
        [totalPages]="totalPages"
        [currentPage]="currentPage + 1"
        [useUIData]="true"
        [UIData]="UIData"
        [globalDataHolder]="globalDataHolder"
        (pageChange)="onPageChange($event)"
    >
    </hvac-pagination>
</div>
<div *ngIf="contextMenuActive" (click)="disableContextMenu()" class="overlay-bg">
</div>
