/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SuggestionsService } from 'common/services/suggestions.service';
import { Content, Document, Product, SuggestionsQuery, SuggestionsResource, SuggestionItem, Scored, ExtendedSuggestionsResults, PartSuggestionItem, SuggestLimiter } from 'common/models';
import { DocumentsState } from '../documents/documents.state';
import { ProductsState } from '../products/products.state';
import { ResourceRepositoryModel } from '../repository/repository.model';
import { SiteContentState } from '../site-content/site-content.state';
import { PerformSuggestions, ReceiveSuggestionsResults, ClearSuggestions } from './suggestions.actions';
/* tslint:enable:max-line-length */

export interface SortOptions {
    parameter: string;
    options: string[];
}

export interface SuggestionsCategoriesModel {
    all?: SuggestionsResource<SuggestionItem & Scored>;
    products?: SuggestionsResource<SuggestionItem & Scored>;
    documents?: SuggestionsResource<SuggestionItem & Scored>;
    content?: SuggestionsResource<SuggestionItem & Scored>;
    parts?: SuggestionsResource<PartSuggestionItem & Scored>;
}

export interface SuggestionsStateModel {
    query: SuggestionsQuery;
    suggestionsCategories: SuggestionsCategoriesModel;
    suggestionsLoading: boolean;
}

const EMPTY_SUGGESTIONS: SuggestionsCategoriesModel = {
    all: { results: [] },
    products: { results: [] },
    documents: { results: [] },
    content: { results: [] },
    parts: { results: [] }
};

@State<SuggestionsStateModel>({
    name: 'suggestions',
    defaults: {
        query: { q: '' },
        suggestionsCategories: EMPTY_SUGGESTIONS,
        suggestionsLoading: false
    }
})

@Injectable({ providedIn: 'root' })
export class SuggestionsState {
    constructor(private readonly suggestionsService: SuggestionsService) {}

    @Selector()
    static suggestionsLoading({ suggestionsLoading }: SuggestionsStateModel): boolean {
        return suggestionsLoading;
    }

    @Selector()
    static query({ query }: SuggestionsStateModel): SuggestionsQuery {
        return query;
    }

    @Selector()
    static suggestionsCategories({ suggestionsCategories }: SuggestionsStateModel): SuggestionsCategoriesModel {
        return suggestionsCategories;
    }

    @Selector()
    static productResults({ suggestionsCategories }: SuggestionsStateModel): SuggestionsResource<SuggestionItem & Scored> {
        return (suggestionsCategories.products || { results: [] });
    }

    @Selector([SuggestionsState.productResults, ProductsState.repository])
    static productResources(_state: SuggestionsStateModel, results: string[], repository: ResourceRepositoryModel<Product>): Product[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((product) => Boolean(product));
    }

    @Selector()
    static contentResults({ suggestionsCategories }: SuggestionsStateModel): SuggestionsResource<SuggestionItem & Scored> {
        return (suggestionsCategories.content || { results: [] });
    }

    @Selector([SuggestionsState.contentResults, SiteContentState.repository])
    static contentResources(_state: SuggestionsStateModel, results: string[], repository: ResourceRepositoryModel<Content>): Content[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((content) => Boolean(content));
    }

    @Selector()
    static documentResults({ suggestionsCategories }: SuggestionsStateModel): SuggestionsResource<SuggestionItem & Scored> {
        return (suggestionsCategories.documents || { results: [] });
    }

    @Selector([SuggestionsState.documentResults, DocumentsState.repository])
    static documentResources(_state: SuggestionsStateModel, results: string[], repository: ResourceRepositoryModel<Document>): Document[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((document) => Boolean(document));
    }

    @Selector()
    static partResults({ suggestionsCategories }: SuggestionsStateModel): SuggestionsResource<SuggestionItem> {
        return (suggestionsCategories.parts || { results: [] });
    }

    @Selector([SuggestionsState.partResults, DocumentsState.repository])
    static partResources(_state: SuggestionsStateModel, results: string[], repository: ResourceRepositoryModel<SuggestionItem>): SuggestionItem[] {
        return results.map((resourceKey) => repository[resourceKey]).filter((part) => Boolean(part));
    }

    @Action(PerformSuggestions)
    performSuggestions(ctx: StateContext<SuggestionsStateModel>, action: PerformSuggestions) {
        const query = { ...action.query.q };
        ctx.patchState({
            ...this.getEmptySuggestion(),
            query
        });

        return this.fetchNewSuggestionsResults(query, action.query.limiter, ctx);
    }

    @Action(ReceiveSuggestionsResults)
    receiveSuggestionsResults(ctx: StateContext<SuggestionsStateModel>, action: ReceiveSuggestionsResults) {
        const { results } = action;
        ctx.patchState({ suggestionsCategories: this.mergeSuggestionsCategories(results) });
    }

    @Action(ClearSuggestions)
    clearSuggestions(ctx: StateContext<SuggestionsStateModel>) {
        ctx.patchState({ suggestionsCategories: EMPTY_SUGGESTIONS });
    }

    private mergeSuggestionsCategories(results: ExtendedSuggestionsResults): SuggestionsCategoriesModel {
        const result = {
            all: { ...this.mapResults(results.all) },
            products: { ...this.mapResults(results.products) },
            documents: { ...this.mapResults(results.documents) },
            content: { ...this.mapResults(results.content) },
            parts: { ...this.mapProduct(results.parts) }
        };

        return result;
    }

    private mapProduct(results: SuggestionsResource<PartSuggestionItem & Scored> = { results: [] }): SuggestionsResource<PartSuggestionItem & Scored> {
        return { results: results.results.map((resource) => resource) };
    }

    private mapResults(results: SuggestionsResource<SuggestionItem & Scored> = { results: [] }) {
        return { results: results.results.map((resource) => resource) };
    }

    private getEmptySuggestion(): { suggestionsCategories: SuggestionsCategoriesModel } {
        return { suggestionsCategories: { ...EMPTY_SUGGESTIONS } };
    }

    private fetchNewSuggestionsResults(query: SuggestionsQuery, limiter: SuggestLimiter, ctx: StateContext<SuggestionsStateModel>) {
        return this.fetchSuggestionsResults(
            query,
            limiter,
            () => ctx.patchState({ suggestionsLoading: true }),
            (results: ExtendedSuggestionsResults) => {
                ctx.dispatch(new ReceiveSuggestionsResults(results));
                ctx.patchState({ suggestionsLoading: false });
            }
        );
    }

    private fetchSuggestionsResults(query: SuggestionsQuery, limiter: SuggestLimiter, preFetch: () => void, postFetch: (results: ExtendedSuggestionsResults) => void) {
        preFetch();

        return this.suggestionsService.fetchSuggestionsResults(query, limiter).subscribe(postFetch);
    }
}
