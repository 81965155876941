import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FeaturedItem } from './featured-content.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'utc-featured-content-item',
    templateUrl: './featured-content-item.component.html',
    styleUrls: ['./featured-content-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FeaturedContentItemComponent implements OnInit {
    @Input() item: FeaturedItem;
    embedableUrl: SafeResourceUrl;

    constructor(private readonly sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.embedableUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.item.media.src);
    }
}
