/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { Observable, Subject } from 'rxjs';

const RECOVER_PASSWORD_MUTATION = 'mutation recoverPassword';

@Injectable({ providedIn: 'root' })
export class RequestHeadersInterceptor implements HttpInterceptor {
    @Select(ContentState.contentBrand) brand$: Observable<string>;

    private httpObservable: Subject<HttpEvent<any>>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req.clone();

        if (req?.body?.query?.includes(RECOVER_PASSWORD_MUTATION)) {
            this.httpObservable = new Subject();

            this.brand$.subscribe((brand) => {
                authReq = req.clone({ headers: req.headers.set('Mobile-App-Brand', brand) });
                next.handle(authReq).subscribe((httpEvent) => {
                    this.httpObservable.next(httpEvent);
                });
            });

            return this.httpObservable;
        }

        authReq = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache') });

        return next.handle(authReq);
    }
}

