import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { CatalogCategory, CatalogState } from 'common/store/catalog/catalog.state';
import { Observable } from 'rxjs';

const partCatalogObj: CatalogCategory = {
    name: 'Parts Catalog',
    parent: null,
    sortOrder: 12,
    urn: 'parts-catalog',
    children: []
};

@Component({
    selector: 'hvac-browse-categories',
    templateUrl: './categories.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit {
    @Select(CatalogState.catalogCategories) categories$!: Observable<CatalogCategory[]>;
    public categoriesSorted: CatalogCategory[];
    public allCategories: CatalogCategory[];
    public isPartsCatalog: boolean;
    public partsEnabled = environment.features.partsCatalog;

    constructor(private readonly route: Router) {}
    ngOnInit() {
        this.isPartsCatalog = this.route.url.includes('parts-catalog') || environment.brand === 'Totaline';

        this.categories$.subscribe((categories) => {
            const categoriesCopy = [...categories];
            this.allCategories = [...categories];
            if (this.partsEnabled && environment.brand !== 'Totaline' && !this.route.url.includes('parts-catalog')) {
                this.allCategories = categories.length ? [...categoriesCopy, partCatalogObj] : [];
            }

            if (this.isPartsCatalog) {
                categoriesCopy.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });
            }
            this.categoriesSorted = categoriesCopy;
        });
    }
}
