<div class="hvac-site-content-list-item hvac-site-content-list-item-{{ mode }}" tabindex="-1">
    <ng-template ngIf="resource[urlKey]">
        <a *ngIf="isExternal; else internalLink" [href]="resource[urlKey]" target="_blank" class="hvac-site-content-list-item-link">
            <div class="hvac-site-content-icon-container">
                <utc-svg src="assets/icons/browser-frame.svg"></utc-svg>
            </div>
            <div class="hvac-site-content-text-container">
                <h3 class="hvac-site-content-list-item-name">{{ resource.title }}</h3>
                <div class="hvac-site-content-highlighting">
                    <p class="hvac-site-content-highlight-text" [innerHTML]="resource.highlighting"></p>
                </div>
                <div class="share-site-icon-container">
                    <div class="share-site-content-icon-container">
                        <utc-svg src="assets/icons/browser-frame.svg"></utc-svg>
                    </div>
                    <div class="hvac-document-list-item-actions">
                        <utc-share-button (click)="$event.stopPropagation()" [url]="baseURL + resource[urlKey]" [title]="resource.title"></utc-share-button>
                    </div>
                </div>
                <div class="hvac-breadcrumb-url">{{resource[urlKey]}}</div>
            </div>
            <div class="hvac-document-list-item-share">
                <utc-share-button (click)="$event.stopPropagation()" [url]="baseURL + resource[urlKey]" [title]="resource.title"></utc-share-button>
            </div>
        </a>
        <ng-template #internalLink>
            <a [routerLink]='resource[urlKey]' class="hvac-site-content-list-item-link">
                <div class="hvac-site-content-icon-container">
                    <utc-svg src="assets/icons/browser-frame.svg"></utc-svg>
                </div>
                <div class="hvac-site-content-text-container">
                    <h3 class="hvac-site-content-list-item-name">{{ resource.title }}</h3>
                    <div class="hvac-site-content-highlighting">
                        <p class="hvac-site-content-highlight-text" [innerHTML]="resource.highlighting"></p>
                    </div>
                    <div class="share-site-icon-container">
                        <div class="share-site-content-icon-container">
                            <utc-svg src="assets/icons/browser-frame.svg"></utc-svg>
                        </div>
                        <div class="hvac-document-list-item-actions">
                            <utc-share-button (click)="$event.stopPropagation()" [url]="baseURL + resource[urlKey]" [title]="resource.title"></utc-share-button>
                        </div>
                    </div>
                    <div class="hvac-breadcrumb-url">{{resource[urlKey]}}</div>
                </div>
                <div class="hvac-document-list-item-share">
                    <utc-share-button (click)="$event.stopPropagation()" [url]="baseURL + resource[urlKey]" [title]="resource.title"></utc-share-button>
                </div>
            </a>

        </ng-template>
    </ng-template>
</div>
