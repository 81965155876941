export const CONTENT_RESOURCE_TYPE = 'content';

export interface Content {
    brand: string;
    content: string;
    id: string;
    score: number;
    title: string;
    url: string;
    resourceType: 'content';
    highlighting: string;
}
