import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState } from 'common/store/auth/auth.state';
import { HideLoginPanel } from 'common/store/login-panel/login-panel.actions';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { UrlService } from 'common/services/url.service';
import { DataService } from 'common/services/data.service';
import { environment } from 'common/environments/environment';
import { BrandService } from '../../services/brand.service';
import { OktaService } from 'common/services/okta/okta.service';

export interface LoginPanelContent {
    SIGN_IN: string;
    CLOSE: string;
    SIGN_OUT: string;
    SIGNED_IN: string;
    HVACP_PARTNERS_SIGN_IN: string;
}

export interface LoginPanelRegistrationContent {
    SIGN_UP: string;
    CREATE_ACCOUNT_DESCRIPTION: string;
    CREATE_ACCOUNT_LEARN_MORE: string;
    REGISTER: string;
    TERMS: string;
    PRIVACY: string;
    createAccountLink: string;
    createAccountRegisterLink: string;
    PRIVACY_LINK: string;
    TERMS_LINK: string;
    DONT_HAVE_ACCOUNT: string;
    SIGN_IN_WITH_DEALER_CREDENTIALS1: string;
    SIGN_IN_WITH_DEALER_CREDENTIALS2: string;
    SIGN_IN: string;
}

@Component({
    templateUrl: './login-panel.component.html',
    selector: 'utc-login-panel',
    styleUrls: ['./login-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginPanelComponent implements OnInit, OnDestroy {
    @Select(LoginPanelState.loginPanelVisible) loginPanelVisible$: Observable<boolean>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<Boolean>;

    @ViewChild('panel') panel: ElementRef;
    @ViewChild('loginCloseButton') loginCloseButton: ElementRef;

    @Input() registrationContent: LoginPanelRegistrationContent;
    @Input() content: LoginPanelContent;
    @Output() onSignUpClicked = new EventEmitter();

    panelVisible = false;
    isICP = this.brandService.isICPBrand(environment.brand);
    isPublicCBP = this.brandService.isPublicCBBrand(environment.brand);
    signInButtonAction$ = new BehaviorSubject<string>('');

    constructor(
        private readonly store: Store,
        private readonly okta: OktaService,
        private readonly urlService: UrlService,
        private dataService: DataService,
        private readonly brandService: BrandService
    ) { }


    @HostListener('keydown', ['$event'])
    public focusRestrict(event: KeyboardEvent) {
        if (this.panelVisible) {
            const focusableEls = this.panel.nativeElement.querySelectorAll(`button, [href], input, select, textarea`);
            const firstFocusableEl = focusableEls[0];
            const lastFocusableEl = focusableEls[focusableEls.length - 1];
            if (event && event.key && event.key.toLowerCase() === 'escape') {
                this.closeSideNav();
            }

            if (event && event.key && event.key.toLowerCase() === 'tab') {
                /* shift + tab */
                if (event.shiftKey) {
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        event.preventDefault();
                    }
                }
                /* regular tab */
                else if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    event.preventDefault();
                }
            }
        }
    }

    ngOnInit() {
        this.loginPanelVisible$.pipe(
            delay(100)
        ).subscribe((visible: boolean) => {
            this.panelVisible = visible;
            if (this.panelVisible) {
                this.loginCloseButton.nativeElement.focus();
            }
        });
        this.okta.setupWidget('#widget-container', {});

        this.urlService.setLoginUrl().subscribe((data) => {
            if (this.isICP) {
                this.signInButtonAction$.next(data);
            }
            else {
                this.signInButtonAction$.next(this.registrationContent?.createAccountRegisterLink);
            }
        });
    }

    ngOnDestroy() {
        this.okta.removeWidget();
    }

    closeSideNav() {
        this.store.dispatch(new HideLoginPanel());
    }

    logout() {
        this.okta.logout();
    }

    onSignUpClick() {
        this.closeSideNav();
        setTimeout(() => {
            this.dataService.scrollToSignUpForm();
        }, 200);
    }
}
