import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductCard } from 'common/components/product/product-card.component';

@Component({
    selector: 'utc-featured-products-product',
    templateUrl: './featured-products-product.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FeaturedProductsProductComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() product: any;
    productCard: ProductCard = {
        urn: '',
        image: ''
    };

    ngOnInit() {
        const content = this.product.Content;

        this.productCard = {
            urn: content.model.Title,
            name: this.formatName(content.name),
            image: this.getProductImage(content)
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatName(name: any) {
        if (!name) {
            return '';
        }

        if (name.Fragments) {
            return name.Fragments.join('');
        }

        return name;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getProductImage(content: any) {
        const { media } = content;
        const mediaValue = media.$values ? media.$values[0] : media;

        return mediaValue.BinaryContent.Url;
    }
}
