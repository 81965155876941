import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';

const DEFAULT_TYPE = 'authCapture';

export interface PaymentDetails {
    approvalCode: string,
    billingName: string,
    cardBrand: string,
    orderId: string,
    totalAmount: string,
    accountNum: string
}

@Injectable({ providedIn: 'root' })
export class PaymentService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptionsService: ApiOptionsService
    ) {}

    getUid(amount: string, invoiceNumber: string): Observable<{details: string}> {
        const body = {
            txnType: DEFAULT_TYPE,
            amount: amount,
            orderId: invoiceNumber,
            formName: 'totaline-payment-form'
        };

        const options = this.apiOptionsService.getHttpOptions();

        return this.httpClient.post<{details: string}>(environment.api.payment as string, body, { ...options })
            .pipe(
                map((response: {details: string}) => response)
            );
    }

    getPaymentDetails(uid: string) {
        const options = this.apiOptionsService.getHttpOptions();

        return this.httpClient.get<{}>(`${environment.api.paymentConfirmation}${uid}` as string, { ...options })
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((response: any) => {
                    const details: PaymentDetails = {
                        approvalCode: response.txn_guid,
                        billingName: response.billing_name,
                        cardBrand: response.card_brand,
                        orderId: response.order_id,
                        totalAmount: response.total_amount,
                        accountNum: response.mpan
                    };

                    return details;
                })
            );
    }
}
