import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Option } from 'common/components/select/select.component';
import { AccountAdminEligibilityCriteriaService } from 'src/private/app/services/account-admin/account-admin-eligibility-criteria.service';
import { HvacAutoCompleteEvent } from '../hvac-autocomplete/hvac-auto-complete.component';
import { QueryBuilderConfig, RuleSet, Rule } from 'common/libs/angular2-query-builder/query-builder/query-builder.interfaces';

export enum ToggleType {
    And = 'and',
    Or = 'or'
}

@Component({
    selector: 'hvac-dynamic-nested-filter',
    templateUrl: './dynamic-nested-filter.component.html',
    styleUrls: ['./dynamic-nested-filter.component.scss']
})
export class DynamicNestedFilterComponent implements OnChanges {
    @Input() QueryBuilderConfigData: QueryBuilderConfig;
    @Input() QueryData: RuleSet;
    @Input() ProgramNameData$: BehaviorSubject<Option[]>;
    @Input() autoComplete$: BehaviorSubject<HvacAutoCompleteEvent>;
    @Output() updatedQueryData = new EventEmitter<RuleSet>();
    @Output() programNameString = new EventEmitter<string>();
    @Output() programNameWithPagination = new EventEmitter<boolean>();

    public config: QueryBuilderConfig;
    public query: RuleSet;
    public toggleTypeOr: string = ToggleType.Or;
    public programNameForm: UntypedFormGroup = new UntypedFormGroup({ programName: new UntypedFormControl('') });

    constructor(
        public eligibilityCriteriaService: AccountAdminEligibilityCriteriaService
    ) {
        this.config = { fields: {} };
    }

    ngOnInit() {
        this.programNameForm.controls.programName.valueChanges.subscribe((programName: string) => this.programNameString.emit(programName));
    }

    ngOnChanges() {
        this.config = this.QueryBuilderConfigData;
        this.query = this.QueryData;
    }

    onChanges($event: Option, rule: Rule) {
        if (rule.field === 'ProgramId') {
            rule.value = String($event.value);
        }

        return rule;
    }

    pagination() {
        this.programNameWithPagination.emit(true);
    }

    onQueryBuilderChange($event: RuleSet) {
        this.updatedQueryData.emit($event);
        const deleteButtonSelector = document.querySelector('.query-builder-delete-button');
        deleteButtonSelector?.setAttribute('style', 'display: none');
    }
}
