import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImportFile, UploadFile } from 'common/components/input-file/input-file.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { Program } from 'src/private/app/models/account-admin-program.model';

export interface ProgramList {
    name: string;
    value: string;
}

const enum ControlType {
    customer = 'customer',
    distributor = 'distributor'
}

@Injectable({ providedIn: 'root' })
export class AccountAdminEligibilityCriteriaService implements OnDestroy {
    public isCustomerTableVisible: boolean = false;
    public isDistributorTableVisible: boolean = false;
    public customerTotalPages: number = 10;
    public customerCurrentPage: number = 1;
    public radix: number = 10;
    public customerPageSize: number = 8;
    public customerTotalCompany: number = 0;
    public distributorTotalPages: number = 10;
    public distributorCurrentPage: number = 1;
    public distributorPageSize: number = 8;
    public distributorTotalCompany: number = 0;
    public isCustomerCompanyIDFiltered: boolean = false;
    public isDistributorCompanyIDFiltered: boolean = false;
    public currentPageAfterFilter: number = 1;
    public totalPagesAfterFilter: number = 1;
    public customerCompanyList$ = new BehaviorSubject<string[][]>([]);
    public distributorCompanyList$ = new BehaviorSubject<string[][]>([]);
    public customerCompanyfilteredData$ = new BehaviorSubject<string[][]>([]);
    public distributorCompanyfilteredData$ = new BehaviorSubject<string[][]>([]);
    public customerCompanySearchFilterData$ = new BehaviorSubject<string[][]>([]);
    public distributorCompanySearchFilterData$ = new BehaviorSubject<string[][]>([]);
    ngOnDestroy$ = new Subject();

    constructor(
        public translate: TranslateService
    ) {
    }

    public mapProgramDetails(data: Program[]) {
        return data.map((options) => ({
            programName: `${options.programName} (${options.status})`,
            programId: options.programId,
            brandName: options.brandName,
            programManagerEmails: options.programManagerEmails,
            programManagerEmailsCount: options.programManagerEmailsCount,
            status: options.status,
            criteriaName: options.criteriaName,
            code: options.code
        }));
    }

    public uploadedFile(uploadFileData: UploadFile) {
        (uploadFileData.control === ControlType.customer) ? this.isCustomerTableVisible = uploadFileData.isContentVisible : this.isDistributorTableVisible = uploadFileData.isContentVisible;
    }

    public importedFile(importFile: ImportFile) {
        const reader: FileReader = new FileReader();
        reader.readAsText(importFile.file);
        reader.onload = () => {
            (importFile.control === ControlType.customer) ? this.isCustomerTableVisible = importFile.isContentVisible : this.isDistributorTableVisible = importFile.isContentVisible;
            const csv: string = reader.result as string;
            const csvList: string[][] = csv.split('\n').filter((line) => line.trim() !== '').map((line) => line.split(','));
            (importFile.control === ControlType.customer) ? this.getCustomerIdValue(csvList) : this.getDistributorIdValue(csvList);
        };
        (importFile.control === ControlType.customer) ? this.customerCurrentPage = 1 : this.distributorCurrentPage = 1;
    }

    public getCustomerIdValue(csvList: string[][]) {
        this.isCustomerTableVisible = true;
        const customerTotalCompaniesCount = csvList.filter((companyList, index) => index === csvList.findIndex((item) => item[0] === companyList[0]));
        this.customerTotalCompany = customerTotalCompaniesCount.length;
        this.customerCompanyfilteredData$.next(customerTotalCompaniesCount);
        this.customerCompanyList$.next(customerTotalCompaniesCount.slice((this.customerCurrentPage - 1) * this.customerPageSize, this.customerCurrentPage * this.customerPageSize));
        this.customerCompanySearchFilterData$.next(customerTotalCompaniesCount.slice((this.customerCurrentPage - 1) * this.customerPageSize, this.customerCurrentPage * this.customerPageSize));
        this.customerTotalPages = Math.ceil(customerTotalCompaniesCount.length / this.customerPageSize);
    }

    public getDistributorIdValue(csvList: string[][]) {
        this.isDistributorTableVisible = true;
        const distributorTotalCompaniesCount = csvList.filter((companyList, index) => index === csvList.findIndex((item) => item[0] === companyList[0]));
        this.distributorTotalCompany = distributorTotalCompaniesCount.length;
        this.distributorCompanyfilteredData$.next(distributorTotalCompaniesCount);
        this.distributorCompanyList$.next(distributorTotalCompaniesCount.slice((this.distributorCurrentPage - 1) * this.distributorPageSize, this.distributorCurrentPage * this.distributorPageSize));
        this.distributorCompanySearchFilterData$.next(distributorTotalCompaniesCount.slice((this.distributorCurrentPage - 1) * this.distributorPageSize, this.distributorCurrentPage * this.distributorPageSize));
        this.distributorTotalPages = Math.ceil(distributorTotalCompaniesCount.length / this.distributorPageSize);
    }

    public handleCustomerPageChange(page: number) {
        this.customerCurrentPage = parseInt(page.toString(), this.radix);
        this.customerCompanySearchFilterData$.next(
            this.customerCompanyfilteredData$.value.slice((this.customerCurrentPage - 1) * this.customerPageSize, this.customerCurrentPage * this.customerPageSize)
        );
    }

    public handleDistributorPageChange(page: number) {
        this.distributorCurrentPage = parseInt(page.toString(), this.radix);
        this.distributorCompanySearchFilterData$.next(
            this.distributorCompanyfilteredData$.value.slice((this.distributorCurrentPage - 1) * this.distributorPageSize, this.distributorCurrentPage * this.distributorPageSize)
        );
    }

    public removeCompanyId(company: string[]) {
        const index = this.customerCompanyfilteredData$.value.indexOf(company);
        this.customerCompanyfilteredData$.value.splice(index, 1);
        this.customerTotalCompany -= 1;

        this.customerCompanyList$.next(
            this.customerCompanyfilteredData$.value.slice((this.customerCurrentPage - 1) * this.customerPageSize, this.customerCurrentPage * this.customerPageSize)
        );
        this.customerCompanySearchFilterData$.next(
            this.customerCompanyfilteredData$.value.slice((this.customerCurrentPage - 1) * this.customerPageSize, this.customerCurrentPage * this.customerPageSize)
        );
        this.customerTotalPages = Math.ceil(this.customerCompanyfilteredData$.value.length / this.customerPageSize);
    }

    public removeDistributorId(company: string[]) {
        const index = this.distributorCompanyfilteredData$.value.indexOf(company);
        this.distributorCompanyfilteredData$.value.splice(index, 1);
        this.distributorTotalCompany -= 1;
        this.distributorCompanyList$.next(
            this.distributorCompanyfilteredData$.value.slice((this.distributorCurrentPage - 1) * this.distributorPageSize, this.distributorCurrentPage * this.distributorPageSize)
        );
        this.distributorCompanySearchFilterData$.next(
            this.distributorCompanyfilteredData$.value.slice((this.distributorCurrentPage - 1) * this.distributorPageSize, this.distributorCurrentPage * this.distributorPageSize)
        );
        this.distributorTotalPages = Math.ceil(this.distributorCompanyfilteredData$.value.length / this.distributorPageSize);
    }

    filterCustomerID(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input.value) {
            this.isCustomerCompanyIDFiltered = true;
            this.customerCompanySearchFilterData$.next(
                this.customerCompanyfilteredData$.value.filter((res) => res[0].trim().localeCompare(input.value) === 0)
            );
        }
        else {
            this.isCustomerCompanyIDFiltered = false;
            this.customerCompanySearchFilterData$.next(this.customerCompanyList$.value);
        }
    }

    filterDistributorID(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input.value) {
            this.isDistributorCompanyIDFiltered = true;
            this.distributorCompanySearchFilterData$.next(
                this.distributorCompanyfilteredData$.value.filter((res) => res[0].trim().localeCompare(input.value) === 0)
            );
        }
        else {
            this.isDistributorCompanyIDFiltered = false;
            this.distributorCompanySearchFilterData$.next(this.distributorCompanyList$.value);
        }
    }

    ngOnDestroy() {
        this.isCustomerTableVisible = false;
        this.isDistributorTableVisible = false;
        this.customerCompanyList$.next([]);
        this.distributorCompanyList$.next([]);
        this.customerCompanyfilteredData$.next([]);
        this.distributorCompanyfilteredData$.next([]);
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.customerCompanySearchFilterData$.next([]);
        this.distributorCompanySearchFilterData$.next([]);
    }
}
