import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AccessToken, IdToken, Tokens, User } from 'common/models/auth';
import { CaptureSignInError, Login, LogOut, SetUser, SignInError, UpdateTokens } from './auth.actions';

export enum LoggedIn {
    LoggedIn = 'Logged In',
    LoggedOut = 'Logged Out',
    Unknown = 'Unknown'
}

export interface AuthStateModel {
    tokens: { idToken?: IdToken, accessToken?: AccessToken };
    user: User|null;
    loggedIn: LoggedIn;
    signInError: SignInError|null;
}

@State<AuthStateModel>({
    name: 'logged',
    defaults: {
        tokens: {},
        user: null,
        loggedIn: LoggedIn.Unknown,
        signInError: null
    }
})
@Injectable({ providedIn: 'root' })
export class AuthState {
    @Selector()
    static loggedIn({ loggedIn }: AuthStateModel): LoggedIn {
        return loggedIn;
    }

    @Selector()
    static isLoggedIn({ loggedIn }: AuthStateModel): Boolean {
        return loggedIn === LoggedIn.LoggedIn;
    }

    @Selector()
    static isLoggedOut({ loggedIn }: AuthStateModel): Boolean {
        return loggedIn === LoggedIn.LoggedOut;
    }

    @Selector()
    static user({ user }: AuthStateModel): User|null {
        return user;
    }

    @Selector()
    static tokens({ tokens }: AuthStateModel): Tokens {
        return { tokens };
    }

    @Selector()
    static idToken({ tokens }: AuthStateModel): IdToken|undefined {
        return tokens.idToken;
    }

    @Selector()
    static signInError({ signInError }: AuthStateModel): SignInError|null {
        return signInError;
    }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, { user, token }: Login) {
        ctx.patchState({
            tokens: {
                idToken: token.tokens.idToken,
                accessToken: token.tokens.accessToken
            },
            loggedIn: LoggedIn.LoggedIn
        });
        ctx.dispatch(new SetUser(user));
    }

    @Action(SetUser)
    setUser(ctx: StateContext<AuthStateModel>, { user }: SetUser) {
        ctx.patchState({ user });
    }

    @Action(LogOut)
    logout(ctx: StateContext<AuthStateModel>) {
        return ctx.patchState({
            tokens: {},
            user: null,
            loggedIn: LoggedIn.LoggedOut
        });
    }

    @Action(UpdateTokens)
    updateTokens(ctx: StateContext<AuthStateModel>, { token }: UpdateTokens) {
        ctx.patchState({
            tokens: {
                idToken: token.tokens.idToken,
                accessToken: token.tokens.accessToken
            }
        });
    }

    @Action(CaptureSignInError)
    captureSignInError(ctx: StateContext<AuthStateModel>, { error }: CaptureSignInError) {
        // Leaving a console warning here! This may be helpful in debugging
        // login issues for users.
        // eslint-disable-next-line no-console
        console.warn(error.error);
        ctx.patchState({ signInError: error });
    }
}
