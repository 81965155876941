/* eslint-disable require-await */
/* eslint-disable max-lines */
/* eslint-disable no-multi-str */
/* tslint:disable:max-file-line-count */
import { Inject, Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
// @ts-ignore
import { OktaAuth } from '@okta/okta-auth-js';
// @ts-ignore
import OktaSignIn from '@okta/okta-signin-widget';
import { environment } from 'common/environments/environment';
import { AccessToken, IdToken, TokenMeta, RefreshToken, Redirect, User, UserProfile } from 'common/models/auth';
import { CaptureSignInError, Login, LogOut, UpdateTokens } from 'common/store/auth/auth.actions';
import { ClearBranding } from 'common/store/branding/branding.actions';
import { isIE11, isiOS12 } from 'common/utils/user-agent';
import { WINDOW } from 'common/window.provider';
import { BehaviorSubject } from 'rxjs';
import { HVAC_REDIRECT_KEY, HVAC_SESSION_EVENT, OktaBaseService, SessionEvent } from './okta-base.service';
import { BrandService } from '../brand.service';
import { HeapService } from '../heap.service';
import { CookieService } from '../cookie/cookie.service';
import { AppConstants } from 'common/app-constants';

const ID_TOKEN = 'idToken';
const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const REDIRECT_EXCLUSIONS: string[] = ['/sign-out', '/logout', '/callback', '/login-error'];
const FULL_USER_GROUP = 'Carrier_HVACPartners';
const TOKEN_BRAND_PREFIX = 'Brand_';
const MAX_TOKEN_ERROR_COUNT = 3;

@Injectable({ providedIn: 'root' })
export class OktaService implements OktaBaseService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public idToken$ = new BehaviorSubject<any>({});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private widget: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private client: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private usePkce: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private auth: any;
    private authScopes: string[] = ['openid', 'email', 'profile', 'groups', 'offline_access'];
    private isICP = this.brandService.isICPBrand(environment.brand);
    private enableSocialLogin: boolean;
    private tokenErrorCount = 0;

    constructor(
        private readonly store: Store,
        @Inject(WINDOW) private readonly window: Window,
        private readonly brandService: BrandService,
        private heapService: HeapService,
        private cookieService: CookieService
    ) {
        this.auth = { ...environment.auth };

        this.usePkce = OktaAuth.features.isPKCESupported();

        this.enableSocialLogin = environment.features.socialLogin;

        // Need a separate client for public sites to resume session without
        // redirecting to secure site.
        this.client = new OktaAuth({
            issuer: `${this.auth.baseUrl}/oauth2/default`,
            clientId: this.auth.clientId,
            pkce: this.usePkce,
            maxClockSkew: 600,
            redirectUri: this.auth.redirectUri,
            postLogoutRedirectUri: this.auth.postLogoutRedirectUri,
            tokenManager: { storage: 'sessionStorage' },
            responseType: 'code',
            scopes: this.authScopes
        });
        this.client.start();
        this.client.tokenManager.on('renewed', (key: string, newToken: IdToken | undefined) => {
            if (key === ID_TOKEN && newToken) {
                try {
                    this.validateTokenClaims(newToken);
                    this.store.dispatch(new UpdateTokens({ tokens: { idToken: newToken } }));
                }
                catch (err) {
                    console.error('Error validiting Token claims', err);
                    // Missing claims issues should be temporary. Retry after a brief wait
                    // and issue should resolve itself before the token expires. If this
                    // continually fails after 3 requests we will force a logout.
                    setTimeout(() => {
                        if (this.tokenErrorCount > MAX_TOKEN_ERROR_COUNT) {
                            this.client.tokenManager.clear();
                            this.logout();
                        }
                        this.tokenErrorCount += 1;
                        this.client.tokenManager.renew('idToken');
                    }, 10000);
                }
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.client.tokenManager.on('error', (err: any) => {
            console.error('Error reauthorizing token', err);
            this.client.tokenManager.clear();
            this.logout();
        });
    }

    removeWidget() {
        if (this.widget) {
            this.widget.remove();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setupWidget(elementSelector: string, options: any) {
        this.removeWidget();

        this.widget = new OktaSignIn({
            baseUrl: this.auth.baseUrl,
            language: 'en',
            i18n: {
                // Overrides default text when using English. Override other languages by adding additional sections.
                en: {
                    // Changes the sign in text
                    'primaryauth.title': 'Sign In',
                    // Changes the sign in button
                    'primaryauth.submit': 'Sign In',
                    'primaryauth.help': 'Change Password?',
                    'password.forgot.email.or.username.placeholder': 'Username',
                    'password.forgot.email.or.username.tooltip': 'Username',
                    'account.unlock.email.or.username.placeholder': 'Username',
                    'account.unlock.email.or.username.tooltip': 'Username',
                    'help': `Help? Call ${this.isICP ? '800-451-0911' : '800-844-2042'}`,
                    'contact.support': 'Please contact HVACpartners support at {0}',
                    'password.forgot.emailSent.desc': 'You entered {0}. If this user name is associated with an existing account, then \
                        you will receive an email with a link to reset your password.',
                    'account.unlock.emailSent.desc': 'You entered {0}. If this user name is associated with an existing account, then \
                        you will receive an email with a link to unlock your account.',
                    'socialauth.divider.text': 'Or',
                    'socialauth.apple.label': 'Continue with Apple',
                    'socialauth.google.label': 'Continue with Google'
                }
            },
            clientId: this.auth.clientId,
            idps: this.enableSocialLogin ? this.auth.identityProviders : [],
            idpDisplay: this.enableSocialLogin ? environment.socialLogin?.idpDisplay : '',
            redirectUri: this.auth.redirectUri,
            postLogoutRedirectUri: this.auth.postLogoutRedirectUri,
            authParams: {
                pkce: this.usePkce,
                scopes: this.authScopes,
                maxClockSkew: 600
            },
            useClassicEngine: true,
            features: {
                rememberMe: true,
                multiOptionalFactorEnroll: true,
                selfServiceUnlock: true,
                smsRecovery: false,
                callRecovery: false
            },
            helpLinks: {
                help: 'tel:1-800-844-2042',
                custom: [{ text: this.enableSocialLogin ? 'Issues signing in with Google or Apple?' : '' }]
            },
            helpSupportNumber: '800-844-2042',
            ...options
        });

        this.widget.showSignInAndRedirect(
            { el: elementSelector }
        );
    }

    async login(username: string, password: string) {
        return this.client.signInWithCredentials({
            username,
            password
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).then((transaction: any) => {
            if (transaction.status === 'SUCCESS') {
                this.client.token.getWithRedirect({
                    scopes: this.authScopes,
                    redirectUri: this.auth.redirectUri,
                    postLogoutRedirectUri: this.auth.postLogoutRedirectUri,
                    prompt: 'none'
                });

                return;
            }

            throw new Error(`Login failed with status: ${transaction.status}`);
        });
    }

    logout(redirect?: string) {
        this.client.stop();
        this.store.dispatch(new LogOut());
        this.store.dispatch(new ClearBranding());
        localStorage.setItem(HVAC_SESSION_EVENT, SessionEvent.LOGOUT);
        if (isIE11(this.window.navigator)) {
            this.client.signOut({ postLogoutRedirectUri: redirect }).then(() => {
                if (redirect) {
                    this.window.location.href = redirect;
                }
                else {
                    this.store.dispatch(new Navigate(['/logout']));
                }
            });
        }
        else {
            let options = {};
            if (redirect) {
                options = { postLogoutRedirectUri: redirect };
            }
            this.client.signOut(options);
        }
    }

    async resumeSecureSession() {
        if (this.isLoginRedirect()) {
            return;
        }

        const tokens = await this.getStoredTokens();
        if (this.hasIdToken(tokens)) {
            this.startSession(tokens);
        }
        else {
            this.window.sessionStorage.setItem(HVAC_REDIRECT_KEY, this.generateRedirectObject());
            this.initiateAuthRedirect();
        }
    }

    initiateAuthRedirect(redirectUri?: string) {
        this.client.token.getWithRedirect({
            scopes: this.authScopes,
            redirectUri: redirectUri ? redirectUri : this.auth.redirectUri,
            postLogoutRedirectUri: this.auth.postLogoutRedirectUri,
            prompt: 'none'
        })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((err: any) => {
                this.store.dispatch(new CaptureSignInError(err));
                this.store.dispatch(new LogOut());
            });
    }

    validateToken(token: string) {
        return !this.client.tokenManager.hasExpired(token);
    }

    async getTokenBrands() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.client.tokenManager.get(ID_TOKEN).then((token: any) => {
            if (token && token.claims.idm_user_roles) {
                return token.claims.idm_user_roles
                    .filter((claim: string) => claim.startsWith(TOKEN_BRAND_PREFIX))
                    .map((claim: string) => claim.substring(6));
            }

            return [];
        });
    }

    async resumePublicSession() {
        const tokens = this.getStoredTokens();
        const idToken = (await tokens).idToken;
        const accessToken = (await tokens).accessToken;
        const refreshToken = (await tokens).refreshToken;
        if (idToken && accessToken && refreshToken) {
            try {
                this.startSession({
                    idToken,
                    accessToken,
                    refreshToken
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catch (err: any) {
                this.store.dispatch(new CaptureSignInError(err));
                this.store.dispatch(new LogOut());
            }
        }
        else {
            this.store.dispatch(new LogOut());
        }
    }

    public storeIDToken(idToken: Partial<IdToken>) {
        if (!idToken) {
            return;
        }

        this.idToken$.next(idToken);
    }


    async parseToken(routerState: RouterStateSnapshot) {
        if (routerState.root.queryParams['error'] === 'login_required') {
            this.client.tokenManager.clear();
            throw new Error('login_required');
        }
        if (routerState.root.queryParams['error'] === 'access_denied') {
            this.client.signOut({ postLogoutRedirectUri: environment.loginErrorUrl });
            throw new Error('access_denied');
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.client.token.parseFromUrl({ scopes: this.authScopes }).then((res: any) => {
            this.validateTokenClaims(res.tokens.idToken);

            this.storeIDToken(res.tokens.idToken);

            return this.startSession(res.tokens);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((err: any) => {
                this.store.dispatch(new CaptureSignInError(err));
                // this error occurs on a successful login on iOS12; workaround is to initiate a  new auth redirect
                // which will pickup the valid tokens and bring the user to the dashboard
                if (isiOS12(this.window.navigator) &&
                    (err.message === 'OAuth flow response state doesn\'t match request state'
                        || err.message === 'Unable to retrieve OAuth redirect params from storage')) {
                    this.initiateAuthRedirect(environment.secureCallbackUrl as string);

                    return;
                }
                throw err;
            });
    }

    private async getStoredTokens(): Promise<{ idToken?: IdToken, accessToken?: AccessToken, refreshToken?: RefreshToken }> {
        const idToken = await this.client.tokenManager.get(ID_TOKEN);
        const idIsValid = idToken && !this.client.tokenManager.hasExpired(idToken);
        const accessToken = await this.client.tokenManager.get(ACCESS_TOKEN);
        const accessIsValid = accessToken && !this.client.tokenManager.hasExpired(accessToken);
        const refreshToken = await this.client.tokenManager.get(REFRESH_TOKEN);
        const refreshValid = refreshToken && !this.client.tokenManager.hasExpired(refreshToken);

        return {
            ...(idIsValid ? { idToken: idToken } : {}),
            ...(accessIsValid ? { accessToken: accessToken } : {}),
            ...(refreshValid ? { refreshToken: refreshToken } : {})
        };
    }

    private hasIdToken(tokens: { idToken?: IdToken }): tokens is { idToken: IdToken } {
        return typeof tokens.idToken !== 'undefined';
    }

    private validateTokenClaims(token: TokenMeta): void {
        if (token.claims.groups.includes(FULL_USER_GROUP) && !token.claims.idm_user_roles) {
            throw new Error('Token missing claims');
        }
    }

    private async startSession(tokens: { idToken: IdToken, accessToken?: AccessToken, refreshToken?: RefreshToken }) {
        const storedTokens = await this.getStoredTokens();

        if (this.hasIdToken(tokens) && storedTokens?.idToken) {
            this.storeIDToken(storedTokens.idToken);
        }

        this.client.tokenManager.add(ID_TOKEN, tokens.idToken);
        if (tokens.accessToken) {
            this.client.tokenManager.add(ACCESS_TOKEN, tokens.accessToken);
        }

        if (tokens.refreshToken) {
            this.client.tokenManager.add(REFRESH_TOKEN, tokens.refreshToken);
        }

        return this.client.token.getUserInfo()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((userInfo: any) => {
                const user: User = {} as User;
                const userProfile: UserProfile = {} as UserProfile;
                userProfile.lastName = userInfo.family_name;
                userProfile.firstName = userInfo.given_name;
                userProfile.email = userInfo.email;
                userProfile.login = userInfo.preferred_username;
                user.profile = userProfile;


                const consentCookie = this.cookieService.getCookie(AppConstants.CONSENT_COOKIE) ?? '';
                if (decodeURIComponent(consentCookie).includes(AppConstants.CONSENT_COOKIE_VALUE)) {
                    this.heapService.identify(userInfo.preferred_username);
                }

                return this.store.dispatch(new Login(user, { tokens }));
            });
    }

    private isLoginRedirect() {
        // two checks needed becasue Okta's method does not catch everything
        return this.client.isLoginRedirect()
            || this.window.location.href.indexOf('/callback') !== -1
            || this.window.location.href.indexOf('/sign-out') !== -1
            || this.window.location.href.indexOf('/logout') !== -1
            || this.window.location.href.indexOf('/login-error') !== -1
            || this.window.location.href.indexOf('/secure-callback') !== -1
            || this.window.location.href.indexOf('/create-account-information') !== -1;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private generateRedirectObject(): any {
        const redirect: Redirect = {} as Redirect;
        const url = new URL(this.window.location.href);
        const path = url.pathname;
        redirect.path = (REDIRECT_EXCLUSIONS.includes(path)) ? '' : path;
        if (url.search) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const query: any = {};
            url.searchParams.forEach((value, key) => {
                query[key] = value;
            });
            redirect.query = query;
        }

        return JSON.stringify(redirect);
    }
}
