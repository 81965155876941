import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { Scored, SuggestionItem, SuggestionsQuery } from 'common/models';
import { SuggestionsCategoriesModel, SuggestionsState } from 'common/store/suggestions/suggestions.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'common/environments/environment';

interface MappedResults {
    title: string;
    results: (SuggestionItem & Scored)[];
}
@Component({
    selector: 'hvac-auto-suggest',
    templateUrl: './auto-suggest.component.html',
    styleUrls: ['./auto-suggest.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AutoSuggestComponent {
    @Input() show: boolean = true;
    @Select(SuggestionsState.suggestionsCategories) suggestionsCategories$!: Observable<SuggestionsCategoriesModel>;
    @Select(SuggestionsState.query) suggestionsQuery$!: Observable<SuggestionsQuery>;
    @Select(SuggestionsState.suggestionsLoading) suggestionsLoading$!: Observable<Boolean>;

    searchTerms = this.suggestionsQuery$.pipe(map((query) => query.q));
    resultCategories$ = this.suggestionsCategories$.pipe(
        map((suggestionsCategories) => {
            const categories = [{
                title: 'Documents',
                results: [],
                ...suggestionsCategories.documents
            }, {
                title: 'Products',
                results: [],
                ...suggestionsCategories.products
            }];

            if (environment.features.searchContent) {
                categories.push({
                    title: 'Content',
                    results: [],
                    ...suggestionsCategories.content
                });
            }

            if (environment.brand === 'Totaline' || environment.features.partsCatalog) {
                categories.splice(2, 0, {
                    title: 'Parts',
                    results: [],
                    ...suggestionsCategories.parts
                });
            }

            return categories;
        })
    );

    hasAnyResults$ = this.resultCategories$.pipe(
        map((resultCategories) => resultCategories.some((category: MappedResults) => Boolean(category.results.length)))
    );

    setAutoSuggestSectionIcon(title: String) {
        if (title && title.toLowerCase() === 'content') {
            return 'assets/icons/browser-frame.svg';
        }

        if (title && title.toLowerCase() === 'documents') {
            return 'assets/icons/document.svg';
        }

        return 'assets/icons/default.svg';
    }
}
