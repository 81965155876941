import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestHeadersInterceptor } from './request-headers.interceptor';
import { UnauthenticatedInterceptor } from './unauthenticated.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: UnauthenticatedInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestHeadersInterceptor,
        multi: true
    }
];
