import { DataTableFilterOption, DataTableLayout, DataTableSort, DataTableSortDirection } from 'common/models/data-table';
import { DocumentField, DocumentFieldName, DocumentQuery } from 'common/models/document';

export interface QueryInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generateQuery(data: any): DocumentQuery;
    filter(documents: string[]): string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields(data: any): DocumentField[];
    fetchFilterOptions(documents: string[]): DataTableFilterOption[];
    filterByType(documents: string[]): DataTableFilterOption[];
    applyFilterOption(documents: string[], option: DataTableFilterOption): string[];
    filterBrands(data: string, brands: string[]): string[];
    pageSize(data: number | string): number;
    layout(): DataTableLayout;
    isSearchable(data: string | boolean): boolean;
    enableLanguageFilter(data: string): boolean;
    enableStatusFilter(data: string): boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sort(data: any): DataTableSort;
    updateFilterOptions(documents: string[], filterOptions: DataTableFilterOption[]): DataTableFilterOption[];

}

export abstract class QueryBase {
    fieldFilters = [
        {
            fieldName: DocumentFieldName.DOCUMENT_TITLE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.DocumentTitle.toLowerCase(), b.DocumentTitle.toLowerCase())
        },
        {
            fieldName: DocumentFieldName.FORM_NUMBER,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.FormNumber.toLowerCase(), b.FormNumber.toLowerCase())
        },
        {
            fieldName: DocumentFieldName.SUB_TYPE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.Subtype.join().toLowerCase(), b.Subtype.join().toLowerCase())
        },
        {
            fieldName: DocumentFieldName.BRAND,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.Brands.join().toLowerCase(), b.Brands.join().toLowerCase())
        },
        {
            fieldName: DocumentFieldName.STATUS,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.Status.toLowerCase(), b.Status.toLowerCase())
        },
        {
            fieldName: DocumentFieldName.COMMENTS,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(a.Comments.toLowerCase(), b.Comments.toLowerCase())
        },
        {
            fieldName: DocumentFieldName.RELEASE_DATE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(new Date(a.ReleaseDate), new Date(b.ReleaseDate))
        },
        {
            fieldName: DocumentFieldName.PRINT_DATE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(new Date(a.PrintDate), new Date(b.PrintDate))
        },
        {
            fieldName: DocumentFieldName.CATALOG_NUMBER,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortFunction: (a: any, b: any) => this.compareFunction(new Date(a.CatalogueNumber), new Date(b.CatalogueNumber))
        }
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTagData(data: any, tagName: string, required = true): any {
        let tagValue = null;
        if (data.Content.tags && data.Content.tags.$values) {
            const matchingTag = data.Content.tags.$values.find((tag:string) => tag.includes(tagName));
            if (matchingTag) {
                tagValue = matchingTag.split(':')[1];
            }
        }
        else if (data.Content.tags && ((typeof data.Content.tags) === 'string')) {
            tagValue = data.Content.tags.split(':')[1];
        }
        if (required && !tagValue) {
            throw new Error(`Required ${tagName} is not defined`);
        }

        return tagValue;
    }

    filter(documents: string[]): string[] {
        return documents;
        // no filters
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields(data: any): DocumentField[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.fieldFilters.filter((fieldFilter: any) => this.fieldsToDisplay(data).includes(fieldFilter.fieldName));
    }

    fetchFilterOptions(_documents: string[]): DataTableFilterOption[] {
        return [];
        // no filters
    }

    filterByType(_documents: string[]): DataTableFilterOption[] {
        return [];
        // no filters
    }

    applyFilterOption(documents: string[], _option: DataTableFilterOption): string[] {
        return documents;
        // no filters
    }

    filterBrands(_data: string, brands: string[]): string[] {
        return brands;
        // no filters
    }

    updateFilterOptions(_documents: string[], filterOptions: DataTableFilterOption[]): DataTableFilterOption[] {
        return filterOptions;
    }


    pageSize(data: number): number {
        return this.getTagData(data, 'pagesize');
    }

    layout(): DataTableLayout {
        return DataTableLayout.TABLE;
    }

    isSearchable(data: string) {
        return this.getTagData(data, 'search', false) === 'on';
    }

    enableLanguageFilter(data: string) {
        return this.getTagData(data, 'language', false) === 'on';
    }

    enableStatusFilter(data: string) {
        return this.getTagData(data, 'status', false) === 'on';
    }

    sort(_data: string): DataTableSort {
        return {
            sortFieldName: DocumentFieldName.PRINT_DATE,
            sortDirection: DataTableSortDirection.DESCENDING
        } as DataTableSort;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    compareFunction(a: any, b: any) {
        let comparison = 0;
        if (a < b) {
            comparison = -1;
        }
        else if (a > b) {
            comparison = 1;
        }

        return comparison;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldsToDisplay(data: any): string[] {
        if (data.Content.fieldsToDisplay && data.Content.fieldsToDisplay.$values) {
            return data.Content.fieldsToDisplay.$values.map((field: {Key:string}) => field.Key);
        }
        if (data.Content.fieldsToDisplay && data.Content.fieldsToDisplay.Key) {
            return [data.Content.fieldsToDisplay.Key];
        }

        return [];
    }
}
