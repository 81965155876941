import { Component, Input } from '@angular/core';
import { Link } from 'common/models/link';
import { ProductDescriptor } from 'common/models/product-descriptor';

@Component({
    selector: 'hvac-system-product-card',
    templateUrl: './system-product-card.component.html',
    styleUrls: ['./system-product-card.component.scss']
})
export class SystemProductCardComponent {
    @Input() image: string = '';
    @Input() name: string;
    @Input() showFaultIndicator? = false;
    @Input() descriptors: ProductDescriptor[];
    @Input() productLink?: Link;
    @Input() systemType?: string;
    @Input() type?: string;
    @Input() noDataMessage?: string;
    @Input() isUpdateEnabled?: boolean;
}
