import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ContentService } from 'common/services/content.service';
import { UIContentResponse } from 'common/models';
import { SetBrand } from '../branding/branding.actions';
import { ClearUIPageContent, ReceiveUIContent, SetBrandLists, SetContentBrandPreference, SetContentFilteringPreference, SetContentLoading, SetDealerAccessCheckLoading } from './content.actions';

export type ContentFilteringPreferenceType = 'Res&Com' | 'Residential' | 'Commercial';
export interface ContentStateModel {
    uiContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        footer: any;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uiPageContent: any;
    contentLoading: boolean;
    contentBrand: string;
    contentFilteringPreference: ContentFilteringPreferenceType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentBrandList: any[];
    dealerAccessCheckLoading: boolean;
}

@State<ContentStateModel>({
    name: 'uiContent',
    defaults: {
        uiContent: {
            header: {},
            footer: {}
        },
        uiPageContent: {},
        contentLoading: false,
        // labeled content to filter by
        contentFilteringPreference: 'Res&Com',
        // branded content to filter by
        contentBrand: 'default',
        // list of available brands to filter content by
        contentBrandList: [],
        dealerAccessCheckLoading: false
    }
})
@Injectable({ providedIn: 'root' })
export class ContentState {
    constructor(
        private readonly contentApiService: ContentService,
        private readonly store: Store
    ) {}

    @Selector()
    static header({ uiContent }: ContentStateModel): UIContentResponse {
        return uiContent.header;
    }

    @Selector()
    static footer({ uiContent }: ContentStateModel): UIContentResponse {
        return uiContent.footer;
    }

    @Selector()
    static uiPageContent({ uiPageContent }: ContentStateModel): UIContentResponse {
        return uiPageContent;
    }

    @Selector()
    static contentLoading({ contentLoading }: ContentStateModel): boolean {
        return contentLoading;
    }

    @Selector()
    static contentFilteringPreference({ contentFilteringPreference }: ContentStateModel): ContentFilteringPreferenceType {
        return contentFilteringPreference;
    }

    @Selector()
    static contentBrand({ contentBrand }: ContentStateModel): string {
        return contentBrand;
    }

    @Selector()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static contentBrandList({ contentBrandList }: ContentStateModel): any[] {
        return contentBrandList;
    }

    @Selector()
    static dealerAccessCheckLoading({ dealerAccessCheckLoading }: ContentStateModel): boolean {
        return dealerAccessCheckLoading;
    }

    @Action(SetContentLoading)
    setContentLoader(ctx: StateContext<ContentStateModel>, action: SetContentLoading) {
        if (action.loading) {
            ctx.patchState({ contentLoading: action.loading });
        }
        else {
            setTimeout(() => {
                ctx.patchState({ contentLoading: action.loading });
            }, 1500);
        }
    }

    @Action(ReceiveUIContent)
    receiveUIContent(ctx: StateContext<ContentStateModel>, action: ReceiveUIContent) {
        const headerContent = action.uiContent.Regions ?
        // Seems to be a typo in Tridion templates for one of the publications and
        // the "Header" region may be "Headerd" so we need to be able to match both.
            action.uiContent.Regions.find((region) => region.Name === 'Header' || region.Name === 'Headerd')
            : {};
        const footerContent = action.uiContent.Regions ? action.uiContent.Regions.find((region) => region.Name === 'Footer') : {};

        this.contentApiService.contentResponse$.next(action.uiContent);

        return ctx.patchState({
            uiContent: {
                header: headerContent,
                footer: footerContent
            }
        });
    }

    @Action(ClearUIPageContent)
    clearUIContent(ctx: StateContext<ContentStateModel>) {
        ctx.patchState({ uiPageContent: {} });
    }

    @Action(SetContentFilteringPreference)
    setContentFilteringPreference(ctx: StateContext<ContentStateModel>, action: SetContentFilteringPreference) {
        ctx.patchState({ contentFilteringPreference: action.preferenceType });
    }

    @Action(SetContentBrandPreference)
    setContentBrandPreference(ctx: StateContext<ContentStateModel>, action: SetContentBrandPreference) {
        const updateBrand = action.brand === 'All Brands' ? 'default' : action.brand;
        ctx.patchState({ contentBrand: updateBrand });
        this.store.dispatch(new SetBrand(updateBrand));
    }

    @Action(SetBrandLists)
    setBrandLists(ctx: StateContext<ContentStateModel>, action: SetBrandLists) {
        return ctx.patchState({ contentBrandList: action.brandList });
    }

    @Action(SetDealerAccessCheckLoading)
    setDealerAccessCheckLoader(ctx: StateContext<ContentStateModel>, action: SetDealerAccessCheckLoading) {
        if (action.loading) {
            ctx.patchState({ dealerAccessCheckLoading: action.loading });
        }
        else {
            setTimeout(() => {
                ctx.patchState({ dealerAccessCheckLoading: action.loading });
            }, 1500);
        }
    }
}
