import { Tokens, User } from 'common/models/auth';
import { TranslatableError } from 'common/models/create-account-errors';

export class SignInError extends TranslatableError {
    public readonly content: string = 'ERROR_CONTENT.SIGN_IN.SIGN_IN_ERROR';
    public readonly name: string = 'signInError';
    constructor(public readonly error: Error) {
        super();
    }
}

export class Login {
    static readonly type = '[auth] Login';
    constructor(public readonly user: User, public readonly token: Tokens) {}
}

export class SetUser {
    static readonly type = '[auth] SetUser';
    constructor(public readonly user: User) {}
}

export class LogOut {
    static readonly type = '[auth] LogOut';
}

export class UpdateTokens {
    static readonly type = '[auth] UpdateTokens';
    constructor(public readonly token: Tokens) {}
}

export class CaptureSignInError {
    static readonly type = '[auth] SignInError';
    public readonly error: SignInError;

    constructor(error: Error) {
        this.error = new SignInError(error);
    }
}
