<div class='hvac-dropdown-navigation-trigger-container' [ngClass]="{'hvac-dropdown-menu-expanded': menuActive}">
    <button [title]="menuActive ? 'Close Menu' : 'Open Menu'" class="hvac-dropdown-menu-selected hvac-dropdown-navigation-icon-trigger" (click)="toggleMenu()">
        <utc-svg *ngIf="iconSrc" [src]="iconSrc"></utc-svg>
    </button>
</div>
<div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{'hvac-dropdown-menu-expanded': menuActive}">
    <ol class="hvac-dropdown-menu-options">
        <li class="hvac-dropdown-menu-option-item" *ngFor="let option of menuOptions">
            <a *ngIf="option.action === 'link'; else newTab" (click)="setOption(option)" class="hvac-dropdown-menu-option" target="_self"
                [routerLink]="[option.value]">
                {{ option.title }}
            </a>
            <ng-template #newTab>
                <a class="hvac-dropdown-menu-option" (click)="setOption(option)" href="{{option.value}}" target="_blank">
                    {{ option.title }}
                </a>
            </ng-template>
        </li>
    </ol>
</div>
