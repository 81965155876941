import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

enum Media {
    Video = 'video',
    Image = 'image',
    Svg = 'svg',
    None = 'none'
}

@Component({
    selector: 'utc-media',
    templateUrl: './media.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MediaComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() content: any;
    media: {
        BinaryContent: {
            Url: string;
        }
    };

    video: {
        Content: {
            videoUrl: string;
        }
    };

    mediaType: Media;

    ngOnInit() {
        this.media = this.content.primaryMedia;
        this.video = this.content.video;
        this.mediaType = this.detectMediaType();
    }

    detectMediaType() {
        if (this.content.video) {
            return Media.Video;
        }

        if (!this.content.primaryMedia) {
            return Media.None;
        }

        if (this.content.primaryMedia.BinaryContent.MimeType.toLowerCase() === 'svg') {
            return Media.Svg;
        }

        return Media.Image;
    }
}
