/* eslint-disable no-implicit-coercion */
import { Injectable } from '@angular/core';
import { Brand, BrandFamily, BrandFamilyType } from '../../common/models/brand.model';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class BrandService {
    brandMap: Brand[] = [
        {
            brandName: 'Carrier',
            brandFamily: BrandFamily.CBP,
            themedBrand: true,
            imageUrl: 'assets/images/carrier-login-logo-sm.png'
        },
        {
            brandName: 'Bryant',
            brandFamily: BrandFamily.CBP,
            themedBrand: true,
            imageUrl: 'assets/images/bryant-login-logo-sm.png'
        },
        {
            brandName: 'Payne',
            brandFamily: BrandFamily.GENERIC,
            imageUrl: 'assets/images/payne-logo.png'
        },
        {
            brandName: 'RC',
            brandFamily: BrandFamily.CBP
        },
        {
            brandName: 'Airquest',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/airquest-logo.svg'
        },
        {
            brandName: 'Arcoaire',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/arcoaire-logo.png'
        },
        {
            brandName: 'Comfortmaker',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/comfortmaker-logo.png'
        },
        {
            brandName: 'Day & Night',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/day-and-night-logo.png'
        },
        {
            brandName: 'Midea',
            brandFamily: BrandFamily.CBP,
            imageUrl: 'assets/images/midea-logo.png'
        },
        {
            brandName: 'Midea ICP',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/midea-logo.png'
        },
        {
            brandName: 'Midea NA',
            brandFamily: BrandFamily.CMNA,
            imageUrl: 'assets/images/midea-logo.png'
        },
        {
            brandName: 'Heil',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/heil-logo.png'
        },
        {
            brandName: 'Keeprite',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/keeprite-logo.png'
        },
        {
            brandName: 'Tempstar',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/tempstar-logo.png'
        },
        {
            brandName: 'Totaline',
            brandFamily: BrandFamily.TOTALINE,
            imageUrl: 'assets/images/totaline-logo.png'
        },
        {
            brandName: 'Smartcomfort',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/smart-comfort-logo.png'
        },
        {
            brandName: 'Ecotemp',
            brandFamily: BrandFamily.ICP,
            imageUrl: 'assets/images/arcoaire-logo.png'
        },
        {
            brandName: 'Fast',
            brandFamily: BrandFamily.GENERIC,
            imageUrl: 'assets/images/fast-logo.png'
        }
    ];

    icpBrandMap: string[] = ['Airquest', 'Arcoaire', 'Comfortmaker', 'Day & Night', 'Heil', 'Keeprite', 'Tempstar'];

    getBrands(): Observable<string[]> {
        return of(this.brandMap.map((item) => item.brandName));
    }

    getBrandMap(): Observable<Brand[]> {
        return of(this.brandMap);
    }

    getBrandFamily(brandName: string): BrandFamilyType {
        const match = this.brandMap.find((brand) => brand.brandName === brandName);

        return match ? match.brandFamily : BrandFamily.GENERIC;
    }

    selectUserBrand(brandNames: Brand[]): string {
        const brands = this.removeGenericBrands(brandNames.map((brand) => brand.brandName));
        const themedBrands = this.filterThemedBrands(brands);

        // if there is only one themed brand in the list of available brands, return that one brand. otherwise default

        if (themedBrands && themedBrands.length === 1) {
            return themedBrands[0];
        }

        return 'default';
    }

    isCBPBrand(brandName: string): boolean {
        return this.isInBrandFamily(brandName, BrandFamily.CBP);
    }

    isICPBrand(brandName: string): boolean {
        return this.isInBrandFamily(brandName, BrandFamily.ICP);
    }

    isPublicCBBrand(brand: string) {
        return brand === 'Carrier' || brand === 'Bryant';
    }

    isPublicICPBrand(brandName: string): boolean {
        return this.icpBrandMap.includes(brandName);
    }

    isCMNABrand(brandName: string): boolean {
        return this.isInBrandFamily(brandName, BrandFamily.CMNA);
    }

    filterICPBrands(brandNames: string[] | undefined): string[] {
        const icpBrands: { [brand: string]: boolean } = this.icpBrandMap
            .reduce((brandMap, brand) => ({
                ...brandMap,
                [brand.toLowerCase()]: true
            }), {});

        return brandNames?.filter((brandName) => icpBrands[brandName.toLowerCase()]) || [];
    }

    removeGenericBrands(brandNames: string[]): string[] {
        const nonGenericBrands: { [brand: string]: boolean } = this.brandMap
            .filter(({ brandFamily }) => !!brandFamily && brandFamily !== BrandFamily.GENERIC)
            .reduce((brandMap, brand) => ({
                ...brandMap,
                [brand.brandName.toLowerCase()]: true
            }), {});

        return brandNames?.filter((brandName) => nonGenericBrands[brandName.toLowerCase()]);
    }

    filterThemedBrands(brands: string[]) {
        const availableThemedBrands = ['carrier', 'bryant', 'payne'];

        return brands.map((brand) => brand.toLowerCase()).filter((brand) => availableThemedBrands.includes(brand));
    }

    private isInBrandFamily(brandName: string, brandFamily: string) {
        const name = brandName.toLowerCase();
        const brand = this.brandMap.find((b) => b.brandName.toLowerCase() === name);

        return !!brand && brand.brandFamily === brandFamily;
    }
}
