import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AccountStatusService } from 'common/services/account-status.service';
import { BrandService } from 'common/services/brand.service';
import { LastLoginService } from 'common/services/last-login.service';
import { environment } from 'common/environments/environment';
import { AccountStatusModel, AccountStatusResponse, UserStatus } from 'common/models/account-status';
import { AuthStateModel } from '../auth/auth.state';
import { SetBrand } from '../branding/branding.actions';
import { SetBrandLists, SetContentBrandPreference, SetContentFilteringPreference } from '../content/content.actions';
import { ContentState } from '../content/content.state';
import {
    AcceptCoolCalcTerms,
    GetUserStatus, ReceiveLastLogin, ReceiveUserStatus,
    SendLastLogin, UpdateUserBrandPreference, UpdateUserContentFilteringPreference
} from './account-status.actions';
import { HeapService } from 'common/services/heap.service';

@State<AccountStatusModel>({
    name: 'status',
    defaults: {
        brandFamily: '',
        firstName: '',
        userStatus: '' as UserStatus,
        companyRoles: [],
        company: {
            id: '',
            name: '',
            companyCategory: {
                name: '',
                code: ''
            },
            companyType: {
                name: '',
                code: ''
            }
        },
        brands: [],
        companyTypes: [],
        isAdmin: false,
        userId: '',
        lastname: '',
        coolCalcTerms: false,
        validateAccount: false,
        email: '',
        phone: ''
    }
})
@Injectable({ providedIn: 'root' })
export class AccountStatusState {
    constructor(
        private readonly statusService: AccountStatusService,
        private readonly lastLoginService: LastLoginService,
        private readonly store: Store,
        private readonly brandService: BrandService,
        private heapService: HeapService
    ) { }

    @Selector()
    static isPublicPlusUser({ userStatus }: AccountStatusModel): boolean {
        return userStatus === UserStatus.Pending;
    }

    @Selector()
    static isActiveUser({ userStatus }: AccountStatusModel): boolean {
        return userStatus === UserStatus.Active;
    }

    @Selector()
    static getBrands({ brands }: AccountStatusModel): object {
        return brands;
    }

    @Selector()
    static getUserPrefCompanyType({ companyTypes }: AccountStatusModel): object {
        return companyTypes;
    }

    @Selector()
    static getUserId({ userId }: AccountStatusModel): string {
        return userId;
    }

    @Selector()
    static getProfileUpdateRequestStatus({ validateAccount }: AccountStatusModel): boolean {
        return validateAccount;
    }

    @Selector()
    static getProfileInfo(userStatus: AccountStatusModel): AccountStatusModel {
        return userStatus;
    }

    @Selector()
    static getUserBrandFamily({ brandFamily }: AccountStatusModel): string {
        return brandFamily;
    }

    @Action(AcceptCoolCalcTerms)
    acceptCoolCalcTerms(ctx: StateContext<AccountStatusModel>) {
        return ctx.patchState({ coolCalcTerms: true });
    }

    @Action(GetUserStatus)
    getUserStatus(ctx: StateContext<AccountStatusModel>) {
        return this.statusService.getUserStatus().subscribe((result: AccountStatusResponse) => {
            ctx.dispatch(new ReceiveUserStatus(result));
            this.heapService.addUserProperties({ brandFamily: result.brandFamily });
        });
    }

    @Action(ReceiveUserStatus)
    receiveUserStatus(ctx: StateContext<AccountStatusModel>, action: ReceiveUserStatus) {
        ctx.setState(action.userStatus);
        if (environment.features.brandFilter && environment.features.brandFilter.content && action.userStatus.companyTypes && action.userStatus.companyTypes.length) {
            ctx.dispatch(new SetContentFilteringPreference(action.userStatus.companyTypes[0].companyType));
        }
        else {
            ctx.dispatch(new SetContentFilteringPreference('Res&Com'));
        }

        if (action.userStatus.brands) {
            ctx.dispatch(new SetBrandLists(action.userStatus.brands));
        }

        ctx.dispatch(new SetBrand(this.brandService.selectUserBrand(action.userStatus.brands)));

        if (environment.features.brandFilter && environment.features.brandFilter.brand && action.userStatus.brand_user_preference) {
            ctx.dispatch(new SetContentBrandPreference(action.userStatus.brand_user_preference));
        }
    }

    @Action(UpdateUserBrandPreference)
    updateUserBrandPref(ctx: StateContext<AccountStatusModel>, action: UpdateUserBrandPreference) {
        const brandName = action.brand === 'default' ? 'All Brands' : action.brand;
        const contentFilteringPreference = this.store.selectSnapshot(ContentState.contentFilteringPreference);
        this.statusService.updateUserPref({
            brandName: brandName,
            companyTypes: contentFilteringPreference
        }).subscribe((_results) => ctx.dispatch(new SetContentBrandPreference(brandName)));
    }

    @Action(UpdateUserContentFilteringPreference)
    updateUserContentFilteringPreference(ctx: StateContext<AccountStatusModel>, action: UpdateUserContentFilteringPreference) {
        const contentFilteringPreference = action.contentFilteringPreference;
        const brandName = this.store.selectSnapshot(ContentState.contentBrand) === 'default' ? 'All Brands' : this.store.selectSnapshot(ContentState.contentBrand);
        this.statusService.updateUserPref({
            brandName: brandName,
            companyTypes: contentFilteringPreference
        }).subscribe((_results) => ctx.dispatch(new SetContentFilteringPreference(contentFilteringPreference)));
    }

    @Action(SendLastLogin)
    sendLastLogin(ctx: StateContext<AuthStateModel>) {
        this.lastLoginService.sendLastLogin().subscribe((results) => {
            ctx.dispatch(new ReceiveLastLogin(results));
        });
    }
}
