<div #documentListItem tabindex="-1" class="hvac-document-list-item-container">
    <a
        [href]="resource.url" target="_blank"
        class="hvac-document-list-item hvac-document-list-item-{{ mode }}"
        >
        <ng-template [ngIf]="resource.url">
            <div class="hvac-document-type-icon-container">
                <utc-svg
                    class="hvac-document-icon"
                    [src]="iconPath"></utc-svg>
            </div>
            <div class="hvac-document-detail-main">
                <span class="hvac-document-list-item-name">
                    {{ resource.title }}
                </span>
                <div class="hvac-document-list-item-details">
                    <span class="hvac-document-list-document-view">
                        <utc-svg
                            class="hvac-document-list-item-file-type"
                            [src]="iconPath"></utc-svg>
                        <div class="hvac-document-list-item-description">
                            <p class="hvac-document-list-item-document-type">
                                {{ resourceSubtype }}
                            </p>
                            <p class="hvac-document-list-item-printdate">
                                {{ isoDate | date:'MM/dd/yyyy' }}
                            </p>
                            <p *ngIf="resource.formNumber" class="hvac-document-list-item-document-formnumber">
                                {{ resource.formNumber }}
                            </p>
                            <p *ngIf="!resource.current" class='hvac-document-list-item-obsolete-check'>
                                {{ 'PRODUCTS.DOCUMENTS_OBSOLETE.'+environmentTranslateKey | translate}}
                            </p>

                        </div>
                    </span>
                    <div class="hvac-document-list-item-actions">
                        <utc-download-link (click)="$event.stopPropagation()" [url]="resource.url"></utc-download-link>
                        <utc-share-button (click)="$event.stopPropagation()" [url]="resource.url" [title]="resource.title"></utc-share-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </a>
</div>
