<div class="hvac-product-list-item hvac-product-list-item-{{ mode }}">
    <ng-template [ngIf]="partItem.code">
        <button type="button" (click)="choosePart()" class="hvac-product-list-item-link">
            <div class="hvac-product-list-item-visual">
                <utc-image-fallback *ngIf="partItem.fallbackImage; else plainImage"
                    [fallbackImageSrc]="partItem.fallbackImage"
                    [suppliedImageSrc]="partItem.image"
                    [suppliedImageAlt]="partItem.description"
                    classNames="hvac-product-list-item-image"
                    ></utc-image-fallback>
                <ng-template #plainImage>
                    <img [src]="partItem.image" alt="partItem.code" class="hvac-product-list-item-image" />
                </ng-template>
            </div>
            <div class="hvac-product-list-item-details">
                <h3 class="hvac-product-list-item-name">{{ resource.code }}</h3>
                <p *ngIf="partItem.description" class="hvac-product-list-item-modelfamily" [innerHTML]="partItem.description | safe:'html'"></p>
            </div>
        </button>
    </ng-template>
</div>