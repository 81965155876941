import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {
    signUpDataSubject;

    private signUpSubject = new Subject<object>();

    constructor() {
        this.signUpDataSubject = this.signUpSubject.asObservable();
    }

    scrollToSignUpForm() {
        this.signUpSubject.next({});
    }
}
