import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'degree' })
export class DegreePipe implements PipeTransform {
    transform(value: string | number): string | number {
        if (typeof value !== 'undefined' && value !== null && value !== 'na' && value !== 'none') {
            return `${value}${String.fromCharCode(176)}`;
        }

        return value;
    }
}
