import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent {
    @Input() checked: boolean = false;
    @Input() value = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() inputControl: any;
    @Input() disabled ? = false;
    @Input() iconSrc = '';
    @Input() inputId = '';
    @Input() inputName = '';
    @Input() required ? = false;
}
