import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'common/environments/environment';
import { CatalogCategory } from 'common/store/catalog/catalog.state';
import { BrowseNavigation, BrowseNavigationService } from '../../services/browse-navigation.service';

@Component({
    selector: 'hvac-browse-subcategory-tile',
    templateUrl: './subcategory-tile.component.html',
    styleUrls: ['./tile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubcategoryTileComponent {
    @Input() category!: CatalogCategory;
    public isTotaline = environment.brand === 'Totaline';

    constructor(private readonly browseNav: BrowseNavigationService,
        private route: ActivatedRoute,
        private router: Router) {}

    browseTo(catalogCategory: CatalogCategory) {
        if (catalogCategory.isPartCategory) {
            this.navigateToSubcategoryDetails(catalogCategory);
            this.browseNav.navigate({ category: catalogCategory.urn });

            return;
        }

        const [category, subcategory] = catalogCategory.urn.split('/');
        const section: BrowseNavigation = {
            category,
            subcategory,
            pushToHistory: true
        };
        this.browseNav.navigate(section);
    }

    private navigateToSubcategoryDetails(catalogCategory: CatalogCategory) {
        this.router.navigate([`${catalogCategory.urn}`], { relativeTo: this.route });
    }
}
