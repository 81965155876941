<div class="hvac-document-list" *ngIf="!(documents.length <= 0 && isHome && this.data.Content.title === 'Bulletins')">
    <div class="hvac-document-list-title-container">
        <div #documentListTitle class="hvac-document-list-title">
            <div *ngIf="titleIcon" class='hvac-document-list-title-image-background'>
                <img class='hvac-document-list-title-image' *ngIf="titleIcon" [src]="titleIcon" />
            </div>
            <div class="hvac-title-subtitle-container">
                <h3 class="hvac-document-list-title-text" *ngIf="data.Content.title" [innerHTML]="data.Content.title | richText"></h3>
                <h2 *ngIf="showSubTitle && data.Content.subTitle" class="hvac-document-list-subtitle-text" [innerHTML]="data.Content.subTitle | richText"></h2>
            </div>
        </div>
    </div>
    <utc-loading-spinner *ngIf="documentListLoading" [spinnerSize]="'small'"></utc-loading-spinner>
    <div class="hvac-document-list-message" *ngIf="documentListError">
        <p class="hvac-document-list-error-content">{{ 'DOCUMENT_LIST.ERROR' | translate }}</p>
    </div>

    <div *ngIf="!documentListLoading && !documentListError">
        <hvac-data-table
            [data]="documents"
            [dataHeaders]="columnHeaders"
            translationPrefix="DOCUMENT_LIST.COLUMN_HEADER."
            [layout]='layout'
            [filterOptions]='filterOptions'
            [languageFilter]='enableLanguageFilter'
            [status]= 'enableStatusFilter'
            (onClick)="onRowClick($event)"
            (documentQuery)="documentQuery($event)"
            [search]="isSearchable"
            [dataSort]="dataSort"
            [pageSize]="pageSize">
        </hvac-data-table>
        <div class="hvac-document-list-button-container" *ngIf="documents.length > 0 && data.Content.links && data.Content.links.linkText">
            <hvac-link [link]="data.Content.links" class="hvac-document-list-button">
                <span>{{ data.Content.links.linkText }}</span>
                <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
            </hvac-link>
        </div>
    </div>
</div>
