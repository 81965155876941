import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FileIcons } from '../../../models/fileIcons';
import { Subject } from 'rxjs';

interface ExpandableRowDataDetails {
    [key: string]: string | boolean | ExpandableRowDataDetails[];
    fileType: string;
}
interface ExpandableRowData {
    [key: string]: string | boolean | ExpandableRowDataDetails[];
    isRead: boolean;
    status: string;
    isExpandable: string;
    details: ExpandableRowDataDetails[];
}

@Component({
    selector: 'app-hvac-data-table-expandable-row',
    templateUrl: './hvac-data-table-expandable-row.component.html',
    styleUrls: ['./hvac-data-table-expandable-row.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HvacDataTableExpandableRowComponent implements OnInit {
    @Input() expandableRowData: ExpandableRowData;
    @Input() dataHeaders: string[];
    @Input() contextMenu: string[];
    @Input() selectedContextRow: boolean | Event | unknown;
    @Input() enableView: Event;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public childEvent = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public toggleMenuEvent = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() public headerRowEvent = new EventEmitter<any>();

    contextMenuActive = false;
    expandedItemsList:unknown = [];
    ngOnDestroy$ = new Subject();
    isExpanded = false;
    isRead: boolean;


    ngOnInit() {
        this.isRead = this.expandableRowData.isRead;
    }

    fileIcon(fileType: string): string {
        return FileIcons[fileType] || FileIcons.Default;
    }

    toggleContextMenu(row: ExpandableRowData) {
        this.toggleMenuEvent.emit(row);
        if (this.selectedContextRow && row !== this.selectedContextRow) {
            this.contextMenuActive = true;
            this.selectedContextRow = this.contextMenuActive ? row : '';

            return;
        }
        this.contextMenuActive = !this.contextMenuActive;
        this.selectedContextRow = this.contextMenuActive ? row : '';
    }

    getMenuActionRow(row: ExpandableRowData) {
        if (!row) {
            return false;
        }

        return row === this.selectedContextRow;
    }

    onContextClick(row: ExpandableRowData | ExpandableRowDataDetails, menuItem: string) {
        this.childEvent.emit({
            menu: menuItem,
            value: row
        });
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    onHeaderRowClick(row: ExpandableRowData) {
        this.toggleExpanded();
        this.headerRowEvent.emit(row);
        if (!this.isRead) {
            this.isRead = true;
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
