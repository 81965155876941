import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'common/window.provider';
import { SessionStorageService as SessionStorageServiceAbstract } from './sessionStorage.service.interface';

const BRANDREF_STORAGE_KEY = 'hvacBrandRef';
const BRAND_STORAGE_KEY = 'hvacBrand';

@Injectable({ providedIn: 'root' })
export class SessionStorageService implements SessionStorageServiceAbstract {
    constructor(
        @Inject(WINDOW) private readonly window: Window
    ) { }

    saveBrandref(brand: string) {
        this.window.sessionStorage.setItem(BRANDREF_STORAGE_KEY, brand);
    }

    removeBrandref() {
        this.window.sessionStorage.setItem(BRANDREF_STORAGE_KEY, '');
    }

    retrieveBrandref(): string | null {
        return this.window.sessionStorage.getItem(BRANDREF_STORAGE_KEY);
    }

    saveBrand(brand: string) {
        this.window.sessionStorage.setItem(BRAND_STORAGE_KEY, brand);
    }

    removeBrand() {
        this.window.sessionStorage.setItem(BRAND_STORAGE_KEY, '');
    }

    retrieveBrand(): string | null {
        return this.window.sessionStorage.getItem(BRAND_STORAGE_KEY);
    }

    tridionAlertIsClosed(id: string): string | null {
        return this.window.sessionStorage.getItem(`tridion_alert_${id}`);
    }

    closeTridionAlert(id: string) {
        this.window.sessionStorage.setItem(`tridion_alert_${id}`, 'true');
    }
}
