import { Component, Input } from '@angular/core';
import { EnergyEventsType } from 'src/private/app/models/event.model';

@Component({
    selector: 'hvac-event-chip',
    templateUrl: './event-chip.component.html',
    styleUrls: ['./event-chip.component.scss']
})
export class EventChipComponent {
    @Input() eventType: EnergyEventsType | '';
}
