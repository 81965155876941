import { Directive, HostBinding, Input } from '@angular/core';

export type LinkTheme = 'primary' | 'alt';

@Directive({ selector: '[hvacLink]' })
export class LinkDirective {
    @Input() hvacLink: LinkTheme = 'primary';
    @Input() hvacLinkDark: boolean | undefined;
    @Input() hvacLinkUnderline: boolean | undefined;

    @HostBinding('class.hvac-link') readonly _link = true;

    @HostBinding('class.hvac-link-primary') get isPrimary() {
        return this.hvacLink === 'primary';
    }

    @HostBinding('class.hvac-link-alt') get isAlt() {
        return this.hvacLink === 'alt';
    }

    @HostBinding('class.hvac-link-underline') get isUnderline() {
        return typeof this.hvacLinkUnderline !== 'undefined' && this.hvacLinkUnderline !== false;
    }

    @HostBinding('class.hvac-link-dark') get isDarkTheme() {
        return typeof this.hvacLinkDark !== 'undefined' && this.hvacLinkDark !== false;
    }
}
