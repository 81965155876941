<div class="hvac-product-list-item hvac-product-list-item-{{ mode }}" tabindex="-1">
    <ng-template [ngIf]="product.urn">
        <a [routerLink]="['/products', 'detail', product.urn]" class="hvac-product-list-item-link">
            <div class="hvac-product-list-item-visual">
                <utc-image-fallback *ngIf="product.fallbackImage; else plainImage"
                    [fallbackImageSrc]="product.fallbackImage"
                    [suppliedImageSrc]="product.image"
                    [suppliedImageAlt]="product.urn"
                    classNames="hvac-product-list-item-image"
                    ></utc-image-fallback>
                <ng-template #plainImage>
                    <img [src]="product.image" alt="product.urn" class="hvac-product-list-item-image" />
                </ng-template>
            </div>
            <div class="hvac-product-list-item-details">
                <h3 class="hvac-product-list-item-name">{{ product.urn }}</h3>
                <p *ngIf="product.name" class="hvac-product-list-item-modelfamily" [innerHTML]="product.name | safe:'html'"></p>
            </div>
        </a>
    </ng-template>
</div>

