<section class="hvac-highlights-section" [ngClass]="{ 'hvac-highlights-section-full-width': fullWidth }">
    <h1 class="hvac-highlights-heading-text">
        <span class="hvac-highlights-heading-title" [innerHTML]="data?.Content?.title | richText"></span>
        <span class="hvac-highlights-heading-subtitle">
            <span *ngIf="data?.Content && data?.Content?.subtitle" class="hvac-highlights-heading-subtitle-text" [innerHTML]="data?.Content?.subtitle | richText"></span>
        </span>
    </h1>
    <div class="hvac-highlights-content">
        <div class="hvac-highlights-highlight-lists">
            <ul class="hvac-highlights-list hvac-highlights-list-media">
                <li class="hvac-highlights-list-item" *ngFor="let item of mediaHighlights">
                    <hvac-media-highlight [data]="item"></hvac-media-highlight>
                </li>
                <li class="hvac-highlights-list-item" *ngFor="let item of linkOnlyHighlights">
                    <a *ngIf="item?.Content?.links.linkOperation.Key === '_blank'; else internalButtonLink"
                        [hvacButton]="'primary'"
                        class="hvac-highlights-list-item-button-link"
                        [href]="item?.Content?.links.linkExternal | decodeUriComponent"
                        rel="noopener noreferrer"
                        target="_blank"
                        [innerHTML]="item?.Content?.title | richText">
                    </a>

                    <ng-template #internalButtonLink>
                        <a class="hvac-highlights-list-item-button-link"
                            [hvacButton]="'primary'"
                            [routerLink]="[item?.Content?.links.linkExternal | decodeUriComponent]"
                            [innerHTML]="item?.Content?.title | richText">
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-highlights-content-button-container" *ngIf="buttonData">
        <hvac-link [link]="buttonData" class="hvac-highlights-content-button">
            <span>{{ linkText }}</span>
            <span class="hvac-arrow-head-container">
                <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
            </span>
        </hvac-link>
    </div>
</section>
