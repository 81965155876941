import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DownloadableFileTypes } from 'common/models/fileIcons';

@Component({
    selector: 'hvac-rich-text',
    templateUrl: './rich-text.component.html',
    styleUrls: ['./rich-text.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RichTextComponent {
    @Input() fragments: string[];
    parsedText: string;

    ngOnInit() {
        this.parsedText = this.fragments ? this.parseContent(this.fragments) : '';
    }

    parseContent(fragments: string[]) {
        const richTextArray: string[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fragments.forEach((fragment: any) => {
            if (fragment.ExternalContent && fragment.ExternalContent.Metadata && fragment.ExternalContent.Metadata.SecureUri) {
                return richTextArray.push(this.parseImage(fragment));
            }

            return richTextArray.push(this.parseLinks(fragment));
        });

        return richTextArray.join('');
    }

    parseLinks(fragment: string): string {
        let modifiedFragment = fragment;
        let startPosition = 0;
        let endPosition = 0;
        let index = -1;
        while (startPosition !== -1) {
            // find start of a href=
            startPosition = fragment.indexOf('href="', index);
            if (startPosition === -1) {
                break;
            }

            // find end of an href=
            endPosition = fragment.indexOf('"', startPosition + 6) + 1;
            const url = fragment.substring(startPosition, endPosition);
            // get file extension and add a media tag if it can be downloaded
            const fileType = url.slice(url.lastIndexOf('.') - url.length + 1, -1).toUpperCase();
            if (DownloadableFileTypes.includes(fileType)) {
                modifiedFragment = modifiedFragment.replace(url, `${url} media="${fileType}" `);
            }
            index = endPosition;
        }

        return modifiedFragment;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseImage(fragment: any): string {
        const styles = this.renderImgStyles(fragment.ExternalContent.Metadata);
        const src = fragment.ExternalContent.Metadata.SecureUri;
        const altText = fragment.Metadata.altText.replace(/[-_]/g, ' ');

        return `<img src="${src}" style="${styles}" alt="${altText} image"/>`;
    }

    renderImgStyles(metadata: {Height: string, Width: string}): string {
        const height = metadata.Height ? `${metadata.Height}px` : 'auto';
        const width = metadata.Width ? `${metadata.Width}px` : 'auto';

        return `height: ${height}; width: ${width}`;
    }
}
