/* tslint:disable:max-line-length */
import { CategoriesResponse, CategoryProductsResponse, ProductAttributeResponse, ProductDocumentsResponse, ProductDataOverviewResponse } from 'common/models/catalog';
/* tslint:enable:max-line-length */

export class FetchCatalogCategories {
    static readonly type = '[Catalog] FetchCatalogCategories';
}
export class FetchPartsCatalogCategories {
    static readonly type = '[Catalog] FetchPartsCatalogCategories';
}

export class ReceiveCatalogCategories {
    static readonly type = '[Catalog] ReceiveCatalogCategories';
    constructor(public readonly categories: CategoriesResponse) {}
}

export class ReceivePartsCatalogCategories {
    static readonly type = '[Catalog] ReceivePartsCatalogCategories';
    constructor(public readonly categories: CategoriesResponse) {}
}

export class ClearCatalogCategories {
    static readonly type = '[Catalog] ClearCatalogCategories';
}

export class FetchCatalogCategoryProducts {
    static readonly type = '[Catalog] FetchCatalogCategoryProducts';
    constructor(public readonly category: string) {}
}

export class ReceiveCatalogCategoryProducts {
    static readonly type = '[Catalog] ReceiveCatalogCategoryProducts';
    constructor(
        public readonly category: string,
        public readonly categoryProducts: CategoryProductsResponse
    ) {}
}

export class FetchCatalogProductAttributes {
    static readonly type = '[Catalog] FetchCatalogProductAttributes';
    constructor(public readonly product: string) {}
}

export class ReceiveCatalogProductAttributes {
    static readonly type = '[Catalog] ReceiveCatalogProductAttributes';
    constructor(public readonly productAttributes: ProductAttributeResponse) {}
}

export class FetchCatalogProductDataOverview {
    static readonly type = '[Catalog] FetchCatalogProductDataOverview';
    constructor(public readonly product: string) {}
}

export class ReceiveCatalogProductDataOverview {
    static readonly type = '[Catalog] ReceiveCatalogProductDataOverview';
    constructor(public readonly product: ProductDataOverviewResponse) {}
}

export class FetchCatalogProductDocuments {
    static readonly type = '[Catalog] FetchCatalogProductDocuments';
    constructor(public readonly product: string) {}
}

export class ReceiveCatalogProductDocuments {
    static readonly type = '[Catalog] ReceiveCatalogProductDocuments';
    constructor(
        public readonly productDocuments: ProductDocumentsResponse,
        public readonly productUrn: string
    ) {}
}

export class SetCurrentBrand {
    static readonly type = '[Catalog] SetCurrentBrand';
    constructor(public currentBrand: string) {}
}

