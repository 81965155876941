<footer [ngClass]="{'hvac-show-footer': showFooter}" class="hvac-footer">
    <div class="hvac-content-container">
        <div *ngIf="footerContent && footerContent.length" [ngClass]="footerContent.length > maxRows ? 'hvac-row' : 'hvac-rhythm-5'">
            <div [ngClass]="{ 'hvac-col-xs-12 hvac-col-md-6': footerContent.length > maxRows, 'hvac-column-layout-footer-content-border' : (footerContent.length % maxColumnInRow === 0 && i < footerContent.length - 2) || (footerContent.length % maxColumnInRow !== 0 && i < footerContent.length - 1), 'hvac-column-layout-footer-content-border-mobile' : (i < footerContent.length - 1) }" class="hvac-footer-content-container" *ngFor="let item of footerContent; let i = index">
                <div class="hvac-footer-item-container">
                    <div class="hvac-footer-cta-content-with-icon hvac-footer-contact-us">
                        <div class="hvac-footer-cta-content-with-icon" *ngIf="item.template === 'CT_Hero-Img'; else newsLetterSignup">
                            <utc-svg class="hvac-footer-cta-icon" [ngClass]="{ 'hvac-footer-cta-icon-backdrop-totaline': isTotaline }" [src]="item.svg || cta1Icon"></utc-svg>
                            <div class="hvac-footer-cta-content-text-and-cta">
                                <a class="hvac-footer-title-link" target="_blank" href="{{item.subtitleLink}}">
                                    <p *ngIf="item.title" class="hvac-footer-cta-content-text" [innerHTML]="item.title | richText"></p>
                                </a>
                                <p *ngIf="item.subtitle" class="hvac-footer-cta-contact-number" [innerHTML]="item.subtitle | richText"></p>
                                <div *ngIf="item.text" class="hvac-footer-login-message-container">
                                    <p class="hvac-footer-login-message">
                                        <span [innerHTML]="item.text | richText"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ng-template #newsLetterSignup>
                            <utc-svg class="hvac-footer-cta-icon" [src]="item.svg || cta2Icon"></utc-svg>
                            <div class="hvac-footer-cta-content-text-and-cta">
                                <p *ngIf="item.subtitleLink" class="hvac-footer-cta-content-text" [innerHTML]="item.title | richText"></p>
                                <button *ngIf="!item.subtitleLink && item.title" type="button" class="hvac-footer-cta-content-text" [innerHTML]="item.title | richText" (click)="signUpNewsLetter()" ></button>
                                <a *ngIf="item.subtitleLink" class="hvac-footer-cta-content-cta" [href]="item.subtitleLink"
                                    target="_blank" [innerHTML]="item.subtitleLinkText || '' | richText">
                                </a>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="hvac-footer-content hvac-footer-connect">
            <div class="hvac-footer-nav-container">
                <nav *ngIf="!isTotaline" class="hvac-footer-nav">
                    <a *ngFor="let menuLink of menuLinks" href="{{menuLink.link}}" class="hvac-footer-contact-link"
                        target="_blank">{{menuLink.title}}</a>
                </nav>
                <div class="hvac-important-footer-links">
                    <a *ngFor="let legalLink of legalLinks" href="{{legalLink.link}}" target="_blank"
                        class="hvac-footer-terms-policy-link">{{ legalLink.title}}</a>
                        <div class="ot-sdk-show-settings hvac-footer-terms-cookie-link">Cookie Preferences</div>
                </div>
                <div class="hvac-important-footer-links">
                    <span class="hvac-footer-legal-disclaimer" [innerHTML]="legalCopy | richText"></span>
                </div>
            </div>
            <div *ngIf="!isTotaline" class="hvac-footer-social-links-container">
                <a *ngFor="let socialLink of socialMenuContent" href="{{socialLink.link}}"
                    class="hvac-footer-social-link" target="_blank">
                    <utc-svg [src]="socialLink.socialAsset"></utc-svg>
                    <p class="hvac-accessible-text">{{socialLink.text}}</p>
                </a>
            </div>
        </div>
    </div>
</footer>
