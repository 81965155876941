import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WINDOW } from 'common/window.provider';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface TabLinkData {
    name: string;
    subtitle: string;
    link: string;
    target: string;
    count: number;
}

export interface TabList {
    name: string;
    list: TabLinkData[];
    theme: string;
}


export interface TabOption {
    name: string;
    value: string;
}

export interface TabObject {
    name: string;
    id: string;
    list: TabList[];
}

@Component({
    selector: 'hvac-tab-link-container',
    templateUrl: './tab-link-container.component.html',
    styleUrls: ['./tab-link-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabLinkContainerComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    activeTab = new BehaviorSubject({} as TabObject);
    activeTab$ = this.activeTab.asObservable();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentList: Observable<any[]>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shownContentList: Observable<any[]>;
    searchControl = new UntypedFormControl('');
    searchValue = new BehaviorSubject('');
    mobileTabSelectGroup = new UntypedFormGroup({ mobileTabSelectControl: new UntypedFormControl('') });
    readonly stylingForNewTabLinksPaths: string[] = ['/marketing/story-archive'];
    enableStyleForNewTabLinksFrCrntPath: boolean = false;
    selectOptions: TabOption[];
    noSearchResults = false;

    constructor(
        @Inject(WINDOW) private readonly window: Window
    ) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.contentList = of(this.data.Content.list.$values.map((section: any) => {
            if (section.Content) {
                return {
                    name: section.Content.title,
                    id: section.Content.title.toLowerCase().split(' ').join('_'),
                    list: this.formatTabSubList(section.Content.links.$values),
                    theme: this.formatTabSubListTheme(section.Content)
                };
            }

            return {};
        }));
        this.searchControl.valueChanges.subscribe((searchVal: string) => {
            this.searchValue.next(searchVal.trim().toLowerCase());
        });

        this.shownContentList = combineLatest([this.contentList, this.searchValue]).pipe(map(([contentList, searchVal]) => {
            this.noSearchResults = false;
            if (!searchVal || searchVal === '') {
                return contentList;
            }

            const contentFiltered = contentList.filter((list:TabObject) => {
                if (list.name.toLowerCase().includes(searchVal)) {
                    return true;
                }
                const allLinks: string[] = [];
                list.list.forEach((linkLists) => {
                    allLinks.push(linkLists.name);
                    // eslint-disable-next-line max-nested-callbacks
                    linkLists.list.forEach((element) => {
                        allLinks.push(element.link);
                    });
                });

                return allLinks.some((element) => element && element.toLowerCase().includes(searchVal));
            });

            if (contentFiltered && contentFiltered.length > 0) {
                return contentFiltered;
            }
            this.noSearchResults = true;

            return contentList;
        }));
        this.shownContentList.subscribe((list: TabObject[]) => {
            const options = list.map((item: TabObject) => ({
                name: item.name,
                value: item.id
            }));

            this.selectOptions = options;

            this.activeTab.next(list[0]);

            return this.mobileTabSelectGroup.controls['mobileTabSelectControl'].setValue(options[0]);
        });
        combineLatest([this.mobileTabSelectGroup.controls['mobileTabSelectControl'].valueChanges, this.shownContentList]).subscribe(([value, contentList]) => {
            if (Array.isArray(value)) {
                // eslint-disable-next-line no-param-reassign
                value = value[0];
                this.mobileTabSelectGroup.controls['mobileTabSelectControl'].setValue(value, { emitEvent: false });
            }
            const activeSection = contentList.find((section:TabObject) => section.id === value.value);
            this.activeTab.next(activeSection ? activeSection : contentList[0]);
        });
        this.enableStyleForNewTabLinksFrCrntPath = this.stylingForNewTabLinksPaths.includes(this.window.location.pathname);
    }

    selectTab(active:{name:string, id: string}) {
        return this.mobileTabSelectGroup.controls['mobileTabSelectControl'].setValue({
            name: active.name,
            value: active.id
        });
    }

    formatTabSubListTheme(content: {tags:string}) {
        if (content.tags) {
            if (Array.isArray(content.tags)) {
                content.tags.forEach((tag: string) => {
                    if (tag.startsWith('style:')) {
                        return tag.slice(6, tag.length);
                    }

                    return null;
                });
            }
            else {
                const tag = content.tags;
                if (tag.startsWith('style:')) {
                    return tag.slice(6, tag.length);
                }

                return null;
            }
        }

        return null;
    }

    formatTabSubList(input: TabList[]) {
        const finalList: TabList[] = [];
        let subList: TabList = {
            name: '',
            list: [],
            theme: ''
        };
        let firstFormat = true;
        let index = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        input.forEach((link: any) => {
            let linkSrc = null;
            let linkToAdd: TabLinkData = {} as TabLinkData;
            if (link.linkInternal) {
                index += 1;
                const internalComponent = link.linkInternal.Content;
                linkToAdd = {
                    name: internalComponent.title ? internalComponent.title : internalComponent.links.linkText,
                    subtitle: internalComponent.subTitle ? internalComponent.subTitle : '',
                    link: internalComponent.links.linkExternal || internalComponent.links.linkInternal,
                    target: internalComponent.links.linkOperation.Key,
                    count: index
                };
            }
            else {
                if (link.linkExternal) {
                    linkSrc = link.linkExternal;
                }
                else if (link.linkInternalParameter) {
                    linkSrc = link.linkInternalParameter;
                }

                linkToAdd = {
                    name: link.linkText,
                    link: linkSrc,
                    target: link.linkOperation.Key,
                    count: index + 1,
                    subtitle: ''
                };
            }
            if (firstFormat) {
                firstFormat = false;
                if (linkToAdd.link) {
                    subList.list.push(linkToAdd);
                }
                else {
                    subList.name = linkToAdd.name;
                }
            }
            else if (linkToAdd.link) {
                subList.list.push(linkToAdd);
            }
            else {
                finalList.push(subList);
                subList = {
                    name: linkToAdd.name,
                    list: [],
                    theme: ''
                };
            }
        });
        finalList.push(subList);

        return finalList;
    }
}
