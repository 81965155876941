import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'common/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, debounceTime, skip, takeUntil } from 'rxjs/operators';
import { DistLocatorQuery, DistributorLocatorData, FilterOptions, SortOptions } from '../../models/distributor-locator';
import { DistributorLocatorService, Marker } from './service/distributor-locator.service';
import { BrandService } from 'common/services/brand.service';
import { Location } from '@angular/common';

export type TabItem = 'distributor' | 'map';

export interface TabActive {
    tab: TabItem;
}
export interface Brand {
    name: string;
    category: string;
    selected: boolean;
    disabled: boolean;
}
export interface SearchParams {
    searchText: string
}
@Component({
    selector: 'hvac-distributor-locator',
    templateUrl: './distributor-locator.component.html',
    styleUrls: ['./distributor-locator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DistributorLocatorComponent implements OnDestroy, OnInit, AfterViewInit {
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    public activeTab: TabActive = { tab: 'distributor' };
    public brand = environment.brand;
    public isTotaline = this.brand === 'Totaline';
    public errorMessage: string;
    public filterOptions: FilterOptions[] = [];
    public filterView$ = new BehaviorSubject<Boolean>(false);
    public loader$ = new BehaviorSubject<Boolean>(true);
    public locationData$ = new BehaviorSubject<DistributorLocatorData[]>([]);
    public mapData: Marker[] = [];
    public searchControl: UntypedFormGroup;
    public brandForm = new UntypedFormGroup({ brandControl: new UntypedFormControl('') });
    public searchText = '';
    public selectedCompanyIdFromMap = '0';
    public selectedMarker: Marker;
    public selectionData: DistributorLocatorData | undefined;
    public sortOption: SortOptions[] = [];
    public usingCurrentLocation = false;
    public allBrands: Brand[];
    public showAll: boolean = false;
    public collapsed: boolean = false
    public brandQuery: string;
    public searchWithIn: number = 100

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private latitude: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private longitude: any;
    private ngOnDestroy$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private queryCurrentLocation$: Subject<boolean | undefined> = new Subject<any>();
    private searchKeyUpSubject$ = new BehaviorSubject<SearchParams>({} as SearchParams);
    private shadowCopy: DistributorLocatorData[];


    constructor(
        private readonly distributorLocatorService: DistributorLocatorService,
        private router: Router,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private readonly brandService: BrandService,
        private location: Location
    ) {
        this.filterOptions = this.distributorLocatorService.getFilterOptions();
        this.sortOption = this.distributorLocatorService.getSortOption();
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe((params) => {
            if (this.isTotaline && params?.brands) {
                const selectedBrands: string[] = [];
                const selectedCategories: string[] = [];
                selectedBrands.push(...params.brands.split(','));
                this.allBrands.forEach((brand) => {
                    brand.selected = selectedBrands.includes(brand.name);
                    if (brand.selected && !selectedCategories.includes(brand.category)) {
                        selectedCategories.push(brand.category);
                    }
                    brand.disabled = !selectedCategories.includes(brand.category);
                });
                const isCBPExists = this.allBrands.find((item) => item.selected && item.category.toLowerCase() === 'cbp');
                const isICPExists = this.allBrands.find((item) => item.selected && item.category.toLowerCase() === 'icp');

                if (isCBPExists && isICPExists) {
                    this.allBrands.forEach((brand) => {
                        brand.selected = false;
                        brand.disabled = false;
                    });
                }
                this.brandQuery = this.getSelectedBrands();
            }

            if (params.q === 'location') {
                this.usingCurrentLocation = true;
                this.searchText = '';

                this.fetchByLocation();
            }
            else if (params?.q) {
                this.usingCurrentLocation = false;

                this.searchText = params.q;
                this.renderer.setProperty(this.searchInput.nativeElement, 'value', params.q);
                this.renderer.selectRootElement(this.searchInput.nativeElement).focus();
                this.searchKeyUpSubject$.next({ searchText: this.searchInput.nativeElement.value.toLowerCase() });
            }

            this.cdr.detectChanges();
        });
    }

    ngOnInit() {
        this.searchControl = new UntypedFormGroup({ search: new UntypedFormControl('', [Validators.maxLength(7)]) });
        this.brandQuery = this.isTotaline ? this.getSelectedBrands() : environment.brand;

        this.searchKeyUpSubject$.pipe(
            skip(1),
            takeUntil(this.ngOnDestroy$),
            debounceTime(1000)
        ).subscribe((res: SearchParams) => {
            this.brandQuery = this.isTotaline ? this.getSelectedBrands() : environment.brand;
            this.searchText = res.searchText.toLowerCase();
            const location = this.searchText || 'location';
            this.location.go(`${this.router.url.split('?')[0]}?q=${location}${this.isTotaline ? `&brands=${this.brandQuery}` : ''}`);


            this.fetchDistributorLocationData(this.searchText);
        });

        this.queryCurrentLocation$.subscribe((currentLocation) => {
            if (currentLocation) {
                this.usingCurrentLocation = true;
                this.fetchByLocation();
            }
        });

        this.brandService.getBrands().subscribe((brands) => {
            const themedBrands = this.brandService.filterThemedBrands(brands).map((brand) => ({
                category: 'CBP',
                name: brand
            }));
            const ICPBrands = this.brandService.filterICPBrands(brands).map((brand) => ({
                category: 'ICP',
                name: brand
            }));
            const filteredBrands = [...themedBrands, ...ICPBrands];
            this.allBrands = filteredBrands.map((brand) => ({
                category: brand.category,
                name: brand.name,
                selected: false,
                disabled: false
            }));
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {},
                queryParamsHandling: 'merge'
            }
        );
    }

    public onSearch() {
        this.searchKeyUpSubject$.next({ searchText: this.searchInput.nativeElement.value.toLowerCase() });
    }

    public clearSearch() {
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
            this.searchText = '';
        }
    }

    getSelectedBrands() {
        return this.allBrands?.length ? this.allBrands.filter((res) => res.selected).map((sel) => sel.name).join(',') : '';
    }

    toggleFilter(brand: Brand) {
        brand.selected = !brand.selected;
        const isOtherCategoryChecked = this.allBrands.some((item) => item.selected && item.category === brand.category);
        this.allBrands.forEach((item) => {
            if (brand.selected && item.category !== brand.category) {
                item.selected = false;
                item.disabled = true;
            }
            if (!isOtherCategoryChecked) {
                item.disabled = false;
            }
        });
        this.searchKeyUpSubject$.next({ searchText: this.searchInput.nativeElement.value.toLowerCase() });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public mapSelection(event: { marker: { id: any } }) {
        this.selectedCompanyIdFromMap = event.marker.id.toString();
        this.selectedMarker = this.distributorLocatorService.findMarker(event.marker.id, this.shadowCopy);
        this.selectionData = this.shadowCopy.find((data) => this.selectedMarker?.id === data.companyId);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public plotToMap(locatiobData: any) {
        this.selectedCompanyIdFromMap = locatiobData.companyId.toString();
        this.selectedMarker = this.distributorLocatorService.findMarker(locatiobData.companyId, this.shadowCopy);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public filterQuery(sortOption: any) {
        this.fetchDistributorLocationData(this.searchText, sortOption.value);
    }

    public toggleFilterMenu() {
        this.filterView$.next(!this.filterView$.value);
    }

    public queryCurrentLocation() {
        this.queryCurrentLocation$.next(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public filterChanged(selectedOption: any) {
        const sorted = this.applySort(this.shadowCopy, { title: selectedOption.value }) || [];

        this.locationData$.next(sorted);
    }

    public inputKeyup(event: Event) {
        this.usingCurrentLocation = false;
        this.searchKeyUpSubject$.next({ searchText: (event.target as HTMLInputElement).value });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public navigateToMap(data: any) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`, '_blank');
    }

    public activateDistributorList() {
        this.activeTab = { tab: 'distributor' };
    }

    public activateMapView() {
        this.activeTab = { tab: 'map' };
    }

    private applySort(distributorLocations: DistributorLocatorData[], sortOption: Partial<SortOptions>) {
        let workingCopy = distributorLocations;

        if (sortOption.title === 'Distance') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            workingCopy = workingCopy.slice().sort((a: any, b: any) => Number(a['distance']) - Number(b['distance']));
        }
        if (sortOption.title === 'Alphabetical') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            workingCopy = workingCopy.slice().sort((a: any, b: any) => {
                const valueA = a.locatorName || '';
                const valueB = b.locatorName || '';

                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            });
        }

        return workingCopy;
    }

    private fetchByLocation() {
        this.loader$.next(true);
        this.locationData$.next([]);
        this.clearSearch();

        navigator.geolocation.getCurrentPosition((position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;

            this.fetchDistributorLocationData(this.searchText, this.searchWithIn);
        });
    }

    private getBrandFamily() {
        const selectedBrand = this.brandQuery.split(',')[0];

        return this.brandService.isICPBrand(selectedBrand) ? 1 : 2;
    }

    private fetchDistributorLocationData(searchText: string, searchWithIn: number = 100) {
        const brands = this.getSelectedBrands();
        if (this.isTotaline && !brands) {
            return;
        }
        const stateCodeRegex = /[a-zA-Z]+/;

        const text = searchText?.trim() || '';
        const brandFamily = this.getBrandFamily();

        const query: DistLocatorQuery = {
            query: {
                brand: this.brandQuery,
                latitude: this.latitude,
                longitude: this.longitude,
                distance: searchWithIn,
                brandFamily: brandFamily
            }
        };

        if (text) {
            if (stateCodeRegex.test(text) && text.length <= 2) {
                query.query.state = text;
            }
            else {
                query.query.zipCode = text;
            }
        }

        this.distributorLocatorService.fetchDistributorLocationData(query).pipe(
            catchError((error: HttpErrorResponse) => {
                const joinedZipCode = searchText.replace(/\s/g, '');
                this.errorMessage = error.message.replace(joinedZipCode, searchText);

                this.loader$.next(false);
                this.locationData$.next([]);
                this.shadowCopy = [];

                this.mapData = [];

                this.cdr.detectChanges();

                throw Error;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).subscribe((locatorData: any) => {
            this.shadowCopy = this.distributorLocatorService.parseData(locatorData.distributorLocations);

            const sorted = this.applySort(locatorData.distributorLocations, { title: 'Distance' }) || [];

            this.errorMessage = '';

            this.locationData$.next(sorted);
            this.loader$.next(false);

            this.mapData = this.distributorLocatorService.filterLocationData(this.locationData$.value);
        });
    }
}
