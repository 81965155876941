import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { SegmentedOption } from '../../segmented-button/segmented-button';
import { FileIcons } from 'common/models/fileIcons';

const STATUS_FILTER = [
    {
        title: 'Current',
        key: 'Current',
        checked: false
    },
    {
        title: 'Obsolete',
        key: 'Obsolete',
        checked: false
    }
];

const PAGE_FILTER = [
    {
        title: '5',
        key: '5',
        checked: false
    },
    {
        title: '10',
        key: '10',
        checked: false
    },
    {
        title: '15',
        key: '15',
        checked: false
    }
];

export interface StatusFilter {
    title: string;
    key: string;
    checked: boolean
}

export interface PageFilter {
    title: string;
    key: string;
    checked: boolean
}

export interface DefaultPage {
    title: string;
    key: string;
    checked: boolean;
}

export interface PageEvent {
    [key: string]: boolean
}

@Injectable({ providedIn: 'root' })
export class HvacDataTableService {
    constructor(
        private readonly translateService: TranslateService
    ) { }

    statusFilterData(): StatusFilter[] {
        return STATUS_FILTER;
    }

    pageFilterData(): PageFilter[] {
        return PAGE_FILTER;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    languageFilterData(globalDataHolder: any[]): Observable<SegmentedOption[]> | any {
        const languageFilterTranslations = this.translateService.instant('PRODUCTS_LITERATURE.FILTER_NAMES.LANGUAGE');
        const filterLanguage = globalDataHolder.map((name) => name['Language']).reduce((names, name) => {
            const count = names[name] || 0;
            names[name] = count + 1;

            return names;
        }, []);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const languageArray = Object.keys(filterLanguage).filter(Boolean).map((filtertitle: any) => ({
            title: languageFilterTranslations[filtertitle] && languageFilterTranslations[filtertitle].length > 0 ? languageFilterTranslations[filtertitle] : filtertitle,
            key: filtertitle,
            checked: false
        } as SegmentedOption));

        // adding a defaut selection for all languages
        languageArray.unshift({
            title: 'All',
            key: 'all',
            checked: true
        });

        return of(languageArray);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statusSelection(_status: any) {
        return {
            title: 'default',
            key: 'default',
            checked: true
        };
    }

    pageSelection(_pageSize: string) {
        return {
            title: _pageSize,
            key: _pageSize,
            checked: true
        };
    }

    isDate(input: string) {
        return Date.parse(input);
    }

    fileIcon(fileType: string): string {
        return FileIcons[fileType] || FileIcons.Default;
    }
}
