import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { HvacDataTable } from 'common/components/data-table/hvac-data-table.component';
import { ContentCardComponent } from 'common/content/components/content-card/content-card.component';
import { ContentHeaderComponent } from 'common/content/components/content-header/content-header.component';
import { IndexCardListComponent } from 'common/content/components/index-card-list/index-card-list.component';
import { RichTextModule } from 'common/pipes/rich-text/rich-text.module';
import { TextOverflowModule } from 'common/pipes/text-overflow/text-overflow.module';
import { SafePipeModule } from 'safe-pipe';
import { HvacDataTableExpandableRowComponent } from '../components/data-table/hvac-data-table-expandable-row/hvac-data-table-expandable-row.component';
import { HvacFilterListComponent } from '../components/document-filter/filter-list/hvac-filter-list.component';
import { ContentUrlModule } from '../pipes/content-url.module';
import { AccordianListComponent } from './components/accordian/accordian-list/accordian-list.component';
import { AccordianComponent } from './components/accordian/accordian.component';
import { CategoryNavigationComponent } from './components/category-navigation/category-navigation.component';
import { CheckListItemComponent } from './components/check-list/check-list-item.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { IconContentCardComponent } from './components/content-card/icon-content-card/icon-content-card.component';
import { ColorContentHeaderComponent } from './components/content-header/color-content-header.component';
import { ContentListItemComponent } from './components/content-list/content-list-item.component';
import { ContentListComponent } from './components/content-list/content-list.component';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { ContentSectionComponent } from './components/content-section/content-section.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { BulletinQuery } from './components/document-list/query-factory/bulletin-query';
import { DynamicQuery } from './components/document-list/query-factory/dynamic-query';
import { ProductLiteratureQuery } from './components/document-list/query-factory/product-literature-query';
import { QueryFactory } from './components/document-list/query-factory/query-factory';
import { WhatsNewQuery } from './components/document-list/query-factory/whats-new-query';
import { FeaturedProductsProductComponent } from './components/featured-products/featured-products-product.component';
import { FeaturedProductsComponent } from './components/featured-products/featured-products.component';
import { HighlightContentSectionComponent } from './components/highlights-content/highlight-content-section.component';
import { HighlightsContentComponent } from './components/highlights-content/highlights-content.component';
import { MediaHighlightContentComponent } from './components/highlights-content/media-highlight-content.component';
import { IndexIconCardListComponent } from './components/index-card-list/index-icon-card-list/index-icon-card-list.component';
import { MediaComponent } from './components/media/media.component';
import { NavLandingComponent } from './components/nav-landing/nav-landing.component';
import { PageHeroComponent } from './components/page-hero/page-hero.component';
import { PublicLinksItemComponent } from './components/public-links/public-links-item.component';
import { PublicLinksComponent } from './components/public-links/public-links.component';
import { RichTextContainerComponent } from './components/rich-text-container/rich-text-container.component';
import { RichTextComponent } from './components/rich-text/rich-text.component';
import { TabLinkContainerComponent } from './components/tab-link-container/tab-link-container.component';
import { ContentHeaderHostDirective } from './directives/content-host/content-header-host.directive';
import { ContentHostDirective } from './directives/content-host/content-host.directive';
import { JumpbarService } from './services/jump-bar.service';
import { VideoListComponent } from './components/video-list/video-list.component';

@NgModule({
    declarations: [
        AccordianComponent,
        AccordianListComponent,
        CategoryNavigationComponent,
        CheckListComponent,
        CheckListItemComponent,
        ColorContentHeaderComponent,
        ContentCardComponent,
        ContentHeaderComponent,
        ContentHeaderHostDirective,
        ContentHostDirective,
        ContentListComponent,
        ContentListItemComponent,
        ContentPageComponent,
        ContentSectionComponent,
        DocumentListComponent,
        FeaturedProductsComponent,
        FeaturedProductsProductComponent,
        HighlightsContentComponent,
        HighlightContentSectionComponent,
        MediaHighlightContentComponent,
        HvacDataTable,
        HvacDataTableExpandableRowComponent,
        HvacFilterListComponent,
        IconContentCardComponent,
        IndexCardListComponent,
        IndexIconCardListComponent,
        MediaComponent,
        NavLandingComponent,
        PageHeroComponent,
        PublicLinksComponent,
        PublicLinksItemComponent,
        RichTextComponent,
        RichTextContainerComponent,
        TabLinkContainerComponent,
        VideoListComponent
    ],
    imports: [
        CommonModule,
        RichTextModule,
        TextOverflowModule,
        SafePipeModule,
        TranslateModule,
        CommonComponentsModule,
        ContentUrlModule,
        RouterModule
    ],
    exports: [
        ContentHostDirective,
        ContentHeaderHostDirective,
        ContentSectionComponent,
        DocumentListComponent,
        ContentHeaderComponent,
        HvacDataTable,
        HvacDataTableExpandableRowComponent,
        HvacFilterListComponent,
        AccordianComponent
    ],
    providers: [
        JumpbarService,
        DatePipe,
        QueryFactory,
        WhatsNewQuery,
        BulletinQuery,
        DynamicQuery,
        ProductLiteratureQuery
    ]
})
export class ContentModule { }
