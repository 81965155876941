<div class="hvac-interactive-banner">
    <div class="hvac-interactive-clipped-image"></div>
    <div class="hvac-flex-container">
        <div class="hvac-challenge-and-knowledge hvac-challenge-and-knowledge-container">
            <h1 class="hvac-challenge-and-knowledge-text hvac-challenge">
                {{'INTERACTIVE_QUESTIONNAIRE.TAKE_THE_CHALLENGE'| translate}}</h1>
            <h1 class="hvac-challenge-and-knowledge-divider">{{'INTERACTIVE_QUESTIONNAIRE.DIVIDER' | translate }}</h1>
            <h1 class='hvac-challenge-and-knowledge-text hvac-knowledge'>{{'INTERACTIVE_QUESTIONNAIRE.GET_THE_KNOWLEDGE'
                | translate }}</h1>
        </div>
    </div>
    <div class="hvac-questionnaire-container">
        <h3 class="hvac-interactive-questionnaire-heading">{{ 'INTERACTIVE_QUESTIONNAIRE.2023_READY' | translate }}</h3>
        <div class="hvac-question-title">{{ randomQuestion.question }}</div>

        <div *ngIf="!answerSubmitted; else responseContainer" class="hvac-options-container">
            <button *ngFor="let option of randomQuestion.options" hvacButton="text" (click)="onRadioButtonClick(option)"
                class="hvac-answer-button">
                <div class="hvac-radio-option-item">
                    <input class="hvac-radio-selection" type="radio" name="radioOption" value="True"
                        [checked]="selectedOption === option ? true : false">
                    <label class="hvac-label">{{ option }}</label>
                </div>
            </button>
        </div>
        <ng-template #responseContainer>
            <div class="hvac-response-container">
                <p>{{ selectedOption === randomQuestion.answer ?
                    ('INTERACTIVE_QUESTIONNAIRE.CORRECT_ANSWER_DISPLAY_TEXT' | translate) :
                    'INTERACTIVE_QUESTIONNAIRE.WRONG_ANSWER_DISPLAY_TEXT' | translate }}</p>
            </div>
        </ng-template>
        <button *ngIf="!answerSubmitted; else learningButton" hvacButton="text" (click)="submitAction()"
            class="hvac-option-submit">{{ 'INTERACTIVE_QUESTIONNAIRE.SUBMIT_BUTTON_TEXT' | translate }}</button>
        <ng-template #learningButton>
            <a hvacButton="secondary" href="https://bcove.video/3jieD0i" target="_blank" class="hvac-learning-button">{{
                'INTERACTIVE_QUESTIONNAIRE.LEARN_MORE' | translate }}</a>
        </ng-template>
    </div>
</div>
