import { Component, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { AuthState } from 'common/store/auth/auth.state';
import { FrameLoaded } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Component({
    selector: 'hvac-primer-frame',
    templateUrl: './primer-frame.component.html',
    styleUrls: ['./primer-frame.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PrimerFrameComponent {
    @Select(AuthState.isLoggedIn) isLoggedIn$!: Observable<boolean>;
    @Select(UiState.frameLoaded) primerIsLoaded$!: Observable<boolean>;

    showIframe$: Observable<boolean>;
    primerFrameUrl: string = environment.primerFrameUrl || '';

    constructor(private readonly store: Store) { }

    ngOnInit() {
        this.showIframe$ = combineLatest([this.isLoggedIn$, this.primerIsLoaded$]).pipe(
            map(([isLoggedIn, primerIsLoaded]) => isLoggedIn && !primerIsLoaded),
            first((showFrame) => showFrame),
            tap((show) => (show ? this.store.dispatch(new FrameLoaded()) : null))
        );
    }
}
