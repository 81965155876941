import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

@Directive({ selector: '[hvacClickOutside]' })
export class ClickOutsideDirective {
    @Output() hvacClickOutside = new EventEmitter<HTMLElement>();

    private eventListener: (event: Event) => void;

    private events: string[] = ['click', 'touchstart', 'focusin'];

    constructor(
        private readonly elementRef: ElementRef
    ) {}

    @HostListener('click', ['$event.target'])
    onClickInside() {
        this.removeEventListeners();

        this.setUpClickOutside(this.elementRef.nativeElement);
    }

    private setUpClickOutside(targetElement: HTMLElement): void {
        this.eventListener = (event: Event) => {
            const clickedInside = this.elementRef.nativeElement.contains(event.target);

            if (!clickedInside) {
                this.hvacClickOutside.emit(targetElement);

                this.removeEventListeners();
            }
        };

        this.events.forEach((eventName: string) => {
            document.addEventListener(eventName, this.eventListener);
        });
    }

    private removeEventListeners() {
        this.events.forEach((eventName: string) => {
            document.removeEventListener(eventName, this.eventListener);
        });
    }
}
