import { Injectable } from '@angular/core';
import { QueryInterface, QueryBase } from './query-base';
import { environment } from 'common/environments/environment';
import { DocumentQuery, DocumentField, DocumentFieldName } from 'common/models/document';
import { DataTableLayout } from 'common/models/data-table';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class WhatsNewQuery extends QueryBase implements QueryInterface {
    constructor(private readonly datepipe: DatePipe) {
        super();
    }

    generateQuery(data: Date): DocumentQuery {
        // get current date and the date X days ago
        const date: Date = new Date();
        let startDate: Date | number = new Date();
        const age = (environment.documents && environment.documents.whatsNewAge)
            ? environment.documents.whatsNewAge
            : this.getTagData(data, 'age');
        startDate = startDate.setDate(date.getDate() - age);

        return {
            count: Number(this.getTagData(data, 'max')),
            sampleMail: true,
            status: ['Current'],
            publishedDate: this.datepipe.transform(startDate, 'MM/dd/yyyy') as string,
            releaseToDate: this.datepipe.transform(date, 'MM/dd/yyyy') as string,
            isParts: environment.brand === 'Totaline'
        } as DocumentQuery;
    }

    fields(_data: string): DocumentField[] {
        const fieldsToDisplay = [
            DocumentFieldName.DOCUMENT_TITLE,
            DocumentFieldName.PRINT_DATE,
            DocumentFieldName.SUB_TYPE
        ];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.fieldFilters.filter((fieldFilter: any) => fieldsToDisplay.includes(fieldFilter.fieldName));
    }

    layout(): DataTableLayout {
        return DataTableLayout.STACKED;
    }
}
