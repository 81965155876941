import { Injectable } from '@angular/core';
import { Navigate, RouterNavigation, RouterState } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ErrorService } from 'common/content/services/error.service';
import { environment } from 'common/environments/environment';
import { MenuContentEntity, UIContentResponse } from 'common/models';
import { LocalStorageService } from 'common/services/localStorage/localStorage.service';
import { ReceiveUIContent } from 'common/store/content/content.actions';
import { createTitle, searchRecentActivityTitle } from 'common/utils/title-util';
import { NavigationContentService, NavMenu } from '../../services/navigation-content.service';
import { StoreRecentActivity, ClearCurrentPage } from './navigation.actions';

export interface NavigationStateModel {
    currentPage: NavMenu;
    navigation: NavMenu;
    legacyMap: { [key: string]: string };
}


const UNSTORED_URLS = ['login',
    'logout',
    'login-error',
    'callback',
    'secure-callback',
    'callback',
    'authenticating',
    'create-account-information',
    'create-account-email-validate',
    'create-account-company-lookup',
    'error',
    'sign-out'];

@State<NavigationStateModel>({
    name: 'navigation',
    defaults: {
        currentPage: {
            title: '',
            key: '',
            url: '/',
            children: [],
            path: [''],
            tag: ''
        },
        navigation: {
            title: '',
            key: '',
            url: '/',
            children: [],
            path: [''],
            tag: ''
        },
        legacyMap: {}
    }
})
@Injectable({ providedIn: 'root' })
export class NavigationState {
    constructor(
        private readonly errorService: ErrorService,
        private readonly navContent: NavigationContentService,
        private readonly store: Store,
        private readonly localStorageService: LocalStorageService
    ) { }

    @Selector()
    static navigation({ navigation }: NavigationStateModel): NavMenu {
        return navigation;
    }

    @Selector()
    static currentPage({ currentPage }: NavigationStateModel): NavMenu {
        return currentPage;
    }

    @Selector()
    static legacyMap({ legacyMap }: NavigationStateModel): object {
        return legacyMap;
    }

    @Action(ReceiveUIContent)
    receiveUiContent(ctx: StateContext<NavigationStateModel>, action: ReceiveUIContent) {
        const menuEntities = this.findMenuEntities(action.uiContent);
        const legacyMapComponent = this.findLegacyMapComponent(action.uiContent);
        const navMenu = this.navContent.buildNavigation(menuEntities);
        const legacyMap = this.navContent.buildLegacyRouteMap(legacyMapComponent);
        ctx.patchState({
            navigation: navMenu,
            legacyMap: legacyMap
        });

        let url = this.store.selectSnapshot(RouterState.url);
        if (url) {
            if (legacyMap && legacyMap[decodeURI(url)]) {
                url = legacyMap[decodeURI(url)];
                this.store.dispatch(new Navigate([url]));
            }
            const currentPage = this.navContent.findNavMenuByPath(url, ctx.getState().navigation);

            if (!currentPage && !this.errorService.isTridion(url) && !this.errorService.isAngularPage(url)) {
                this.errorService.errorStatus$.next(404);
            }

            ctx.patchState({ currentPage });
        }
    }

    @Action(RouterNavigation)
    navigate(ctx: StateContext<NavigationStateModel>, action: RouterNavigation) {
        const { url } = action.routerState;
        const { legacyMap } = ctx.getState();
        if (Object.keys(legacyMap).length && Object.keys(legacyMap).length > 0 && legacyMap[decodeURI(url)]) {
            this.store.dispatch(new Navigate([legacyMap[decodeURI(url)]]));
        }
        else if (!UNSTORED_URLS.some((unstoredUrl) => url.includes(unstoredUrl))) {
            this.store.dispatch(new StoreRecentActivity(url));
        }
    }

    @Action(ClearCurrentPage)
    clearcurrentpage(ctx: StateContext<NavigationStateModel>) {
        ctx.patchState({
            // we are updating current state
            // right now we are currently clearing the current page, we need to set a default value
            currentPage: {
                title: '',
                key: '',
                url: '/',
                children: [],
                path: [''],
                tag: ''
            }
        });
    }

    @Action(StoreRecentActivity)
    storeRecentActivity(ctx: StateContext<NavigationStateModel>, action: {url:string, title:string}) {
        const navpath: string = action.url;
        const currentPage = this.navContent.findNavMenuByPath(navpath, ctx.getState().navigation);
        if (currentPage) {
            this.localStorageService.storeRecentActivity(
                {
                    timeStamp: '',
                    pageTitle: currentPage.title,
                    pageUrl: currentPage.url,
                    navMenu: currentPage
                }
            );
        }
        else {
            this.localStorageService.storeRecentActivity(
                {
                    timeStamp: '',
                    pageTitle: action.title ? action.title : this.setRecentActivityTitle(navpath),
                    pageUrl: decodeURI(navpath),
                    navMenu: null
                }
            );
        }
    }

    private findLegacyMapComponent(uiContent: UIContentResponse) {
        if (!uiContent.Regions) {
            return [];
        }

        // Seems to be a typo in Tridion templates for one of the publications and
        // the "Header" region may be "Headerd" so we need to be able to match both.
        const headerContent = uiContent.Regions.find((region) => region.Name === 'Header' || region.Name === 'Headerd');

        if (!headerContent) {
            return [];
        }

        const menuRegion = headerContent.Regions?.find((region) => region.Name === 'Main');

        if (!menuRegion) {
            return [];
        }

        return menuRegion.Entities;
    }

    private findMenuEntities(uiContent: UIContentResponse): MenuContentEntity[] {
        if (!uiContent.Regions) {
            return [];
        }

        // Seems to be a typo in Tridion templates for one of the publications and
        // the "Header" region may be "Headerd" so we need to be able to match both.
        const headerContent = uiContent.Regions.find((region) => region.Name === 'Header' || region.Name === 'Headerd');

        if (!headerContent) {
            return [];
        }

        let searchKey = 'Menu';

        if (environment.brand !== 'Carrier' && environment.brand !== 'Bryant' && environment.brand !== 'private') {
            searchKey = 'Nav-01';
        }

        const menuRegion = headerContent.Regions?.find((region: {Name:string}) => region.Name === searchKey);

        if (!menuRegion) {
            return [];
        }

        return menuRegion.Entities as MenuContentEntity[];
    }

    private setRecentActivityTitle(url: string) {
        if (url.includes('search')) {
            return searchRecentActivityTitle(url);
        }
        else if (url.includes('/products/detail')) {
            return `Product Detail: ${createTitle(url)}`;
        }

        return createTitle(url);
    }
}
