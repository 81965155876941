<div class="hvac-select-menu"
    *ngIf="( selectedOption$ | async ) let selectedOption"
    [ngClass]="{
        'hvac-select-menu-disabled': disabled,
        'hvac-select-menu-expanded': active,
        'hvac-select-menu-has-icon': icon,
        'hvac-select-menu-small': size === 'small'
    }">
    <button
        [disabled]="disabled"
        type="button"
        [disabled]="disabled"
        class="hvac-select-menu-trigger"
        (click)="toggleOptions()">
        <utc-svg *ngIf="icon" class="hvac-select-menu-trigger-icon" [src]="icon"></utc-svg>
        <span class="hvac-select-menu-trigger-label">{{ selectedOption.name || placeholder }}</span>
        <utc-svg class="hvac-select-menu-trigger-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
        <div class="hvac-select-menu-spinner" *ngIf="loading$ | async">
            <utc-loading-spinner [spinnerSize]="'small'"></utc-loading-spinner>
        </div>
    </button>
    <ol class="hvac-select-menu-options">
        <li class="hvac-select-menu-item"
            *ngFor="let option of options$ | async">
            <button class="hvac-select-menu-item-trigger"
                [disabled]="option.disabled"
                type="button"
                (click)="setSelected(option)"
                [ngClass]="{ 'hvac-select-menu-item-selected': option.value === selectedOption.value }">
                {{ option.name }}
                <utc-svg class="hvac-select-menu-selected-icon" src="assets/icons/check.svg"></utc-svg>
            </button>
        </li>
    </ol>
</div>
