<span
    [popper]="tooltipContent"
    [popperTrigger]="isDesktopDevice && !disabled ? 'hover' : 'none'"
    popperPlacement="top"
    [popperHideOnClickOutside]="false"
    popperApplyClass="hvac-tooltip"
    popperApplyArrowClass="hvac-tooltip-arrow">
    <ng-content select="utc-tooltip-anchor-content"></ng-content>
</span>

<popper-content #tooltipContent>
    <ng-content #tooltipContent select="utc-tooltip-content"></ng-content>
</popper-content>
