import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hvac-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent {
    @Input() variant: 'zebra' | '' = '';

    @HostBinding('class.hvac-list-zebra') get isZebra() {
        return this.variant === 'zebra';
    }
}
