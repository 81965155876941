import { Component, EventEmitter, Input, Output } from '@angular/core';
export type ColourTheme = 'light' | 'default';

@Component({
    selector: 'hvac-accordion-new',
    templateUrl: './accordion-new.component.html',
    styleUrls: ['./accordion-new.component.scss']
})
export class AccordionNewComponent {
    @Input() id?: string;
    @Input() open: boolean;
    @Input() disabled: boolean;
    @Input() theme: ColourTheme = 'default';
    @Input() customiseHeaderColors?: boolean = false;
    @Output() readonly toggle = new EventEmitter<string | undefined>();

    onClick() {
        this.toggle.emit(this.id);
    }
}
