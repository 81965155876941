export type BannerTheme = 'info' | 'error' | 'success' | 'warning';
export type HeaderDropdown = 'help' | 'bookmarks' | 'userDetails' | 'contentPeerList' | 'brandFilter' | 'brandType' | 'none';

export interface Bannerinfo {
    visible: boolean;
    theme: BannerTheme;
    message: string;
    name?: string;
    closeButton?: boolean;
    timeOut?: number;
    autoClose?: boolean;
}

export interface BusinessLocator {
    businessLocatorVisible: boolean;
    businessLocatorType: 'ZipCode' | 'CompanyCode';
}

export interface UiStateModel {
    businessLocator: BusinessLocator;
    banner: Bannerinfo;
    mobileMenuVisible: boolean;
    tabletMenuVisible: boolean;
    headerDropdown: HeaderDropdown;
    frameLoaded: boolean;
    bookMark: boolean;
    dealerHeaderDropdown: boolean;
    newsLetterSignUp: boolean;
    footerSignUp: boolean;
}

export class MobileMenuVisible {
    static readonly type = '[UI] MobileMenuVisible';
    constructor(public readonly visible: boolean) { }
}

export class TabletMenuVisible {
    static readonly type = '[UI] TabletMenuVisible';
    constructor(public readonly visible: boolean) { }
}

export class ShowBusinessLocator {
    static readonly type = '[UI] ShowBusinessLocator';
    constructor(public readonly type: 'ZipCode' | 'CompanyCode') { }
}

export class HideBusinessLocator {
    static readonly type = '[UI] HideBusinessLocator';
}

export class ToggleHeaderDropdown {
    static readonly type = '[UI] ToggleHeaderDropdown';
    constructor(public readonly dropdown: HeaderDropdown) { }
}

export class FrameLoaded {
    static readonly type = '[UI] FrameLoaded';
}

export class OpenRecentActivity {
    static readonly type = '[RecentActivity] OpenRecentActivity';
}

export class CloseRecentActivity {
    static readonly type = '[RecentActivity] CloseRecentActivity';
}

export class ToggleBookmarkSlide {
    static readonly type = '[UI] ToggleBookmarkSlide';
    constructor(public readonly visible: boolean) { }
}

export class BookmarksSlideVisible {
    static readonly type = '[UI] BookmarksSlideVisible';

    constructor(public visible: boolean) { }
}

export class ToggleDealerHeaderDropdown {
    static readonly type = '[UI] ToggleDealerHeaderDropdown';
    constructor(public dropdown: boolean) { }
}

export class OpenNewsLetterSignUp {
    static readonly type = '[UI] OpenNewsLetterSignUp';
    constructor(public ui: boolean) { }
}

export class DisableFooterSignUp {
    static readonly type = '[UI] HideFooterSignUp';
    constructor(public ui: boolean) { }
}


